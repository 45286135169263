import React, {useState, useEffect, useContext} from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import lang from "../Styles/images/icons/Header/Language.svg";
import styles from './gp.module.css'
import { SetTabHeaders } from "../Store/actions/registration";

import axios from "axios";
import config from '../IP_Configrations.json';
import { toast } from "react-toastify";

import DataServices from "../Services/DataServices";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import API from "../Services/Axios";

const host = config.static;

const GeneralPreferences = () => {
    const languages = [
        { value: 'En', label: 'English' },
        { value: 'Ar', label: 'Arabic' },
    ]

    const dateformats = [
        { value: 'dd-mm-yyyy', label: 'DD/MM/YYYY' },
        { value: 'yyyy-mm-dd', label: 'YYYY/MM/DD' },
        { value: 'mm-dd-yyyy', label: 'MM/DD/YYYY' },
    ]

    const timeformats = [
        { value: '12h', label: '12h (AM/PM)' },
        { value: '24h', label: '24h' },
    ]

    const timezones = [
        { value: '-1', label: 'GMT -1' },
        { value: '-2', label: 'GMT -2' },
        { value: '-3', label: 'GMT -3' },
        { value: '0', label: 'GMT' },
        { value: '+1', label: 'GMT +1' },
        { value: '+2', label: 'GMT +2' },
        { value: '+3', label: 'GMT +3' },
        
    ]

    const [loadingPage, setLoadingPage] = useState(false)
    const [language, setLanguage] = useState(languages[0])
    const [dateFormat, setDateFormat] = useState(dateformats[0])
    const [timeFormat, setTimeFormat] = useState(timeformats[0])
    const [timezone, setTimezone] = useState(timezones[4])
    const [isChanged, setIsChanged] = useState(false)

    const customStyles = {
        control: (base) => ({
            ...base,
            height: 56,
            minHeight: 56,
            paddingLeft: 40,
            paddingTop: 6
        }),
    };
    const customStyles2 = {
        control: (base) => ({
            ...base,
            height: 56,
            minHeight: 56,
            paddingTop: 6
        }),
    };

    
    const handleChangeLang = (l) => {
        setIsChanged(true)
        setLanguage(l)
    }

    
    const handleChangeDF = (l) => {
        setIsChanged(true)
        setDateFormat(l)
    }

    
    const handleChangeTF = (l) => {
        setIsChanged(true)
        setTimeFormat(l)
    }

    
    const handleChangeTZ = (l) => {
        setIsChanged(true)
        setTimezone(l)
    }
    const values = {
        "language": language.value,
        "timeZone": timezone.value,
        "dateFormat": dateFormat.value,
        "timeFormat": timeFormat.value,
    }

    const handleSubmit = () => {
        API.post("UpdateAccountPreferences" ,{ companyId: parseInt(companyId), ...values }).then(res =>{
            toast.success("Account preferences updated successfully");
        }).catch(err =>{
            toast.error("Error updating account preferences");
        })
    }
    
    
    const dispatch = useDispatch()
	const currentStep = useSelector((state) => state.registration.currentStep);

    const companyId = localStorage.getItem("CompanyId");
    const userToken = localStorage.getItem('userToken')
    const [preferences, setPreferences] = useState(false);


	const animateInputs = () => {
        const inputs = document.querySelectorAll("input");
        inputs.forEach((input) => {
            input.addEventListener(
                "focusin",
                function (e) {
                    input.classList.add("focused");
                },
                false
            );
            input.addEventListener("focusout", function (e) {
                if (e.target.value == "") {
                    input.classList.remove("focused");
                }
            });
        });
    };

    useEffect(() => {
        animateInputs();
        dispatch(SetTabHeaders("Account preferences"), currentStep);
        
    }, []);

    useEffect(()=>{
        console.log('lang', language)
    },[language])

    useEffect(() => {
        const getCommunicationOptions = async () => {
            setLoadingPage(true)
            await axios.get(`${host}GetAccountPreferencesForEdit?companyId=${companyId}`,
                {
                    headers: {
                        Authorization: userToken,
                    },
                }
            ).then((res) => {
                console.log(res)
                const AccountData = res.data
                if (AccountData.language) {
                    const langObj = {En: 'English', Ar: 'Arabic'} 
                    setLanguage({
                        value: AccountData.language,
                        label: langObj[AccountData.language]
                    })
                }
                if (AccountData.dateFormat) {
                    setDateFormat({
                        value: AccountData.dateFormat,
                        label: AccountData.dateFormat.replaceAll('-','/').toUpperCase()
                    })
                }
                if (AccountData.timeFormat) {
                    const tzObj = {'12h': '12h (AM/PM)', '24h': '24h'} 
                    setTimeFormat({
                        value: AccountData.timeFormat,
                        label: tzObj[AccountData.timeFormat]
                    })
                }
                if (AccountData.timeZone) {
                    if (AccountData.timeZone == '0'){
                        AccountData.timeZone = ''
                    }
                    setTimezone({
                        value: AccountData.timeZone,
                        label: `GMT ${AccountData.timeZone}`
                    })
                }
                
            
                setLoadingPage(false)
            }).catch((err) => console.log(err));
        }

        getCommunicationOptions()
    }, [])

    return (
        loadingPage ? <Loading /> : 
        <div className={`company_info ${styles.container}`}>
            <div className={`proForm ${styles.form}`}>
                <div className={`sideByside ${styles.row}`}>
                    <div className={`form-group sm ${styles.form_group}`}>
                        <Select 
                            className="select_react"
                            styles={customStyles}
                            options={languages}
                            name="Language"
                            placeholder=""
                            value={language}
                            onChange={(op) => handleChangeLang(op)}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                        <div className={styles.label}>
                            Language
                        </div>
                        <img src={lang} className={styles.licon} alt="language" />
                    </div>
                    <div className={`form-group sm ${styles.form_group}`}>
                        <Select 
                            className="select_react"
                            styles={customStyles}
                            options={timezones}
                            name="timezone"
                            placeholder=""
                            value={timezone}
                            onChange={(op) => handleChangeTZ(op)}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                        <div className={styles.label}>
                            Time zone
                        </div>
                        <img src={lang} className={styles.licon} alt="time zone" />
                        {/* <div className={`help-text ${styles.cur_time}`}>
                            Current time: 
                        </div> */}
                    
                    </div>
                </div>
                
                <div className={`sideByside ${styles.row}`}>
                    <div className={`form-group sm ${styles.form_group}`}>
                        <Select 
                            className="select_react"
                            styles={customStyles2}
                            options={dateformats}
                            name="dateFormat"
                            placeholder=""
                            value={dateFormat}
                            onChange={(op) => handleChangeDF(op)}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                        <div className={styles.label2}>
                            Date format
                        </div>
                    </div>
                    <div className={`form-group sm ${styles.form_group}`}>
                        <Select 
                            className="select_react"
                            styles={customStyles2}
                            options={timeformats}
                            name="timeFormat"
                            placeholder=""
                            value={timeFormat}
                            onChange={(op) => handleChangeTF(op)}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                        <div className={styles.label2}>
                            Time format
                        </div>
                    </div>
                </div>
                
            </div>

            <button
                className="btn primaryBtn largeBtn "
                onClick={handleSubmit}
                type="submit"
                disabled={!isChanged}
            >
                Save changes
            </button>
        </div>
    )

}

export default GeneralPreferences