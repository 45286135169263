import { toast } from "react-toastify";
//import Config from "./Config";

import config from "../IP_Configrations.json";

const host = config.static;

export default class Api {
  static headers() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      dataType: "json",
    };
  }

  static get(route, params) {
    return this.xhr(route, params === null ? null : params, "GET");
  }

  static post(route, params) {
    return this.xhr(route, params, "POST");
  }

  static postCustom(route, params) {
    return this.xhrCustom(route, params, "POST");
  }

  static UploadPhoto(route, params) {
    return this.xhrUploadPhoto(route, params, "POST");
  }

  static xhrCustom(route, params, verb) {
    const url = `${host}${route}`;
    let json = null;

    let options = Object.assign({ method: verb }, params ? { body: JSON.stringify(params) } : null);

    options.headers = Api.headers();

    return fetch(url, options)
      .then((resp) => {
        if (resp.status === 200) {
          json = resp.json();
          if (json === undefined) return null;
          json.status = 200;
          return json;
        } else if (resp.status === 400) {
          json = resp.json();
          if (json === undefined) return null;

          json.status = 400;
          return json;
        } else if (resp.status === 401) {
          localStorage.removeItem("userToken");
          json = "";
          // window.location.reload();
          return json;
        } else if (resp.status === 500) {
          json = null;
          // toast.error("Sorry. something went wrong .A team of highly trained developers has been dispatched to deal with this situation!");
          return json;
        } else if (resp.status === 409) {
          return resp;
        } else if (resp.status === 404) {
          return resp;
        }
        return json.then((err) => {
          throw err;
        });
      })
      .then((json) => (json.result ? json.result : json))
      .catch((reason) => {
        return null;
      });
  }

  static xhr(route, params, verb) {
    const url = `${host}${route}`;
    let json = null;

    let options = Object.assign({ method: verb }, params ? { body: JSON.stringify(params) } : null);

    options.headers = Api.headers();
    if (localStorage.getItem("userToken")) {
      options.headers.Authorization = localStorage.getItem("userToken");
    }
    return fetch(url, options)
      .then((resp) => {
        if (resp.status === 200) {
          json = resp.json();
          if (json === undefined) return null;
          return json;
        } else if (resp.status === 401) {
          localStorage.removeItem("userToken");
          json = "";
          // window.location.reload();
          return json;
        } else if (resp.status === 500) {
          json = 500;
          //   toast.error(
          //     "Sorry. something went wrong .A team of highly trained developers has been dispatched to deal with this situation!"
          //   );

          return json;
        } else if (resp.status === 409) {
          return resp;
        } else if (resp.status === 404) {
          return resp;
        }
        return json.then((err) => {
          throw err;
        });
      })
      .then((json) => (json.result ? json.result : json))
      .catch((reason) => {
        return null;
      });
  }

  static xhrUploadPhoto(route, params, verb) {
    const url = `${host}${route}`;
    let json = null;

    let options = Object.assign({ method: verb }, params ? { body: params } : null);

    options.headers = {};
    if (localStorage.getItem("userToken")) {
      options.headers.Authorization = localStorage.getItem("userToken");
    }
    // options.headers["Content-Type"] = "multipart/form-data;";
    options.headers["companyId"] = localStorage.getItem("CompanyId");

    return fetch(url, options)
      .then((resp) => {
        if (resp.status === 200) {
          json = resp.json();
          if (json === undefined) return null;
          return json;
        } else if (resp.status === 401) {
          localStorage.removeItem("userToken");
          json = "";
          // window.location.reload();
          return json;
        } else if (resp.status === 500) {
          json = null;
          //   toast.error(
          //     "Sorry. something went wrong .A team of highly trained developers has been dispatched to deal with this situation!"
          //   );

          return json;
        } else if (resp.status === 409) {
          return resp;
        } else if (resp.status === 404) {
          return resp;
        }
        return json.then((err) => {
          throw err;
        });
      })
      .then((json) => (json.result ? json.result : json))
      .catch((reason) => {
        return null;
      });
  }

  static GetPayload() {
    var payload = [];

    return JSON.parse(payload);
  }

  static IsAllow(code) {
    let userPermissions = [];
    let isCompany = true;
    if (localStorage.getItem("permissions")) {
      // let perms = JSON.parse(CryptoJS.enc.Base64.parse(localStorage.getItem("permissions")).toString(CryptoJS.enc.Utf8));
      userPermissions = [];
    }

    if (isCompany === false) {
      let isAllowed = userPermissions.indexOf(code);
      if (isAllowed > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  static postFile(route, params, header) {
    let json = "";

    const url = `${host}${route}`;
    let headers = {};
    headers.Authorization = localStorage.getItem("userToken");
    if (header) {
      headers.docType = header.docType;
      headers.companyId = header.companyId;
      headers.documentTypeId = header.documentTypeId;
    }
    return fetch(url, {
      method: "POST",
      headers: {
        ...headers,
      },
      body: params,
    })
      .then((resp) => {
        if (resp.status === 200) {
          json = resp.json();
          if (json === undefined) return null;
          return json;
        } else if (resp.status === 500) {
          json = 500;
          //   toast.error(
          //     "Sorry. something went wrong .A team of highly trained developers has been dispatched to deal with this situation!"
          //   );

          return json;
        } else if (resp.status === 401) {
          localStorage.removeItem("userToken");
          json = "";
          // window.location.reload();
          return json;
        } else if (resp.status === 409) {
          return resp;
        } else if (resp.status === 400) {
          return 400;
        }

        return json.then((err) => {
          throw err;
        });
      })
      .then((json) => (json.result ? json.result : json))
      .catch((reason) => {
        return null;
        // response is not a valid json string
      });
  }

  static Login(route, params) {
    const url = `${host}${route}`;

    let json = null;
    let options = Object.assign(
      {
        method: "Post",
      },
      params
        ? {
            body: params,
          }
        : null
    );

    options.headers = {
      Accept: "*/*",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return fetch(url, options)
      .then((resp) => {
        if (resp.status === 200) {
          json = resp != null ? resp.json() : "";
          return json;
        } else if (resp.status === 400) {
          return resp;
        } else if (resp.status === 401) {
          localStorage.removeItem("userToken");

          window.location.reload();
        }
        return json.then((err) => {
          throw err;
        });
      })
      .then((json) => (json.result ? json.result : json));
  }

  static IsAuthorized() {
    let authorize = false;
    if (localStorage.getItem("userToken")) {
      authorize = true;
    }
    return authorize;
  }
}
