import React, {useState, useEffect, useContext} from "react";
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";

import { SetTabHeaders } from "../Store/actions/registration";
import ProfileCompletness from "../Components/ProfileCompletness/ProfileCompletness";
import up from "../Styles/images/icons/chevron-up-20.svg";
import down from "../Styles/images/icons/chevron-down-20.svg";
import styles from './sec.module.css'

const Item = ({viewed, setViewed, text, subText}) => {
    const [showContent, setShowContent] = useState(false)
    const remove = () => {
        setViewed(viewed.filter((i) => i !== text))
        setShowContent(false)
    }
    const add = () => {
        setViewed((old) => [...old, text])
        setShowContent(true)
    }

    const dispatch = useDispatch()
	const currentStep = useSelector((state) => state.registration.currentStep);

	useEffect(() => {
		dispatch(SetTabHeaders("Security", "", currentStep));
	})

    return (
        <>
            <div className={styles.item}>
                <span>{text}</span>
                <div className={styles.item_left}>
                    <span className={styles.sub}>{text}</span>
                    {viewed.includes(text) ? 
                        <img src={up} className="img-fluid icon" alt="compress" onClick={remove}/>
                        :
                        <img src={down} className="img-fluid icon" alt="expand" onClick={add}/>
                    }
                    
                </div>
            </div>
            <div className={styles.item_content}>
                {showContent && 
                    <div>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </div>

                }
            </div>
        </>
        
    )
}
const items = ['2 step verification', 'Manage your logins', 'Option 3', 'Option 4']
const SecuritySettings = () => {
	const [viewedSections, setViewedSections] = useState([])
	const handleSubmit = () => {

	}
	
    return (
        <div className={`company_info ${styles.container}`}>
            <div className={styles.list}>
                {items.map((i, idx)=> (
                    <Item 
                        viewed={viewedSections} 
                        setViewed={setViewedSections} 
                        text={i} 
                        key={`${i} - ${idx}`}
                    />
                ))}
            </div>

        </div>
    )

}

export default SecuritySettings