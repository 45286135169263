import React from "react";

import SteperCheckmark from "../../Styles/images/icons/steper_h_Checkmark.svg";

const RegistrationStatus = ({ dataDashboard }) => {
  return dataDashboard?.comments?.length > 0 ? (
    <div className="registeration_status">
      <h4 className="h4-bold">Some data needs to be edited</h4>
      <h6 className="sh6-med">
        <span className="status">
          FGC team revised your registration request, but some data needs to be
          edited from your side, Take a look at these items, edit them , and
          submit changes
        </span>
      </h6>

      <ul className="list-unstyled stepper_h customize">
        {dataDashboard.comments.map((comment) => {
          return (
            <li
              className={
                "step " +
                (comment.status == true ? "done" : "") +
                " success customize"
              }
            >
              <span className="step__count customize">
                <span className="num h5-med">1</span>
              </span>
            </li>
          );
        })}
      </ul>

      {dataDashboard?.comments?.map((comment, index) => {
        let nextComment =
          index + 1 <= dataDashboard.comments.length
            ? dataDashboard.comments[index + 1]
            : null;
        return index % 2 == 0 ? (
          <div className="full-Card">
            <div className="terms_agree customize-Card ">
              <div className="form-check customize">
                <input
                  className="form-check-input"
                  type="checkbox"
                  style={{ pointerEvents: "none" }}
                  value=""
                  checked={comment.status}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {" "}
                  {comment.friendlyName}{" "}
                </label>
              </div>

              <div className="form-check" style={{ marginLeft: "22px" }}>
                <label className="form-check-label" htmlFor="flexCheckDefault2">
                  {" "}
                  {comment.comment}
                </label>
              </div>
              <div className="form-check" style={{ marginLeft: "22px" }}>
                <label className="form-check-label" htmlFor="flexCheckDefault2">
                  {" "}
                  <a href={`/${comment.tabLink}`} className="link">
                    {" "}
                    Edit {comment.friendlyName} from here
                  </a>{" "}
                </label>
              </div>
            </div>
            {nextComment != null ? (
              <div className="terms_agree customize-Card ">
                <div className="form-check customize">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    style={{ pointerEvents: "none" }}
                    value=""
                    checked={nextComment.status}
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {" "}
                    {nextComment.friendlyName}{" "}
                  </label>
                </div>

                <div className="form-check" style={{ marginLeft: "22px" }}>
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault2"
                  >
                    {" "}
                    {nextComment.comment}
                  </label>
                </div>
                <div className="form-check" style={{ marginLeft: "22px" }}>
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault2"
                  >
                    {" "}
                    <a href={`/${nextComment.tabLink}`} className="link">
                      {" "}
                      Edit {nextComment.friendlyName} from here
                    </a>{" "}
                  </label>
                </div>
              </div>
            ) : null}
          </div>
        ) : null;
      })}
    </div>
  ) : (
    <div className="registeration_status">
      <h4 className="h4-bold">Your registration status</h4>
      <h6 className="sh6-med">
        Your request is <span className="status">pending our revision</span>
      </h6>

      <ul className="list-unstyled stepper_h">
        {dataDashboard?.pendingVendors?.map((level, index) => {
          return (
            <li
              key={index}
              className={
                "step  success " + (level.status == true ? "done " : "")
              }
            >
              <span className="step__count">
                {level.status == true ? (
                  <img
                    src={SteperCheckmark}
                    className="img-fluid checked"
                    alt="done"
                  />
                ) : (
                  <span className="num h5-med">{level.serial}</span>
                )}
              </span>
              <p className="step__text">
                <span className="step__name"> {level.title}</span>
                {/* <span className='step__date'>Yesterday</span> */}
              </p>
            </li>
          );
        })}
      </ul>
      <p className="desc">
        Your registration request number is{" "}
        <span className="num">{dataDashboard?.registrationNo}</span>
      </p>
    </div>
  );
};

export default RegistrationStatus;
