import React, { useEffect, useState } from "react";
import JobTitle from "../../../../Styles/images/icons/JobTitle.svg";
import WarrningIcon from "../../../../Styles/images/icons/warrning-icon-22.svg";
import CloseSVG from "../../../../Styles/images/icons/popup_close.svg";
import ProfileCompletness from "../../../ProfileCompletness/ProfileCompletness";
import { useSelector, useDispatch } from "react-redux";
import { SetTabHeaders } from "../../../../Store/actions/registration";
import Select from "react-select";
import axios from "axios";
import Api from "../../../../Services/Api";
import Loading from "../../../Loading/index";
import DataServices from "../../../../Services/DataServices";
import config from "../../../../IP_Configrations.json";
import { toast } from "react-toastify";
import { Form } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import MUITextField from "../../../MUITextField";
import AddNewBank from "./AddNewBank";
import FinancialContact from "./FinancialContact";
import API from "../../../../Services/Axios";
const CharsDigsRege = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
const CharsDigsAcc =
  /^[a-zA-Z_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;
const CharsDigsRegex =
  /^[a-zA-Z0-9_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;
const NumDigsRegex = /^[0-9]/;
// const IBANregx = /^[a-zA-Z]{2}[0-9]{22}$/;
const IBANregx = /^[a-zA-Z]{2}[0-9a-zA-Z]{22}$/;

const host = config.static;

const customStyles = {
  control: (base) => ({
    ...base,
    height: 52,
    minHeight: 52,
  }),
};

const BankDetails = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.registration.currentStep);
  const CompanyId =
    useSelector((state) => state.registration.companyId) ||
    localStorage.getItem("CompanyId");
  const userToken = localStorage.getItem("user_token");

  const [dataBankDetails, setDataBankDetails] = useState({});
  const [currency, setCurrency] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [banksList, setBanksList] = useState([]);
  const [currencyChanged, setCurrencyChanged] = useState();
  const [bankChanged, setBankChanged] = useState();
  const [isAnyChanges, setIsAnyChanges] = useState(true);
  const [newObjectData, setNewObjectData] = useState();
  const [errorInput, setErrorInput] = useState("");
  const [isFinancialFormChanged, setIsFinancialFormChanged] = useState(false);
  const [newObjectFinancial, setNewObjectFinancial] = useState({
    haveFinancialFacility: dataBankDetails.haveFinancialFacility,
    haveGuarantee: dataBankDetails.haveGuarantee,
    CompanyId,
  });

  const [haveFinancial, setHaveFinancial] = useState(false);
  const [notHaveFinancial, setNotHaveFinancial] = useState(false);
  const [DoseHaveGuarantee, setHaveGuarantee] = useState(false);
  const [notHaveGuarantee, setNotHaveGuarantee] = useState(false);
  const [showEditedBankDetails, setShowEditedBankDetailsFlag] = useState(false);
  const [showAddNewBank, setShowAddNewBank] = useState(false);
  const [showMakPrimaryBankDetails, setShowMakPrimaryBankDetails] =
    useState(false);
  const [showDeleteBankDetails, setShowDeleteBankDetails] = useState(false);

  //
  const [modalElements, setModalElements] = useState();
  const [showReqPending, setReqPendingFlag] = useState(false);
  const [requestId, setRequestId] = useState();
  const [reqMsg, setReqMsg] = useState("");
  const [reqStatus, setReqStatus] = useState("");
  const pendingMsg =
    "it has been sent to our support team, We’ll revise it and send you respond as soon as possible. In the mean time, the authorized company representative remains as it is.";

  useEffect(() => {
    if (
      newObjectFinancial.haveFinancialFacility === true ||
      newObjectFinancial.haveFinancialFacility === false ||
      newObjectFinancial.haveGuarantee === true ||
      newObjectFinancial.haveGuarantee === false
    ) {
      setIsFinancialFormChanged(true);
    } else {
      setIsFinancialFormChanged(false);
    }
  }, [newObjectFinancial, isFinancialFormChanged, dataBankDetails]);

  useEffect(() => {
    if (newObjectData) {
      if (
        dataBankDetails.bankId !== newObjectData.bankId ||
        dataBankDetails.bankName !== newObjectData.bankName ||
        dataBankDetails.branchName !== newObjectData.branchName ||
        dataBankDetails.contactName !== newObjectData.contactName ||
        dataBankDetails.currencyId !== newObjectData.currencyId ||
        dataBankDetails.accountNumber !== newObjectData.accountNumber ||
        dataBankDetails.swiftCode !== newObjectData.swiftCode ||
        dataBankDetails.iban !== newObjectData.iban ||
        dataBankDetails.description !== newObjectData.description
      ) {
        setIsAnyChanges(true);
      } else {
        setIsAnyChanges(false);
      }

      if (
        newObjectData.bankName === "" &&
        dataBankDetails?.isSaudia === false
      ) {
        setErrorInput("bankName");
        setIsAnyChanges(false);
      } else if (newObjectData.branchName === "") {
        setErrorInput("branch");
        setIsAnyChanges(false);
      } else if (newObjectData.contactName === "") {
        setErrorInput("Beneficiary");
      } else if (newObjectData.iban === "") {
        setErrorInput("IBAN");
        setIsAnyChanges(false);
      } else if (newObjectData.accountNumber === "") {
        setErrorInput("accountNumber");
        setIsAnyChanges(false);
      } else if (newObjectData.swiftCode === "") {
        setErrorInput("Swift");
        setIsAnyChanges(false);
      } else if (
        dataBankDetails?.isSaudia === true &&
        newObjectData.iban &&
        newObjectData.iban.length > 24
      ) {
        console.log("here error");
        setErrorInput("IBAN");
        setIsAnyChanges(false);
      } else {
        setErrorInput("");
      }
    }
  }, [dataBankDetails, isAnyChanges, newObjectData]);

  useEffect(() => {
    dispatch(SetTabHeaders("Financial details", "", currentStep));

    const inputs = document.querySelectorAll(".input.large");

    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function () {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value === "") {
          input.classList.remove("focused");
        }
      });
    });
  }, []);
  const [allBanksData, setallBanksData] = useState([]);
  const [countBankEdit, setCountBankEdit] = useState(0);

  useEffect(() => {
    DataServices.GetDataListDetailed(`GetBankForList`, "title", "id").then(
      (res) => setBanksList(res)
    );

    DataServices.GetDataList(`GetCurrency`, "title", "id").then((res) =>
      setCurrency(res)
    );

    DataServices.GetDataList(`GetCountriesList`, "title", "id").then((res) => {
      // console.log(
      //   res.filter((item) => item.value === dataBankDetails.countryId)[0].label
      // );
      setCountriesList(res);
    });

    // saudia 437 country id

    const getBankDetailsData = async () => {
      await axios
        .get(`${host}GetCompanyBankDetailForEdit?companyId=${CompanyId}`, {
          headers: {
            Authorization: userToken,
          },
        })
        .then((res) => {
          setallBanksData(res.data);
        })
        .catch((err) => console.log(err));
    };
    getBankDetailsData();
  }, [CompanyId, countBankEdit]);
  const [activeStep, setActiveStep] = useState("");

  function getCurrentBankActive(bankObj, tabName) {
    // console.log("bankObj", bankObj);
    if (activeStep === tabName) {
      setActiveStep("");
    } else {
      setActiveStep(tabName);
    }
    setDataBankDetails(bankObj);
    if (bankObj.haveFinancialFacility === true) {
      setHaveFinancial(true);
      setNotHaveFinancial(false);
    } else if (bankObj.haveFinancialFacility === false) {
      setHaveFinancial(false);
      setNotHaveFinancial(true);
    }
    if (bankObj.haveGuarantee === true) {
      setHaveGuarantee(true);
      setNotHaveGuarantee(false);
    } else if (bankObj.haveGuarantee === false) {
      setHaveGuarantee(false);
      setNotHaveGuarantee(true);
    }

    if (bankObj.haveFinancialFacility === null) {
      setHaveFinancial(false);
      setNotHaveFinancial(false);
    }

    if (bankObj.haveGuarantee === null) {
      setHaveGuarantee(false);
      setNotHaveGuarantee(false);
    }

    if (!bankObj.accountActivities) {
      //no requests
      setReqPendingFlag(false);
    } else {
      //pending
      setReqStatus(bankObj.accountActivities?.requestStatus);
      if (bankObj.accountActivities?.requestStatus === "Pending") {
        setReqPendingFlag(true);
        setRequestId(bankObj.accountActivities?.requestId);
        setModalElements(JSON.parse(bankObj.accountActivities?.newObject));
        setReqMsg(pendingMsg);
      } else if (
        bankObj.accountActivities?.requestStatus === "Approved" ||
        bankObj.accountActivities?.requestStatus === "Rejected" ||
        bankObj.accountActivities?.requestStatus === "Cancelled"
      ) {
        setReqPendingFlag(false);
        setShowEditedBankDetailsFlag(true);
        setRequestId(bankObj.accountActivities?.requestId);
        setModalElements(JSON.parse(bankObj.accountActivities?.newObject));
        setReqMsg(bankObj.accountActivities?.message);
      }
    }
  }
  const handleChangeCurrency = (e) => {
    setCurrencyChanged(e);
  };

  const undoReqHandler = async () => {
    await axios
      .post(
        `${host}UndoActivitiesForEditBankDetails?requestId=${requestId}&BankId=${dataBankDetails.BankTableId}`,
        {
          headers: {
            Authorization: userToken,
          },
        }
      )
      .then((res) => {
        setReqPendingFlag(false);
        setCountBankEdit((perv) => perv + 1);
      })
      .catch((err) => console.log(err));
  };

  const userviewedReqsatausHandler = () => {
    if (reqStatus !== "Pending") {
      axios
        .post(
          `${host}UpdateViewForActivities?requestId=${parseInt(requestId)}`,
          {
            headers: {
              Authorization: userToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setCountBankEdit((perv) => perv + 1);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleChangeBank = (e) => {
    setBankChanged(e);
  };

  const showCurrency = () => {
    if (currency) {
      return currency.find(
        (option) => option.value === dataBankDetails?.currencyId
      );
    } else {
      return "";
    }
  };

  const getSelectedCurrency = (id) => {
    return currency.find((option) => option.value === id).label;
  };

  const showBankList = () => {
    if (currency) {
      return banksList.find(
        (option) => option.value === dataBankDetails?.bankId
      );
    } else {
      return "";
    }
  };

  const cherryPick = (obj, keys, filter = 0) =>
    keys
      .filter((key) => (filter ? obj[key] : 1))
      .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});

  const handleEditBankDetails = async (e) => {
    const elements = cherryPick(getValues(), Object.keys(dirtyFields));
    setModalElements(elements);
    setReqMsg(pendingMsg);
    setReqPendingFlag(true);

    API
      .post(
        `AddActivitiesForEditBankDetails?CompanyId=${parseInt(CompanyId)}&newObj=${JSON.stringify(elements)}&bankTableId=${dataBankDetails.BankTableId}`,
        {},
        {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setIsAnyChanges(true);
        setRequestId(res.data);
        setCountBankEdit((perv) => perv + 1);
        reset(dataBankDetails);
        toast.success("Changes add successfully and waiting admin approval");
      })
      .catch((err) => toast.error("there is an error please try again"));
  };

  const handleEditFinancialFacility = (e) => {
    e.preventDefault();

    axios
      .post(`${host}UpdateFinancialFacility`, newObjectFinancial, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        setDataBankDetails(res.data);
        setNewObjectFinancial({
          haveFinancialFacility: null,
          haveGuarantee: null,
          CompanyId,
        });
        setIsFinancialFormChanged(true);
        toast.success("Changes Saved");
      })
      .catch((err) => console.log(err));
  };

  const [form] = Form.useForm();

  const [count, setcount] = useState(0);

  let schema;
  schema = Yup.object().shape({
    accountNumber: Yup.string()
      .required("Account number is required")
      .matches(
        CharsDigsRege,
        "Invalid account no. Please ensure that the number consists of digits only with no spaces or any other characters"
      ),
    iban:
      dataBankDetails?.isSaudia === true
        ? Yup.string()
          .required("IBAN is required")
          .matches(IBANregx, "Invalid IBAN, ")
        : Yup.string().matches(
          CharsDigsRege,
          "Invalid IBAN, Please ensure that the number consists of characters with no spaces or any special characters."
        ),
    branchName: Yup.string()
      .matches(
        CharsDigsRegex,
        "Invalid branch name Please ensure that the name in english with no spaces or any special characters"
      )
      .required("Branch name is required"),

    contactName: Yup.string()
      .matches(
        CharsDigsAcc,
        "Invalid beneficiary name Please ensure that the name in english with no spaces, numbers or any special characters"
      )
      .required("Beneficiary name is required"),
    swiftCode:
      dataBankDetails?.isSaudia === false
        ? Yup.string()
          .matches(
            CharsDigsRegex,
            "Invalid swift code Please ensure that the name in english with no spaces or any special characters"
          )
          .required("Swift code is required")
        : Yup.string().nullable(),
  });
  const {
    control,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: dataBankDetails,
  });

  //   useEffect(() => {
  //     register({ name: "select" });
  //   }, [register]);
  const [isEdit, setisEdit] = useState(true);
  const [isEdit1, setisEdit1] = useState(true);

  useEffect(() => {
    if (isEdit === true && count !== 0) {
      setValue("accountNumber", "");
      setisEdit(false);
    }
  }, [getValues("iban")]);

  useEffect(() => {
    if (isEdit === true && count !== 0) {
      if (dataBankDetails?.isSaudia) {
        setValue("iban", "SA");
      } else {
        setValue("iban", "");
      }
      setisEdit(false);
    }
  }, [getValues("accountNumber")]);
  useEffect(() => {
    if (getValues("description") === "") {
      setValue("description", " ");
    }
  }, [getValues("description")]);
  useEffect(() => {
    if (isEdit1 === true && count !== 0) {
      setValue("bankName", "");
      setValue("branchName", "");
      setValue("contactName", "");
      setValue("accountNumber", "");
      //   setValue("swiftCode", "");
      if (dataBankDetails?.isSaudia) {
        setValue("iban", "SA");
      } else {
        setValue("iban", "");
      }
      setValue("description", " ");
      setBankChanged("");
      dataBankDetails.bankId = "";

      setNewObjectData({
        ...dataBankDetails,
        bankId: 0,
      });

      setisEdit1(false);
    }
  }, [getValues("swiftCode")]);

  //   useEffect(() => {
  //     setValue("iban", "");
  //   }, [watch("accountNumber")]);
  useEffect(() => {
    if (dataBankDetails) {
      setValue("bankName", dataBankDetails?.bankName);
      setValue("branchName", dataBankDetails?.branchName);
      setValue("contactName", dataBankDetails?.contactName);
      setValue("accountNumber", dataBankDetails?.accountNumber);
      setValue("swiftCode", dataBankDetails?.swiftCode);
      setValue("iban", dataBankDetails?.iban);
      setValue(
        "description",
        dataBankDetails?.description ? dataBankDetails?.description : " "
      );
    }
  }, [dataBankDetails]);

  const editModalHandler = (flag) => {
    setShowEditedBankDetailsFlag(flag);
  };
  function makeBankPrimary() {
    axios
      .put(
        `${host}SetCompanyBankDetailsPrimary?BankTableId=${dataBankDetails.BankTableId}`,
        null,
        {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setCountBankEdit((perv) => perv + 1);
        setDataBankDetails(res.data);
        setActiveStep("");
        toast.success("Changes Saved");
      })
      .catch((err) => console.log(err));
    setShowMakPrimaryBankDetails(false);
  }
  function makeBankDeleted() {
    axios
      .put(
        `${host}DeleteCompanyBankDetails?BankTableId=${dataBankDetails.BankTableId}`,
        null,
        {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setCountBankEdit((perv) => perv + 1);
        setActiveStep("");
        toast.success("Changes Saved");
      })
      .catch((err) => console.log(err));
  }
  // console.log("====================================");
  // console.log(allBanksData);
  // console.log("====================================");
  return (
    <>
      {!dataBankDetails && !currency && !countriesList && !banksList ? (
        <Loading />
      ) : (
        <div className="company_info company_document  financial_details">
          <div className="left_sec">
            <div className="proForm">
              <div className="company_document__sec">
                <div className="main_head">
                  <div className="text">
                    <h4 className="sh4-bold">
                      Bank details ({allBanksData?.length} accounts)
                    </h4>
                    <span className="req">Required</span>
                  </div>
                  {allBanksData?.length < 3 ? (
                    <span
                      className="add-div"
                      onClick={() => setShowAddNewBank(true)}
                    >
                      <div className="add-btn">+</div>
                      Add new bank account
                    </span>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="content first">
                  <div className="accordion" id="company_info">
                    {allBanksData.map((item, index) => (
                      <a href={`#primaryInfo-${index}`} key={index}>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`headingOne-${index}`}
                          >
                            <button
                              className="accordion-button sh5-med"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              // aria-expanded={
                              //   activeStep == `#primaryInfo-${index}`
                              //     ? "true"
                              //     : "false"
                              // }
                              aria-controls="collapseOne"
                              onClick={() =>
                                getCurrentBankActive(
                                  item,
                                  `#primaryInfo-${index}`
                                )
                              }
                            >
                              <span className="title">
                                <h4 className="">
                                  {item?.isSaudia
                                    ? banksList.find(
                                      (option) =>
                                        option.value === item?.bankId
                                    )?.label
                                    : item?.bankName}{" "}
                                  / {item?.branchName}
                                </h4>
                              </span>
                              {item.statusId !== 2 ? (
                                <></>
                              ) : (
                                <span className="required">
                                  pending approval new bank account
                                </span>
                              )}
                            </button>
                          </h2>
                          <div
                            id={`#primaryInfo-${index}`}
                            className={
                              "accordion-collapse collapse " +
                              (activeStep == `#primaryInfo-${index}`
                                ? "show"
                                : "")
                            }
                            aria-labelledby="headingOne"
                            data-bs-parent="#company_info"
                          >
                            <div className="accordion-body">
                              <Form
                                className="proForm"
                                form={form}
                                layout="vertical"
                                onFinish={handleSubmit(handleEditBankDetails)}
                              >
                                {showReqPending && (
                                  <div className="req-status-card">
                                    <div className="req-details">
                                      <span className="warning-icon">
                                        <img
                                          src={WarrningIcon}
                                          className=" icon"
                                          alt="warning"
                                        />
                                      </span>
                                      {item.accountActivities?.newObject ? (
                                        <p>
                                          Your request for changing 'Bank
                                          details' is pending approval <br />
                                          <span
                                            className="seeDetails"
                                            onClick={() =>
                                              editModalHandler(true)
                                            }
                                          >
                                            See details
                                          </span>
                                        </p>
                                      ) : (
                                        <p>
                                          Your request for Adding 'New Bank
                                          details' is pending approval <br />
                                        </p>
                                      )}
                                    </div>
                                    <div className="action">
                                      <span
                                        className=""
                                        onClick={() => undoReqHandler()}
                                      >
                                        Undo
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="form-group">
                                  {dataBankDetails?.isSaudia ? (
                                    <Select
                                      className="select_react "
                                      styles={customStyles}
                                      options={banksList}
                                      name="BusinessType"
                                      placeholder="Bank Name"
                                      classNamePrefix="select"
                                      defaultValue={
                                        bankChanged
                                          ? bankChanged
                                          : showBankList()
                                      }
                                      value={
                                        bankChanged
                                          ? bankChanged
                                          : showBankList()
                                      }
                                      onChange={(e) => {
                                        handleChangeBank(e);
                                        setNewObjectData({
                                          ...dataBankDetails,
                                          bankId: e.value,
                                        });
                                        setValue(
                                          "bankName",
                                          dataBankDetails?.bankName
                                        );
                                        setValue("branchName", "");
                                        setValue("contactName", "");
                                        setValue("accountNumber", "");
                                        setValue("swiftCode", "");
                                        setValue("iban", "");
                                        setValue("description", "");
                                        setisEdit1(false);
                                        // fieldChangeHandler("bankId", e.value);
                                      }}
                                    />
                                  ) : (
                                    <div className={`input populated`}>
                                      <MUITextField
                                        name="bankName"
                                        type="text"
                                        placeholder={" Bank name*"}
                                        id="bankName"
                                        className={`form-control ${errors?.bankName ? "has-error" : ""
                                          }`}
                                        errorMsg={errors?.bankName?.message}
                                        validateStatus={
                                          errors?.bankName ? "error" : ""
                                        }
                                        control={control}
                                        setcount={setcount}
                                      />
                                    </div>
                                  )}
                                  <div className="help-text">
                                    <p className="text"></p>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <div className="input populated">
                                    <MUITextField
                                      name="branchName"
                                      type="text"
                                      placeholder={"branch*"}
                                      id="branchName"
                                      className={`form-control ${errors?.branchName ? "has-error" : ""
                                        }`}
                                      errorMsg={errors?.branchName?.message}
                                      validateStatus={
                                        errors?.branchName ? "error" : ""
                                      }
                                      control={control}
                                      setcount={setcount}
                                    />
                                  </div>
                                  <div className="help-text">
                                    <p className="text"></p>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <div className="input  populated">
                                    <MUITextField
                                      name="contactName"
                                      type="text"
                                      placeholder={"Beneficiary name*"}
                                      id="contactName"
                                      className={`form-control ${errors?.contactName ? "has-error" : ""
                                        }`}
                                      errorMsg={errors?.contactName?.message}
                                      validateStatus={
                                        errors?.contactName ? "error" : ""
                                      }
                                      control={control}
                                      setcount={setcount}
                                    />
                                  </div>
                                  <div className="help-text"></div>
                                </div>

                                <div className="sideByside">
                                  <div className="form-group">
                                    <div className="input populated disable ">
                                      <label
                                        className="control-label"
                                        htmlFor="Country"
                                      >
                                        Country*{" "}
                                      </label>
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        defaultValue={
                                          countriesList?.filter(
                                            (item2) =>
                                              item2.value ===
                                              dataBankDetails.countryId
                                          )[0]?.label
                                        }
                                        id="Country"
                                        name="Country"
                                        disabled
                                      />
                                    </div>
                                    <div className="help-text"></div>
                                  </div>
                                  <div className="form-group">
                                    <Select
                                      className="select_react"
                                      styles={customStyles}
                                      options={currency}
                                      name="Currency"
                                      placeholder="Currency"
                                      classNamePrefix="select"
                                      defaultValue={
                                        currencyChanged
                                          ? currencyChanged
                                          : showCurrency()
                                      }
                                      value={
                                        currencyChanged
                                          ? currencyChanged
                                          : showCurrency()
                                      }
                                      onChange={(e) => {
                                        handleChangeCurrency(e);
                                        setNewObjectData({
                                          ...dataBankDetails,
                                          currencyId: e.value,
                                        });
                                        setValue("currencyId", e.value, {
                                          shouldDirty: true,
                                        });
                                      }}
                                    />
                                    <div className="help-text">
                                      <p className="text"></p>
                                    </div>
                                  </div>
                                </div>

                                <div className="sideByside">
                                  <div className="form-group">
                                    <div className="input populated ">
                                      <MUITextField
                                        name="accountNumber"
                                        type="text"
                                        placeholder={"Account number*"}
                                        id="accountNumber"
                                        className={`form-control ${errors?.accountNumber
                                          ? "has-error"
                                          : ""
                                          }`}
                                        errorMsg={
                                          errors?.accountNumber?.message
                                        }
                                        validateStatus={
                                          errors?.accountNumber ? "error" : ""
                                        }
                                        control={control}
                                        setcount={setcount}
                                      />
                                    </div>
                                    <div className="help-text"></div>
                                  </div>
                                  <div className="form-group">
                                    <div className="input populated">
                                      <MUITextField
                                        name="swiftCode"
                                        type="text"
                                        placeholder={"Swift code*"}
                                        id="swiftCode"
                                        className={`form-control ${errors?.swiftCode ? "has-error" : ""
                                          }`}
                                        errorMsg={errors?.swiftCode?.message}
                                        validateStatus={
                                          errors?.swiftCode ? "error" : ""
                                        }
                                        control={control}
                                        setcount={setcount}
                                        disabled={dataBankDetails?.isSaudia}
                                      />
                                    </div>
                                    <div className="help-text">
                                      <p className="text"></p>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <div className="input large populated">
                                    <MUITextField
                                      name="iban"
                                      type="text"
                                      placeholder={"IBAN*"}
                                      id="iban"
                                      className={`form-control ${errors?.iban ? "has-error" : ""
                                        }`}
                                      errorMsg={errors?.iban?.message}
                                      validateStatus={
                                        errors?.iban ? "error" : ""
                                      }
                                      control={control}
                                      setcount={setcount}
                                    //   {...register("iban")}
                                    />
                                  </div>
                                  <div className="help-text"></div>
                                </div>

                                <div className="form-group">
                                  <div
                                    className={`input large ${dataBankDetails?.description?.length > 0
                                      ? "populated"
                                      : ""
                                      }`}
                                  >
                                    <MUITextField
                                      name="description"
                                      type="text"
                                      placeholder={"Other details (optional)"}
                                      id="description"
                                      className={`form-control ${errors?.description ? "has-error" : ""
                                        }`}
                                      isplace={true}
                                      errorMsg={errors?.description?.message}
                                      validateStatus={
                                        errors?.description ? "error" : ""
                                      }
                                      control={control}
                                      setcount={setcount}
                                    />
                                  </div>
                                  <div className="help-text">
                                    <p className="text"></p>
                                  </div>
                                </div>
                                {item.statusId !== 2 ? (
                                  <div className="form_actions bank-action">
                                    <button
                                      className={`btn primaryBtn largeBtn ${!isAnyChanges || showReqPending
                                        ? "disabled"
                                        : ""
                                        }`}
                                      type="submit"
                                    >
                                      Save changes
                                    </button>

                                    <div className="remove-div">
                                      {!item.isPrimary ? (
                                        <a
                                          onClick={() =>
                                            setShowDeleteBankDetails(false)
                                          }
                                        >
                                          Remove bank account
                                        </a>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className="rideo-div">
                                      {!dataBankDetails.isPrimary ? (
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            onChange={() =>
                                              setShowMakPrimaryBankDetails(true)
                                            }
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      ) : (
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            disabled
                                            checked
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      )}
                                      Make primary
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form_actions bank-action">
                                    <div className="rideo-div">
                                      {!dataBankDetails.isPrimary ? (
                                        <label className="switch"></label>
                                      ) : (
                                        <>
                                          <label className="switch">
                                            <input
                                              type="checkbox"
                                              disabled
                                              checked
                                            />
                                            <span className="slider round"></span>
                                          </label>
                                          Make primary
                                        </>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
                <FinancialContact />

                <div className="content with_main_head">
                  <form action="#" onSubmit={handleEditFinancialFacility}>
                    <div className="main_head">
                      <div className="text">
                        <h4 className="sh4-bold">Financial Facility</h4>
                      </div>
                      <span className="req">Optional</span>
                    </div>
                    <div className="form-group facility_check">
                      <p className="facility_title">
                        Do you have financial facilities?
                      </p>
                      <div className="form-check">
                        <input
                          className="form-check-input rounded-circle"
                          type="radio"
                          name="haveFacilities"
                          id="yes"
                          value="yes"
                          checked={haveFinancial}
                          onChange={(e) => {
                            setNewObjectFinancial({
                              ...newObjectFinancial,
                              haveFinancialFacility: true,
                            });
                            setHaveFinancial(true);
                            setNotHaveFinancial(false);
                          }}
                        />
                        <label className="form-check-label" htmlFor="yes">
                          {" "}
                          Yes{" "}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input rounded-circle"
                          type="radio"
                          name="haveFacilities"
                          id="No"
                          value="no"
                          checked={notHaveFinancial}
                          onChange={(e) => {
                            setNewObjectFinancial({
                              ...newObjectFinancial,
                              haveFinancialFacility: false,
                            });
                            setHaveFinancial(false);
                            setNotHaveFinancial(true);
                          }}
                        />
                        <label className="form-check-label" htmlFor="No">
                          {" "}
                          No{" "}
                        </label>
                      </div>
                    </div>
                    <div className="form-group facility_check last">
                      <p className="facility_title">
                        Are you able to issue bonds / Letter of guarantee?
                      </p>
                      <div className="form-check">
                        <input
                          className="form-check-input rounded-circle"
                          type="radio"
                          name="bonds"
                          id="yes2"
                          value="yes"
                          checked={DoseHaveGuarantee}
                          onChange={(e) => {
                            setNewObjectFinancial({
                              ...newObjectFinancial,
                              haveGuarantee: true,
                            });
                            setHaveGuarantee(true);
                            setNotHaveGuarantee(false);
                          }}
                        />
                        <label className="form-check-label" htmlFor="yes2">
                          {" "}
                          Yes{" "}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input rounded-circle"
                          type="radio"
                          name="bonds"
                          id="No2"
                          value="no"
                          checked={notHaveGuarantee}
                          onChange={(e) => {
                            setNewObjectFinancial({
                              ...newObjectFinancial,
                              haveGuarantee: false,
                            });
                            setHaveGuarantee(false);
                            setNotHaveGuarantee(true);
                          }}
                        />
                        <label className="form-check-label" htmlFor="No2">
                          {" "}
                          No{" "}
                        </label>
                      </div>
                    </div>
                    <div className="form_actions">
                      <button
                        className={`btn primaryBtn largeBtn ${!isFinancialFormChanged ? "disabled" : ""
                          }`}
                      >
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <ProfileCompletness />
          {showAddNewBank && (
            <AddNewBank
              setShowAddNewBank={setShowAddNewBank}
              defaultBank={allBanksData[0]}
              setCountBankEdit={setCountBankEdit}
            />
          )}
          {showEditedBankDetails && (
            <div className="popup active popup_contact_support change_company_account">
              <div className="popup__content">
                <div className="popup__body">
                  <div className="head">
                    <h5 className="sh5-bold">Change bank details</h5>
                    <span
                      className="close"
                      onClick={() => {
                        editModalHandler(false);
                        userviewedReqsatausHandler();
                      }}
                    >
                      <img src={CloseSVG} className="img-fluid" alt="close" />
                    </span>
                  </div>
                  <div className="content">
                    {modalElements &&
                      Object.entries(modalElements).map(
                        ([key, value], index) => {
                          return (
                            <div key={index} className="form-group mb-3">
                              <div className="input populated ant-form-item-control-input-content disable ">
                                <label className="control-label" htmlFor={key}>
                                  {key === "currencyId" ? "currency" : key}*{" "}
                                </label>
                                <input
                                  autoComplete="off"
                                  type="text"
                                  className="form-control"
                                  defaultValue={
                                    key === "currencyId"
                                      ? getSelectedCurrency(value)
                                      : value
                                  }
                                  id={`edited ${key}`}
                                  name="Country"
                                  disabled
                                />
                              </div>
                            </div>
                          );
                        }
                      )}

                    <p className="desc ">
                      Your request no. is{" "}
                      <span className="bold"> {requestId}</span>, {reqMsg}
                    </p>
                  </div>
                  <div className="footer_actions">
                    <button
                      className="btn primaryBtn mediumBtn"
                      onClick={() => {
                        editModalHandler(false);
                        userviewedReqsatausHandler();
                      }}
                    >
                      Ok, done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showMakPrimaryBankDetails && (
            <div className="popup active popup_contact_support change_company_account">
              <div className="popup__content">
                <div className="popup__body">
                  <div className="content">
                    <p className="desc ">
                      Are you sure you want to make this bank account: <br></br>
                      <span className="bold">
                        {" "}
                        {dataBankDetails?.isSaudia
                          ? showBankList()
                          : dataBankDetails?.bankName}{" "}
                        / {dataBankDetails?.branchName}
                      </span>
                      <br></br>
                      your primary bank account?
                    </p>
                  </div>
                  <div className="footer_actions footer_actions22">
                    <button
                      className="btn  mediumBtn cancel-btn"
                      onClick={() => {
                        setShowMakPrimaryBankDetails(false);
                      }}
                    >
                      No, cancel
                    </button>
                    <button
                      className="btn primaryBtn mediumBtn"
                      onClick={() => {
                        makeBankPrimary();
                      }}
                    >
                      Yes, make it primary
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showDeleteBankDetails && (
            <div className="popup active popup_contact_support change_company_account">
              <div className="popup__content">
                <div className="popup__body">
                  <div className="content">
                    <p className="desc ">
                      Are you sure you want to remove this bank account:{" "}
                      <br></br>
                      <span className="bold">
                        {dataBankDetails?.isSaudia
                          ? showBankList()
                          : dataBankDetails?.bankName}
                        / {dataBankDetails?.branchName}
                      </span>
                    </p>
                  </div>
                  <div className="footer_actions footer_actions22">
                    <button
                      className="btn  mediumBtn cancel-btn"
                      onClick={() => {
                        setShowMakPrimaryBankDetails(false);
                      }}
                    >
                      No, cancel
                    </button>
                    <button
                      className="btn primaryBtn mediumBtn"
                      onClick={() => {
                        makeBankDeleted();
                      }}
                    >
                      Yes, remove account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BankDetails;
