import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";

import config from "../../../../IP_Configrations.json";
import UploadFile from "../../../UploadFile";
import DataServices from "../../../../Services/DataServices";

import checkmarkedCircle_20 from "../../../../Styles/images/icons/checkmarkedCircle-20.svg";
import attached_file from "../../../../Styles/images/settings/attached_file.svg";
import Calendar_disabled from "../../../../Styles/images/icons/Calendar_disabled.svg";
import calendar from "../../../../Styles/images/icons/calendar.svg";
import Warning_yellow_black from "../../../../Styles/images/icons/Warning_yellow_black.svg";
import Error_20 from "../../../../Styles/images/icons/error-20.svg";
import Warning_white_24 from "../../../../Styles/images/icons/Warning_white_24.svg";

const numRegex = /^[0-9]+$/;

const animateInputs = () => {
  const inputs = document.querySelectorAll(".input");
  inputs.forEach((input) => {
    input.addEventListener(
      "focusin",
      function (e) {
        input.classList.add("focused");
      },
      false
    );
    input.addEventListener("focusout", function (e) {
      if (e.target.value == "") {
        input.classList.remove("focused");
      }
    });
  });
};

function CCCSection({
  SaveAdditionalChanges,
  Saving,
  companyId,
  setAdditionalAttachments,
}) {
  const [Changed, setChanged] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [Document, setDocument] = useState(false);
  const [Flag, setFlag] = useState(false);

  const documentTypeId = 5;

  useEffect(() => {
    animateInputs();
    getDoc();
  }, []);

  const getDoc = (flag) => {
    DataServices.GetRowById(
      `GetUPLOADDOCUMENTSBySection?companyId=${companyId}&documentTypeId=${documentTypeId}`
    ).then((res) => {
      if (res?.length > 0) {
        if (flag) {
          setFlag(true);
          setDocument({ ...validate.values, attachLink: res[0].attachLink });
        } else {
          setDocument(res[0]);
          setAdditionalAttachments((additionalAttachments) => ({
            ...additionalAttachments,
            CCC: true,
          }));
        }
      }
    });
  };

  useEffect(() => {
    if (Document?.expireDate) {
      const date1 = new Date();
      const date2 = new Date(Document?.expireDate);
      const diffTime = date2 - date1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 1) {
        setEdit(`${diffDays}`);
      }
    }
  }, [Document?.expireDate]);

  const validate = useFormik({
    enableReinitialize: true,
    initialValues: {
      documentNumber: Edit ? "" : Document?.documentNumber,
      expireDate: Edit ? null : Document?.expireDate,
      File: Edit ? null : Document?.attachLink,
      edit: Flag || Edit,
    },
    validationSchema: Yup.object().shape({
      documentNumber: Yup.string()
        .matches(
          numRegex,
          "Invalid contractors classification certificate number. Please ensure that the number consists of 10 digits with no spaces or other characters"
        )
        .required("Contractors classification certificate number is required"),
      expireDate: Yup.date()
        .required(
          "Contractors classification certificate expiration date is required"
        )
        .typeError("Invalid contractors classification certificate date."),
      File: Yup.mixed().required(
        "Contractors classification certificate certificate copy is required"
      ),
    }),

    onSubmit: (values) => {
      SaveAdditionalChanges(
        { ...values, documentTypeId },
        "Contractors classification certificate"
      );
      validate.setFieldValue("edit", false);
      setChanged(false);
    },
  });

  const [Warn, setWarn] = useState({});
  const [Load, setLoad] = useState(false);
  const _calendar = useRef();

  const openDatepicker = () => _calendar.current.setOpen(true);
  return (
    <div className="content">
      <div className="sub_head">
        <div className="top">
          <h4 className="title">
            <span>Contractors classification certificate (optional)</span>
            {Edit || !validate.values.documentNumber ? (
              <img src={Error_20} className="img-fluid icon" alt="info" />
            ) : validate.values.edit ? (
              ""
            ) : (
              <img
                src={checkmarkedCircle_20}
                className="img-fluid icon"
                alt="checked"
              />
            )}
          </h4>
          {!validate.values.edit && (
            <span
              className="edit"
              role="button"
              onClick={() => {
                validate.setFieldValue("edit", true);
              }}
            >
              Edit
            </span>
          )}
        </div>
        <p className="desc"></p>
      </div>
      {Edit && (
        <div className="waring_box sm sh5-med">
          <img
            src={Warning_white_24}
            className="img-fluid icon"
            alt="warning"
          />
          <span>
            Your ‘Contractors classification certificate’ expired {Edit} days
            ago, please update your document
          </span>
        </div>
      )}
      <div className="sideByside">
        <div className="form-group">
          <div
            className={
              "input " + (validate.values.documentNumber ? "populated" : "")
            }
          >
            <label className="control-label" htmlFor="documentNumber">
              Contractors classification certificate no.
            </label>
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control " +
                (validate.errors.documentNumber &&
                  validate.touched.documentNumber &&
                  "has-error")
              }
              disabled={validate.values.edit ? false : true}
              value={validate.values?.documentNumber}
              onChange={(e) => {
                validate.handleChange(e);
                setChanged(true);
              }}
              onBlur={validate.handleBlur}
              id="documentNumber"
              name="documentNumber"
            />
          </div>
          <div className="help-text">
            {validate.values.edit && (
              <ul>
                {validate.errors.documentNumber &&
                validate.touched.documentNumber ? (
                  <p className="text has-error">
                    {validate.errors.documentNumber}
                  </p>
                ) : (
                  ""
                )}
              </ul>
            )}
          </div>
        </div>

        <div className="form-group date">
          {validate.values.edit ? (
            <>
              <div className="input date populated">
                <label className="control-label date" htmlFor="expireDate">
                  Certificate expiration date
                </label>
                <DatePicker
                  ref={(c) => (_calendar.current = c)}
                  placeholderText="Please select expiration date"
                  dateFormat={"dd/MM/yyyy"}
                  autoComplete="off"
                  showYearDropdown
                  className={
                    "form-control " +
                    (validate.errors.expireDate &&
                      validate.touched.expireDate &&
                      "has-error")
                  }
                  id="expireDate"
                  name="expireDate"
                  selected={
                    validate.values?.expireDate
                      ? new Date(validate.values?.expireDate)
                      : ""
                  }
                  onChange={(date) => {
                    setWarn({});
                    const date1 = new Date();
                    const date2 = new Date(date);
                    const diffTime = date2 - date1;
                    const diffDays = Math.ceil(
                      diffTime / (1000 * 60 * 60 * 24)
                    );
                    if (diffDays < 1) {
                      setWarn({
                        expiredCommercialRegisterNoExpireDate: true,
                      });
                    } else if (diffDays < 30) {
                      setWarn({
                        failCommercialRegisterNoExpireDate: diffDays,
                      });
                    } else {
                      if (diffDays < 90) {
                        setWarn({
                          warnCommercialRegisterNoExpireDate: diffDays,
                        });
                      }
                      validate.setFieldValue("expireDate", date);
                      setChanged(true);
                    }
                  }}
                  onBlur={validate.handleBlur}
                />
                <img
                  src={calendar}
                  className="img-fluid right_icon"
                  alt="calendar"
                  onClick={openDatepicker}
                />
              </div>
              <div className="help-text">
                <ul>
                  {validate.errors.expireDate && validate.touched.expireDate ? (
                    <p className="text  has-error">
                      {validate.errors.expireDate}
                    </p>
                  ) : Warn.expiredCommercialRegisterNoExpireDate ? (
                    <p className="text has-error">{`this document expired, please renew your certificate and re-register`}</p>
                  ) : Warn.failCommercialRegisterNoExpireDate ? (
                    <p className="text has-error">{`this document Will expire in ${Warn.failCommercialRegisterNoExpireDate}  days, expiration date must be at least 30 days beyond the registration date, please renew your certificate and re-register`}</p>
                  ) : Warn.warnCommercialRegisterNoExpireDate ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={Warning_yellow_black}
                        className="img-fluid right_icon2"
                        alt=""
                        style={{ width: "14px", marginRight: "5px" }}
                      />
                      <p className="text" style={{ color: "#000" }}>
                        {`this document Will expire soon in ${Warn.warnCommercialRegisterNoExpireDate} days, don’t forget to renew it soon.`}
                      </p>
                    </div>
                  ) : (
                    <p className="text">
                      Must be valid, with an expiration date at least 30 days
                      beyond the registration date.
                    </p>
                  )}
                </ul>
              </div>
            </>
          ) : (
            <div
              className={
                "input date" + (validate.values?.expireDate && "populated")
              }
            >
              <label className="control-label" htmlFor="expiration">
                Certificate expiration date
              </label>
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                disabled
                value={
                  validate.values?.expireDate
                    ? new Date(validate.values?.expireDate).toLocaleDateString(
                        "en-GB"
                      )
                    : ""
                }
                id="expiration"
                name="expiration"
              />
              <img
                src={Calendar_disabled}
                className="img-fluid right_icon "
                alt="calendar"
              />
            </div>
          )}
          <div className="help-text">
            <p className="text"></p>
          </div>
        </div>
      </div>
      {validate.values.edit && (
        <div
          className={
            "upload_box" +
            (validate.errors.File && validate.touched.File ? " has-error" : " ")
          }
        >
          <h6 className="sh6-med">
            Valid Contractors classification certificate
          </h6>
          <UploadFile
            className=" sm "
            load={Load}
            setLoad={setLoad}
            fieldName="File"
            title="Contractors classification certificate"
            removeFile={() => validate.setFieldValue("File", null)}
            errors={validate.errors.File}
            touched={validate.touched.File}
            fileName={
              validate.values.File?.split("/")[4] || validate.values.File
            }
            folderName="File"
            documentTypeId={documentTypeId}
            companyId={companyId}
            Api="UploadFilesForAdditionalDocumentsTempFolder"
            handleBlur={() => validate.setFieldTouched("File")}
            handleChange={() => {
              validate.setFieldValue("File", "Done");
              setChanged(true);
              getDoc(true);
            }}
          />
        </div>
      )}
      {!validate.values.edit && Document?.attachLink && (
        <div className="uploaded_file">
          <Link
            target="_blank"
            to={
              Document?.attachLink && config.download
                ? config.download + Document?.attachLink
                : ""
            }
            className="file"
          >
            <img src={attached_file} className="img-fluid icon" alt="icon" />
            <span>{Document?.attachLink?.split("/")[4]}</span>
          </Link>
          <span className="hint">You can view the file by clicking on it</span>
        </div>
      )}
      {validate.values.edit && (
        <div className="form_actions d-flex">
          <button
            className={
              "btn primaryBtn largeBtn " +
              (!Changed || Saving ? "disabled" : "")
            }
            type="button"
            onClick={validate.handleSubmit}
          >
            {Saving ? "Saving..." : "Save changes"}
          </button>
          <button
            className={"btn secondaryBtn largeBtn mx-3"}
            type="button"
            onClick={() => {
              validate.setFieldValue("edit", false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}

export default CCCSection;
