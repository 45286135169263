import React, { Component } from "react";
import LeftMenu from "../../LeftMenu/LeftMenu";
import TopHeader from "../../Components/TopHeader/TopHeader";

import { connect } from "react-redux";
import * as actions from "../../Store/actions/registration";
import { bindActionCreators } from "redux";

import registeredRoutes from "../../router";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDashboard: this.props.isDashboard,
            isColapsed: false,
        };
    }
    setIsColapsed = () => {
        this.setState({
            isColapsed: !this.state.isColapsed,
        });
    };
    componentDidMount() {
        const currentPath = window.location.pathname;
        const isDashboard = currentPath.includes('Dashboard');
        this.props.actions.GetLayoutFromStore(isDashboard);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isDashboard !== this.props.isDashboard) {
            this.setState({
                isDashboard: this.props.isDashboard,
            });
        }
    }

    render() {
        const currentPath = window.location.pathname;
        const isDashboard = currentPath.includes('Dashboard');
        return (
            <section className={this.props.isDashboard === true  || isDashboard? "dashboard_page" : "setting_page"}>
                <LeftMenu isColapsed={this.state.isColapsed} setIsColapsed={this.setIsColapsed} />
                <div id="parentMenuClass" className={this.props.isDashboard === true || isDashboard? " " : "setting_container " + (this.state.isColapsed && "subNavOpen")}>
                    <TopHeader title={this.props.stepMainTitle} />
                    {registeredRoutes}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isDashboard: state.registration.isDashboard,
        stepMainTitle: state.registration.stepMainTitle,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
