import React, { useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../Styles/images/icons/upload_icon.svg";
import Warning_48 from "../Styles/images/icons/Warning_48.svg";
import Api from "../Services/Api";
import PreviewFile from "./PreviewFile";
import { SetVatFileUploaded } from "../Store/actions/registration";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import upload_uploading from "../Styles/images/icons/upload_uploading.svg";
import no_attached_file from "../Styles/images/settings/no_attached_file.svg";

function UploadFile(props) {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const { load, setLoad } = props;

    const toastId = useRef(null);

    const notify = () =>
        (toastId.current = toast.info("Uploading " + props.title + "...", {
            autoClose: false,
        }));

    const dismiss = () => toast.dismiss(toastId.current);

    const { getRootProps, getInputProps } = useDropzone({
        disabled : load ,
        maxFiles: 1,
        onDragEnter: () => console.log("onDragEnter"),
        onDragLeave: () => console.log("onDragLeave"),

        accept: { "application/pdf": [".pdf"] },

        onDrop: (acceptedFiles) => {
            setLoad(true);
            notify();
            let formData = new FormData();
            formData.append("file", acceptedFiles[0]);
            let header = {
                docType: props.docType ? props.docType : "",
                documentTypeId: props.documentTypeId ? props.documentTypeId : "",
                companyId: props.companyId,
            };

            Api.postFile(props.Api, formData, header).then((res) => {
                if (res === 400 || res === 500) {
                    setLoad(false);
                    dismiss();
                    toast.error("There is an error when try to upload this file please try again", {
                        hideProgressBar: true,
                    });
                } else {
                    setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
                    setLoad(false);
                    dismiss();
                    props.handleChange();
                    dispatch(SetVatFileUploaded(acceptedFiles[0].name, props.id));
                    if (props.Api === `UploadConductFile`) {
                        window.localStorage.setItem(`CodeOfConductFormSignitured`, `uploadedSuccessfully`);
                    }
                }
            });
        },
    });
    
    const removeFile = () => {
        setFiles([]);
        props.removeFile();
    };

    // console.log(`Props Error`,props.errors , `Props touced` , props.touched)
    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <>
            <div className={"upload_items " + (props.className ? props.className : "") + (load ? "uploading" : props.errors && props.touched ? "has-error" : "")} onBlur={() => props.handleBlur()}>
                <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} name={props.fieldName} id={props.fieldName} />
                    {load ? <img src={upload_uploading} className="img-fluid icon" alt="upload" /> : props.errors && props.touched ? <img src={Warning_48} className="img-fluid icon" alt="upload" /> : <img src={upload} className="img-fluid icon" alt="upload" />}
                    {load ? <p className="title">Uploading...</p> : props.errors && props.touched ? <p className="title">This document is required, Click or drag file to this area to upload</p> : <p className="title">Click or drag file to this area to upload</p>}

                    {load ? <p className="title"></p> : <p className="hint">Supported formats: PDF only</p>}
                </div>
            </div>
            {files.length > 0 ? (
                <PreviewFile fileName={files[0].name} removeFile={removeFile} />
            ) : props.fileName != "" && props.fileName != null ? (
                <PreviewFile fileName={props.fileName} removeFile={removeFile} />
            ) : (
                <div className="uploaded_file">
                    <div className="file empty">
                        <img src={no_attached_file} className="img-fluid icon" alt="icon" />
                        <span>No document uploaded</span>
                    </div>
                </div>
            )}
        </>
    );
}

export default UploadFile;
