import React, { Component } from "react";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.css";
import "./Styles/css/fontawesome5-all.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Styles/SCSS/en-us/layout.css";
import "./custom.css";
import RegistrationForm from "./Registration/RegistrationForm";
import { Provider } from "react-redux";
import configureStore from "./Store/ConfigureStore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Login from "./Login/Login";
import ForgetPasword from "./Login/ForgetPasword";
import ChangePassword from "./Login/ChangePassword";
import CompanyProfile from "./CompanyProfile/CompanyProfile";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./TermsOfUse/TermsOfUse";
import Dashboard from "./Dashboard/Dashboard";
import SettingVendorCategories from "./SettingVendorCategories/SettingVendorCategories";
import SharedCompanyProfile from "./SharedCompanyProfile/SharedCompanyProfile";

import TermsAndCon from "./TermsAndConditions/TermsAndCon";
import ChangePasswordSettings from "./ChangePassword/ChangePasswordSettings";
import StepSix from "./Registration/StepSix";
import SecuritySettings from "./SecuritySettings/SecuritySettings";
import GeneralPreferences from "./GeneralPreferences/GeneralPreferences";
import NotificationsSettings from "./Notifications/NotificationsSettings";

import Config from "./Services/Config";
import Menu from "./Components/Menu/Menu";
import ContactSupportModal from "./Components/ContactSupportModal/ContactSupportModal";
import ChangeCompanyAccountModal from "./Components/ChangeCompanyAccountModal/ChangeCompanyAccountModal";
import CompanyProfileShare from "./CompanyProfile/CompanyProfileShare";
import CheckListProfileCompletness from "./Components/DashboardComponents/CheckListProfileCompletness";
import NotFoundPage from "./not-found-page/NotFoundPage";
import ContinueRegistration from "./Components/ContainueRegistration/containueRegisteration";
import BackToTopButton from "./Components/BackToTopButton";
import Loading from "./Components/Loading";

const store = configureStore();



const IsAuthorize = Config.IsAuthorizedLeftMenu();
class App extends Component {
  componentDidMount() {

    if (localStorage.getItem("loginCounter") == 0) {
      setTimeout(() => {
        localStorage.setItem("loginCounter", 1);
      }, 3000);
    }
    if (localStorage.getItem("cs") === null) {
      window.localStorage.setItem("cs", 1);
    }
    /* to top*/
    // $("body").append('<div className="back-to-top-btn"><i className="fas fa-arrow-up"></i></div>');
    // $(window).scroll(function () {
    //   if ($(this).scrollTop() != 0) {
    //     $(".back-to-top-btn").fadeIn();
    //   } else {
    //     $(".back-to-top-btn").fadeOut();
    //   }
    // });

    // $(".back-to-top-btn").on("click", function () {
    //   $("html, body").animate({ scrollTop: 0 });
    //   return false;
    // });

    // or simply just AOS.init();
    AOS.init({
      // initialise with other settings
      duration: 1600,
    });
  }

  render() {
    const signIntoDashboard = IsAuthorize ? (
      <>
        <Menu />
      </>
    ) : (
      <>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route key="registeredRoutes-route-004" path="/Dashboard/:id" element={<Loading />} />
          <Route path="/CompanyShare/:id" element={<CompanyProfileShare />} />
          <Route path="/" exact element={<Login />} />
          <Route path="/Login" exact element={<Login />} />
          <Route path="/RegistrationForm" exact element={<RegistrationForm />} />

          <Route path="/continue-registration" exact element={<ContinueRegistration />} />

          <Route path="/SharedCompanyProfile/:companyId" exact element={<SharedCompanyProfile />} />
          <Route path="/CompanyDetails" exact element={<CompanyProfile />} />
          <Route path="/PrivacyPolicy" exact element={<PrivacyPolicy />} />
          <Route path="/TermsOfUse" exact element={<TermsOfUse />} />
          <Route path="/SettingsContainer/TermsAndConditions" exact element={<TermsAndCon />} />
          <Route path="/Dashboard" exact element={<Dashboard />} />
          <Route path="/ForgetPasword" exact element={<ForgetPasword />} />

          <Route path="/ChangePasswordSettings" exact element={<ChangePasswordSettings />} />
          <Route path="/ChangePassword" exact element={<ChangePassword />} />

          <Route path="/SettingsContainer/SecuritySettings" exact element={<SecuritySettings />} />
          <Route path="/SettingsContainer/AccountPreferences" exact element={<GeneralPreferences />} />
          <Route path="/SettingsContainer/NotificationsSettings" exact element={<NotificationsSettings />} />

          <Route path="/SettingVendorCategories" exact element={<SettingVendorCategories />} />
          <Route path="/ChangePassword/:mail/:code" exact element={<ChangePassword />} />
          <Route path="/ChangePassword/:mail/:code" exact element={<ChangePassword />} />
          <Route key="registeredRoutes-route-005" path="/ForgetPasword" exact element={<ForgetPasword />} />
        </Routes>
      </>
    );

    return (
      <Provider store={store}>
        <BrowserRouter>
          {signIntoDashboard}
          <ToastContainer />
          <ContactSupportModal />
          <ChangeCompanyAccountModal />
          <CheckListProfileCompletness />
          <BackToTopButton />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
