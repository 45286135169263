import React from 'react';
import { useController } from 'react-hook-form';
import { Form } from 'antd';
// import { Input } from '@mui/material';

const MUITextField = ({
  control,
  name,
  type,
  label,
  placeholder,
  prefix,
  validateStatus,
  className,
  errorMsg,
  id,
  disabled,
  setcount,
  isplace
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });

  return (
    <Form.Item label={label} help={errorMsg} validateStatus={validateStatus} colon={false}>

      <label className="control-label" htmlFor={id}>
        {placeholder}
      </label>
      <input
        autoComplete="off"
        type={type}
        {...field}
        className={className}
        placeholder={!isplace ? placeholder : ""}
        id={id}
        onFocus={() => {
          setcount((p) => p + 1)
        }}
        onBlur={() => {
          setcount((p) => p + 1)
        }}
        onKeyUp={() => {
          setcount((p) => p + 1)
        }}
        disabled={disabled}
      // onChange={() => setIsAnyChanges(false)}
      />
    </Form.Item>
  );
};

export default MUITextField;
