import { useEffect, useState } from "react";
import CloseSVG from "../../Styles/images/icons/popup_close.svg";
import EPCSVG from "../../Styles/images/epic.png";
import CheckmarkedCircle_empty from "../../Styles/images/settings/CheckmarkedCircle_empty.svg";
import CheckmarkedCircle from "../../Styles/images/settings/CheckmarkedCircle.svg";
import ModalContext from "../../Store/ModalProvider";
import { useContext } from "react";
import axios from "axios";
import config from "../../IP_Configrations.json";
import { useNavigate } from "react-router-dom";
import DataServices from "../../Services/DataServices";

const host = config.static;

const CheckListProfileCompletness = () => {
  const userToken = localStorage.getItem("user_token");
  const { chooseCompanyId, setchooseCompanyId, setModalOpenProfile, modalOpenProfile, setCompanyCompeltenes } =
    useContext(ModalContext);
  const [CompanyList, setCompanyList] = useState();
  const [activeStep, setActiveStep] = useState(1);
  const [dataDashboard, setDataDashboard] = useState();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [company, setCompany] = useState({});
  const companyId = localStorage.getItem("CompanyId");

  useEffect(() => {}, []);
  useEffect(() => {
    if (userToken) {
      const getCompaniesList = async () => {
        axios
          .get(`${host}GetCompaniesForList`, {
            headers: {
              Authorization: userToken,
            },
          })
          .then((res) => {
            setCompanyList(res.data.items);
          })
          .catch((err) => console.log(err));
      };
      // console.log("jj", userToken);
      getCompaniesList();
    }

    if (companyId && modalOpenProfile) {
      DataServices.GetRowById(`GetContactPersonForEdit?companyId=${companyId}`).then((res) => {
        setUserInfo(res);
      });

      DataServices.GetRowById(`GetCOMPANIESForEdit?companyId=${companyId}`).then((res) => {
        setCompany(res);
      });
    }
  }, [userToken, modalOpenProfile]);

  useEffect(() => {
    // disaptch(SetTabHeaders("Your dashboard"));
    if (chooseCompanyId !== 0) {
      const getDashboardData = async () => {
        await axios
          .get(`${host}GetCompanyDashboard?companyId=${chooseCompanyId}`, {
            headers: {
              Authorization: userToken,
            },
          })
          .then((res) => {
            setDataDashboard(res.data);
            setCompanyCompeltenes(res?.data?.dtoProfileCompleteness?.ProfileCompletenessRatio);
            // console.log(res);
            // if (res?.data?.vendorId === null && res?.data?.statusApproval) {
            //   getDashboardData();
            // }
          })
          .catch((err) => console.log(err));
      };
      getDashboardData();
    }
  }, [chooseCompanyId]);
  const getPage = (page) => {
    setModalOpenProfile(false);
    navigate(page);
    window.location.reload();
  };
  return (
    modalOpenProfile && (
      <div className="popup active popup_contact_support change_company_account checkList-popup">
        <div className="popup__content">
          <div className="popup__body ">
            <div className="head">
              <h5 className="sh5-bold">Get started checklist</h5>
              <span
                className="close"
                onClick={() => {
                  window.localStorage.removeItem("toChooseCompany");
                  window.localStorage.removeItem("chosenCompany");
                  setModalOpenProfile(false);
                }}
              >
                <img src={CloseSVG} className="img-fluid" alt="close" />
              </span>
            </div>
            <div className="content ">
              {/* .setting_page .company_info */}
              {/* <p className="desc">Get started checklist</p> */}
              <div className="right_sec">
                <div className="company_document_progress accordion">
                  <div className="check-header">
                    <div className="active"></div>
                    <div className="active"></div>
                    <div className="active"></div>
                    <div className="active"></div>
                    <div className="active"></div>
                    <div className="active"></div>
                    <div className={`${dataDashboard?.dtoProfileCompleteness?.companyLogo ? "active" : ""}`}></div>
                    <div
                      className={`${dataDashboard?.dtoProfileCompleteness?.aditionalCompanyInfo ? "active" : ""}`}
                    ></div>
                    <div
                      className={`${dataDashboard?.dtoProfileCompleteness?.verifyRecoveryMail ? "active" : ""}`}
                    ></div>
                    <div className={`${dataDashboard?.dtoProfileCompleteness?.companySignature ? "active" : ""}`}></div>
                    <div
                      className={`${dataDashboard?.dtoProfileCompleteness?.companyAddtionalDocuments ? "active" : ""}`}
                    ></div>
                  </div>
                  <ul className="list-unstyled items_list">
                    <a onClick={() => setActiveStep(1)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 1 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img src={CheckmarkedCircle} className="img-fluid icon" alt="done" />
                                <span>Create a user account</span>
                              </a>
                            </li>
                            {/* <span className="required">Required</span> */}
                          </button>
                        </h2>
                        {/* <div
                          id="collapse1"
                          className={
                            "accordion-collapse collapse" +
                            (activeStep == 1 ? "show" : "")
                          }
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">jkjkjkj</div>
                        </div> */}
                      </div>
                    </a>
                    <a onClick={() => setActiveStep(2)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 2 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img src={CheckmarkedCircle} className="img-fluid icon" alt="done" />
                                <span>Verify user email</span>
                              </a>
                            </li>
                            {/* <span className="required">Required</span> */}
                          </button>
                        </h2>
                        {/* <div
                          id="collapse2"
                          className={
                            "accordion-collapse collapse" +
                            (activeStep == 2 ? "show" : "")
                          }
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">jkjkjkj</div>
                        </div> */}
                      </div>
                    </a>
                    <a onClick={() => setActiveStep(3)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 3 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img src={CheckmarkedCircle} className="img-fluid icon" alt="done" />
                                <span>Create company account</span>
                              </a>
                            </li>
                          </button>
                        </h2>
                        {/* <div
                          id="collapse3"
                          className={
                            "accordion-collapse collapse" +
                            (activeStep == 3 ? "show" : "")
                          }
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">jkjkjkj</div>
                        </div> */}
                      </div>
                    </a>
                    <a onClick={() => setActiveStep(4)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 4 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img src={CheckmarkedCircle} className="img-fluid icon" alt="done" />
                                <span>Add registration documents</span>
                              </a>
                            </li>
                          </button>
                        </h2>
                        {/* <div
                          id="collapse4"
                          className={
                            "accordion-collapse collapse" +
                            (activeStep == 4 ? "show" : "")
                          }
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">jkjkjkj</div>
                        </div> */}
                      </div>
                    </a>
                    <a onClick={() => setActiveStep(5)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 5 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img src={CheckmarkedCircle} className="img-fluid icon" alt="done" />
                                <span>Add bank details</span>
                              </a>
                            </li>
                          </button>
                        </h2>
                        {/* <div
                          id="collapse5"
                          className={
                            "accordion-collapse collapse" +
                            (activeStep == 5 ? "show" : "")
                          }
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">jkjkjkj</div>
                        </div> */}
                      </div>
                    </a>
                    <a onClick={() => setActiveStep(6)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 6 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img src={CheckmarkedCircle} className="img-fluid icon" alt="done" />
                                <span>Approve our code of conduct</span>
                              </a>
                            </li>
                          </button>
                        </h2>
                        {/* <div
                          id="collapse6"
                          className={
                            "accordion-collapse collapse" +
                            (activeStep == 6 ? "show" : "")
                          }
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">jkjkjkj</div>
                        </div> */}
                      </div>
                    </a>
                    <a onClick={() => setActiveStep(7)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 7 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img
                                  src={!company?.logo ? CheckmarkedCircle_empty : CheckmarkedCircle}
                                  className="img-fluid icon"
                                  alt="done"
                                />
                                <span>
                                  Add company logo <span className="opt">(Optional)</span>
                                </span>
                              </a>
                            </li>
                          </button>
                        </h2>
                        <div
                          id="collapse7"
                          className={"accordion-collapse collapse" + (activeStep == 7 ? "show" : "")}
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">
                            <p>Reveal your identity, and make a strong impression by adding your logo</p>
                            <a className="link" onClick={() => getPage("SettingsContainer/CompanyInfo")}>
                              Add your company logo
                            </a>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a onClick={() => setActiveStep(8)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 8 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img
                                  src={
                                    !dataDashboard?.dtoProfileCompleteness?.aditionalCompanyInfo
                                      ? CheckmarkedCircle_empty
                                      : CheckmarkedCircle
                                  }
                                  className="img-fluid icon"
                                  alt="done"
                                />
                                <span>
                                  {" "}
                                  Add Additional company info <span className="opt">(Optional)</span>
                                </span>
                              </a>
                            </li>
                          </button>
                        </h2>
                        <div
                          id="collapse8"
                          className={"accordion-collapse collapse" + (activeStep == 8 ? "show" : "")}
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">
                            <p>Add some additional info about your company, to know you more</p>
                            <a className="link" onClick={() => getPage("SettingsContainer/CompanyInfo#additionalInfo")}>
                              Add additional company info
                            </a>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a onClick={() => setActiveStep(9)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 9 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img
                                  src={!userInfo?.verifyRecoveryEmail ? CheckmarkedCircle_empty : CheckmarkedCircle}
                                  className="img-fluid icon"
                                  alt="done"
                                />
                                <span>
                                  Verify recovery email <span className="opt">(Optional)</span>
                                </span>
                              </a>
                            </li>
                          </button>
                        </h2>
                        <div
                          id="collapse9"
                          className={"accordion-collapse collapse" + (activeStep == 9 ? "show" : "")}
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">
                            <p>An alternate email address that helps you get back in if you faced any problem</p>
                            <a className="link" onClick={() => getPage("SettingsContainer/ContactPerson")}>
                              Verify recovery email
                            </a>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a onClick={() => setActiveStep(10)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 10 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img
                                  src={
                                    !dataDashboard?.dtoProfileCompleteness?.companySignature
                                      ? CheckmarkedCircle_empty
                                      : CheckmarkedCircle
                                  }
                                  className="img-fluid icon"
                                  alt="done"
                                />
                                <span>
                                  Add your E-signature <span className="opt">(Optional)</span>
                                </span>
                              </a>
                            </li>
                          </button>
                        </h2>
                        <div
                          id="collapse10"
                          className={"accordion-collapse collapse" + (activeStep == 10 ? "show" : "")}
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">
                            <p>Your signature that will be shown in all your signed documents</p>
                            <a className="link" onClick={() => getPage("SettingsContainer/UserSettings")}>
                              Add E-signature
                            </a>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a onClick={() => setActiveStep(11)}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button sh5-med"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeStep == 11 ? "true" : "false"}
                            aria-controls="collapseOne"
                          >
                            <li>
                              <a href="#" className={"item item-check-list"}>
                                <img
                                  src={
                                    !dataDashboard?.dtoProfileCompleteness?.companyAddtionalDocuments
                                      ? CheckmarkedCircle_empty
                                      : CheckmarkedCircle
                                  }
                                  className="img-fluid icon"
                                  alt="done"
                                />
                                <span>
                                  {" "}
                                  Upload company additional documents <span className="opt">(Optional)</span>
                                </span>
                              </a>
                            </li>
                          </button>
                        </h2>
                        <div
                          id="collapse11"
                          className={"accordion-collapse collapse" + (activeStep == 11 ? "show" : "")}
                          aria-labelledby="headingOne"
                          data-bs-parent="#company_info"
                        >
                          <div className="accordion-body">
                            <p>Some additional documents that related to your company and business</p>
                            <a className="link" onClick={() => getPage("SettingsContainer/CompanyDocuments")}>
                              Add additional documents
                            </a>
                          </div>
                        </div>
                      </div>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CheckListProfileCompletness;
