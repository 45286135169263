import * as types from '../types';
import Api from '../../Services/Api';
import { toast } from "react-toastify";
import { axiosLoginApi } from '../../Services/Axios';

export function login(userObject,callback) {
    console.log(userObject)
    return (dispatch) => {
        return axiosLoginApi.post("Login", {
            email:userObject.mail,
            password:userObject.Password
        }).then(res => {
            callback(res)
            // console.log(res)
            // toast.success("LOGIN Successfully");
            // dispatch({
            //     type: types.LOGIN,
            //     user: userObject
            // });
        }).catch(err =>{
            toast.error(err?.response?.data?.msg ? err.response.data.msg : 'There was an error creating the user please try again');

        });
    }
}

export function sendMail(mail,callback) {
    console.log(mail)
    return (dispatch) => {
        return axiosLoginApi.post("SendEmailToForgotPassword",{},{ params : {
            email:mail,
        }}).then(res => {
            callback(res)
            console.log(res)
            // toast.success("LOGIN Successfully");
            // dispatch({
            //     type: types.LOGIN,
            //     user: userObject
            // });
        });
    }
}

export function ResetPassword(data,callback) {
    console.log(data)
    return (dispatch) => {
        return axiosLoginApi.post("ResetForgotPassword", {
            password:data.password,
            email:data.email,
            code:data.code
        }).then(res => {
            callback(res)
            console.log(res)
            // toast.success("LOGIN Successfully");
            // dispatch({
            //     type: types.LOGIN,
            //     user: userObject
            // });
        });
    }
}