import React, {useState, useEffect, useContext} from "react";
import {Formik, Form, useFormik} from "formik";
import * as Yup from "yup";

import PasswordImg from "../Styles/images/icons/Password.svg";
import ShowPass from "../Styles/images/icons/ShowPass.svg";
import HidePass from "../Styles/images/icons/HidePass.svg";
import success_pass_icon from "../Styles/images/icons/success_pass_icon.svg";
import checkmarkedCircle_20 from "../Styles/images/icons/checkmarkedCircle-20.svg";
import error_24_updated from "../Styles/images/icons/error_24_updated.svg";
import error_24 from "../Styles/images/icons/error-24.svg";
import Warning from "../Styles/images/icons/Warning.svg";

import {useDispatch} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import {ResetPassword} from "../Store/actions/login";

import styles from './changePass.module.css'

const ChangePasswordSettings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

	const [currPassword, setCurrPassword] = useState('')
    const [showCurrPassword, setShowCurrPassword] = useState(false)

    const [newPassword, setNewPassword] = useState('')
    const [showNewPassword, setShowNewPassword] = useState(false)

    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

    const [errorMessage, setErrorMessage] = useState(``);
    const [isLoading, setIsLoading] = useState(false);
    const validationChangePassword = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        

        validationSchema: Yup.object().shape({
            password: Yup.string()
                .min(8, "Pasword must be 9 or more characters")
                .matches(
                    /(?=.*[a-z])(?=.*[A-Z])\w+/,
                    "Password should contain at least one uppercase and lowercase character"
                )
                .matches(/\d/, "Password should contain at least one number")
                .matches(
                    /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
                    "Password should contain at least one special character"
                )
                .required("the password is weak and does not meet the requirements"),

            passwordConfirmation: Yup.string()
                .required("Pasword must be 9 or more characters")
                .oneOf([Yup.ref("password")], "The passwords do not match")
                .required("Pasword must be 9 or more characters"),
        }),

        onSubmit: (values, actions) => {
            // console.log(values)
            setIsLoading(true);
            dispatch(
                ResetPassword(
                    {
                        password: values.password,
                        email: params.mail,
                        code: params.code,
                    },
                    (result) => {
                        console.log(`Result`, result);
                        if (result.data.status === 200) {
                            setIsLoading(false);
                            setTimeout(() => {
                                navigate(`/Login`);
                            }, 3000);
                            setErrorMessage(``);
                        } else {
                            setIsLoading(false);
                            setErrorMessage(result.data.msg);
                        }
                    }
                )
            );
        },
    });

    const isLengthGreaterThan = (str, length) => {
        let word = str || "";

        return word.length >= length;
    };

    const isContainsUppercas = (str) => {
        return /[A-Z]/.test(str);
    };

    const isContainsLowercas = (str) => {
        return /[a-z]/.test(str);
    };

    const isContainsOneNumbe = (str) => {
        return /\d/.test(str);
    };

    const isContainSpecialChar = (str) => {
        return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str);
    };

	const handleSubmit = () => {

	}
	
    return (
        <div className={styles.container}>
            <div className={styles.input_container}>
                
                <input
                    autoComplete="off"
                    required
                    type={showCurrPassword ? "text" : "password"}
                    className={`form-control ${styles.input}`}
                    id="curr_password"
                    name="curr_password"
                    value={currPassword}
                    onChange={(e) => {
                        setCurrPassword(e.target.value);
                    }}
                />
                <label className={styles.label} htmlFor="curr_password">
                    Current password*
                </label>
                <img
                    src={PasswordImg}
                    className={styles.pass_icon}
                    alt="password"
                />
                {showCurrPassword ? (
                    <img
                        onClick={() => {
                            setShowCurrPassword(!showCurrPassword );
                        }}
                        src={HidePass}
                        className={styles.show_hide}
                        alt="HidePass"
                    />
                ) : (
                    <img
                        onClick={() => {
                            setShowCurrPassword(!showCurrPassword );
                        }}
                        src={ShowPass}
                        className={styles.show_hide}
                        alt="ShowPass"
                    />
                )}
            </div>
            <div className={styles.input_container}>
                
                <input
                    autoComplete="off"
                    required
                    type={showNewPassword ? "text" : "password"}
                    className={`form-control ${styles.input}`}
                    id="new_password"
                    name="new_password"
                    value={newPassword}
                    onChange={(e) => {
                        setNewPassword(e.target.value);
                    }}
                />
                <label className={styles.label} htmlFor="new_password">
                    Enter new password *
                </label>
                <img
                    src={PasswordImg}
                    className={styles.pass_icon}
                    alt="password"
                />
                {showNewPassword ? (
                    <img
                        onClick={() => {
                            setShowNewPassword(!showNewPassword);
                        }}
                        src={HidePass}
                        className={styles.show_hide}
                        alt="HidePass"
                    />
                ) : (
                    <img
                        onClick={() => {
                            setShowNewPassword(!showNewPassword);
                        }}
                        src={ShowPass}
                        className={styles.show_hide}
                        alt="ShowPass"
                    />
                )}

                    {validationChangePassword.errors.password &&
                    validationChangePassword.touched.password ? (
                        <img
                            src={error_24}
                            className="img-fluid right_icon2"
                            alt="coPassword"
                        />
                    ) : !validationChangePassword.errors.password &&
                    validationChangePassword.touched.password ? (
                        <img
                            src={checkmarkedCircle_20}
                            className="img-fluid right_icon2"
                            alt="coPassword"
                        />
                    ) : (
                        " "
                    )}
                </div>
                <div className={`help-text password_help ${styles.help_text}`}>
                    <p
                        className={
                            "text " +
                            (isLengthGreaterThan(newPassword, 9) == true
                                ? " has-success "
                                : " has-error ")
                        }
                    >
                        <img
                            src={
                                isLengthGreaterThan(newPassword, 9) == true
                                    ? checkmarkedCircle_20
                                    : error_24_updated
                            }
                            className="img-fluid"
                            alt="close"
                        />

                        <span>Must be at least 9 numbers</span>
                    </p>

                    <p
                        className={
                            "text " +
                            (isContainsUppercas(newPassword) == true
                                ? " has-success "
                                : " has-error ")
                        }
                    >
                        <img
                            src={
                                isContainsUppercas(newPassword) == true
                                    ? checkmarkedCircle_20
                                    : error_24_updated
                            }
                            className="img-fluid"
                            alt="close"
                        />

                        <span>One uppercase letter (eg. XYZ)</span>
                    </p>

                    <p
                        className={
                            "text " +
                            (isContainsLowercas(newPassword) == true
                                ? " has-success "
                                : " has-error ")
                        }
                    >
                        <img
                            src={
                                isContainsLowercas(newPassword) == true
                                    ? checkmarkedCircle_20
                                    : error_24_updated
                            }
                            className="img-fluid"
                            alt="close"
                        />
                        <span>One lowercase letter (eg. xyz) </span>
                    </p>

                    <p
                        className={
                            "text " +
                            (isContainsOneNumbe(newPassword) == true
                                ? " has-success "
                                : " has-error ")
                        }
                    >
                        <img
                            src={
                                isContainsOneNumbe(newPassword) == true
                                    ? checkmarkedCircle_20
                                    : error_24_updated
                            }
                            className="img-fluid"
                            alt="close"
                        />
                        <span>One number (eg. 123) </span>
                    </p>

                    <p
                        className={
                            "text " +
                            (isContainSpecialChar(newPassword) == true
                                ? " has-success "
                                : " has-error ")
                        }
                    >
                        <img
                            src={
                                isContainSpecialChar(newPassword) == true
                                    ? checkmarkedCircle_20
                                    : error_24_updated
                            }
                            className="img-fluid"
                            alt="close"
                        />

                        <span>At least 1 special character (eg. @#)</span>
                    </p>
                </div>
            <div className={styles.input_container}>
                
                <input
                    autoComplete="off"
                    required
                    type={showConfirmNewPassword ? "text" : "password"}
                    className={`form-control ${styles.input}`}
                    id="con_password"
                    name="con_password"
                    value={confirmNewPassword}
                    onChange={(e) => {
                        setConfirmNewPassword(e.target.value);
                    }}
                />
                <label className={styles.label} htmlFor="con_password">
                Confirm new password *
                </label>
                <img
                    src={PasswordImg}
                    className={styles.pass_icon}
                    alt="password"
                />
                {showConfirmNewPassword ? (
                    <img
                        onClick={() => {
                            setShowConfirmNewPassword(!showConfirmNewPassword);
                        }}
                        src={HidePass}
                        className={styles.show_hide}
                        alt="HidePass"
                    />
                ) : (
                    <img
                        onClick={() => {
                            setShowConfirmNewPassword(!showConfirmNewPassword);
                        }}
                        src={ShowPass}
                        className={styles.show_hide}
                        alt="ShowPass"
                    />
                )}
            </div>
        </div>
    )

}

export default ChangePasswordSettings;

{/* <label className={styles.label} htmlFor="password">
                    Current password*
                </label>
                <img
                    src={PasswordImg}
                    className={styles.pass_icon}
                    alt="password"
                />
                 <img src={ShowPass} className="img-fluid showPass right_icon" alt="ShowPass" />
                <img src={HidePass} className="img-fluid showPass right_icon" alt="ShowPass" /> 
                 */}

