import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { SetTabHeaders } from "../../../../Store/actions/registration";
import DataServices from "../../../../Services/DataServices";
import Loading from "../../../Loading";
import VATSection from "./VATSection";
import CRNumSection from "./CRNumSection";
import NASection from "./NASection";
import ZakatSection from "./ZakatSection";
import RightSection from "./RightSection";
import RepresentativeSection from "./RepresentativeSection";
import AOASection from "./AOASection";
import COCRSection from "./COCRSection";
import QCSection from "./QCSection";
import LCSection from "./LCSection";
import CCCSection from "./CCCSection";
import COCCSection from "./COCCSection";
import ILCSection from "./ILCSection";
import CPPSection from "./CPPSection";
import { useContext } from "react";
import ModalContext from "../../../../Store/ModalProvider";
import API from "../../../../Services/Axios";

function CompanyDocuments() {
  const companyId = localStorage.getItem("CompanyId");
  const { setCompanyCompeltenes } = useContext(ModalContext);
  const [Saving, setSaving] = useState(false);
  const [Load, setLoad] = useState(true);

  const [AdditionalAttachments, setAdditionalAttachments] = useState({
    AOA: false,
    COCR: false,
    QC: false,
    LC: false,
    CCC: false,
    COCC: false,
    ILC: false,
    CPP: false,
  });

  const [CompanyDocument, setCompanyDocument] = useState({});

  const dispatch = useDispatch();

  const animateInputs = () => {
    const inputs = document.querySelectorAll(".input");
    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function (e) {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value == "") {
          input.classList.remove("focused");
        }
      });
    });
  };

  useEffect(() => {
    setLoad(true);
    animateInputs();
    dispatch(SetTabHeaders("Company documents"));

    DataServices.GetRowById(`GetCompanyDocumentDetailsById?companyId=${companyId}`).then((res) => {
      setCompanyDocument(res);
      setLoad(false);
    });

    // DataServices.GetRowById(`GetCompanyDashboard?companyId=${companyId}`).then(
    //   (res) => {
    //     setCompanyCompeltenes(
    //       res?.dtoProfileCompleteness?.profileCompletenessRatio
    //     );
    //   }
    // );
  }, []);

  const SaveChanges = (obj, title) => {
    setSaving(true);
    const Obj = {
      ...CompanyDocument,
      ...obj,
    };
    DataServices.addObject("UpdateCompanyDocumentDetails", Obj).then((res) => {
      toast.success(title + " saved", { hideProgressBar: true });
      setSaving(false);
      setCompanyDocument(res);
    });
  };

  const SaveAdditionalChanges = async (obj, title) => {
    setSaving(true);
    const Obj = {
      companyId: companyId,
      IsSuadia: CompanyDocument?.isSaudia,
      ...obj,
    };
    return await API.post('UpdateAdditionalDocuments', Obj).then(res => {
        toast.success(title + " saved", { hideProgressBar: true });
        setSaving(false);
        return true
    }).catch(err => {
      setSaving(false);
      toast.error('there was an error please try again');
      return false;
    })

  };

  const propsObj = {
    CompanyDocument: CompanyDocument,
    SaveChanges: SaveChanges,
    SaveAdditionalChanges: SaveAdditionalChanges,
    Saving: Saving,
    companyId: companyId,
    setAdditionalAttachments: setAdditionalAttachments,
  };

  if (Load) return <Loading />;
  return (
    <div className="company_info company_document">
      <div className="left_sec">
        <div className="proForm">
          <div className="company_document__sec">
            <div className="content">
              <div className="main_head">
                <div className="text">
                  <h4 className="sh4-bold">Primary Documents</h4>
                </div>
                <span className="req">Required</span>
              </div>
              {CompanyDocument?.isSaudia && <VATSection {...propsObj} />}
            </div>

            <CRNumSection {...propsObj} />

            {CompanyDocument?.isSaudia && <NASection companyId={companyId} />}

            {CompanyDocument?.isSaudia && <ZakatSection {...propsObj} />}

            <RepresentativeSection {...propsObj} />

            <div className="content with_main_head">
              <div className="main_head">
                <div className="text">
                  <h4 className="sh4-bold">Additional documents</h4>
                </div>
                <span className="req">Optional</span>
              </div>
              {CompanyDocument?.isSaudia && <AOASection {...propsObj} />}
            </div>

            {CompanyDocument?.isSaudia && <COCRSection {...propsObj} />}

            <QCSection {...propsObj} />

            <LCSection {...propsObj} />

            {CompanyDocument?.businessTypeId === 5711 && <CCCSection {...propsObj} />}

            {CompanyDocument?.businessTypeId === 5712 && <COCCSection {...propsObj} />}

            {CompanyDocument?.businessTypeId === 5709 && <ILCSection {...propsObj} />}

            <CPPSection {...propsObj} />
          </div>
        </div>
      </div>
      <RightSection CompanyDocument={CompanyDocument} AdditionalAttachments={AdditionalAttachments} />
    </div>
  );
}

export default CompanyDocuments;
