/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import dashboardLogo from "../../Styles/images/dashboard/logo.svg";
import CloseSVG from "../../Styles/images/dashboard/Close.svg";
import FullCorrect from "../../Styles/images/dashboard/full_corrrect.svg";

const DashboardApprovalModal = ({ openModal, setOpenModal, dataDashboard }) => {
  console.log(dataDashboard, "dataDashboard");
  const closeModal = () => {
    setOpenModal(false);
    localStorage.setItem("modalApproval", false);
  };

  return (
    <section className="dashboard_page approve">
      <header className="header">
        <div className="box">
          <a href="#" className="logo">
            <img src={dashboardLogo} className="img-fluid" alt="img-fluid" />
          </a>
          <div onClick={closeModal} className="close">
            <a href="#">
              <img src={CloseSVG} className="img-fluid" alt="close" />
            </a>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="icon">
          <img src={FullCorrect} className="img-fluid" alt="correct" />
        </div>
        <div className="text">
          <h1 className="title">You’re registered now with fgc</h1>
          <h3 className="sh3-bold">As a vendor</h3>
          <h6 className="sh6-med">
            Your Vendor ID is :<h3 className="sh3-bold">({dataDashboard.vendorId})</h3>
          </h6>
          <h6 className="sh6-med">Please use this number in any invoicing, quotations, or correspondence with us.</h6>
          <button className="btn secondaryBtn mediumBtn" onClick={closeModal}>
            Ok, Done
          </button>
        </div>
      </div>
    </section>
  );
};

export default DashboardApprovalModal;
