import { createStore, applyMiddleware , compose } from 'redux';
import thunkMiddleware  from 'redux-thunk'; 
import rootReducer from './reducer';

let middleware = [thunkMiddleware];
 const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
 
 export default function configureStore(initialState) {
    return createStore(
        rootReducer,
         initialState,
         composeEnhancers( applyMiddleware(...middleware)),
    );
}
