import './NotFound.styles.css';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NotFoundPage = () => {

  return (
    <div className="notFoundPage">
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <h1 className="display-1 fw-bold">404</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger">Opps!</span> Page not found.
          </p>
          <p className="lead">The page you’re looking for doesn’t exist.</p>
          <RouterLink to={'/'} className='btn secondaryBtn largeBtn mt-3'>
            Dashboard
          </RouterLink>
        </div>
      </div>

    </div>
  );
};

export default NotFoundPage;
