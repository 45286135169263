import React, {useEffect, useState} from 'react'
import logo from '../Styles/images/FGC_Secondary_logo_EN.svg';
import email from "../Styles/images/icons/Email.svg"
import Phone_16 from "../Styles/images/icons/Phone_16.svg"
import email_16 from "../Styles/images/icons/email_16.svg"
import forget_pass_icon from "../Styles/images/icons/forget_pass_icon.svg"
import emailverfication_icon from "../Styles/images/icons/emailverfication_icon.svg"
import {Link} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {sendMail} from '../Store/actions/login';
import Warning from "../Styles/images/icons/Warning.svg"


const ForgetPasword = () => {
    const [step, setStep] = useState(1)
    const [mail, setMail] = useState(``)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(``)
    console.log(errorMessage)
    const dispatch = useDispatch()


    useEffect(() => {
        const inputs = document.querySelectorAll(".input.large");

        inputs.forEach(input => {
            input.addEventListener("focusin", function () {
                input.classList.add("focused");
            }, false)
            input.addEventListener("focusout", function () {
            })
        })
    }, [])

    const SendMail = (mail) => {
        if (mail !== ``) {
            setIsLoading(true)
            dispatch(sendMail(mail, (result) => {
                console.log(`Result`, result)
                if (result.data.status === 200) {
                    setErrorMessage(``)
                    setIsLoading(false)
                    setStep(2)
                    // console.log(`LoggedIn`)
                } else {
                    setIsLoading(false)
                    setErrorMessage(result.data.msg)
                    // setErrorMessage(result.data.msg)

                }
            }))
        } else {

        }
    }
    return (
        <>
            {step === 1 ? (
                    <section className="register_page login_page">
                        <div className="register_container">
                            <header className="header">
                                <div className="box">
                                    <a href="#" className="logo">
                                        <img src={logo} className="img-fluid"/>
                                    </a>
                                    <div className="account">
                                        <Link to={'/RegistrationForm'}>
                                            <p className="text">Don’t have an account? <a href="#"
                                                                                          className="link btn textBtn smallBtn">Create
                                                a new vendor
                                                account</a></p>
                                        </Link>
                                    </div>
                                </div>
                            </header>
                            <div className="register_content">
                                <div className="sec_head text-center">
                                    <img src={forget_pass_icon} className="img-fluid icon" alt="icon"/>
                                    <h3 className="h3-bold">Forgot your password?</h3>
                                    <h6 className="sh6-med desc">Enter your email and we’ll send you a link to reset your
                                        password</h6>
                                </div>
                                <div className="mb-80 proForm">
                                    {errorMessage !== `` ? (
                                        <div className=" sh5-med" style={{
                                            display: 'flex',
                                            justifyContent: `center`,
                                            backgroundColor: `#FFF1ED`,
                                            alignItems: `center`,
                                            padding: 16,
                                            minHeight: 56,
                                            borderRadius: 8,
                                            border: `1px solid #E94225`,
                                            marginBottom: 24,
                                            color: `#84101D`
                                        }}>
                                            <img src={Warning} style={{marginRight: 9}} className="img-fluid icon"
                                                 alt="warning"/>
                                            <span>{errorMessage}</span>
                                        </div>
                                    ) : null}
                                    <div className="form-group">
                                        <div className="input withIcon large">
                                            <label className="control-label" htmlFor="mail">Email address *</label>
                                            <img src={email} className="img-fluid left_icon" alt="mail"/>
                                            <input
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    setMail(e.target.value)
                                                }}
                                                type="text"
                                                className="form-control"
                                                id="mail"
                                                name="mail"
                                            />
                                        </div>
                                        <div className="help-text">
                                        </div>
                                    </div>


                                    <div className="form_actions">
                                        <button disabled={isLoading} onClick={() => {
                                            SendMail(mail)
                                        }} className={`btn primaryBtn largeBtn ${isLoading ? `disabled` : ``} w-100`}>Send
                                            link
                                        </button>
                                        <Link to={"/Login"}>
                                            <button className="btn textBtn largeBtn w-100"> Return to login</button>
                                        </Link>
                                    </div>
                                </div>

                                <div className="contact_info hasbg">
                                    <p className="title">For any help contact our support at: </p>
                                    <ul className="list-unstyled contact_info__list">
                                        <li>
                                            <a href="tel:+966 11 219 0888" className="link">
                                                <img src={Phone_16} className="img-fluid icon" alt="phone"/>
                                                <span className="text">+966 11 219 0888</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="mailto:portal.support@fgc.sa" className="link">
                                                <img src={email_16} className="img-fluid icon" alt="email"/>
                                                <span className="text">portal.support@fgc.sa</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                )
                : (
                    <section className="register_page login_page">
                        <div className="register_container">
                            <header className="header">
                                <div className="box">
                                    <a href="#" className="logo">
                                        <img src={logo} className="img-fluid"/>
                                    </a>
                                    <div className="account">
                                        <p className="text">Don’t have an account? <a href="#"
                                                                                      className="link btn textBtn smallBtn">Create
                                            a new vendor
                                            account</a></p>
                                    </div>
                                </div>
                            </header>
                            <div className="register_content">
                                <div className="sec_head text-center">
                                    <h3 className="h3-bold">Look for an email in your inbox</h3>
                                    <h6 className="sh6-med desc">We sent a recovery link to you at</h6>
                                    <h6 className="sh6-bold">{mail}</h6>
                                </div>
                                <div className="mb-80 proForm">
                                    <div className="text-center">
                                        <img src={emailverfication_icon} className="img-fluid icon" alt="icon"/>
                                    </div>

                                    <div className="mb-80 form_actions text-center">
                                        <button
                                            disabled={isLoading}
                                            onClick={() => {
                                                console.log(mail)
                                                SendMail(mail)
                                            }}
                                            className={`btn secondaryBtn largeBtn ${isLoading ? `disabled` : ``} d-inline-flex`}>Resend
                                            link
                                        </button>
                                    </div>
                                </div>

                                <div className="contact_info hasbg">
                                    <p className="title">For any help contact our support at: </p>
                                    <ul className="list-unstyled contact_info__list">
                                        <li>
                                            <a href="tel:+966 11 219 0888" className="link">
                                                <img src={Phone_16} className="img-fluid icon" alt="phone"/>
                                                <span className="text">+966 11 219 0888</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="mailto:portal.support@fgc.sa" className="link">
                                                <img src={email_16} className="img-fluid icon" alt="email"/>
                                                <span className="text">portal.support@fgc.sa</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
        </>
    )
}

export default ForgetPasword
