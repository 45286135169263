import React from "react";

import SupportSVG from "../../Styles/images/dashboard/Support.svg";
import PhoneDark from "../../Styles/images/icons/phone_dark.svg";
import EmailDark from "../../Styles/images/icons/Email_dark.svg";
import ClockSVG from "../../Styles/images/icons/clock_.svg";

const DashboardSupport = () => {
  return (
    <div className="support">
      <div className="support__icon">
        <img src={SupportSVG} className="img-fluid" alt="Support" />
      </div>
      <h4 className="h4-bold">Need assistance?</h4>
      <p className="desc">Don’t worry, we can help</p>
      <ul className="list-unstyled contact_info">
        <li>
          <a href="tel:+966-11-219-0888" className="link">
            <img src={PhoneDark} className="img-fluid icon" alt="phone" />
            <span className="h6-reg">+966-11-219-0888</span>
          </a>
        </li>
        <li>
          <a href="mailto:portal.support@fgc.sa" className="link">
            <img src={EmailDark} className="img-fluid icon" alt="email" />
            <span className="h6-reg">portal.support@fgc.sa</span>
          </a>
        </li>
        <li>
          <p className="link">
            <img src={ClockSVG} className="img-fluid icon" alt="clock" />
            <span className="h6-reg">
              Working hours: Sun-Thu / 08:00 AM - 05:00 PM (GMT +1)
            </span>
          </p>
        </li>
      </ul>
    </div>
  );
};

export default DashboardSupport;
