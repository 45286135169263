import logo from "../Styles/images/FGC_Secondary_logo_EN.svg";

import React, { Component } from "react";

import RegisterHead from "../Registration/RegisterHead";
import RightSteps from "../Components/RightSteps";

import StepOne from "../Registration/StepOne";
import StepTwo from "../Registration/StepTwo";
import StepThird from "../Registration/StepThird";
import StepFour from "../Registration/StepFour";
import StepFive from "../Registration/StepFive";
import StepSix from "../Registration/StepSix";

import { connect } from "react-redux";
import * as actions from "../Store/actions/registration";
import { bindActionCreators } from "redux";
import StepSeven from "./StepSeven";
import Loading from "../Components/Loading";
import ClearDataModal from "./ClearDataModal";
import { Link } from "react-router-dom";

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowModal: false,
      show: true,
      user: JSON.parse(window.localStorage.getItem("u")),
    };
  }

  componentDidMount() {
    this.props.actions.updateReduxStore();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeStep !== this.props.activeStep) {
      if (this.state.user?.id && this.state.show) {
        if (window.localStorage.getItem("continue registration") === "true") return;
        this.setState({
          ShowModal: true,
          show: false,
        });
      }
    }
  }

  hideModal = () => {
    this.setState({
      ShowModal: false,
    });
  };

  render() {
    return (
      <>
        {this.props.currentStep == 7 ? (
          <StepSeven />
        ) : (
          <>
            <section className="register_page">
              <div className="register_container">
                <header className="header">
                  <div className="box">
                    <Link
                      to={"/"}
                      className="logo"
                      onClick={() =>
                        this.state.user?.id
                          ? this.setState({
                              ShowModal: true,
                            })
                          : null
                      }
                    >
                      <img src={logo} className="img-fluid" />
                    </Link>
                    <div className="account">
                      <p className="text">
                        Already have an account?
                        <Link to="/login" className="link btn textBtn smallBtn">
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </header>

                <div className="register_content">
                  <RegisterHead />
                  {this.props.isLoading == true ? (
                    <Loading />
                  ) : (
                    <>
                      {this.props.activeStep == 1 ? <StepOne /> : null}
                      {this.props.activeStep == 2 ? <StepTwo /> : null}
                      {this.props.activeStep == 3 ? <StepThird /> : null}
                      {this.props.activeStep == 4 ? <StepFour /> : null}
                      {this.props.activeStep == 5 ? <StepFive /> : null}
                      {this.props.activeStep == 6 ? <StepSix /> : null}
                    </>
                  )}
                </div>
              </div>

              <RightSteps />
            </section>
            <ClearDataModal ShowModal={this.state.ShowModal} hideModal={this.hideModal} />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentStep: state.registration.currentStep,
    activeStep: state.registration.activeStep,
    isLoading: state.registration.isLoading,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
