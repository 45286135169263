import { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Loading from "../Components/Loading/index";
import ProfileCompletness from "../Components/ProfileCompletness/ProfileCompletness";
import { useSelector, useDispatch } from "react-redux";
import { SetTabHeaders } from "../Store/actions/registration";
import config from "../IP_Configrations.json";
import ModalContext from "../Store/ModalProvider";
import { useContext } from "react";
import castle_bg from "../Styles/images/icons/castle_bg.svg";
import { toast } from "react-toastify";
import API from "../Services/Axios";
const host = config.static;

const SettingVendorCategories = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.registration.currentStep);

  const userToken = localStorage.getItem("user_token");
  const CompanyId = localStorage.getItem("CompanyId");

  const [VendorCategories, setVendorsCategories] = useState();
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategoriesId, setSelectedCategoriesId] = useState([]);
  const [updatedCategories, setUpdatedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [oldCategories, setOldCategories] = useState([]);
  const [isAllIsOld, setIsAllIsOld] = useState(false);
  const [isTyping, setIsTyping] = useState("");
  const [selectedBusinessType, setSelectedBusinessType] = useState();
  useEffect(() => {
    dispatch(SetTabHeaders("Vendor Category", "", currentStep));

    const inputs = document.querySelectorAll(".input.large");

    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function () {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value === "") {
          input.classList.remove("focused");
        }
      });
    });

    if (selectedCategoriesId.length) {
      const isAllIsOld = [];
      for (let c of selectedCategoriesId) {
        if (oldCategories.includes(c)) {
          isAllIsOld.push(true);
        } else {
          isAllIsOld.push(false);
        }

        if (
          isAllIsOld.every((i) => i) &&
          oldCategories.length === selectedCategoriesId.length
        ) {
          setIsAllIsOld(true);
        } else {
          setIsAllIsOld(false);
        }
      }
    }
  }, [categoriesList, selectedCategoriesId]);

  const getCompanyCategory = async () => {
    await axios
      .get(`${host}GetCompaniesCategories?companyId=${CompanyId}`, {
        headers: {
          Authorization: userToken,
        },
      })
      .then((res) => {
        setVendorsCategories(res.data.selectedCategories);
        setSelectedBusinessType(res.data?.businessTypeName);
        let selectedCategories = [];
        res.data.selectedCategories.map((item) =>
          selectedCategories.push(item.categoryId)
        );
        setSelectedCategoriesId(selectedCategories);
        setOldCategories(selectedCategories);
      })
      .catch((err) => console.log(err));
  };

  const getCategoriesList = async () => {
    await axios
      .get(`${host}GetCompanyCategoriesListCustomize`, {
        headers: {
          Authorization: userToken,
        },
      })
      .then((res) => {
        setCategoriesList(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCompanyCategory();
    getCategoriesList();
  }, []);

  const handleCategoryChange = (event) => {
    const category = +event.target.id;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCategoriesId([...selectedCategoriesId, category]);
      setUpdatedCategories([...updatedCategories, category]);
    } else {
      setSelectedCategoriesId(
        selectedCategoriesId.filter((c) => c !== category)
      );
      setUpdatedCategories(updatedCategories.filter((c) => c !== category));
    }
  };

  const updateVendorCategory = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    API.post('AddVendorCategories', {
      companyId: CompanyId,
      selectedCategories: selectedCategoriesId,
    }).then(res =>{
      setIsLoading(false);
      setOldCategories([...selectedCategoriesId]);
      setIsAllIsOld(true);
      toast.success("operation completed successfully")
    }).catch(err =>{
      setIsLoading(false);
      toast.error("There was an error while updating the selected categories .... please try again later")
    })
  };

  const keypressHandler = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await addCategory(event);
    }
  };

  const addCategory = async (e) => {
    if (e.target.value) {
      setIsTyping(e.target.value);
      await axios
        .post(
          `${host}AddCategoryManual`,

          {
            title: e.target.value,
          },
          {
            headers: {
              Authorization: userToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let categories = [...categoriesList];
          categories.push(res.data);
          setCategoriesList(categories);
          let newSelectedCategoriesId = [...selectedCategoriesId];
          newSelectedCategoriesId.push(res.data.id);
          setSelectedCategoriesId(newSelectedCategoriesId);
          setUpdatedCategories([...updatedCategories, res.data.id]);

          e.target.value = "";
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      {!VendorCategories && categoriesList ? (
        <Loading />
      ) : (
        <div className="company_info  vendor_categories">
          <div className="left_sec">
            <form className="proForm" onSubmit={updateVendorCategory}>
              <div className="form-group">
                <Select
                  className="select_react disabled"
                  name="Country"
                  placeholder={selectedBusinessType}
                  classNamePrefix="select"
                  aria-label="title here"
                />
                <div className="help-text">
                  <p className="text"></p>
                </div>
              </div>
              <h5 className="sh5-bold">
                What best describes your company categories?
              </h5>
              <div className="vendor_categories__options">
                {categoriesList.map((item) => (
                  <div
                    key={item?.id}
                    className={`form-check custom ${
                      selectedCategoriesId.includes(item?.id) ? "selected" : ""
                    }`}
                  >
                    <input
                      className="form-check-input rounded-circle"
                      type="checkbox"
                      value=""
                      id={item?.id}
                      onChange={handleCategoryChange}
                      defaultChecked={
                        selectedCategoriesId.includes(item?.id) ? true : false
                      }
                    />
                    <label className="form-check-label" htmlFor={item?.id}>
                      {item?.title}
                    </label>
                  </div>
                ))}
              </div>

              <div className="form-group">
                <div
                  className={`input large ${
                    isTyping.length > 0 ? `focused` : ""
                  }`}
                >
                  <label className="control-label" htmlFor="category">
                    Other category
                  </label>
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    id="category"
                    name="category"
                    onKeyDown={(event) => keypressHandler(event)}
                    onBlur={(e) => {
                      addCategory(e);
                    }}
                  />
                </div>
                <div className="help-text"></div>
              </div>
              <div className="form_actions">
                <button
                  className={`btn primaryBtn largeBtn ${
                    !isAllIsOld ? "" : "disabled"
                  }`}
                >
                  {isLoading ? "Loading" : "Save changes"}
                </button>
              </div>
            </form>
          </div>
          <ProfileCompletness />
        </div>
      )}
    </>
  );
};

export default SettingVendorCategories;
