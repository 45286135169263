import React, { Component } from "react";

import email from "../Styles/images/icons/Email.svg";
import Phone from "../Styles/images/icons/Phone.svg";
import Select from "react-select";
import arright_left_16 from "../Styles/images/icons/arright-left-16.svg";

import DataServices from "../Services/DataServices";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import * as actions from "../Store/actions/registration";
import { bindActionCreators } from "redux";

import checkmarkedCircle_24 from "../Styles/images/icons/checkmarkedCircle-24.svg";
import error_24 from "../Styles/images/icons/error-24.svg";
import Warning_yellow from "../Styles/images/icons/Warning_yellow.svg";

const phoneNumbersRegex = /^([0|\+[0-9]{1,5})?([0-9]{9})$/;
const nameRegex = /^[A-Za-z]+$/;
const nameArRegex = /^[\u0621-\u064A0-9 ]+$/;

const otherPhoneNumbersRegex = /^[+]\d*$/;

const CharsDigsRegex =
  /^[a-zA-Z0-9_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;
const ArCharsDigsRegex =
  /^[\u0621-\u064A\u0660-\u06690-9_.!@#$%^&*,<>)(-+=)/\[\]/\{\})]+( [\u0621-\u064A\u0660-\u06690-9_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+)*$/;
const numbersRegex = /^\d+$/;

const user = JSON.parse(window.localStorage.getItem("u"));
const countryCode = window.localStorage.getItem("countryCode");
class StepThird extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAvailable: true,
      companyObject: {
        phone: countryCode,
        extPhone: "",
        companyName: "",
        companyNameAr: "",
        otherType: "",
        selectedCategories: [],

        isEmail: true,
        phoneCall: false,
        sms: false,

        countryId: null,
        cityId: null,
        businessTypeId: null,

        isSaudia: true,
        isArabic: true,

        recoveryEmail: "",
        address: "",
        zipCode: "",
        addressLine1: "",
        addressLine2: "",
      },
      validationSchema: {},
      categoryName: "",
      categories: [],
      countries: [],
      companyTypes: [],
      cities: [],
      selectedCountry: { label: "Select Country*", value: "0" },
      selectedCompanyType: { label: "Select Business Type*", value: "0" },
      selectedCity: { label: "Select City*", value: "0" },
    };
  }

  createValidationSchema = (isSaudia, isArabic, otherTypeFlag = false) => {
    let u = window.localStorage.getItem("u");
    u = JSON.parse(u);
    let validationSchema = Yup.object().shape({
      companyNameAr:
        isSaudia == true || isArabic == true
          ? Yup.string()
              .matches(ArCharsDigsRegex, "Please enter company name in arabic")
              .required("Arabic company name is required")
          : Yup.string().nullable(true),

      companyName: Yup.string()
        .matches(CharsDigsRegex, "Please enter company name in english")
        .required("Company name is required"),

      phone: Yup.string()
        .matches(
          otherPhoneNumbersRegex,
          "other phone must start with + and then enter the phone"
        )
        .test(
          "len",
          "Must not be less than 9 digits or more than 14 digits",
          (val) => val?.length >= 9 && val?.length <= 14
        )
        .required("Phone number is required"),

      extPhone: Yup.string()
        .matches(
          numbersRegex,
          "Invalid phone extension must be less than 5 digits"
        )
        .test(
          "check_length",
          "Invalid phone extension must be less than 5 digits",
          (value) => {
            if (value == undefined) {
              return true;
            }
            return value?.length <= 5;
          }
        ),
      recoveryEmail: Yup.string()
        .email("Not valid email address")
        .test(
          "email_same",
          "Please add a different email, not your registration email",
          (value) => {
            if (value == undefined) {
              return true;
            }
            return value !== u.email;
          }
        )
        .required("Recovery email is required"),

      businessTypeId: Yup.string().required("Business type is required"),
      countryId: Yup.string().required("Country is required"),
      cityId:
        isSaudia == false
          ? Yup.string().required("City is required")
          : Yup.string().nullable(true),
      zipCode:
        isSaudia == false
          ? Yup.string()
              .matches(numbersRegex, "Enter valid numbers for zip code")
              .test(
                "len",
                "Not valid zip code, only 5 digits",
                (val) => val?.length === 5
              )
              .required("Please enter zip code")
          : Yup.string().nullable(true),
      addressLine1:
        isSaudia == false
          ? Yup.string()
              .matches(
                CharsDigsRegex,
                "Please enter valid job title and delete white space"
              )
              .required("Your Address is required")
          : Yup.string().nullable(true),
      // addressLine1:
      //   isSaudia == false
      //     ? Yup.string()
      //         .matches(
      //           CharsDigsRegex,
      //           "Please enter address and delete white space"
      //         )
      //         .required("Your Address is required")
      //     : Yup.string().nullable(true),
      addressLine2:
        isSaudia == false && isArabic == true
          ? Yup.string()
              .matches(
                CharsDigsRegex,
                "Please enter address and delete white space"
              )
              .required("Your Address is required")
          : Yup.string().nullable(true),
      otherType: otherTypeFlag
        ? Yup.string()
            .matches(
              CharsDigsRegex,
              "Please enter business type and delete white space"
            )
            .required("Your business type is required")
        : Yup.string().nullable(true),
    });

    this.setState({ validationSchema });
  };

  componentDidMount() {
    this.animateInputs();

    this.props.actions.SetTabHeaders(
      "Account details",
      "Let’s setup your company account, please tell us more about your company",
      this.props.currentStep
    );

    if (this.props.company.id) {
      const { company } = this.props;
      this.setState({
        companyObject: company,
        selectedCountry: { value: company.countryId },

        selectedCity: { value: company.cityId },
        selectedCompanyType: { value: company.businessTypeId },
      });

      this.createValidationSchema(
        this.props.company.isSaudia,
        this.props.company.isArabic
      );
      DataServices.GetDataListDetailed(`GetCountriesList`, "title", "id").then(
        (res) => {
          this.setState({
            countries: res,
          });
        }
      );
    } else {
      const user = JSON.parse(window.localStorage.getItem("u"));
      DataServices.GetDataListDetailed(`GetCountriesList`, "title", "id").then(
        (res) => {
          let selectedCountry = res.find(
            (option) => option.id === user?.countryId
          );
          this.setState({
            countries: res,
            isSaudia: selectedCountry.action == 0 ? true : false,
            selectedCountry: selectedCountry
              ? { label: selectedCountry.title, value: selectedCountry.id }
              : { label: "Select Country*", value: "0" },
          });
          this.selectCountryHandler(selectedCountry);
          this.createValidationSchema(
            selectedCountry.action == 0 ? true : false,
            selectedCountry.action == 1 ? true : false
          );
        }
      );
    }

    DataServices.GetDataList(`GetBusinessTypesList`, "title", "id").then(
      (res) => {
        const companyTypes = [...res];
        this.setState({
          companyTypes,
        });
      }
    );
    DataServices.GetDataList(`GetCompanyCategoriesList`, "title", "id").then(
      (res) => {
        this.setState({
          categories: res || [],
        });
      }
    );

    const { user } = this.props;
    console.log("user: ", user);
    if (user?.recoveryEmail) {
      this.setState({
        companyObject: {
          ...this.props.company,
          phone: user?.otherPhone,
          extPhone: user?.extPhone,
          countryId: user?.countryId,
          recoveryEmail: user?.recoveryEmail,
          isMulti: true,
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.countries !== this.state.countries) {
      const value = this.state.selectedCountry.value;
      DataServices.GetDataList(
        `GetCitiesList?countryId=` + value,
        "title",
        "id"
      ).then((res) => {
        this.setState({
          selectedCountry: {
            value: value,
            label:
              this.state.countries.filter(
                (country) => country.value == value
              )[0]?.label || "Select Country*",
          },
          cities: res,
        });
      });
    }

    if (prevState.cities !== this.state.cities) {
      const value = this.state.selectedCity.value;
      this.setState({
        selectedCity: {
          value: value,
          label:
            this.state.cities.filter((city) => city.value == value)[0]?.label ||
            "Select City*",
        },
      });
    }

    if (prevState.companyTypes !== this.state.companyTypes) {
      const value = this.state.selectedCompanyType.value;
      this.setState({
        selectedCompanyType: {
          value: value,
          label:
            this.state.companyTypes.filter((city) => city.value == value)[0]
              ?.label || "Select Business Type*",
        },
      });
    }
  }

  selectCountryHandler(e) {
    if (e) {
      this.setState({
        isLoading: true,
      });
      let original_document = { ...this.state.companyObject };
      let updated_document = {};
      updated_document.countryId = e.value;
      updated_document.cityId = null;
      if (e.action == 0) {
        updated_document.isSaudia = true;
        updated_document.isArabic = true;
      } else if (e.action == 1) {
        updated_document.isSaudia = false;
        updated_document.isArabic = true;
      } else {
        updated_document.isSaudia = false;
        updated_document.isArabic = false;
      }
      updated_document = Object.assign(original_document, updated_document);
      this.setState({
        selectedCountry: e,
        selectedCity: { label: " Select City*", value: "0" },
        isLoading: false,
        companyObject: updated_document,
      });

      this.createValidationSchema(
        updated_document.isSaudia,
        updated_document.isArabic
      );

      if (updated_document.isSaudia == false) {
        DataServices.GetDataList(
          `GetCitiesList?countryId=` + e.value,
          "title",
          "id"
        ).then((res) => {
          this.setState({
            selectedCountry: e,
            cities: res,
            isLoading: false,
            companyObject: updated_document,
          });
        });
      }
    } else {
      this.createValidationSchema(false);
      this.setState({
        selectedCountry: { label: " Select Country*", value: "0" },
      });
    }
  }

  HandleSelectCountry(e, setFieldValue, setFieldTouched) {
    if (e) {
      this.setState({
        isLoading: true,
      });

      setFieldValue("countryId", e.value);
      setFieldValue("cityId", null);
      setFieldValue("zipCode", "");
      setFieldTouched("zipCode", false);

      if (e.action == 0) {
        setFieldValue("isSaudia", true);
        setFieldValue("isArabic", true);
      } else if (e.action == 1) {
        setFieldValue("isSaudia", false);
        setFieldValue("isArabic", true);
      } else {
        setFieldValue("isSaudia", false);
        setFieldValue("isArabic", false);
      }
      this.setState({
        selectedCountry: e,
        selectedCity: { label: " Select City*", value: "0" },
        isLoading: false,
      });

      this.createValidationSchema(
        e.action == 0 ? true : false,
        e.action == 1 ? true : false
      );

      if (e.action != 0) {
        DataServices.GetDataList(
          `GetCitiesList?countryId=` + e.value,
          "title",
          "id"
        ).then((res) => {
          this.setState({
            selectedCountry: e,
            cities: res,
            isLoading: false,
          });
        });
      }
    } else {
      this.createValidationSchema(false);
      this.setState({
        selectedCountry: { label: " Select Country*", value: "0" },
      });
    }
  }

  selectCityHandler(e, setFieldValue) {
    if (e.value) {
      this.setState({
        isLoading: true,
      });

      setFieldValue("cityId", e.value);

      this.setState({
        selectedCity: e,
        isLoading: false,
      });
    } else {
      this.setState({
        selectedCity: { label: " Select City*", value: "0" },
      });
    }
  }

  selectedCompanyTypeHandler(e, setFieldValue, values) {
    if (e) {
      this.setState({
        isLoading: true,
      });

      setFieldValue("businessTypeId", e.value);

      this.setState({
        selectedCompanyType: e,
        otherTypeFlag: e.value === 5715 ? true : false,
        isLoading: false,
      });
      this.createValidationSchema(
        values.isSaudia,
        values.isArabic,
        e.value === 5715
      );
    } else {
      this.setState({
        selectedCompanyType: { label: " Select Business Type*", value: "0" },
      });
    }
  }

  animateInputs() {
    const inputs = document.querySelectorAll(".input.large");
    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function (e) {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value == "") {
          input.classList.remove("focused");
        }
      });
    });
  }

  handleChangeCheckBox = (field, setFieldValue, values) => {
    let original_document = { ...values };
    let fieldValue = original_document[field];
    setFieldValue(field, !fieldValue);
  };

  AddCompany = (values) => {
    let companyObject = { ...values };

    this.props.actions.SetLoading();

    this.props.actions.addCompanyStepthree(companyObject);
  };

  addCategoryHandler = (e) => {
    this.setState({
      categoryName: e,
    });
  };

  addCategory(e, setFieldValue, values) {
    if (e.target.value) {
      this.setState({
        isLoading: true,
      });

      DataServices.addObject(`AddCategoryManual`, {
        title: e.target.value,
      }).then((res) => {
        let categories = this.state.categories;
        categories.push({ label: res.title, value: res.id });
        this.selectCategory(
          { label: res.title, value: res.id },
          setFieldValue,
          values
        );

        this.setState({
          isLoading: false,
          categories,
          categoryName: "",
        });
      });
    }
  }

  selectCategory(item, setFieldValue, values) {
    let original_document = { ...values };
    original_document.selectedCategories =
      original_document.selectedCategories || [];

    const check = original_document.selectedCategories.indexOf(item.value);
    if (check > -1) {
      original_document.selectedCategories.splice(check, 1);
    } else {
      original_document.selectedCategories.push(item.value);
    }
    this.setState({
      companyObject: original_document,
    });

    setFieldValue("selectedCategories", original_document.selectedCategories);
  }

  keypressHandler = (event, setFieldValue, values) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.addCategory(event, setFieldValue, values);
    }
  };

  OtherTypeBlur = (e, setFieldValue) => {
    DataServices.addObject("AddBusinessTypeManual", {
      title: e.target.value,
    }).then((res) => {
      const companyTypes = [...this.state.companyTypes];
      companyTypes.unshift({ label: res.title, value: res.id });
      setFieldValue("businessTypeId", res.id);
      this.setState({
        companyTypes,
        selectedCompanyType: { label: res.title, value: res.id },
        otherTypeFlag: false,
      });
    });
  };

  render() {
    let selectedCategories = this.state.companyObject.selectedCategories || [];
    return (
      <>
        <Formik
          initialValues={{ ...this.state.companyObject }}
          validationSchema={this.state.validationSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.AddCompany(values);
          }}
        >
          {({
            values,
            submitForm,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form
              onSubmit={handleSubmit}
              noValidate="novalidate"
              className="proForm"
            >
              <div className="step3">
                <div className="waring_box sh5-med">
                  <img
                    src={Warning_yellow}
                    className="img-fluid icon"
                    alt="warning"
                  />
                  <span>This info needs to be accurate</span>
                </div>
                <div className="contact_person">
                  <h3 className="h3-med">Contact person</h3>

                  <div className="sideByside">
                    <div className="form-group">
                      <div className="input focused large">
                        <label className="control-label" htmlFor="first_name">
                          First name*
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control "
                          disabled
                          value={this.props.user.firstName}
                          id="first_name"
                          name="first_name"
                        />
                      </div>
                      <div className="help-text">
                        <p className="text"></p>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input populated large">
                        <label className="control-label" htmlFor="last_name">
                          Last name*
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control "
                          disabled
                          value={this.props.user.lastName}
                          id="last_name"
                          name="first_name"
                        />
                      </div>
                      <div className="help-text">
                        <p className="text"></p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="input withIcon populated large">
                      <label className="control-label" htmlFor="mail">
                        Work email address *
                      </label>
                      <img
                        src={email}
                        className="img-fluid left_icon"
                        alt="mail"
                      />
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        disabled
                        value={this.props.user.email}
                        id="mail"
                        name="mail"
                      />
                    </div>
                    <div className="help-text"></div>
                  </div>

                  <div className="form-group">
                    <div className="input withIcon populated large">
                      <label className="control-label" htmlFor="phone">
                        Phone no.*
                      </label>
                      <img
                        src={Phone}
                        className="img-fluid left_icon"
                        alt="phone"
                      />
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        disabled
                        value={
                          window.localStorage.getItem("countryCode") +
                          this.props.user.phone
                        }
                        id="phone"
                        name="phone1"
                      />
                    </div>
                    <div className="help-text"></div>
                  </div>

                  <div className="form-group">
                    <div
                      className={`input withIcon large ${
                        values.extPhone ? `focused` : ""
                      }`}
                    >
                      <label className="control-label" htmlFor="extPhone">
                        Ext (Optional)
                      </label>
                      <img
                        src={Phone}
                        className="img-fluid left_icon"
                        alt="extPhone"
                      />
                      <input
                        autoComplete="off"
                        type="text"
                        className={
                          "form-control" +
                          (errors.extPhone && touched.extPhone
                            ? " has-error"
                            : values.extPhone &&
                              !errors.extPhone &&
                              touched.extPhone
                            ? " has-success"
                            : " ")
                        }
                        value={values.extPhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="extPhone"
                        name="extPhone"
                        disabled={
                          this.state.companyObject?.isMulti ? true : false
                        }
                      />

                      {errors.extPhone && touched.extPhone ? (
                        <img
                          src={error_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : values.extPhone &&
                        !errors.extPhone &&
                        touched.extPhone ? (
                        <img
                          src={checkmarkedCircle_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : (
                        " "
                      )}
                    </div>

                    <div className="help-text">
                      {errors.extPhone && touched.extPhone ? (
                        <p className="text has-error">{errors.extPhone}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <div
                      className={`input withIcon large ${
                        values.recoveryEmail ? `focused` : ""
                      }`}
                    >
                      <label className="control-label" htmlFor="recoveryEmail">
                        Other email address* (for recovery issues)
                      </label>
                      <img
                        src={email}
                        className="img-fluid left_icon"
                        alt="mail"
                      />
                      <input
                        autoComplete="off"
                        type="text"
                        className={
                          "form-control" +
                          (errors.recoveryEmail && touched.recoveryEmail
                            ? " has-error"
                            : !errors.recoveryEmail && touched.recoveryEmail
                            ? " has-success"
                            : " ")
                        }
                        value={values.recoveryEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="recoveryEmail"
                        name="recoveryEmail"
                        disabled={
                          this.state.companyObject?.isMulti ? true : false
                        }
                      />

                      {errors.recoveryEmail && touched.recoveryEmail ? (
                        <img
                          src={error_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : !errors.recoveryEmail && touched.recoveryEmail ? (
                        <img
                          src={checkmarkedCircle_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : (
                        " "
                      )}
                    </div>

                    <div className="help-text">
                      <ul style={{ paddingLeft: 0 }}>
                        {errors.recoveryEmail && touched.recoveryEmail ? (
                          <p className="text has-error">
                            {errors.recoveryEmail}
                          </p>
                        ) : (
                          <p className="text">
                            We’ll use it for account recovery issues.
                          </p>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="form-group">
                    <div
                      className={`input withIcon large ${
                        values.phone ? `focused` : ""
                      }`}
                    >
                      <label className="control-label" htmlFor="phone">
                        Other phone no.* (for recovery issues)
                      </label>
                      <img
                        src={Phone}
                        className="img-fluid left_icon"
                        alt="companyPhone"
                      />
                      <input
                        id="phone"
                        name="phone"
                        autoComplete="off"
                        type="text"
                        className={
                          "form-control" +
                          (errors.phone && touched.phone
                            ? " has-error"
                            : !errors.phone && touched.phone
                            ? " has-success"
                            : " ")
                        }
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          this.state.companyObject?.isMulti ? true : false
                        }
                      />
                      {errors.phone && touched.phone ? (
                        <img
                          src={error_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : !errors.phone && touched.phone ? (
                        <img
                          src={checkmarkedCircle_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : (
                        " "
                      )}
                    </div>

                    <div className="help-text">
                      {errors.phone && touched.phone ? (
                        <p className="text has-error">{errors.phone}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="communication">
                    <h5 className="sh5-bold">Communication options</h5>
                    <p className="desc secondary_text">
                      How would you prefer to communicate with you?
                    </p>
                    <div className="communication__options">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="isEmail"
                          onChange={(e) =>
                            this.handleChangeCheckBox(
                              "isEmail",
                              setFieldValue,
                              values
                            )
                          }
                          checked={values.isEmail}
                        />
                        <label className="form-check-label" htmlFor="isEmail">
                          {" "}
                          Email{" "}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          value=""
                          id="sms"
                          onChange={(e) =>
                            this.handleChangeCheckBox(
                              "sms",
                              setFieldValue,
                              values
                            )
                          }
                          checked={values.sms}
                        />
                        <label className="form-check-label" htmlFor="sms">
                          {" "}
                          SMS{" "}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="phoneCall"
                          onChange={(e) =>
                            this.handleChangeCheckBox(
                              "phoneCall",
                              setFieldValue,
                              values
                            )
                          }
                          checked={values.phoneCall}
                        />
                        <label className="form-check-label" htmlFor="phoneCall">
                          Phone calls
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="company_info">
                  <h3 className="h3-med">Company info</h3>
                  <div className="form-group">
                    <Select
                      className={
                        "select_react large " +
                        (errors.countryId && touched.countryId
                          ? " has-error"
                          : !errors.countryId && touched.countryId
                          ? " has-success"
                          : " ")
                      }
                      classNamePrefix="select"
                      placeholder="Select country*"
                      value={
                        this.state?.countries
                          ? this.state?.countries.find(
                              (option) => option.value === values.countryId
                            )
                          : ""
                      }
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      options={this.state.countries}
                      name="countryId"
                      id="countryId"
                      onChange={(e) => {
                        this.HandleSelectCountry(
                          e,
                          setFieldValue,
                          setFieldTouched
                        );
                      }}
                      onBlur={() => setFieldTouched("countryId", true)}
                    />
                    <div className="help-text">
                      {errors.countryId && touched.countryId ? (
                        <p className="text has-error">{errors.countryId}</p>
                      ) : null}
                    </div>
                  </div>

                  {values.isSaudia == false ? (
                    <>
                      <div className="sideByside">
                        <div className="form-group">
                          <Select
                            className={
                              "select_react large " +
                              (errors.cityId && touched.cityId
                                ? " has-error"
                                : !errors.cityId && touched.cityId
                                ? " has-success"
                                : " ")
                            }
                            classNamePrefix="select"
                            placeholder="Select city*"
                            value={
                              this.state?.cities
                                ? this.state?.cities.find(
                                    (option) => option.value === values.cityId
                                  ) || ""
                                : ""
                            }
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            options={this.state.cities}
                            name="cityId"
                            id="cityId"
                            onChange={(e) => {
                              this.selectCityHandler(e, setFieldValue);
                            }}
                            onBlur={() => setFieldTouched("cityId", true)}
                          />
                          <div className="help-text">
                            {errors.cityId && touched.cityId ? (
                              <p className="text has-error">{errors.cityId}</p>
                            ) : null}
                          </div>
                        </div>

                        <div className="form-group">
                          <div
                            className={`input large ${
                              values.zipCode ? `focused` : ""
                            }`}
                          >
                            <label className="control-label" htmlFor="zipCode">
                              Zip / Postal code *
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              className={
                                "form-control" +
                                (errors.zipCode && touched.zipCode
                                  ? " has-error"
                                  : !errors.zipCode && touched.zipCode
                                  ? " has-success"
                                  : " ")
                              }
                              id="zipCode"
                              name="zipCode"
                              value={values.zipCode}
                              onChange={handleChange}
                              onBlur={(e) => {
                                if (e.target.value == "")
                                  e.target.parentElement.classList.remove(
                                    "focused"
                                  );
                                handleBlur(e);
                              }}
                              onFocus={(e) =>
                                e.target.parentElement.classList.add("focused")
                              }
                            />
                            {errors.zipCode && touched.zipCode ? (
                              <img
                                src={error_24}
                                className="img-fluid right_icon"
                                alt=""
                              />
                            ) : !errors.zipCode && touched.zipCode ? (
                              <img
                                src={checkmarkedCircle_24}
                                className="img-fluid right_icon"
                                alt=""
                              />
                            ) : (
                              " "
                            )}
                          </div>
                          <div className="help-text">
                            {errors.zipCode && touched.zipCode ? (
                              <p className="text has-error">{errors.zipCode}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div
                          className={`input large ${
                            values.addressLine1 ? `focused` : ""
                          }`}
                        >
                          <label
                            className="control-label"
                            htmlFor="addressLine1"
                          >
                            Address line 1*
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control" +
                              (errors.addressLine1 && touched.addressLine1
                                ? " has-error"
                                : !errors.addressLine1 && touched.addressLine1
                                ? " has-success"
                                : " ")
                            }
                            value={values.addressLine1}
                            onChange={handleChange}
                            id="addressLine1"
                            name="addressLine1"
                            onFocus={(e) =>
                              e.target.parentElement.classList.add("focused")
                            }
                            onBlur={(e) => {
                              if (e.target.value == "")
                                e.target.parentElement.classList.remove(
                                  "focused"
                                );
                              handleBlur(e);
                            }}
                          />
                          {errors.addressLine1 && touched.addressLine1 ? (
                            <img
                              src={error_24}
                              className="img-fluid right_icon"
                              alt=""
                            />
                          ) : !errors.addressLine1 && touched.addressLine1 ? (
                            <img
                              src={checkmarkedCircle_24}
                              className="img-fluid right_icon"
                              alt=""
                            />
                          ) : (
                            " "
                          )}
                        </div>
                        <div className="help-text">
                          {errors.addressLine1 && touched.addressLine1 ? (
                            <p className="text has-error">
                              {errors.addressLine1}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {values.isSaudia == false ? (
                    <div className="form-group">
                      <div
                        className={`input large ${
                          values.addressLine2 ? `focused` : ""
                        }`}
                      >
                        <label className="control-label" htmlFor="addressLine2">
                          Address line 2*
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className={
                            "form-control" +
                            (errors.addressLine2 && touched.addressLine2
                              ? " has-error"
                              : !errors.addressLine2 && touched.addressLine2
                              ? " has-success"
                              : " ")
                          }
                          value={values.addressLine2}
                          onChange={handleChange}
                          id="addressLine2"
                          name="addressLine2"
                          onFocus={(e) =>
                            e.target.parentElement.classList.add("focused")
                          }
                          onBlur={(e) => {
                            if (e.target.value == "")
                              e.target.parentElement.classList.remove(
                                "focused"
                              );
                            handleBlur(e);
                          }}
                        />
                        {errors.addressLine2 && touched.addressLine2 ? (
                          <img
                            src={error_24}
                            className="img-fluid right_icon"
                            alt=""
                          />
                        ) : !errors.addressLine2 && touched.addressLine2 ? (
                          <img
                            src={checkmarkedCircle_24}
                            className="img-fluid right_icon"
                            alt=""
                          />
                        ) : (
                          " "
                        )}
                      </div>
                      <div className="help-text">
                        {errors.addressLine2 && touched.addressLine2 ? (
                          <p className="text has-error">
                            {errors.addressLine2}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  <div className="form-group">
                    <div
                      className={`input large ${
                        values.companyName ? `focused` : ""
                      }`}
                    >
                      <label className="control-label" htmlFor="companyName">
                        Company name (English) *
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        className={
                          "form-control" +
                          (errors.companyName && touched.companyName
                            ? " has-error"
                            : !errors.companyName && touched.companyName
                            ? " has-success"
                            : " ")
                        }
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="companyName"
                        name="companyName"
                      />
                      {errors.companyName && touched.companyName ? (
                        <img
                          src={error_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : !errors.companyName && touched.companyName ? (
                        <img
                          src={checkmarkedCircle_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : (
                        " "
                      )}
                    </div>

                    <div className="help-text">
                      {errors.companyName && touched.companyName ? (
                        <p className="text has-error">{errors.companyName}</p>
                      ) : null}
                    </div>
                  </div>

                  {values.isSaudia === true || values.isArabic === true ? (
                    <div className="form-group ar">
                      <div
                        className={`input large ${
                          values.companyNameAr ? `focused` : ""
                        }`}
                      >
                        <label
                          className="control-label"
                          htmlFor="companyNameAr"
                        >
                          Company name (Arabic) *
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className={
                            "form-control" +
                            (errors.companyNameAr && touched.companyNameAr
                              ? " has-error"
                              : !errors.companyNameAr && touched.companyNameAr
                              ? " has-success"
                              : " ")
                          }
                          value={values.companyNameAr}
                          onChange={handleChange}
                          id="companyNameAr"
                          name="companyNameAr"
                          onFocus={(e) =>
                            e.target.parentElement.classList.add("focused")
                          }
                          onBlur={(e) => {
                            if (e.target.value == "")
                              e.target.parentElement.classList.remove(
                                "focused"
                              );
                            handleBlur(e);
                          }}
                        />
                        {errors.companyNameAr && touched.companyNameAr ? (
                          <img
                            src={error_24}
                            className="img-fluid right_icon"
                            alt=""
                          />
                        ) : !errors.companyNameAr && touched.companyNameAr ? (
                          <img
                            src={checkmarkedCircle_24}
                            className="img-fluid right_icon"
                            alt=""
                          />
                        ) : (
                          " "
                        )}
                      </div>
                      {errors.companyNameAr && touched.companyNameAr ? (
                        <div className="help-text">
                          <p className="text has-error">
                            {errors.companyNameAr}
                          </p>
                        </div>
                      ) : (
                        <div className="help-text" dir="rtl">
                          <p className="text ar">
                            من فضلك، قم بإدخال اسم شركتك باللغة العربية كما هو
                            مسجل
                          </p>
                        </div>
                      )}
                    </div>
                  ) : null}

                  <div className="form-group">
                    <Select
                      className={
                        "select_react large" +
                        (errors.businessTypeId && touched.businessTypeId
                          ? " has-error"
                          : "")
                      }
                      classNamePrefix="select"
                      placeholder="Select business type*"
                      value={
                        this.state?.companyTypes
                          ? this.state?.companyTypes.find(
                              (option) => option.value === values.businessTypeId
                            )
                          : ""
                      }
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      options={this.state.companyTypes}
                      name="businessTypeId"
                      id="businessTypeId"
                      onChange={(e) => {
                        this.selectedCompanyTypeHandler(
                          e,
                          setFieldValue,
                          values
                        );
                      }}
                      onBlur={() => setFieldTouched("businessTypeId", true)}
                    />
                    <div className="help-text">
                      {errors.businessTypeId && touched.businessTypeId ? (
                        <p className="text has-error">
                          {errors.businessTypeId}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {this.state?.otherTypeFlag && (
                    <div className="form-group">
                      <div
                        className={`input large ${
                          values.otherType ? `focused` : ""
                        }`}
                      >
                        <label className="control-label" htmlFor="otherType">
                          Please specify other type
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className={
                            "form-control" +
                            (errors.otherType && touched.otherType
                              ? " has-error"
                              : !errors.otherType && touched.otherType
                              ? " has-success"
                              : " ")
                          }
                          value={values.otherType}
                          onChange={handleChange}
                          id="otherType"
                          name="otherType"
                          onFocus={(e) =>
                            e.target.parentElement.classList.add("focused")
                          }
                          onBlur={(e) => {
                            if (e.target.value == "")
                              e.target.parentElement.classList.remove(
                                "focused"
                              );
                            else {
                              this.OtherTypeBlur(e, setFieldValue);
                            }
                            handleBlur(e);
                          }}
                        />
                        {errors.otherType && touched.otherType ? (
                          <img
                            src={error_24}
                            className="img-fluid right_icon"
                            alt=""
                          />
                        ) : !errors.otherType && touched.otherType ? (
                          <img
                            src={checkmarkedCircle_24}
                            className="img-fluid right_icon"
                            alt=""
                          />
                        ) : (
                          " "
                        )}
                      </div>
                      <div className="help-text">
                        {errors.otherType && touched.otherType ? (
                          <p className="text has-error">{errors.otherType}</p>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>

                <div className="vendor_categories">
                  <h3 className="h3-med">Vendor categories</h3>
                  <p className="desc secondary_text">
                    What best describes your company categories
                  </p>
                  <div className="vendor_categories__options">
                    {this.state.categories.map((item) => {
                      return (
                        <div
                          key={item.value}
                          className={
                            "form-check custom " +
                            (selectedCategories.indexOf(item.value) > -1
                              ? "selected"
                              : "")
                          }
                        >
                          <input
                            className="form-check-input rounded-circle"
                            type="checkbox"
                            value=""
                            onChange={(e) =>
                              this.selectCategory(item, setFieldValue, values)
                            }
                            id={item.value}
                            checked={
                              selectedCategories.indexOf(item.value) > -1
                                ? true
                                : false
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={item.value}
                          >
                            {item.label}{" "}
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  <div className="form-group">
                    <div className="input large">
                      <label className="control-label" htmlFor="category">
                        Other category
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="category"
                        value={this.state.categoryName}
                        onChange={(e) =>
                          this.addCategoryHandler(e.target.value)
                        }
                        onBlur={(e) => {
                          this.addCategory(e, setFieldValue, values);
                        }}
                        name="category"
                        onKeyDown={(event) =>
                          this.keypressHandler(event, setFieldValue, values)
                        }
                      />
                    </div>
                    <div className="help-text"></div>
                  </div>
                </div>
              </div>

              <div className="form_actions">
                {this.props.isLoading == true ? (
                  <button className="btn primaryBtn largeBtn disabled loading w-100">
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={submitForm}
                    className={
                      "btn primaryBtn largeBtn  w-100" +
                      (this.props.prevSaved == true ? " " : " ") +
                      "" +
                      (selectedCategories.length === 0 ? "disabled" : "")
                    }
                  >
                    Save and next step
                  </button>
                )}
                <button
                  className="btn textBtn largeBtn icon-l w-100"
                  onClick={(e) => this.props.actions.backAction()}
                >
                  <img src={arright_left_16} className="icon" alt="arrow" />{" "}
                  Back
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.registration.user,
    company: state.registration.company,
    currentStep: state.registration.currentStep,
    activeStep: state.registration.activeStep,
    isLoading: state.registration.isLoading,
    prevSaved: state.registration.prevSaved,
    isSaudia: state.registration.isSaudia,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepThird);
