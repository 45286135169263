import React, { Component } from "react";

import { connect } from "react-redux";
import * as actions from "../Store/actions/registration";
import { bindActionCreators } from "redux";

class RegisterHead extends Component {
  constructor(props) {
    super(props);
  }
  handlerNextStep() {
    if (this.props.currentStep < parseInt(window.localStorage.getItem("cs")))
      this.props.actions.NextAction();
  }
  handlerBackStep() {
    if (this.props.activeStep > 1) this.props.actions.backAction();
  }
  render() {
    return (
      <div className="register_step_head">
        <div className="text-center">
          <h2 className="h1-black">Join FGC</h2>
          <h2 className="h1-bold">Vendor Registration Request</h2>
        </div>
        <ul className="list-unstyled register_step_progress">
          <li
            className={
              "progress-item " + (this.props.activeStep >= 1 ? "done" : "")
            }
          />
          <li
            className={
              "progress-item " + (this.props.activeStep >= 2 ? "done" : "")
            }
          />
          <li
            className={
              "progress-item " + (this.props.activeStep >= 3 ? "done" : "")
            }
          />
          <li
            className={
              "progress-item " + (this.props.activeStep >= 4 ? "done" : "")
            }
          />
          <li
            className={
              "progress-item " + (this.props.activeStep >= 5 ? "done" : "")
            }
          />
          <li
            className={
              "progress-item " + (this.props.activeStep >= 6 ? "done" : "")
            }
          />
        </ul>

        <div className="step_title">
          <div className="step_text">
            <h4 className="h5-med">
              Step {this.props.activeStep}/6: {this.props.stepMainTitle}
            </h4>
            <p className="step_desc tertiary_text">{this.props.stepSubTitle}</p>
          </div>

          <ul className="list-unstyled step_actions">
            <li>
              <span
                onClick={(e) => this.handlerBackStep()}
                className={
                  "arrow-forward sh6-med " +
                  (this.props.activeStep == 1 ? " disabled" : "")
                }
              >
                <svg
                  className="icon"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.99949 7.99938C4.00194 7.76892 4.08802 7.57032 4.27048 7.39463L9.54975 2.22259C9.70348 2.07442 9.88676 2 10.1081 2C10.5441 2 10.8945 2.34243 10.8945 2.78274C10.8945 2.99733 10.8072 3.19593 10.6561 3.35021L5.88991 7.99815L10.6561 12.6485C10.806 12.8016 10.8945 12.9959 10.8945 13.2172C10.8945 13.6563 10.5441 14 10.1081 14C9.88919 14 9.70348 13.9255 9.54975 13.7718L4.27048 8.60412C4.08679 8.42287 3.99949 8.22861 3.99949 7.99938Z"
                    fill="#334252"
                  />
                </svg>
                Back
              </span>
            </li>

            <li>
              <span
                onClick={(e) => this.handlerNextStep()}
                className={
                  "arrow-forward sh6-med " +
                  (this.props.activeStep == 6 ||
                  this.props.currentStep >=
                    parseInt(window.localStorage.getItem("cs"))
                    ? " disabled"
                    : "")
                }
              >
                Next
                <svg
                  className="icon"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.895 7.99938C11.8926 7.76892 11.8065 7.57032 11.624 7.39463L6.34478 2.22259C6.19105 2.07442 6.00777 2 5.7864 2C5.35043 2 5 2.34243 5 2.78274C5 2.99733 5.0873 3.19593 5.23845 3.35021L10.0046 7.99815L5.23845 12.6485C5.08852 12.8016 5 12.9959 5 13.2172C5 13.6563 5.35043 14 5.7864 14C6.00534 14 6.19105 13.9255 6.34478 13.7718L11.624 8.60412C11.8077 8.42287 11.895 8.22861 11.895 7.99938Z"
                    fill="#334252"
                  />
                </svg>
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentStep: state.registration.currentStep,
    activeStep: state.registration.activeStep,
    isLoading: state.registration.isLoading,
    stepSubTitle: state.registration.stepSubTitle,
    stepMainTitle: state.registration.stepMainTitle,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterHead);
