import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import DataServices from "../../../../Services/DataServices";
import UploadFile from "../../../UploadFile";
import config from "../../../../IP_Configrations.json";

import checkmarkedCircle_20 from "../../../../Styles/images/icons/checkmarkedCircle-20.svg";
import attached_file from "../../../../Styles/images/settings/attached_file.svg";
import Error_20 from "../../../../Styles/images/icons/error-20.svg";

function AOASection({ SaveAdditionalChanges, Saving, companyId, setAdditionalAttachments }) {
    const [Changed, setChanged] = useState(false);
    const [AOA, setAOA] = useState(false);
    const [Edit, setEdit] = useState(false);

    const documentTypeId = 1;

    useEffect(() => {
        getDoc();
    }, []);

    const getDoc = (flag) => {
        DataServices.GetRowById(`GetUPLOADDOCUMENTSBySection?companyId=${companyId}&documentTypeId=${documentTypeId}`).then((res) => {
            if (res?.length > 0) {
                setAOA(res[0]);
                setAdditionalAttachments((additionalAttachments) => ({
                    ...additionalAttachments,
                    AOA: true,
                }));
                if (flag) {
                    setEdit(true);
                }
            }
        });
    };

    const validate = useFormik({
        enableReinitialize: true,
        initialValues: {
            ArticleAssociationFile: AOA?.attachLink,
            edit: Edit,
        },
        validationSchema: Yup.object().shape({
            ArticleAssociationFile: Yup.mixed().required("file copy is required"),
        }),

        onSubmit: (values) => {
            SaveAdditionalChanges({ ...values, documentTypeId }, "Article of association");
            validate.setFieldValue("edit", false);
            setChanged(false);
        },
    });

    const [Load, setLoad] = useState(false);

    return (
        <>
            <div className="sub_head">
                <div className="top">
                    <h4 className="title">
                        <span>Article of association (optional)</span>
                        {AOA?.attachLink ? validate.values.edit ? "" : <img src={checkmarkedCircle_20} className="img-fluid icon" alt="checked" /> : <img src={Error_20} className="img-fluid icon" alt="info" />}
                    </h4>
                    {!validate.values.edit && (
                        <span
                            className="edit"
                            role="button"
                            onClick={() => {
                                validate.setFieldValue("edit", true);
                            }}
                        >
                            Edit
                        </span>
                    )}
                </div>
                <p className="desc">Article of association form a document that specifies the regulations for a company's operations and defines the company's purpose.</p>
            </div>

            {validate.values.edit && (
                <div className={"upload_box" + (validate.errors.File && validate.touched.File ? " has-error" : " ")}>
                <UploadFile
                        className=" sm "
                        load={Load}
                        setLoad={setLoad}
                        fieldName="ArticleAssociationFile"
                        title="Article of association"
                        removeFile={() => validate.setFieldValue("ArticleAssociationFile", null)}
                        errors={validate.errors.ArticleAssociationFile}
                        touched={validate.touched.ArticleAssociationFile}
                        fileName={validate.values.ArticleAssociationFile?.split("/")[4] || validate.values.ArticleAssociationFile}
                        folderName="ArticleAssociationFile"
                        documentTypeId={documentTypeId}
                        companyId={companyId}
                        Api="UploadFilesForAdditionalDocumentsTempFolder"
                        handleBlur={() => validate.setFieldTouched("ArticleAssociationFile")}
                        handleChange={() => {
                            validate.setFieldValue("ArticleAssociationFile", "Done");
                            setChanged(true);
                            getDoc(true);
                        }}
                    />
                </div>
            )}
            {validate.values.ArticleAssociationFile && !validate.values.edit && (
                <div className="uploaded_file">
                    <Link target="_blank" to={AOA?.attachLink && config.download ? config.download + AOA?.attachLink : ""} className="file">
                        <img src={attached_file} className="img-fluid icon" alt="icon" />
                        <span>{AOA?.attachLink?.split("/")[4]}</span>
                    </Link>
                    <span className="hint">You can view the file by clicking on it</span>
                </div>
            )}
            {validate.values.edit && (
                <div className="form_actions d-flex">
                    <button className={"btn primaryBtn largeBtn " + (!Changed || Saving ? "disabled" : "")} type="button" onClick={validate.handleSubmit}>
                        {Saving ? "Saving..." : "Save changes"}
                    </button>
                    <button
                        className={"btn secondaryBtn largeBtn mx-3"}
                        type="button"
                        onClick={() => {
                            validate.setFieldValue("edit", false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </>
    );
}

export default AOASection;
