import React, { useEffect, useState } from "react";
import checkmarkedCircle_24 from "../../../../Styles/images/icons/checkmarkedCircle-24.svg";
import JobTitle from "../../../../Styles/images/icons/JobTitle.svg";
import error_24 from "../../../../Styles/images/icons/error-24.svg";
import Warning from "../../../../Styles/images/icons/Warning_yellow.svg";
import Select from "react-select";
import InputMask from "react-input-mask";

import back from "../../../../Styles/images/icons/arright-left-16.svg";
import DataServices from "../../../../Services/DataServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  SetLoading,
  SetTabHeaders,
  AddCompanyBankDetail,
  backAction,
} from "../../../../Store/actions/registration";
import { toast } from "react-toastify";

import CloseSVG from "../../../../Styles/images/icons/popup_close.svg";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import config from "../../../../IP_Configrations.json";

const host = config.static;
const CharsDigsRege = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
const CharsDigsAcc =
  /^[a-zA-Z_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;
const CharsDigsRegex =
  /^[a-zA-Z0-9_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;
const NumDigsRegex = /^[0-9]/;
// const IBANregx = /^[a-zA-Z]{2}[0-9]{22}$/;
const IBANregx = /^[a-zA-Z]{2}[0-9a-zA-Z]{22}$/;
const AddNewBank = ({ setShowAddNewBank, defaultBank, setCountBankEdit }) => {
  const dispatch = useDispatch();

  const currentStep = useSelector((state) => state.registration.currentStep);
  const isLoading = useSelector((state) => state.registration.isLoading);
  // const IsSaudi = useSelector((state) => state.registration.company.isSaudia);

  const companyId = useSelector((state) => state.registration.companyId);
  const countryId = useSelector(
    (state) => state.registration.company.countryId
  );
  const [isSaudia, setIsSaudia] = useState(false);
  const userToken = localStorage.getItem("user_token");

  const [Banks, setBanks] = useState([]);
  const [Currency, setCurrency] = useState([]);
  const [isPrimary, setisPrimary] = useState(false);
  const [countriesList, setCountriesList] = useState([]);

  const StoredBankData = localStorage.getItem("CompanyBankDetail");

  const validationBankDetails = useFormik({
    enableReinitialize: true,
    initialValues: {
      bankName: "",
      selectedBank: "",

      branch: "",
      beneficiaryName: "",
      countryId: "",
      currency: "",
      accountNumber: "",
      IBAN: "",
      swiftCode: "",
      otherDetails: "",
    },
    validationSchema: Yup.object().shape({
      countryId: Yup.string().required("This country is Required"),
      bankName:
        isSaudia === true
          ? Yup.string().nullable()
          : Yup.string()
              .matches(
                CharsDigsRegex,
                "Invalid bank name Please ensure that the name in english with no spaces or any special characters"
              )
              .required("Bank name is required"),

      selectedBank:
        isSaudia === true
          ? Yup.string().required("This Bank is Required")
          : Yup.string().nullable(),

      branch: Yup.string()
        .matches(
          CharsDigsRegex,
          "Invalid branch name Please ensure that the name in english with no spaces or any special characters"
        )
        .required("Branch name is required"),

      beneficiaryName: Yup.string()
        .matches(
          CharsDigsAcc,
          "Invalid beneficiary name Please ensure that the name in english with no spaces, numbers or any special characters"
        )
        .required("Beneficiary name is required"),
      //   country: Yup.string().required("Country is required"),
      currency: Yup.string().required("Currency is required"),
      accountNumber: Yup.string()
        .required("Account number is required")
        .matches(
          CharsDigsRege,
          "Invalid account no. Please ensure that the number consists of digits only with no spaces or any other characters"
        ),
      IBAN:
        isSaudia === true
          ? Yup.string()
              .required("IBAN is required")
              .matches(IBANregx, "Invalid IBAN, ")
          : //   .test(
            //     "len",
            //     "Invalid IBAN, Please ensure that the number consists of 24 characters with no spaces or any special characters.",
            //     (val) => {
            //       const less = val?.replace("_", "");
            //       return less?.length === 24;
            //     }
            //   )
            Yup.string().matches(
              CharsDigsRege,
              "Invalid IBAN, Please ensure that the number consists of characters with no spaces or any special characters."
            ),
      swiftCode:
        isSaudia === false
          ? Yup.string()
              .matches(
                CharsDigsRegex,
                "Invalid swift code Please ensure that the name in english with no spaces or any special characters"
              )
              .required("Swift code is required")
          : Yup.string().nullable(),
    }),

    onSubmit: async (values, actions) => {
      // dispatch(SetLoading());
      //   console.log("====================================");
      //   console.log(values);
      //   console.log("====================================");
      // dispatch(
      //   AddCompanyBankDetail({
      //     companyId: defaultBank.companyId,
      //     bankId: parseInt(values.selectedBank),
      //     bankName: values.bankName,
      //     branchName: values.branch,
      //     contactName: values.beneficiaryName,
      //     currencyId: parseInt(values.currency),
      //     accountNumber: values.accountNumber,
      //     iban: values.IBAN,
      //     swiftCode: values.swiftCode,
      //     description: values.otherDetails,
      //     isPrimary: isPrimary,
      //   })
      // );
      await axios
        .post(
          `${host}AddNewCompanyBankDetail`,
          {
            companyId: defaultBank.companyId,
            bankId: parseInt(values.selectedBank),
            bankName: values.bankName,
            branchName: values.branch,
            contactName: values.beneficiaryName,
            currencyId: parseInt(values.currency),
            accountNumber: values.accountNumber,
            iban: values.IBAN,
            swiftCode: values.swiftCode,
            description: values.otherDetails,
            isPrimary: isPrimary,
            countryId: values.countryId,
            // isSaudia: isSaudia
          },
          {
            headers: {
              Authorization: userToken,
            },
          }
        )
        .then((res) => {
          setCountBankEdit((perv) => perv + 1);
          setShowAddNewBank(false);
          actions.setSubmitting(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.msg);
        });
    },
  });

  useEffect(() => {
    const inputs = document.querySelectorAll(".input.large");
    if (isSaudia === true) {
    }
    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function () {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value == "") {
          input.classList.remove("focused");
        }
      });
    });

    dispatch(
      SetTabHeaders(
        "Bank details",
        "In order to complete your vendor registration process, please provide your us with these required documents",
        currentStep
      )
    );

    DataServices.GetDataListDetailed(`GetBankForList`, "title", "id").then(
      (res) => setBanks(res)
    );

    DataServices.GetDataList(`GetCurrency`, "title", "id").then((res) =>
      setCurrency(res)
    );

    DataServices.GetDataList(`GetCountriesList`, "title", "id").then((res) => {
      setCountriesList(res);
    });
  }, []);

  const {
    errors,
    touched,
    handleBlur,
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
  } = validationBankDetails;
  const handleChange2 = (e) => {
    let isChecked = e.target.checked;
    setisPrimary(e.target.checked);
  };
  return (
    <div className="popup active popup_contact_support change_company_account add_bank_popup">
      <div className="popup__content">
        <div className="popup__body">
          <div className="head">
            <h5 className="sh5-bold">Add new bank account</h5>
            <span
              className="close"
              onClick={() => {
                setShowAddNewBank(false);
              }}
            >
              <img src={CloseSVG} className="img-fluid" alt="close" />
            </span>
          </div>
          <div className="content">
            <div noValidate="novalidate" className="proForm">
              <div className="step5">
                <div className="sideByside">
                  <div className="form-group">
                    <div
                      className={`input  large ${
                        values.countryId ? `focused` : ""
                      }`}
                    >
                      <label className="control-label" htmlFor="Country">
                        Country*
                      </label>
                      <Select
                        className={
                          "select_react large " +
                          (errors.countryId && touched.countryId
                            ? " has-error"
                            : !errors.countryId && touched.countryId
                            ? " has-success"
                            : " ")
                        }
                        classNamePrefix="select"
                        placeholder="Select Currency*"
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={countriesList}
                        name="countryId"
                        id="countryId"
                        onChange={(option) => {
                          setFieldValue("countryId", option.value);
                          if (option.label?.includes("audi")) {
                            setIsSaudia(true);
                          } else {
                            setIsSaudia(false);
                          }
                          // console.log(option);
                        }}
                        //   onBlur={() => setFieldTouched("currency", true)}
                        // disabled={JSON.parse(StoredBankData) !== null ? true : false}
                      />
                    </div>
                    <div className="help-text">
                      {errors.countryId && touched.countryId ? (
                        <p className="text has-error">{errors.countryId}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <Select
                      className={
                        "select_react large " +
                        (errors.currency && touched.currency
                          ? " has-error"
                          : !errors.currency && touched.currency
                          ? " has-success"
                          : " ")
                      }
                      classNamePrefix="select"
                      placeholder="Select Currency*"
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      options={Currency}
                      name="currency"
                      id="currency"
                      onChange={(option) =>
                        setFieldValue("currency", option.value)
                      }
                      onBlur={() => setFieldTouched("currency", true)}
                      // disabled={JSON.parse(StoredBankData) !== null ? true : false}
                    />
                    <div className="help-text">
                      {errors.currency && touched.currency ? (
                        <p className="text has-error">{errors.currency}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                {isSaudia ? (
                  <div className="form-group">
                    <Select
                      className={
                        "select_react large " +
                        (errors.selectedBank && touched.selectedBank
                          ? " has-error"
                          : !errors.selectedBank && touched.selectedBank
                          ? " has-success"
                          : " ")
                      }
                      classNamePrefix="select"
                      placeholder="Select Bank*"
                      value={Banks.find((option) => {
                        return option.value === values.selectedBank;
                      })}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      options={Banks}
                      name="selectedBank"
                      id="selectedBank"
                      onChange={(option) => {
                        setFieldValue("selectedBank", option.value);
                        setFieldValue("swiftCode", option.swiftCode);
                        setFieldValue("bankName", option.label);
                      }}
                      onBlur={() => setFieldTouched("selectedBank", true)}
                      // disabled={JSON.parse(StoredBankData) !== null ? true : false}
                    />
                    <div className="help-text">
                      {errors.selectedBank && touched.selectedBank ? (
                        <p className="text has-error">{errors.selectedBank}</p>
                      ) : null}{" "}
                    </div>
                  </div>
                ) : (
                  <div className="form-group">
                    <div
                      className={`input large ${
                        values.bankName ? `focused` : ""
                      }`}
                    >
                      <label className="control-label" htmlFor="bankName">
                        Bank name*
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        className={
                          "form-control" +
                          (errors.bankName && touched.bankName
                            ? " has-error"
                            : !errors.bankName && touched.bankName
                            ? " has-success"
                            : " ")
                        }
                        value={values.bankName || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="bankName"
                        name="bankName"
                        // disabled={JSON.parse(StoredBankData) !== null ? true : false}
                      />
                      {errors.bankName && touched.bankName ? (
                        <img
                          src={error_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : !errors.bankName && touched.bankName ? (
                        <img
                          src={checkmarkedCircle_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : (
                        " "
                      )}
                    </div>
                    <div className="help-text">
                      {errors.bankName && touched.bankName ? (
                        <p className="text has-error">{errors.bankName}</p>
                      ) : null}
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <div
                    className={`input large ${values.branch ? `focused` : ""}`}
                  >
                    <label className="control-label" htmlFor="branch">
                      Branch*
                    </label>
                    <input
                      autoComplete="off"
                      type="text"
                      className={
                        "form-control" +
                        (errors.branch && touched.branch
                          ? " has-error"
                          : !errors.branch && touched.branch
                          ? " has-success"
                          : " ")
                      }
                      value={values.branch || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="branch"
                      name="branch"
                      // disabled={JSON.parse(StoredBankData) !== null ? true : false}
                    />

                    {errors.branch && touched.branch ? (
                      <img
                        src={error_24}
                        className="img-fluid right_icon"
                        alt=""
                      />
                    ) : !errors.branch && touched.branch ? (
                      <img
                        src={checkmarkedCircle_24}
                        className="img-fluid right_icon"
                        alt=""
                      />
                    ) : (
                      " "
                    )}
                  </div>

                  <div className="help-text">
                    {errors.branch && touched.branch ? (
                      <p className="text has-error">{errors.branch}</p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={`input  large ${
                      values.beneficiaryName ? `focused` : ""
                    }`}
                  >
                    <label className="control-label" htmlFor="Beneficiary">
                      Beneficiary name*
                    </label>

                    <input
                      name="beneficiaryName"
                      autoComplete="off"
                      className={
                        "form-control" +
                        (errors.beneficiaryName && touched.beneficiaryName
                          ? " has-error"
                          : !errors.beneficiaryName && touched.beneficiaryName
                          ? " has-success"
                          : " ")
                      }
                      type="text"
                      id="Beneficiary"
                      onChange={handleChange}
                      value={values.beneficiaryName || ""}
                      onBlur={handleBlur}
                      // disabled={JSON.parse(StoredBankData) !== null ? true : false}
                    />
                    {errors.beneficiaryName && touched.beneficiaryName ? (
                      <img
                        src={error_24}
                        className="img-fluid right_icon"
                        alt=""
                      />
                    ) : !errors.beneficiaryName && touched.beneficiaryName ? (
                      <img
                        src={checkmarkedCircle_24}
                        className="img-fluid right_icon"
                        alt=""
                      />
                    ) : (
                      " "
                    )}
                  </div>
                  <div className="help-text">
                    {errors.beneficiaryName && touched.beneficiaryName ? (
                      <p className="text has-error">{errors.beneficiaryName}</p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={`input large ${
                      values.accountNumber ? `focused` : ""
                    }`}
                  >
                    <label className="control-label" htmlFor="Account_number">
                      Account number*
                    </label>
                    <input
                      autoComplete="off"
                      type="text"
                      className={
                        "form-control" +
                        (errors.accountNumber && touched.accountNumber
                          ? " has-error"
                          : !errors.accountNumber && touched.accountNumber
                          ? " has-success"
                          : " ")
                      }
                      name="accountNumber"
                      id="Account_number"
                      onChange={handleChange}
                      value={values.accountNumber || ""}
                      onBlur={handleBlur}
                      // disabled={JSON.parse(StoredBankData) !== null ? true : false}
                    />
                    {errors.accountNumber && touched.accountNumber ? (
                      <img
                        src={error_24}
                        className="img-fluid right_icon"
                        alt=""
                      />
                    ) : !errors.accountNumber && touched.accountNumber ? (
                      <img
                        src={checkmarkedCircle_24}
                        className="img-fluid right_icon"
                        alt=""
                      />
                    ) : (
                      " "
                    )}
                  </div>
                  <div className="help-text">
                    {errors.accountNumber && touched.accountNumber ? (
                      <p className="text has-error">{errors.accountNumber}</p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={`input large ${values.IBAN ? `focused` : ""}`}
                  >
                    <label className="control-label" htmlFor="IBAN">
                      {isSaudia ? "IBAN*" : "IBAN (Optional)"}
                    </label>
                    {isSaudia ? (
                      <input
                        autoComplete="off"
                        type="text"
                        className={
                          "form-control" +
                          (errors.IBAN && touched.IBAN
                            ? " has-error"
                            : values.IBAN && !errors.IBAN && touched.IBAN
                            ? " has-success"
                            : " ")
                        }
                        name="IBAN"
                        id="IBAN"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.IBAN}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        type="text"
                        className={
                          "form-control" +
                          (errors.IBAN && touched.IBAN
                            ? " has-error"
                            : values.IBAN && !errors.IBAN && touched.IBAN
                            ? " has-success"
                            : " ")
                        }
                        name="IBAN"
                        id="IBAN"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.IBAN}
                      />
                    )}
                    {isSaudia ? (
                      errors.IBAN && touched.IBAN ? (
                        <img
                          src={error_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : !errors.IBAN && touched.IBAN ? (
                        <img
                          src={checkmarkedCircle_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : (
                        " "
                      )
                    ) : errors.IBAN && touched.IBAN ? (
                      <img
                        src={error_24}
                        className="img-fluid right_icon"
                        alt=""
                      />
                    ) : values.IBAN && !errors.IBAN && touched.IBAN ? (
                      <img
                        src={checkmarkedCircle_24}
                        className="img-fluid right_icon"
                        alt=""
                      />
                    ) : (
                      " "
                    )}
                  </div>

                  <div className="help-text">
                    <ul>
                      {errors.IBAN && touched.IBAN ? (
                        <p className="text has-error">{errors.IBAN}</p>
                      ) : isSaudia ? (
                        <p className="text">must be 24 characters</p>
                      ) : null}
                    </ul>
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={`input large ${
                      values.swiftCode ? `focused` : ""
                    }`}
                  >
                    <label className="control-label" htmlFor="Swift">
                      {!isSaudia ? "Swift code*" : "Swift code"}
                    </label>
                    <input
                      autoComplete="off"
                      type="text"
                      className={
                        !isSaudia
                          ? "form-control" +
                            (errors.swiftCode && touched.swiftCode
                              ? " has-error"
                              : !errors.swiftCode && touched.swiftCode
                              ? " has-success"
                              : " ")
                          : "form-control" +
                            (errors.swiftCode && touched.swiftCode
                              ? " has-error"
                              : !errors.swiftCode &&
                                touched.swiftCode &&
                                values.swiftCode
                              ? " has-success"
                              : " ")
                      }
                      id="Swift"
                      name="swiftCode"
                      onChange={handleChange}
                      value={values.swiftCode || ""}
                      onBlur={handleBlur}
                      disabled={isSaudia ? true : false}
                    />
                    {!isSaudia ? (
                      errors.swiftCode && touched.swiftCode ? (
                        <img
                          src={error_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : !errors.swiftCode && touched.swiftCode ? (
                        <img
                          src={checkmarkedCircle_24}
                          className="img-fluid right_icon"
                          alt=""
                        />
                      ) : (
                        " "
                      )
                    ) : errors.swiftCode && touched.swiftCode ? (
                      <img
                        src={error_24}
                        className="img-fluid right_icon"
                        alt=""
                      />
                    ) : !errors.swiftCode &&
                      touched.swiftCode &&
                      values.swiftCode ? (
                      <img
                        src={checkmarkedCircle_24}
                        className="img-fluid right_icon"
                        alt=""
                      />
                    ) : (
                      " "
                    )}
                  </div>
                  <div className="help-text">
                    {errors.swiftCode && touched.swiftCode ? (
                      <p className="text has-error">{errors.swiftCode}</p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={`input large ${
                      values.otherDetails ? `focused` : ""
                    }`}
                  >
                    <label className="control-label" htmlFor="details">
                      Other details (Optional)
                    </label>
                    {
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="details"
                        name="otherDetails"
                        value={values.otherDetails || ""}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(StoredBankData) !== null ? true : false
                        }
                      />
                    }
                  </div>
                </div>
                <div className="rideo-div2">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="isPrimary"
                      onChange={(e) => handleChange2(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                  Make primary
                </div>
              </div>
              <div className="mesg">
                <ul>
                  <li>
                    This information may be visible in payment statements,
                    invoices, and receipts.
                  </li>
                  <li>
                    By clicking ‘Add account’ button, you agree that FGC’s
                    financial department has the rights to check the data
                    accuracy with your entered bank, and you confirm that all
                    the entered data is correct and any error in this data is
                    your responsibility.
                  </li>
                </ul>
              </div>
              <div className="footer_actions">
                <button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className={
                    "btn primaryBtn mediumBtn " +
                    (isSubmitting === true ||
                    JSON.parse(StoredBankData) !== null
                      ? " "
                      : " ") +
                    " w-50"
                  }
                  disabled={isSubmitting}
                >
                  Add account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewBank;
