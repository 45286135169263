import * as types from "../types";
import Api from "../../Services/Api";
import { toast } from "react-toastify";
import axios from "axios";
// import { toast } from 'react-toastify';
import config from "../../IP_Configrations.json";

const host = config.static;

export function addUserStepOne(userObject) {
  return async (dispatch) => {
    window.localStorage.setItem("DN", userObject.email.split("@")[1].split(".")[0]);
    window.localStorage.setItem("user_phone", userObject.phone);
    await axios
      .post(`${host}RegisterUser`, userObject)
      .then((res) => {
        userObject.id = res?.data?.lastAccount;
        window.localStorage.setItem("userToken", res.data.access_token.access_token);
        dispatch({
          type: types.User_Added,
          user: userObject,
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.msg ? err.response.data.msg : "There was an error creating the user please try again"
        );
        dispatch(StopLoading());
      });
  };
}

export function editUserStepOne(userObject) {
  return async (dispatch) => {
    window.localStorage.setItem("user_phone", userObject.phone);
    await axios
      .post(`${host}EditUser`, userObject, {
        headers: {
          Authorization: localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        userObject.id = res?.data?.lastAccount;
        window.localStorage.setItem("userToken", res.data.access_token.access_token);
        dispatch({
          type: types.User_Edited,
          user: userObject,
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.msg ? err.response.data.msg : "There was an error creating the user please try again"
        );
        dispatch(StopLoading());
      });

    // await Api.post("EditUser", userObject).then((res) => {
    //   userObject.id = res?.lastAccount;
    //   window.localStorage.setItem(
    //     "userToken",
    //      res.access_token.access_token
    //   );
    //   dispatch({
    //     type: types.User_Edited,
    //     user: userObject,
    //   });
    // });
  };
}

export function addTransferOwnerShipStep1(userObject, TransferToExistAccount) {
  return async (dispatch) => {
    await Api.post(`TransferAccountOwnership?TransferToExistAccount=${TransferToExistAccount}`, userObject).then(
      (res) => {
        console.log(res);
        let newAccountDetails = { ...userObject };
        newAccountDetails.ToAccountId = res.toAccountId;
        dispatch({
          type: types.Set_Representive_Flag,
          hasRepresentive: res.hasRepresentive,
        });
        dispatch({
          type: types.Set_RequestActivityId,
          requestActivityId: res.requestActivityId,
        });
        dispatch({
          type: types.Set_TransferedOwnerUser,
          transferedOwnerUser: newAccountDetails,
        });
        dispatch({
          type: types.Set_RepresentativeDetails,
          representativeDetails: res.representativeDetails,
        });
        dispatch({
          type: types.Change_Step,
          value: 1,
        });
      }
    );
  };
}

export function addNewCompany(userObject) {
  return async (dispatch) => {
    dispatch({
      type: types.ADD_NEW_COMPANY,
      user: userObject,
    });
  };
}

export function addCompanyStepthree(CompanyObject) {
  return async (dispatch) => {
    Api.post("AddCompanies", CompanyObject)
      .then((res) => {
        window.localStorage.setItem("thirdStep", JSON.stringify(res));
        dispatch({
          type: types.Company_Added,
          objectCompany: res,
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.msg ? err.response.data.msg : "There was an error creating the user please try again"
        );
        dispatch(StopLoading());
      });
  };
}

export function AddCompanyBankDetail(bankDetails) {
  return async (dispatch) => {
    Api.post("AddCompanyBankDetail", bankDetails)
      .then((res) => {
        //toast.success('Operation Success');
        window.localStorage.setItem("CompanyBankDetail", JSON.stringify(bankDetails));
        dispatch({
          type: types.Company_Bank_Detail,
          payload: bankDetails,
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.msg ? err.response.data.msg : "There was an error creating the user please try again"
        );
        dispatch(StopLoading());
      });
  };
}

export function AddCompanyFinancialContactPerson(financialContactPerson) {
  return async (dispatch) => {
    Api.post("AddCompanyFinancialContactPerson", financialContactPerson).then((res) => {
      //toast.success('Operation Success');
      window.localStorage.setItem("CompanyFinancialContactPerson", JSON.stringify(financialContactPerson));
      dispatch({
        type: types.Company_financial_Contact_Person,
        payload: financialContactPerson,
      });
    });
  };
}

export function AddTermsAndPolicy(TermsAndPolicy) {
  return async (dispatch) => {
    Api.post("UpdateCompanyPolicies", TermsAndPolicy)
      .then((res) => {
        dispatch({
          type: types.TERMS_AND_POLICY,
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.msg ? err.response.data.msg : "There was an error creating the user please try again"
        );
        dispatch(StopLoading());
      });
  };
}

export function VerifyCodeStepTwo(code) {
  return async (dispatch) => {
    Api.post("VerifyEmail", { code: code }).then((res) => {
      if (res.verificationStatus == false) {
        //toast.warn(res.msg);
        dispatch({
          type: types.Verify_Email_Invalid,
        });
      } else {
        dispatch({
          type: types.Verify_Email,
        });
      }
    });
  };
}

export function VerifyCodeStepTwoTransferedmail(code, id) {
  return async (dispatch) => {
    Api.post("VerifyEmailForTransferOwnership", { id: id, code: code })
      .then((res) => {
        if (res.verificationStatus == false) {
          //toast.warn(res.msg);
          dispatch({
            type: types.Verify_Email_Invalid,
          });
        } else {
          dispatch({
            type: types.Verify_Email,
          });
          dispatch({
            type: types.Change_Step,
            value: 1,
          });
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.msg ? err.response.data.msg : "There was an error creating the user please try again"
        );
        dispatch(StopLoading());
      });
  };
}

export function updateReduxStore() {
  return (dispatch) => {
    let lastCompany =
      localStorage.getItem("thirdStep") === null ? {} : JSON.parse(window.localStorage.getItem("thirdStep")) || {};
    let fourthStep =
      localStorage.getItem("fourthStep") === null ? {} : JSON.parse(window.localStorage.getItem("fourthStep")) || {};

    let currentUser = localStorage.getItem("u") === null ? {} : JSON.parse(window.localStorage.getItem("u")) || {};
    let currentStep = localStorage.getItem("cs") === null ? 1 : parseInt(window.localStorage.getItem("cs"));
    let verificationCode = localStorage.getItem("code") === null ? "" : window.localStorage.getItem("code");
    window.localStorage.setItem("user_phone", currentUser.phone);

    dispatch({
      type: types.Update_Redux_Store,
      user: currentUser,
      verificationCode: verificationCode,
      currentStep: currentStep,
      activeStep: currentStep,
      company: lastCompany,
      companyId: lastCompany.id,
      prevSaved: Object.keys(lastCompany).length === 0 ? false : true,
      isSaudia: lastCompany.isSaudia,
      companyDetails: fourthStep,

      vatCertificationFile: fourthStep.vatCertificationFile,
    });
  };
}

export function SendCodeAgainStepTwo() {
  return (dispatch) => {
    Api.post("ReSendVerifyEmailForCode").then((res) => {
      //toast.success('Check Your Email Again !!');
      dispatch({
        type: types.Resend_Code,
        isLoading: false,
      });
    });
  };
}

export function SendCodeAgainStepTwoForTransferedMail(id) {
  return (dispatch) => {
    Api.post(`ReSendVerifyEmailForTransferOwnership?accountId=${id}`).then((res) => {
      dispatch({
        type: types.Resend_Code,
        isLoading: false,
      });
    });
  };
}

export function addCompany(doc) {
  return (dispatch, getState) => {
    dispatch({
      type: types.Document_Adding,
    });
  };
}

export function SetLoading() {
  return (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
    });
  };
}

export function SetLoadingFlag(loading) {
  return (dispatch) => {
    dispatch({
      type: types.Set_LoadingFlag,
      loadingFlag: loading,
    });
  };
}

export function SetTransferedModal(transferedModal) {
  return (dispatch) => {
    dispatch({
      type: types.Set_TransferModal,
      transferedModal: transferedModal,
    });
  };
}

export function SetTransferOwnerShip(isTransferOwnerShip) {
  return (dispatch) => {
    dispatch({
      type: types.Set_TransferOwnerShip,
      isTransferOwnerShip: isTransferOwnerShip,
    });
  };
}

export function SetRepresentiveFlag(hasRepresentive) {
  return (dispatch) => {
    dispatch({
      type: types.Set_Representive_Flag,
      hasRepresentive: hasRepresentive,
    });
  };
}

export function SetTranseferedDone(isTranferedOwnerDone) {
  return (dispatch) => {
    dispatch({
      type: types.Set_transferedDone,
      isTranferedOwnerDone: isTranferedOwnerDone,
    });
  };
}

export function SetTabHeaders(stepMainTitle, stepSubTitle, activeStep) {
  return (dispatch) => {
    dispatch({
      type: types.Set_Tab_Headers,
      stepMainTitle: stepMainTitle,
      stepSubTitle: stepSubTitle,
      activeStep: activeStep,
    });
  };
}

export function setActiveStep(activeStep) {
  return (dispatch) => {
    dispatch({
      type: types.Change_Step,
      activeStep: activeStep,
    });
  };
}

export function setRegistrationStep(currentStep) {
  console.log("currentStep actions", currentStep);
  return (dispatch) => {
    dispatch({
      type: types.Continue_Registration,
      value: currentStep,
    });
  };
}

export function backAction() {
  return (dispatch) => {
    dispatch({
      type: types.Change_Step,
      value: -1,
    });
  };
}

export function NextAction() {
  return (dispatch) => {
    dispatch({
      type: types.Change_Step,
      value: 1,
    });
  };
}

export function SetVatFileUploaded(file, fieldName) {
  return (dispatch) => {
    dispatch({
      type: types.FILE_UPLOAD,
      file: file,
      fieldName,
    });
  };
}
console.log("THIRD STEP");

export function AddCompanyDetails(bankDetails) {
  const thirdStepData = JSON.parse(localStorage.getItem("thirdStep"));
  return async (dispatch) => {
    Api.post("AddCompanyDetails", { ...thirdStepData, ...bankDetails })
      .then((res) => {
        if (res !== 500) {
          window.localStorage.setItem("fourthStep", JSON.stringify(res));
          dispatch({
            type: types.Company_Details,
            companyDetails: res,
          });
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.msg ? err.response.data.msg : "There was an error creating the user please try again"
        );
        dispatch(StopLoading());
      });
  };
}

export function GetCompanySummary(companyId) {
  return (dispatch) => {
    Api.post(`GetCompanySummary`, { companyId: companyId }).then((res) => {
      //console.log(`res`, res);

      dispatch({
        type: types.Company_Summary,
        payload: res,
      });
    });
  };
}

export function ChangeLayout(isDashboard) {
  localStorage.setItem("dashboardLAyout", isDashboard);
  return (dispatch) => {
    dispatch({
      type: types.Set_Dashboard,
      isDashboard: isDashboard,
    });
  };
}

export function GetLayoutFromStore() {
  let checkDashboard = window.location.pathname.includes("Dashboard") ? true : false;

  // console.log(window.location.pathname.includes('Dashboard'), checkDashboard, window.location)

  let isDashboard =
    localStorage.getItem("dashboardLAyout") === null ? true : localStorage.getItem("dashboardLAyout") || true;
  return (dispatch) => {
    dispatch({
      type: types.Set_Dashboard,
      isDashboard: checkDashboard,
    });
  };
}

export function SetCompanyId(companyId) {
  return (dispatch) => {
    dispatch({
      type: types.Set_companyId,
      companyId: companyId,
    });
    // window.location.reload();
  };
}

function StopLoading() {
  return (dispatch) => {
    dispatch({
      type: types.User_Exist_Added,
    });
  };
}
