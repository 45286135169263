import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import ProfileCompletness from "../../../../Components/ProfileCompletness/ProfileCompletness";
import castle_24 from "../../../../Styles/images/icons/castle_24.svg";
import company_avatar from "../../../../Styles/images/dashboard/company_avatar.png";
import DataServices from "../../../../Services/DataServices";
import config from "../../../../IP_Configrations.json";
import { SetTabHeaders } from "../../../../Store/actions/registration";

import Loading from "../../../../Components/Loading/index";
import example from "../../../../Styles/images/example.jpg";
import CloseSVG from "../../../../Styles/images/icons/popup_close.svg";
import EPCSVG from "../../../../Styles/images/epic.png";
import axios from "axios";
import ModalContext from "../../../../Store/ModalProvider";
import { useContext } from "react";

const host = config.static;

const CompanyInfo = () => {
  const numRegex = /^[0-9]*$/;
  const currency = /^[0-9].+$/;
  const currency2 = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\d]*$/g;

  // const currency = /^(\d+(\.\d+)?)$/;
  const CharsDigsRegex =
    /^[a-zA-Z0-9_.!@#$%^&*,<>)(-+=)'"-/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)'"-(-+=/\[\]/\{\}]+)*$/;
  //const companyId = localStorage.getItem("CompanyId");
  const companyId = useSelector((state) => state.registration.companyId) || localStorage.getItem("CompanyId");
  const userToken = localStorage.getItem("user_token");
  const [Company, setCompany] = useState({});
  const [NationalAddress, setNationalAddress] = useState({});
  const [AdditionalInfo, setAdditionalInfo] = useState({});
  const [OtherFlag, setOtherFlag] = useState(false);
  const [OtherTyping, setOtherTyping] = useState(false);

  const [Logo, setLogo] = useState(false);
  const [Saving, setSaving] = useState(false);
  const [Load, setLoad] = useState(true);
  const [Changed, setChanged] = useState(false);
  const [ChangedAI, setChangedAI] = useState(false);
  const [activeStep, setActiveStep] = useState(1);

  const [Countries, setCountries] = useState([]);
  const [Cities, setCities] = useState([]);
  const [Districts, setDistricts] = useState([]);
  const [BusinessTypes, setBusinessTypes] = useState([]);

  const dispatch = useDispatch();

  const [openModalChooseCompany, setOpenModalChooseCompany] = useState(false);
  const [chosenCompany, setChosenCompany] = useState(window.localStorage.getItem("toChooseCompany"));
  const [CompanyList, setCompanyList] = useState();
  const { setModalComponent, setModalOpen, modalOpen, chooseCompany, setchooseCompany } = useContext(ModalContext);
  useEffect(() => {
    if (chooseCompany) {
      window.localStorage.setItem("toChooseCompany", false);
      setModalOpen(true);
      setchooseCompany(false);
    }
  }, []);

  useEffect(() => {
    const getCompaniesList = async () => {
      axios
        .get(`${host}GetCompaniesForList`, {
          headers: {
            Authorization: userToken,
          },
        })
        .then((res) => {
          setCompanyList(res.data.items);
        })
        .catch((err) => console.log(err));
    };

    getCompaniesList();
  }, [userToken]);

  const animateInputs = () => {
    const inputs = document.querySelectorAll(".input");
    // console.log("inputs");
    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function (e) {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value == "") {
          input.classList.remove("focused");
        }
      });
      // input.addEventListener("onchange", function (e) {
      //     if (e.target.name == "lastYearRevenue") {
      //         //lastYearRevenue
      //         input.classList.add("input_typing");
      //     }
      // });
    });
  };

  useEffect(() => {
    setLoad(true);
    animateInputs();

    dispatch(SetTabHeaders("Company info"));

    DataServices.GetDataListDetailed(`GetCountriesList`, "title", "id").then((res) => setCountries(res));
    DataServices.GetDataListDetailed(`GetCompanyTypesList`, "title", "id").then((res) => setBusinessTypes(res));
    DataServices.GetRowById(`GetCOMPANIESForEdit?companyId=${companyId}`).then((res) => {
      setCompany(res);
    });
    DataServices.GetRowById(`GetCompanyNationalAddressForEdit?companyId=${companyId}`).then((res) => {
      setNationalAddress(res);
    });
    DataServices.GetRowById(`GetCompanyAdditionInfoForEdit?companyId=${companyId}`).then((res) => {
      setAdditionalInfo(res);
      setLoad(false);
    });
    console.log(window.location.href.split("#")[1], "didmount");
    let step = window.location.href.split("#")[1];
    setActiveStep(step == "address" ? 2 : step == "additionalInfo" ? 3 : 1);
  }, [companyId]);

  useEffect(() => {
    if (Company?.countryId) {
      DataServices.GetDataListDetailed(`GetCitiesList?countryId=${Company?.countryId}`, "title", "id").then((res) =>
        setCities(res)
      );
    }
  }, [Company?.countryId]);

  useEffect(() => {
    if (NationalAddress?.cityId) {
      console.log(NationalAddress.cityId);
      DataServices.GetDataListDetailed(`GetDistrictByCityId?cityId=${NationalAddress?.cityId}`, "title", "id").then(
        (res) => {
          setDistricts(res);
        }
      );
    }
  }, [NationalAddress?.cityId]);

  const toastId = useRef(null);

  const notify = (msg) =>
    (toastId.current = toast.info("Uploading " + msg + "...", {
      autoClose: false,
    }));

  const dismiss = () => toast.dismiss(toastId.current);

  const uploadCompanyLogo = () => {
    notify("Logo");
    setSaving(true);
    const formData = new FormData();
    formData.append("logo", Logo);
    DataServices.addLogo("UploadCompanyLogo", formData).then(() => {
      dismiss();
      setSaving(false);
      toast.success("Logo saved successfully", { hideProgressBar: true });
    });
  };

  const ChangeNationalAddress = (e) => {
    setChanged(true);
    handleChange(e);
  };

  const ChangeDropDownNA = (label, value) => {
    setChanged(true);
    setFieldValue(label, value);
  };

  const ChangeAdditionalInfo = (e) => {
    setChangedAI(true);
    handleChange2(e);
  };

  const ChangeDropDownAI = (label, value) => {
    setChangedAI(true);
    if (value === 5715) {
      setOtherFlag(true);
      setOtherTyping(true);
    } else setOtherFlag(false);
    setFieldValue2(label, value);
  };

  const handleOtherType = (e) => {
    if (e.target.value == "") e.target.parentElement.classList.remove("focused");
    else {
      DataServices.addObject("AddBusinessTypeManual", {
        title: e.target.value,
      }).then((res) => {
        const companyTypes = [...BusinessTypes];
        companyTypes.unshift({ label: res.title, value: res.id });
        setBusinessTypes(companyTypes);
        setFieldValue2("companyTypeId", res.id);
        setOtherFlag(false);
      });
    }
    setOtherTyping(false);
  };

  const SaveNationalAddress = () => {
    setSaving(true);
    DataServices.addObject("UpdateCompanyNationalAddress", values).then(() => {
      toast.success("National address saved", { hideProgressBar: true });
      setSaving(false);
      setChanged(false);
    });
  };

  const SaveAdditionalInfo = () => {
    // console.log("====================================");
    // console.log("kk");
    // console.log("====================================");
    const additionalInfo = {
      ...values2,
      lastYearNetProfit: values2?.lastYearNetProfit ? values2?.lastYearNetProfit?.replace(/[^a-zA-Z0-9 ]/g, "") : null,
      lastYearRevenue: values2?.lastYearRevenue ? values2?.lastYearRevenue?.replace(/[^a-zA-Z0-9 ]/g, "") : null,
      companyId: parseInt(companyId),
      id: 0,
      companyTypeName: "",
      companyName: "",
      IsSuadia: Company?.isSaudia,
    };
    // console.log("====================================");
    // console.log(additionalInfo);
    // console.log("====================================");
    setSaving(true);
    DataServices.addObject("UpdateCompanyAdditionInfo", additionalInfo).then(() => {
      toast.success("Additional info saved", { hideProgressBar: true });
      setSaving(false);
      setChangedAI(false);
    });
  };

  const numberWithCommas = (numbers) => {
    numbers = numbers == null ? "" : numbers;

    return numbers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const validateNationalAddress = useFormik({
    enableReinitialize: true,
    initialValues: { ...NationalAddress },
    validationSchema: NationalAddress?.isSaudia
      ? Yup.object().shape({
          shortAddress: Yup.string()
            .matches(
              /^[a-zA-Z]{4}[0-9]{4}$/g,
              "Invalid Short address. Please ensure that the number consists of 4 characters then 4 numbers with no spaces or any special characters."
            )
            .max(
              8,
              "Invalid Short address. Please ensure that the number consists of 4 characters then 4 numbers with no spaces or any special characters."
            )
            .min(
              8,
              "Invalid Short address. Please ensure that the number consists of 4 characters then 4 numbers with no spaces or any special characters."
            ),
          street: Yup.string().required("Street is required"),
          secondryNo: Yup.string()
            .required("Secondary no. is required")
            .matches(
              numRegex,
              "Invalid secondary no. Please ensure that the number consists of 4 digits with no spaces or any other characters."
            )
            .test(
              "len",
              "Invalid secondary no. Please ensure that the number consists of 4 digits with no spaces or any other characters.",
              (val) => val?.length === 4
            ),
          buildingNo: Yup.string()
            .required("Building no. is required")
            .matches(
              numRegex,
              "Invalid building no. Please ensure that the number consists of 4 digits with no spaces or any other characters."
            )
            .test(
              "len",
              "Invalid building no. Please ensure that the number consists of 4 digits with no spaces or any other characters.",
              (val) => val?.length === 4
            ),
          cityId: Yup.string().required("City is required"),
          districtId: Yup.string().required("District is required"),
          zipCode: Yup.string()
            .required("Zip / Postal code is required")
            .matches(
              numRegex,
              "Invalid zip code Please ensure that the number consists of 5 digits with no spaces or any other characters."
            )
            .test(
              "len",
              "Invalid zip code Please ensure that the number consists of 5 digits with no spaces or any other characters.",
              (val) => val?.length === 5
            ),
        })
      : Yup.object().shape({
          addressLine1: Yup.string()
            .matches(
              CharsDigsRegex,
              "Invalid address. Please ensure that the address consists of characters and numbers with no additional spaces."
            )
            .nullable(),
          addressLine2: Yup.string()
            .matches(
              CharsDigsRegex,
              "Invalid address. Please ensure that the address consists of characters and numbers with no additional spaces."
            )
            .nullable(),
          zipCode: Yup.string()

            .matches(
              numRegex,
              "Invalid zip code Please ensure that the number consists of 5 digits with no spaces or any other characters."
            )
            .test(
              "len",
              "Invalid zip code Please ensure that the number consists of 5 digits with no spaces or any other characters.",
              (val) => val?.length === 5 || !val?.length
            )
            .nullable(),
          cityId: Yup.string().nullable(),
        }),

    onSubmit: () => {
      SaveNationalAddress();
    },
  });

  const validateAdditionalInfo = useFormik({
    enableReinitialize: true,
    initialValues: { ...AdditionalInfo, otherType: "" },
    validationSchema: Yup.object().shape({
      companyTypeId: Yup.string().notRequired(),
      yearsOfBusniss: Yup.string()
        .notRequired()
        .matches(
          numRegex,
          "Invalid Years of business Please ensure that the number consists of 10 digits with no spaces or any other characters."
        ),
      // .test(
      //   "len",
      //   "Invalid Years of business Please ensure that the number consists of 10 digits with no spaces or any other characters.",
      //   (val) => val?.length <= 10 || !val
      // )
      numberEmployeeKsa: Yup.string()
        .notRequired()
        .matches(
          numRegex,
          "Invalid number of employees inside KSA Please ensure that the number consists of 6 digits with no spaces or any other characters."
        )
        .test(
          "len",
          "Invalid number of employees inside KSA Please ensure that the number consists of 6 digits with no spaces or any other characters.",
          (val) => val?.length <= 6 || !val
        ),
      numberEmployeeOutsideKsa: Yup.string()
        .notRequired()
        .matches(
          numRegex,
          "Invalid number of employees outside KSA Please ensure that the number consists of 6 digits with no spaces or any other characters."
        )
        .test(
          "len",
          "Invalid number of employees outside KSA Please ensure that the number consists of 6 digits with no spaces or any other characters.",
          (val) => val?.length <= 6 || !val
        ),
      lastYearRevenue: Yup.string()
        .notRequired()
        .matches(
          currency2,
          "Invalid last year revenue Please ensure that the number consists digits with no spaces or any other characters."
        ),
      lastYearNetProfit: Yup.string()
        .notRequired()
        .matches(
          currency2,
          "Invalid last year net profit Please ensure that the number consists digits with no spaces or any other characters."
        ),
      otherType: OtherFlag ? Yup.string().required("Other type is required").notRequired() : Yup.string().notRequired(),
    }),

    onSubmit: () => {
      SaveAdditionalInfo();
    },
  });

  const { errors, touched, handleBlur, handleSubmit, values, handleChange, setFieldValue } = validateNationalAddress;
  const {
    errors: errors2,
    touched: touched2,
    handleBlur: handleBlur2,
    handleSubmit: handleSubmit2,
    values: values2,
    handleChange: handleChange2,
    setFieldValue: setFieldValue2,
  } = validateAdditionalInfo;

  function commafy(num) {
    if (num) {
      var str = num
        .toString()
        .replace(/[^a-zA-Z0-9]/g, "")
        .split(".");
      if (str[0].length >= 5) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, "$1 ");
      }
      console.log(str.join("."));
      return str.join(".");
    }
  }

  if (Load) return <Loading />;
  return (
    <div className="company_info">
      <div className="left_sec">
        <div className="accordion" id="company_info">
          <a href="#primaryInfo">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button sh5-med"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded={activeStep == 1 ? "true" : "false"}
                  aria-controls="collapseOne"
                >
                  <span className="title"> Primary Info</span>
                  <span className="required">Required</span>
                </button>
              </h2>
              <div
                id="collapseOne"
                className={"accordion-collapse collapse " + (activeStep == 1 ? "show" : "")}
                aria-labelledby="headingOne"
                data-bs-parent="#company_info"
              >
                <div className="accordion-body">
                  <form className="proForm">
                    <div className="form-group company_logo">
                      <div className="company_logo__image">
                        <img
                          src={
                            Logo
                              ? URL.createObjectURL(Logo)
                              : Company?.logo
                              ? config.download + Company?.logo
                              : company_avatar
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="company_logo__content">
                        <div className="company_logo__content--head">
                          <img src={castle_24} className="img-fluid icon" alt="castle" />
                          <h3 className="company_logo__name">Your company logo</h3>
                        </div>
                        {Company?.vendorId && <p className="num sh6-bold">Vendor ID: ({Company?.vendorId})</p>}
                        <input
                          type="file"
                          className="inputfile"
                          id="companyPic"
                          accept="image/png, image/jpeg"
                          maxLength={1}
                          onChange={(e) => setLogo(e.target.files[0])}
                        />
                        <label htmlFor="companyPic" className="secondaryBtn mediumBtn btn d-inline-flex">
                          Update logo
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="input populated">
                        <label className="control-label" htmlFor="company_name_en">
                          Company name (English) *
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control "
                          defaultValue={Company?.companyName}
                          id="company_name_en"
                          name="company_name_en"
                          disabled
                        />
                      </div>
                      <div className="help-text">
                        <p className="text"></p>
                      </div>
                    </div>

                    {(Company?.isArabic || Company?.isSaudia) && (
                      <div className="form-group ar compony-name-ar">
                        <div className="input populated">
                          <label className="control-label" htmlFor="company_name_ar">
                            Company name (Arabic) *
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control ltr "
                            defaultValue={Company?.companyNameAr}
                            id="company_name_ar"
                            name="company_name_ar"
                            disabled
                          />
                        </div>
                        <div className="help-text">
                          <p className="text"></p>
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Select
                        className="select_react disabled"
                        name="country"
                        placeholder="Select Country"
                        isDisabled
                        value={Countries ? Countries.find((option) => option.value === Company?.countryId) : ""}
                        classNamePrefix="select"
                      />

                      <div className="help-text">
                        <p className="text"></p>
                      </div>
                    </div>

                    <div className="form_actions">
                      <button
                        className={"btn primaryBtn largeBtn " + (!Logo || Saving ? "disabled" : "")}
                        type="button"
                        onClick={uploadCompanyLogo}
                      >
                        {Saving ? "Saving..." : "Save changes"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </a>

          <a href="#address">
            {values?.isSaudia ? (
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button sh5-med collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded={activeStep == 2 ? "true" : "false"}
                    aria-controls="collapseTwo"
                  >
                    <span className="title"> Address</span>
                    <span className="required">Required</span>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={"accordion-collapse collapse " + (activeStep == 2 ? "show" : "")}
                  aria-labelledby="headingTwo"
                  data-bs-parent="#company_info"
                >
                  <div className="accordion-body">
                    <form className="proForm">
                      <div className="form-group">
                        <div className={`input ${values?.shortAddress ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="shortAddress">
                            Short address (optional)
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            disabled
                            className={"form-control " + (errors?.shortAddress && touched?.shortAddress && "has-error")}
                            value={values?.shortAddress}
                            onChange={ChangeNationalAddress}
                            id="shortAddress"
                            name="shortAddress"
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur(e);
                            }}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                          />
                        </div>
                        <div className="help-text">
                          {errors.shortAddress && touched.shortAddress ? (
                            <p className="text has-error">{errors.shortAddress}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="sideByside">
                        <div className="form-group">
                          <div className={`input ${values?.buildingNo ? `focused` : ""}`}>
                            <label className="control-label" htmlFor="buildingNo">
                              Building no.*
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              disabled
                              className={"form-control " + (errors?.buildingNo && touched?.buildingNo && "has-error")}
                              value={values?.buildingNo}
                              onChange={ChangeNationalAddress}
                              id="buildingNo"
                              name="buildingNo"
                              onBlur={(e) => {
                                if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                                handleBlur(e);
                              }}
                              onFocus={(e) => e.target.parentElement.classList.add("focused")}
                            />
                          </div>
                          <div className="help-text">
                            {errors.buildingNo && touched.buildingNo ? (
                              <p className="text has-error">{errors.buildingNo}</p>
                            ) : (
                              <p className="text">Must be 4 digits</p>
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className={`input ${values?.street ? `focused` : ""}`}>
                            <label className="control-label" htmlFor="street">
                              Street*
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              className={"form-control " + (errors?.street && touched?.street && "has-error")}
                              disabled
                              value={values?.street}
                              onChange={ChangeNationalAddress}
                              id="street"
                              name="street"
                              onBlur={(e) => {
                                if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                                handleBlur(e);
                              }}
                              onFocus={(e) => e.target.parentElement.classList.add("focused")}
                            />
                          </div>
                          <div className="help-text">
                            {errors.street && touched.street && <p className="text has-error">{errors.street}</p>}
                          </div>
                        </div>
                      </div>

                      <div className="sideByside">
                        <div className="form-group">
                          <div className={`input ${values?.secondryNo ? `focused` : ""}`}>
                            <label className="control-label" htmlFor="secondryNo">
                              Secondary no.*
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              className={"form-control " + (errors?.secondryNo && touched?.secondryNo && "has-error")}
                              disabled
                              value={values?.secondryNo}
                              onChange={ChangeNationalAddress}
                              id="secondryNo"
                              name="secondryNo"
                              onBlur={(e) => {
                                if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                                handleBlur(e);
                              }}
                              onFocus={(e) => e.target.parentElement.classList.add("focused")}
                            />
                          </div>
                          <div className="help-text">
                            {errors.secondryNo && touched.secondryNo ? (
                              <p className="text has-error">{errors.secondryNo}</p>
                            ) : (
                              <p className="text">Must be 4 digits</p>
                            )}
                          </div>
                        </div>
                        <div className="form-group select_md">
                          <Select
                            className="select_react disabled"
                            name="city"
                            placeholder="Select City"
                            disabled
                            value={Cities ? Cities.find((option) => option.value === values?.cityId) || "" : ""}
                            options={Cities}
                            onChange={(e) => ChangeDropDownNA("cityId", e.value)}
                            classNamePrefix="select"
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur(e);
                            }}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                          />
                          <div className="help-text">
                            {errors.cityId && touched.cityId && <p className="text has-error">{errors.cityId}</p>}
                          </div>
                        </div>
                      </div>

                      <div className="sideByside">
                        <div className="form-group">
                          <div className={`input ${values?.zipCode ? `focused` : ""}`}>
                            <label className="control-label" htmlFor="zipCode">
                              Zip/Postal code*
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              className={"form-control " + (errors?.zipCode && touched?.zipCode && "has-error")}
                              disabled
                              value={values?.zipCode}
                              onChange={ChangeNationalAddress}
                              id="zipCode"
                              name="zipCode"
                              onBlur={(e) => {
                                if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                                handleBlur(e);
                              }}
                              onFocus={(e) => e.target.parentElement.classList.add("focused")}
                            />
                          </div>
                          <div className="help-text">
                            {errors.zipCode && touched.zipCode ? (
                              <p className="text has-error">{errors.zipCode}</p>
                            ) : (
                              <p className="text">Must be 5 digits</p>
                            )}
                          </div>
                        </div>
                        <div className="form-group select_md">
                          <Select
                            className="select_react disabled"
                            name="district"
                            placeholder="Select District"
                            value={Districts ? Districts.find((option) => option.value === values?.districtId) : ""}
                            options={Districts}
                            onChange={(e) => ChangeDropDownNA("districtId", e.value)}
                            classNamePrefix="select"
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur(e);
                            }}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                            disabled={false}
                          />
                          <div className="help-text">
                            {errors.districtId && touched.districtId && (
                              <p className="text has-error">{errors.districtId}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form_actions">
                        {/* <button
                          className={
                            "btn primaryBtn largeBtn " +
                            (!Changed || Saving ? "disabled" : "")
                          }
                          type="button"
                          onClick={handleSubmit}
                        >
                          {Saving ? "Saving..." : "Save changes"}
                        </button> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button sh5-med collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded={activeStep == 2 ? "true" : "false"}
                    aria-controls="collapseTwo"
                  >
                    <span className="title"> Address</span>
                    <span className="required">Required</span>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={"accordion-collapse collapse " + (activeStep == 2 ? "show" : "")}
                  aria-labelledby="headingTwo"
                  data-bs-parent="#company_info"
                >
                  <div className="accordion-body">
                    <form className="proForm">
                      <div className="form-group">
                        <div className={`input ${values?.addressLine1 ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="addressLine1">
                            Address Line 1
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={"form-control " + (errors?.addressLine1 && touched?.addressLine1 && "has-error")}
                            value={values?.addressLine1}
                            onChange={ChangeNationalAddress}
                            id="addressLine1"
                            name="addressLine1"
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur(e);
                            }}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                          />
                        </div>
                        <div className="help-text">
                          {errors.addressLine1 && touched.addressLine1 && (
                            <p className="text has-error">{errors.addressLine1}</p>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <div className={`input ${values?.addressLine2 ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="addressLine2">
                            Address Line 2
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={"form-control " + (errors?.addressLine2 && touched?.addressLine2 && "has-error")}
                            value={values?.addressLine2}
                            onChange={ChangeNationalAddress}
                            id="addressLine2"
                            name="addressLine2"
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur(e);
                            }}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                          />
                        </div>
                        <div className="help-text">
                          {errors.addressLine2 && touched.addressLine2 && (
                            <p className="text has-error">{errors.addressLine2}</p>
                          )}
                        </div>
                      </div>

                      <div className="sideByside">
                        <div className="form-group">
                          <div className={`input ${values?.zipCode ? `focused` : ""}`}>
                            <label className="control-label" htmlFor="zipCode">
                              Zip/Postal code
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              className={"form-control " + (errors?.zipCode && touched?.zipCode && "has-error")}
                              value={values?.zipCode}
                              onChange={ChangeNationalAddress}
                              id="zipCode"
                              name="zipCode"
                              onBlur={(e) => {
                                if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                                handleBlur(e);
                              }}
                              onFocus={(e) => e.target.parentElement.classList.add("focused")}
                            />
                          </div>
                          <div className="help-text">
                            {errors.zipCode && touched.zipCode ? (
                              <p className="text has-error">{errors.zipCode}</p>
                            ) : (
                              <p className="text">Must be 5 digits</p>
                            )}
                          </div>
                        </div>
                        <div className="form-group select_md">
                          <Select
                            className="select_react"
                            name="city"
                            placeholder="Select City"
                            value={Cities ? Cities.find((option) => option.value === values?.cityId) || "" : ""}
                            options={Cities}
                            onChange={(e) => ChangeDropDownNA("cityId", e.value)}
                            classNamePrefix="select"
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur(e);
                            }}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                          />
                          <div className="help-text">
                            {errors.cityId && touched.cityId && <p className="text has-error">{errors.cityId}</p>}
                          </div>
                        </div>
                      </div>

                      <div className="form_actions">
                        <button
                          className={"btn primaryBtn largeBtn " + (!Changed || Saving ? "disabled" : "")}
                          type="button"
                          onClick={handleSubmit}
                        >
                          {Saving ? "Saving..." : "Save changes"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </a>

          <a href="#additionalInfo">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button sh5-med collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded={activeStep == 3 ? "true" : "false"}
                  aria-controls="collapseThree"
                >
                  <span className="title"> Additional info</span>
                  <span className="required">Optional</span>
                </button>
              </h2>
              <div
                id="collapseThree"
                className={"accordion-collapse collapse " + (activeStep == 3 ? "show" : "")}
                aria-labelledby="headingThree"
                data-bs-parent="#company_info"
              >
                <div className="accordion-body">
                  <form className="proForm">
                    <div className="sideByside">
                      <div className="form-group">
                        <div className={`input ${values2.numberEmployeeKsa ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="numberEmployeeKsa">
                            no. of employees inside KSA
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control " +
                              (errors2?.numberEmployeeKsa && touched2?.numberEmployeeKsa && "has-error")
                            }
                            value={values2?.numberEmployeeKsa || ""}
                            onChange={ChangeAdditionalInfo}
                            id="numberEmployeeKsa"
                            name="numberEmployeeKsa"
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur2(e);
                            }}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                          />
                        </div>
                        <div className="help-text">
                          {errors2.numberEmployeeKsa && touched2.numberEmployeeKsa && (
                            <p className="text has-error">{errors2.numberEmployeeKsa}</p>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <div className={`input ${values2.numberEmployeeOutsideKsa ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="numberEmployeeOutsideKsa">
                            no. of employees outside KSA
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control " +
                              (errors2?.numberEmployeeOutsideKsa && touched2?.numberEmployeeOutsideKsa && "has-error")
                            }
                            value={values2?.numberEmployeeOutsideKsa || ""}
                            onChange={ChangeAdditionalInfo}
                            id="numberEmployeeOutsideKsa"
                            name="numberEmployeeOutsideKsa"
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur2(e);
                            }}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                          />
                        </div>
                        <div className="help-text">
                          {errors2?.numberEmployeeOutsideKsa && touched2?.numberEmployeeOutsideKsa && (
                            <p className="text has-error">{errors2.numberEmployeeOutsideKsa}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="sideByside">
                      <div className="form-group">
                        <div className={`input currency ${values2.lastYearRevenue ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="lastYearRevenue">
                            Last year revenue (SAR)
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control " + (errors2?.lastYearRevenue && touched2?.lastYearRevenue && "has-error")
                            }
                            value={commafy(values2?.lastYearRevenue) || ""}
                            onChange={ChangeAdditionalInfo}
                            id="lastYearRevenue"
                            name="lastYearRevenue"
                            onKeyDown={(e) => {
                              e.target.parentElement.classList.add("input_typing");
                              //   commafy(values2?.lastYearRevenue);
                              //   console.log(
                              //     values2?.lastYearRevenue?.replace(
                              //       /\B(?=(?:\d{3})+)$/g,
                              //       ","
                              //     )
                              //   );
                              //   e.target.value = commafy(
                              //     values2?.lastYearRevenue
                              //   );
                            }}
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");

                              handleBlur2(e);
                              if (values2?.lastYearRevenue)
                                setFieldValue2("lastYearRevenue", commafy(values2?.lastYearRevenue));
                            }}
                            onFocus={(e) => {
                              e.target.parentElement.classList.add("focused");
                            }}
                          />
                        </div>
                        <div className="help-text">
                          {errors2.lastYearRevenue && touched2.lastYearRevenue && (
                            <p className="text has-error">{errors2.lastYearRevenue}</p>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <div className={`input currency ${values2.lastYearNetProfit ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="lastYearNetProfit">
                            Last year net profit (SAR)
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control " +
                              (errors2?.lastYearNetProfit && touched2?.lastYearNetProfit && "has-error")
                            }
                            value={commafy(values2?.lastYearNetProfit) || ""}
                            onChange={ChangeAdditionalInfo}
                            id="lastYearNetProfit"
                            name="lastYearNetProfit"
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur2(e);
                              if (values2?.lastYearNetProfit)
                                // setFieldValue2(
                                //   "lastYearNetProfit",
                                //   parseFloat(
                                //     values2?.lastYearNetProfit
                                //   ).toFixed(2)
                                // );
                                setFieldValue2("lastYearNetProfit", commafy(values2?.lastYearNetProfit));
                            }}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                          />
                        </div>
                        <div className="help-text">
                          {errors2.lastYearNetProfit && touched2.lastYearNetProfit && (
                            <p className="text has-error">{errors2.lastYearNetProfit}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="sideByside">
                      <div className="form-group select_md">
                        <Select
                          className="select_react"
                          name="businessType"
                          placeholder="Select company type"
                          value={
                            BusinessTypes
                              ? BusinessTypes.find((option) => option.value === values2?.companyTypeId) || ""
                              : ""
                          }
                          options={BusinessTypes}
                          onChange={(e) => ChangeDropDownAI("companyTypeId", e.value)}
                          classNamePrefix="select"
                          onBlur={handleBlur2}
                        />
                        <div className="help-text">
                          {errors2.companyTypeId && touched2.companyTypeId && (
                            <p className="text has-error">{errors2.companyTypeId}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className={`input ${values2.yearsOfBusniss ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="yearsOfBusniss">
                            Years in business
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control " + (errors2?.yearsOfBusniss && touched2?.yearsOfBusniss && "has-error")
                            }
                            value={values2?.yearsOfBusniss || ""}
                            onChange={ChangeAdditionalInfo}
                            id="yearsOfBusniss"
                            name="yearsOfBusniss"
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur2(e);
                            }}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                          />
                        </div>
                        <div className="help-text">
                          {errors2.yearsOfBusniss && touched2.yearsOfBusniss && (
                            <p className="text has-error">{errors2.yearsOfBusniss}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="sideByside">
                      {OtherFlag && (
                        <div className="form-group">
                          <div className={`input ${values2.otherType?.length > 0 ? `focused` : ""}`}>
                            <label className="control-label" htmlFor="otherType">
                              Other type
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              className={"form-control " + (errors2?.otherType && touched2?.otherType && "has-error")}
                              value={values2.otherType || ""}
                              onChange={ChangeAdditionalInfo}
                              id="otherType"
                              name="otherType"
                              onBlur={async (e) => {
                                if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                                await handleBlur2(e);
                                await handleOtherType(e);
                              }}
                              onFocus={(e) => e.target.parentElement.classList.add("focused")}
                            />
                          </div>
                          <div className="help-text">
                            {errors2.otherType && touched2.otherType && (
                              <p className="text has-error">{errors2.otherType}</p>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="form-group"></div>
                    </div>
                    <div className="form_actions">
                      <button
                        className={"btn primaryBtn largeBtn " + (!ChangedAI || Saving || OtherTyping ? "disabled" : "")}
                        type="button"
                        onClick={handleSubmit2}
                      >
                        {Saving ? "Saving..." : "Save changes"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <ProfileCompletness />
    </div>
  );
};

export default CompanyInfo;
