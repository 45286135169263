/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, Fragment } from "react";
import axios from "axios";

import QRCode from "../Styles/images/company_profile/QRCode.png";
import HeaderCompanyProfile from "../Components/CompanyProfileComponents/HeaderCompanyProfile";
import SideSectionInfo from "../Components/CompanyProfileComponents/SideSectionInfo";
import MainCompanyInfo from "../Components/CompanyProfileComponents/MainCompanyInfo";
import CompanyInfo from "../Components/CompanyProfileComponents/CompanyInfo";
import FinancialDetails from "../Components/CompanyProfileComponents/FinancialDetails";
import CompanyDocuments from "../Components/CompanyProfileComponents/CompanyDocuments";
import Loading from "../Components/Loading/index";
import { useDispatch } from "react-redux";
import { ChangeLayout, SetTabHeaders } from "../Store/actions/registration";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import CloseSVG from "../Styles/images/icons/popup_close.svg";
import EPCSVG from "../Styles/images/epic.png";

import config from "../IP_Configrations.json";
import SideSectionInfoDownload from "../Components/CompanyProfileComponents/SideSectionInfoDownload";
const host = config.static;

const CompanyProfileDownload = ({ isPrint, setisPrint }) => {
  const [companyData, setCompanyData] = useState();
  const CompanyId = localStorage.getItem("CompanyId");
  const userToken = localStorage.getItem("user_token");
  const [openModalChooseCompany, setOpenModalChooseCompany] = useState(false);

  const dispatch = useDispatch();

  const [chosenCompany, setChosenCompany] = useState(window.localStorage.getItem("chosenCompany"));
  const [CompanyList, setCompanyList] = useState();

  useEffect(() => {
    if (chosenCompany) {
      window.localStorage.setItem("toChooseCompany", true);
      setOpenModalChooseCompany(true);
    }
  }, [chosenCompany]);

  useEffect(() => {
    const getCompaniesList = async () => {
      if (userToken) {
        axios
          .get(`${host}GetCompaniesForList`, {
            headers: {
              Authorization: userToken,
            },
          })
          .then((res) => {
            setCompanyList(res.data.items);
          })
          .catch((err) => console.log(err));
      }
    };

    getCompaniesList();
  }, [userToken]);

  const [countryCodeList, setCountryCodeList] = useState([]);

  useEffect(() => {
    const getCountriesCode = async () => {
      await axios
        .get(`${host}GetCountriesList`, {
          headers: {
            Authorization: userToken,
          },
        })
        .then((res) => {
          const countriesList = res.data.map((item) => {
            return {
              label: item.countryCode,
              value: item.id,
            };
          });

          setCountryCodeList(countriesList);
        })
        .catch((err) => console.log(err));
    };
    getCountriesCode();
  }, []);

  useEffect(() => {
    dispatch(ChangeLayout(true));
    dispatch(SetTabHeaders("Company details"));
    const divs = document.querySelectorAll(".setting_container.subNavOpen");

    divs.forEach((div) => {
      div.classList.remove("setting_container");
      div.classList.remove("subNavOpen");
    });

    const getCompanyProfileData = async () => {
      await axios
        .get(`${host}GetCompanyProfile?companyId=${CompanyId}`, {
          headers: {
            Authorization: userToken,
          },
        })
        .then((res) => {
          setCompanyData(res.data);
        })
        .catch((err) => console.log(err));
    };

    getCompanyProfileData();
  }, []);

  useEffect(() => {
    if (isPrint) {
      downloadPDF();
    }
  }, [isPrint]);

  const downloadPDF = () => {
    const capture = document.querySelector(".print");

    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "mm", "a4");

      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();

      doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);

      doc.save("companyProfile.pdf");
      toast.success("Downloading File");
      setisPrint(false);
    });
  };

  // const handleSharePage = () => {
  //   navigator.clipboard.writeText(
  //     `${process.env.REACT_APP_SHARED_COMPANY_PROFILE}/SharedCompanyProfile/${CompanyId}`
  //   );
  //   toast.success("Link copied");
  // };

  return (
    <Fragment>
      {!companyData || openModalChooseCompany ? (
        <></>
      ) : (
        <section className={`company_profile_page ${isPrint ? "block" : "none"}`}>
          <div className="custom_container print">
            <div className="sec_container2">
              <SideSectionInfoDownload companyInfoData={companyData} countryCodeList={countryCodeList} />

              <div className="company_details">
                <MainCompanyInfo mainInfo={companyData} />

                <CompanyInfo dataInfo={companyData} />

                <FinancialDetails dataInfoCompany={companyData} />

                <CompanyDocuments dataInfoCompany={companyData} />
              </div>
              <div className="qrcode text-center">
                <img src={QRCode} className="img-fluid" alt="qr" />
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default CompanyProfileDownload;
