import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import Select from "react-select";

import { SetTabHeaders } from "../../../Store/actions/registration";
import ProfileCompletness from "../../ProfileCompletness/ProfileCompletness";

import DataServices from "../../../Services/DataServices";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import API from "../../../Services/Axios";

function Preferences() {
    const companyId = localStorage.getItem("CompanyId");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [Changed, setChanged] = useState(false);
    const [Load, setLoad] = useState(true);

    const [preferences, setPreferences] = useState(false);

    const animateInputs = () => {
        const inputs = document.querySelectorAll(".input");
        inputs.forEach((input) => {
            input.addEventListener(
                "focusin",
                function (e) {
                    input.classList.add("focused");
                },
                false
            );
            input.addEventListener("focusout", function (e) {
                if (e.target.value == "") {
                    input.classList.remove("focused");
                }
            });
        });
    };

    useEffect(() => {
        animateInputs();
        dispatch(SetTabHeaders("Account preferences"));

        DataServices.GetRowById(`GetAccountPreferencesForEdit?companyId=${companyId}`).then((res) => {
            setPreferences(res);
            setLoad(false);
        });
    }, []);

    const validate = useFormik({
        enableReinitialize: true,
        initialValues: { ...preferences },
        validationSchema: Yup.object().shape({
            language: Yup.string().nullable(),
            timeZone: Yup.string().nullable(),
            dateFormat: Yup.string().nullable(),
            timeFormat: Yup.string().nullable(),
        }),

        onSubmit: () => {
            API.post("UpdateAccountPreferences" ,{ companyId: parseInt(companyId), ...values }).then(res =>{
                toast.success("Account preferences updated successfully");
            }).catch(err =>{
                toast.error("Error updating account preferences");
            })
        },
    });

    const { errors, touched, handleBlur, handleSubmit, values, setFieldValue } = validate;

    const ChangeDropDown = (label, value) => {
        setChanged(true);
        setFieldValue(label, value);
    };

    const languages = [
        { label: "English", value: "En" },
        // { label: "Arabic", value: "Ar" },
    ];

    const timeZones = [
        { value: "-1200", label: "(GMT -12:00) Eniwetok, Kwajalein" },
        { value: "-1100", label: "(GMT -11:00) Midway Island, Samoa" },
        { value: "-1000", label: "(GMT -10:00) Hawaii" },
        { value: "-0950", label: "(GMT -9:30) Taiohae" },
        { value: "-0900", label: "(GMT -9:00) Alaska" },
        { value: "-0800", label: "(GMT -8:00) Pacific Time (US &amp; Canada" },
        { value: "-0700", label: "(GMT -7:00) Mountain Time (US &amp; Canada" },
        { value: "-0600", label: "(GMT -6:00) Central Time (US &amp; Canada), Mexico City" },
        { value: "-0500", label: "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima" },
        { value: "-0450", label: "(GMT -4:30) Caracas" },
        { value: "-0400", label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz" },
        { value: "-0350", label: "(GMT -3:30) Newfoundland" },
        { value: "-0300", label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown" },
        { value: "-0200", label: "(GMT -2:00) Mid-Atlantic" },
        { value: "-0100", label: "(GMT -1:00) Azores, Cape Verde Islands" },
        { value: "+0000", label: "(GMT) Western Europe Time, London, Lisbon, Casablanca" },
        { value: "+0100", label: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris" },
        { value: "+0200", label: "(GMT +2:00) Kaliningrad, South Africa" },
        { value: "+0300", label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg" },
        { value: "+0350", label: "(GMT +3:30) Tehran" },
        { value: "+0400", label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi" },
        { value: "+0450", label: "(GMT +4:30) Kabul" },
        { value: "+0500", label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent" },
        { value: "+0550", label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi" },
        { value: "+0575", label: "(GMT +5:45) Kathmandu, Pokhara" },
        { value: "+0600", label: "(GMT +6:00) Almaty, Dhaka, Colombo" },
        { value: "+0650", label: "(GMT +6:30) Yangon, Mandalay" },
        { value: "+0700", label: "(GMT +7:00) Bangkok, Hanoi, Jakarta" },
        { value: "+0800", label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong" },
        { value: "+0875", label: "(GMT +8:45) Eucla" },
        { value: "+0900", label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk" },
        { value: "+0950", label: "(GMT +9:30) Adelaide, Darwin" },
        { value: "+1000", label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok" },
        { value: "+1050", label: "(GMT +10:30) Lord Howe Island" },
        { value: "+1100", label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia" },
        { value: "+1150", label: "(GMT +11:30) Norfolk Island" },
        { value: "+1200", label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka" },
        { value: "+1275", label: "(GMT +12:45) Chatham Islands" },
        { value: "+1300", label: "(GMT +13:00) Apia, Nukualofa" },
        { value: "+1400", label: "(GMT +14:00) Line Islands, Tokelau" },
    ];

    const dateFormats = [
        { label: "DD/MM/YY", value: "/" },
        { label: "MM/DD/YY", value: "/r" },
        { label: "YY/MM/DD", value: "-" },
        { label: "Month D, Yr", value: "," },
    ];

    const timeFormats = [
        { label: "12 hours (am/pm)", value: "12h" },
        { label: "24 hours", value: "24h" },
    ];

    if (Load) return <Loading />;
    return (
        <div className="company_info  contact_person">
            <div className="left_sec">
                <form className="proForm">
                    <div className="sideByside">
                        <div className="form-group sm">
                            <Select
                                className={"select_react " + (errors.language && touched.language && "has-error")}
                                name="language"
                                placeholder="Language"
                                value={languages.find((option) => option.value === values.language) || ""}
                                options={languages}
                                onChange={(e) => ChangeDropDown("language", e.value)}
                                classNamePrefix="select"
                                onBlur={handleBlur}
                            />
                            <div className="help-text">{errors.language && touched.language && <p className="text has-error">{errors.language}</p>}</div>
                        </div>
                        <div className="form-group sm">
                            <Select
                                className={"select_react " + (errors.timeZone && touched.timeZone && "has-error")}
                                name="timeZone"
                                placeholder="Time zone"
                                value={timeZones.find((option) => option.value === values.timeZone) || ""}
                                options={timeZones}
                                onChange={(e) => ChangeDropDown("timeZone", e.value)}
                                classNamePrefix="select"
                                onBlur={handleBlur}
                            />
                            <div className="help-text">{errors.timeZone && touched.timeZone && <p className="text has-error">{errors.timeZone}</p>}</div>
                        </div>
                        <div className="form-group sm">
                            <Select
                                className={"select_react " + (errors.dateFormat && touched.dateFormat && "has-error")}
                                name="dateFormat"
                                placeholder="Date format"
                                value={dateFormats.find((option) => option.value === values.dateFormat) || ""}
                                options={dateFormats}
                                onChange={(e) => ChangeDropDown("dateFormat", e.value)}
                                classNamePrefix="select"
                                onBlur={handleBlur}
                            />
                            <div className="help-text">{errors.dateFormat && touched.dateFormat && <p className="text has-error">{errors.dateFormat}</p>}</div>
                        </div>
                        <div className="form-group sm">
                            <Select
                                className={"select_react " + (errors.timeFormat && touched.timeFormat && "has-error")}
                                name="timeFormat"
                                placeholder="Date format"
                                value={timeFormats.find((option) => option.value === values.timeFormat) || ""}
                                options={timeFormats}
                                onChange={(e) => ChangeDropDown("timeFormat", e.value)}
                                classNamePrefix="select"
                                onBlur={handleBlur}
                            />
                            <div className="help-text">{errors.timeFormat && touched.timeFormat && <p className="text has-error">{errors.timeFormat}</p>}</div>
                        </div>
                    </div>
                    <div className="form_actions">
                        <button type="button" onClick={handleSubmit} className={"btn primaryBtn largeBtn " + (!Changed && "disabled")}>
                            Save changes
                        </button>
                    </div>
                </form>
            </div>
            <ProfileCompletness />
        </div>
    );
}

export default Preferences;
