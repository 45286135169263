import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {SetTranseferedDone} from "../../src/Store/actions/registration"
import Api from "../Services/Api";
import attached_file from "../Styles/images/settings/attached_file.svg";
import Phone from "../Styles/images/icons/Phone.svg";
import { useEffect } from "react";


function RepresentativeDetails() {
    const dispatch = useDispatch();
    const representativeDetails = useSelector((state)=> state.registration.representativeDetails) || window.localStorage.getItem('representativeDetails');
    const requestActivityId =  useSelector((state)=> state.registration.requestActivityId)

    const [Changed, setChanged] = useState(false);

    const [representativeStatus, setRepresentativeStatus]= useState(false);

    useEffect(()=> {
        console.log(representativeDetails, requestActivityId)
    })

    const handleSubmit = async()=> {
        await Api.get(`UpdateRepresentiveStateOnActivityRequest?requestId=${requestActivityId}&RemoveRepresentive=${representativeStatus}`)
        .then ((res)=> {
            console.log('res',res)
            dispatch(SetTranseferedDone(true))
        })
        .catch((err)=> {
            console.log('err',err);
            dispatch(SetTranseferedDone(false))
        })
    }

    return (
        <div className="content proForm">
            <div className="sub_head">
                <div className="top">
                    <h4 className="transferText darkColor mt-4 mb-3">
                        <span>We’ve noticed that your company has an ‘Authorized company representative’</span>
                    </h4>
                </div>
            </div>

            <div className="mb-3">
                <label className="control-label">Your representative info:</label>
                <div className="form-group mb-3">
                    <div className={"input " + (representativeDetails.representativeName ? "populated" : "")}>
                        <label className="control-label" htmlFor="Representative_name">
                            Representative full name*
                        </label>
                        <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            disabled="true"
                            value={representativeDetails.representativeName}
                            id="representativeName"
                            name="representativeName"
                        />
                    </div>
                </div>

                <div className="form-group mb-3">
                    <div className={"input withIcon " + (representativeDetails.representativePhoneNumber ? "populated" : "")}>
                        <label className="control-label" htmlFor="Phone_no">
                            Phone no.*
                        </label>
                        <input
                            autoComplete="off"
                            type="text"
                            className="form-control "
                            disabled="true"
                            value={representativeDetails.representativePhoneNumber}
                            id="representativePhoneNumber"
                            name="representativePhoneNumber"
                        />
                        <img src={Phone} className="img-fluid left_icon" alt="calendar" />
                    </div>
                </div>
            </div>

           
            <div className="mb-3">
                <label className="control-label" htmlFor="">
                    Authorized company representation document:
                </label>
                <div className="uploaded_file">
                    <Link target="_blank" to={representativeDetails.representativeFile} className="file">
                        <img src={attached_file} className="img-fluid icon" alt="icon" />
                        <span>{representativeDetails.representativeFile?.split("/")[4]}</span>
                    </Link>
                </div>
            </div>


            <label className="control-label mt-3 mb-2" htmlFor="">
                Do you want to keep this authorized company representative as it is?
            </label>
            <div className="sideByside  mt-6">
                <div className="form-group">
                    <div className="form-check custom ">
                        <input
                            className="form-check-input rounded-circle"
                            type="radio"
                            name="isAuth"
                            id="yes"
                            onClick={() => {
                                setRepresentativeStatus(false);
                                setChanged(true)
                            }}
                        />
                        <label className="form-check-label" htmlFor="yes">
                        Yes, keep this representative
                        </label>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-check custom ">
                        <input
                            className="form-check-input rounded-circle"
                            type="radio"
                            name="isAuth"
                            id="no"
                            onClick={() => {
                                setRepresentativeStatus(true);
                                setChanged(true)
                               
                            }}
                        />
                        <label className="form-check-label" htmlFor="no">
                        No, remove this representative 
                        </label>
                    </div>
                </div>
            </div>
            
            <div className="form_actions  mt-4">
                <button className={"btn primaryBtn largeBtn  w-100   " + (!Changed? "disabled" : "")} type="button" onClick={()=>handleSubmit()}>
                    save and finish
                </button>
            </div>
            
        </div>
    );
}

export default RepresentativeDetails;
