import React, { useEffect, useState } from "react";
import CloseSVG from "../../../../Styles/images/icons/popup_close.svg";
import DataServices from "../../../../Services/DataServices";
import { toast } from "react-toastify";

function VerifyModal({ setShowVerify, Email, companyId , updateRecoveryEmail }) {
  const [Code, setCode] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  const [Loading, setLoading] = useState(false);
  const [Invalid, setInvalid] = useState(false);
  const [Counter, setCounter] = useState(120);
  const [count, setCount] = useState(1);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (Counter > 0) {
        setCounter((counter) => counter - 1);
      }
    }, 1000);

    return () => clearInterval(myInterval);
  }, [count]);

  const handleChange = (field, e, fieldIndex) => {
    let code = { ...Code };
    e = e.replace(/[^0-9+]/g, "");
    code[field] = e;
    setCode({
      ...code,
    });
    if (e && parseInt(fieldIndex) < 4) {
      // Get the next input field
      const nextSibling = document.querySelector(`input[name=ssn-${parseInt(fieldIndex) + 1}]`);
      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  const handleBackSpace = (e, fieldIndex) => {
    if (e.key === "Backspace" && parseInt(fieldIndex) <= 4) {
      const nextSibling = document.querySelector(`input[name=ssn-${parseInt(fieldIndex) - 1}]`);
      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  const SubmitVerifyCode = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    setInvalid(false);
    const verifyCode = Code.first + Code.second + Code.third + Code.fourth;
    DataServices.addObject(`VerifyEmailForRecoveryEmail`, {
      code: verifyCode,
      companyId: companyId,
    }).then((res) => {
      if (res?.VerificationStatus === false) {
        toast.error("Invalid code");
        setInvalid(true);
      } else if (res?.VerificationStatus === true) {
        setShowVerify(false);
        updateRecoveryEmail()
      }
      setLoading(false);
    });
  };

  const handleResend = () => {
    if (Counter < 0) {
      DataServices.addObject(`SendVerificationCodeForRecoveryEmail`, {
        companyId: companyId,
      }).then((res) => {
        setInvalid(false);
        setCounter(120);
        setCount((perv) => perv + 1);
        // console.log(res);
      });
    }
  };

  return (
    <div className="popup fullscreen active verify_recovery_emai">
      <div className="popup__content">
        <div className="popup__body">
          <div className="head">
            <h5 className="sh5-bold"> </h5>
            <span className="close" onClick={() => setShowVerify(false)}>
              <img src={CloseSVG} className="img-fluid" alt="close" />
            </span>
          </div>
          <div className="content">
            <form className="proForm">
              <div className="step2 text-center">
                <h3 className="h3-med">Verify your email</h3>
                <p className="verification secondary_text">We sent a verification code to:</p>
                <p className="mail_link">{Email}</p>
                <p className="verification secondary_text">to confirm email verification,</p>
                <h6 className="sh6-bold">please enter the code below</h6>

                <div className="form-group">
                  <div className="input">
                    <input
                      pattern="[0-9\.]+"
                      maxLength="1"
                      autoComplete="off"
                      type="text"
                      placeholder="-"
                      className="form-control"
                      required
                      tabIndex={1}
                      name="ssn-1"
                      onChange={(e) => handleChange("first", e.target.value, 1)}
                      value={Code.first}
                      onKeyUp={(e) => handleBackSpace(e, 1)}
                    />

                    <input
                      pattern="[0-9\.]+"
                      maxLength="1"
                      autoComplete="off"
                      type="text"
                      placeholder="-"
                      className="form-control"
                      required
                      tabIndex={2}
                      name="ssn-2"
                      onChange={(e) => handleChange("second", e.target.value, 2)}
                      value={Code.second}
                      onKeyUp={(e) => handleBackSpace(e, 2)}
                    />

                    <input
                      pattern="[0-9\.]+"
                      maxLength="1"
                      autoComplete="off"
                      type="text"
                      placeholder="-"
                      className="form-control"
                      required
                      tabIndex={3}
                      name="ssn-3"
                      onChange={(e) => handleChange("third", e.target.value, 3)}
                      value={Code.third}
                      onKeyUp={(e) => handleBackSpace(e, 3)}
                    />

                    <input
                      pattern="[0-9\.]+"
                      maxLength="1"
                      autoComplete="off"
                      type="text"
                      placeholder="-"
                      className="form-control"
                      required
                      tabIndex={4}
                      name="ssn-4"
                      onChange={(e) => handleChange("fourth", e.target.value, 4)}
                      value={Code.fourth}
                      onKeyUp={(e) => handleBackSpace(e, 4)}
                    />
                  </div>
                  {Invalid && (
                    <p className="time" style={{ justifyContent: "center", marginTop: "20px" }}>
                      <div className="help-text" style={{ justifyContent: "center" }}>
                        <p className="text has-error">Invalid code</p>
                      </div>
                    </p>
                  )}
                </div>
                <p className="time">
                  <a href="#" className={`resend ${Counter > 0 ? "disabled " : ""}`} onClick={handleResend}>
                    Resend code
                  </a>

                  {Counter > 0 && <span className="remain sh6-med">{Counter}</span>}
                </p>

                {Loading == true ? (
                  <button className="btn primaryBtn largeBtn disabled loading">Loading...</button>
                ) : (
                  <button
                    type="button"
                    className={
                      "btn primaryBtn largeBtn " +
                      (Code.first && Code.second && Code.third && Code.fourth ? "" : "disabled")
                    }
                    onClick={(e) => SubmitVerifyCode(e)}
                  >
                    Done
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyModal;
