import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DataServices from "../Services/DataServices";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../Styles/images/FGC_Secondary_logo_EN.svg";
import email from "../Styles/images/icons/Email.svg";
import Password from "../Styles/images/icons/Password.svg";
import ShowPass from "../Styles/images/icons/ShowPass.svg";
import HidePass from "../Styles/images/icons/HidePass.svg";
import Phone_16 from "../Styles/images/icons/Phone_16.svg";
import email_16 from "../Styles/images/icons/email_16.svg";
import Warning from "../Styles/images/icons/Warning.svg";
import error_24 from "../Styles/images/icons/error-24.svg";
import { login } from "../Store/actions/login";
import { SetCompanyId, setRegistrationStep } from "../Store/actions/registration";
import * as actions from "../Store/actions/registration";
import axios from "axios";
import config from "../IP_Configrations.json";
import * as types from "../Store/types";

import Menu from "../Components/Menu/Menu";

const host = config.static;
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const [visualisePassword, setVisualisePassword] = useState(false);
  const [NotRegistered, setNotRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(``);
  const [unCompleteAccount, setUnCompleteAccount] = useState(false);
  const emailRef = React.useRef(null);

  const handleRegistration = async () => {
    window.localStorage.clear();

    await axios.get(`${host}GetPendingStep?email=${emailRef.current.value}`).then(async (res) => {
      const { PendingOnStep, CompanyId } = res.data;
      const UserId = res.data.data.aci;
      const token = res.data.data.access_token;
      const loginCounter = res?.data?.LoginCounter;
      localStorage.setItem("cs", res.data.PendingOnStep);
      localStorage.setItem("userToken", res.data.data.access_token);
      localStorage.setItem("DN", emailRef.current.value.split("@")[1].split(".")[0]);
      localStorage.setItem("loginCounter", `${loginCounter}`);

      const userData = await fetch(`${host}GetUserInfo`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });

      const userDataJson = await userData.json();
      window.localStorage.setItem(
        "u",
        JSON.stringify({
          id: UserId,
          firstName: userDataJson.firstName,
          lastName: userDataJson.lastName,
          email: userDataJson.email,
          phone: userDataJson.phone,
          jobTitle: userDataJson.jobTitle,
          zipCode: userDataJson.zipCode,
          companyAddress: userDataJson.companyAddress,
          building: userDataJson.building,
          countryId: userDataJson.countryId,
          cityId: userDataJson.cityId,
          countryCode: "",
          isLoading: false,
          isSaudia: null,
          isArabic: null,
        })
      );

      if (PendingOnStep === 2) {
        await axios.get(`${host}GetOTPByEmail/${emailRef.current.value}`);
      }

      if (PendingOnStep > 3) {
        await axios
          .get(`${host}GetCOMPANIESForEdit?companyId=${CompanyId}`, {
            method: "GET",
            headers: {
              Authorization: `  ${token}`,
            },
          })
          .then(async (res) => {
            const contactPerson = await fetch(`${host}GetContactPersonForEdit?companyId=${CompanyId}`, {
              method: "GET",
              headers: {
                Authorization: `  ${token}`,
              },
            });

            const dataJson = await contactPerson.json();

            const address = await fetch(`${host}GetCompanyNationalAddressForEdit?companyId=${CompanyId}`, {
              method: "GET",
              headers: {
                Authorization: `  ${token}`,
              },
            });

            const addressJson = await address.json();

            const categories = await fetch(`${host}GetCompaniesCategories?companyId=${CompanyId}`, {
              method: "GET",
              headers: {
                Authorization: `  ${token}`,
              },
            });
            const categoriesJson = await categories.json();
            const categoriesArray = [];
            categoriesJson.selectedCategories.map((item) => {
              categoriesArray.push(item.categoryId);
            });

            const thirdStep = {
              id: CompanyId,
              phone: "",
              extPhone: dataJson.extPhone,
              companyName: res.data.companyName,
              companyNameAr: res.data.companyNameAr,
              isEmail: dataJson.isEmail,
              phoneCall: dataJson.phoneCall,
              sms: dataJson.sms,
              countryId: dataJson.accountCountryId,
              cityId: addressJson.cityId,
              businessTypeId: res.data.businessTypeId,
              recoveryEmail: dataJson.otherEmail,
              isSaudia: res.data.isSaudia,
              isArabic: res.data.isArabic,
              address: addressJson.shortAddress,
              zipCode: addressJson.zipCode,
              addressLine1: addressJson.addressLine1,
              addressLine2: addressJson.addressLine2,
              selectedCategories: categoriesArray,
            };
            localStorage.setItem("thirdStep", JSON.stringify(thirdStep));
          });
      }

      if (PendingOnStep > 4) {
        await axios
          .get(`${host}GetCompanyDocumentDetailsById?companyId=${CompanyId}`, {
            method: "GET",
            headers: {
              Authorization: `  ${token}`,
            },
          })
          .then((res) => {
            const stepInfo = {
              id: res.data.id,
              companyId: res.data.CompanyId,
              vatNo: res.data.vatNo,
              vatCertificationFile: res.data.vatCertificationFile,
              commercialRegisterNo: res.data.commercialRegisterNo,
              commercialRegisterNoExpireDate: res.data.commercialRegisterNoExpireDate,
              commercialRegisterNoFile: res.data.commercialRegisterNoFile,
              zakatcertificateNo: res.data.zakatCertificateNo,
              zakatcertificateExpireDate: res.data.zakatCertificateExpireDate,
              zakatcertificateFile: res.data.zakatCertificateFile,
              conductFormFile: res.data.conductFormFile,
              representativeName: res.data.representativeName,
              representativePhoneNumber: res.data.representativePhoneNumber,
              representativeFile: res.data.representativeFile,
              terms: true,
              privacyPolicy: true,
              hasOtherAuthorized: res.data.hasOtherAuthorized,
              company: null,
            };

            window.localStorage.setItem("Company_Details", JSON.stringify(stepInfo));
            window.localStorage.setItem("fourthStep", JSON.stringify(stepInfo));
          });
      }
      if (PendingOnStep > 5) {
        await axios
          .get(`${host}GetCompanyFinancialContactPerson?companyId=${CompanyId}`, {
            method: "GET",
            headers: {
              Authorization: `  ${token}`,
            },
          })
          .then((res) => {
            window.localStorage.setItem(
              "CompanyFinancialContactPerson",
              JSON.stringify({
                companyId: CompanyId,
                FinancialContactPersonName: res.data[0].financialContactPersonName,
                FinancialContactPersonPosition: res.data[0].financialContactPersonPosition,
                FinancialContactPersonPhone: res.data[0].financialContactPersonPhone,
                FinancialContactPersonEmail: res.data[0].financialContactPersonEmail,
              })
            );
          });

        await axios
          .get(`${host}GetCompanyBankDetailForEdit?companyId=${CompanyId}`, {
            method: "GET",
            headers: {
              Authorization: `  ${token}`,
            },
          })
          .then((res) => {
            window.localStorage.setItem(
              "CompanyBankDetail",
              JSON.stringify({
                companyId: res.data[0].companyId,
                bankId: res.data[0].bankId,
                bankName: res.data[0].bankName,
                branchName: res.data[0].branchName,
                contactName: res.data[0].contactName,
                currencyId: res.data[0].currencyId,
                accountNumber: res.data[0].accountNumber,
                iban: res.data[0].iban,
                swiftCode: res.data[0].swiftCode,
                description: res.data[0].description,
                IsPrimary: res.data[0].isPrimary,
                countryId: res.data[0].countryId,
              })
            );
          });
      }

      window.localStorage.setItem("continue registration", "true");
      dispatch(actions.setRegistrationStep(PendingOnStep));
      window.location.href = "/continue-registration";
    });
  };

  const animateInputs = () => {
    const inputs = document.querySelectorAll(".input.large");
    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function (e) {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value == "") {
          input.classList.remove("focused");
        }
      });
    });
  };

  useEffect(() => {
    animateInputs();
  }, []);

  const validationLogin = useFormik({
    enableReinitialize: true,
    initialValues: {
      mail: "",
      Password: "",
    },

    validationSchema: Yup.object().shape({
      mail: Yup.string().required("please Fill the email field "),

      Password: Yup.string().required("please Fill the password field"),
    }),

    onSubmit: (values, actions) => {
      setNotRegistered(false);
      setNotRegistered(false);
      setErrorMessage("");
      setUnCompleteAccount(false);
      setIsLoading(true);
      dispatch(
        login(values, (result) => {
          console.log("res", result);
          if (result.data.status === 400 || result.data.Status === 400) {
            if (typeof result.data.msg === "string") {
              toast.error(result.data.msg);
            }
            if (result.data.msg === `Wrong User's Password.`) {
              setErrorMessage("Wrong email or password, please try again");
            } else if (result.data.msg.includes("Your Company Profile Not Complete")) {
              setUnCompleteAccount(true);
            } else if (result.data.msg.includes("invalid Email")) {
              setNotRegistered(true);
            }
            setIsLoading(false);
          } else {
            const result_data = result.data ? (result.data.data ? { ...result.data.data } : { ...result.data }) : null;
            if (result_data && Object.keys(result_data).length > 0) {
              setErrorMessage(``);
              toast.success("Log in Successfully");
              localStorage.setItem("CompanyId", result_data.com);
              localStorage.setItem("user_token", `  ${result_data.access_token}`);
              localStorage.setItem("userToken", `  ${result_data.access_token}`);
              localStorage.setItem("loginCounter", `${result.data.LoginCounter}`);
              localStorage.setItem("userId", result_data.aci);
              localStorage.setItem("userEmail", result_data.sub);
              dispatch(SetCompanyId(result_data.com));

              setIsLoading(false);
              if (result_data.countOfCom === 1) {
                navigate(`/Dashboard/${result_data.com}`, {
                  replace: true,
                });
              } else {
                navigate(`/allCompanies`, { replace: true });
              }

              window.location.reload();
            } else {
              setIsLoading(false);
              toast.error("something went wrong please try again");
            }
          }
        })
      );
    },
  });

  return (
    <>
      <section className="register_page login_page">
        <div className="register_container">
          <header className="header">
            <div className="box">
              <a href="#" className="logo">
                <img src={logo} className="img-fluid" />
              </a>
            </div>
          </header>
          <div className="register_content">
            <div className="sec_head text-center">
              <h3 className="h3-bold">Login to your account</h3>
            </div>
            <div className="mb-80 proForm">
              {errorMessage !== `` ? (
                <div
                  className="sh5-med"
                  style={{
                    display: "flex",
                    backgroundColor: `#FFF1ED`,
                    alignItems: `center`,
                    padding: 16,
                    minHeight: 56,
                    borderRadius: 8,
                    border: `1px solid #E94225`,
                    marginBottom: 24,
                    color: `#84101D`,
                  }}
                >
                  <img src={Warning} style={{ marginRight: 9 }} className="img-fluid icon" alt="warning" />
                  <span>{errorMessage}</span>
                </div>
              ) : null}

              {unCompleteAccount && (
                <div
                  className="sh5-med"
                  style={{
                    backgroundColor: `#FFF1ED`,
                    padding: 16,
                    minHeight: 56,
                    borderRadius: 8,
                    border: `1px solid #E94225`,
                    marginBottom: 24,
                    color: `#84101D`,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: `center`,
                    }}
                  >
                    <img src={Warning} style={{ marginRight: 9 }} className="img-fluid icon" alt="warning" />
                    <span>
                      This email is associated with an un-completed company account, please complete your company
                      registration{" "}
                    </span>
                  </div>

                  <div style={{ marginTop: "1rem", marginLeft: "2rem" }}>
                    <button
                      style={{
                        backgroundColor: "#00416B",
                        width: "274px",
                        height: "36px",
                        border: "1px solid rgba(16, 24, 32, 0.15)",
                        boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.043)",
                        borderRadius: "4px",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#FFFFFF",
                      }}
                      onClick={() => handleRegistration()}
                    >
                      Complete your company registration
                    </button>
                  </div>
                </div>
              )}

              <div className="form-group">
                <div className="input withIcon large">
                  <label className="control-label" htmlFor="mail">
                    Email address*
                  </label>
                  <img src={email} className="img-fluid left_icon" alt="mail" />
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    style={
                      NotRegistered
                        ? {
                            background: `#FFF2ED`,
                            border: "1px solid #E94225",
                          }
                        : {}
                    }
                    ref={emailRef}
                    id="mail"
                    name="mail"
                    onChange={validationLogin.handleChange}
                  />
                  {unCompleteAccount && <img src={Warning} className="img-fluid right_icon" alt="coPassword" />}

                  {NotRegistered && <img src={error_24} className="img-fluid right_icon" alt="coPassword" />}
                </div>

                <div className="help-text">
                  {unCompleteAccount && (
                    <p className="text has-error">
                      This email address is associated with a company account that is not completed
                    </p>
                  )}
                  {NotRegistered ? (
                    <p className="text has-error">This email address doesn’t exist, it’s not registered yet</p>
                  ) : null}
                </div>
              </div>

              <div className="form-group">
                <div className="input withIcon large">
                  <label className="control-label" htmlFor="Password">
                    Password *
                  </label>
                  <img src={Password} className="img-fluid left_icon" alt="Password" />
                  {visualisePassword ? (
                    <img
                      onClick={() => {
                        setVisualisePassword(!visualisePassword);
                      }}
                      src={HidePass}
                      className="img-fluid showPass right_icon"
                      alt="ShowPass"
                    />
                  ) : (
                    <img
                      onClick={() => {
                        setVisualisePassword(!visualisePassword);
                      }}
                      src={ShowPass}
                      className="img-fluid showPass right_icon"
                      alt="ShowPass"
                    />
                  )}
                  <input
                    autoComplete="off"
                    type={visualisePassword ? "text" : "password"}
                    className="form-control"
                    id="Password"
                    name="Password"
                    onChange={validationLogin.handleChange}
                  />
                </div>
                <div className="help-text password_help">
                  {validationLogin.errors.Password && validationLogin.touched.Password ? (
                    <p className="text has-error">{validationLogin.errors.Password}</p>
                  ) : null}
                </div>
              </div>
              <Link to={"/ForgetPasword"} className="link_forget">
                Forgot password?
              </Link>
              <div className="form-group">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="Stay" />
                  <label className="form-check-label" htmlFor="Stay">
                    {" "}
                    Stay logged in{" "}
                  </label>
                </div>
              </div>

              <div className="form_actions">
                {isLoading ? (
                  <button className="btn primaryBtn largeBtn loading w-100">
                    Loading
                    <span className="loading-dots">.</span>
                    <span className="loading-dots">.</span>
                    <span className="loading-dots">.</span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={() => {
                      validationLogin.handleSubmit();
                    }}
                    className="btn primaryBtn largeBtn w-100"
                  >
                    Sign In
                  </button>
                )}
              </div>

              <div className="" style={{ textAlign: "center" }}>
                <p
                  className=""
                  style={{
                    fontSize: "12px",
                    color: "#101820",
                    fontFamily: "Raleway",
                  }}
                >
                  Don’t have an account?{" "}
                </p>
                <Link to={"/RegistrationForm"}>
                  <button
                    type="button"
                    className="btn primaryBtn largeBtn w-100"
                    style={{
                      background: "none",
                      color: "#00416B",
                      marginTop: "1rem",
                    }}
                  >
                    Create a new vendor account
                  </button>
                </Link>

                {/* <a href='/RegistrationForm' className='btn primaryBtn largeBtn w-100' style={{ background: 'none', color: '#00416B', marginTop: '1rem' }}>
                Create a new vendor account
              </a> */}
              </div>
            </div>

            <div className="contact_info">
              <p className="title">For any help contact our support at: </p>
              <ul className="list-unstyled contact_info__list nbg">
                <li>
                  <a href="tel:+966 11 219 0888" className="link">
                    <img src={Phone_16} className="img-fluid icon" alt="phone" />
                    <span className="text">+966 11 219 0888</span>
                  </a>
                </li>
                <li>
                  <a href="mailto:portal.support@fgc.sa" className="link">
                    <img src={email_16} className="img-fluid icon" alt="email" />
                    <span className="text">portal.support@fgc.sa</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
