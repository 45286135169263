import React from "react";

import HeaderDashboard from "./HeaderDashboard";
import DashboardLastChild from "./DashboardLastChild";
import DashboardSupport from "./DashboardSupport";
import RegistrationStatus from "../RegistrationStatus/RegistrationStatus";
import axios from "axios";
import dashboardImg from "../../Styles/images/dashboard/all.png";

import config from "../../IP_Configrations.json";
import { useEffect } from "react";
import { useState } from "react";
const host = config.static;

const DashboardBeforeApproval = ({ dataDashboard }) => {
  const [CompanyList, setCompanyList] = useState();
  const userToken = localStorage.getItem("user_token");

  useEffect(() => {
    const getCompaniesList = async () => {
      if (userToken) {
        axios
          .get(`${host}GetCompaniesForList`, {
            headers: {
              Authorization: userToken,
            },
          })
          .then((res) => {
            setCompanyList(res.data.items.filter((res) => res.statusApproval === true));
          })
          .catch((err) => console.log(err));
      }
    };

    getCompaniesList();
  }, []);
  return (
    <>
      <div className="custom_container">
        <HeaderDashboard dataDashboard={dataDashboard} />

        <div className="sec_container">
          <RegistrationStatus dataDashboard={dataDashboard} />

          <DashboardSupport />
        </div>

        <DashboardLastChild />
      </div>
    </>
  );
};

export default DashboardBeforeApproval;
