import React from "react";
import CheckmarkedCircle_empty from "../../../../Styles/images/settings/CheckmarkedCircle_empty.svg";
import documents from "../../../../Styles/images/settings/documents.svg";
import CheckmarkedCircle from "../../../../Styles/images/settings/CheckmarkedCircle.svg";
import ProfileCompletness from "../../../ProfileCompletness/ProfileCompletness";

function RightSection({ CompanyDocument, AdditionalAttachments }) {
  return (
    <div className="right_sec">
      <ProfileCompletness />
      <div className="company_document_progress">
        <img src={documents} className="img-fluid doc" alt="document" />
        <h3 className="main_title">Complete your documents</h3>
        <h5 className="title">Primary Documents</h5>
        <ul className="list-unstyled items_list">
          {CompanyDocument?.isSaudia && (
            <li>
              <a href="#" className="item done">
                <img src={CheckmarkedCircle} className="img-fluid icon" alt="done" />
                <span>VAT number*</span>
              </a>
            </li>
          )}

          <li>
            <a href="#" className="item done">
              <img src={CheckmarkedCircle} className="img-fluid icon" alt="done" />
              <span>{CompanyDocument?.isSaudia ? "Commercial registration number*" : "Registration number*"}</span>
            </a>
          </li>
          {CompanyDocument?.isSaudia && (
            <li>
              <a href="#" className="item done">
                <img src={CheckmarkedCircle} className="img-fluid icon" alt="done" />
                <span>National address*</span>
              </a>
            </li>
          )}
          {CompanyDocument?.isSaudia && (
            <li>
              <a href="#" className="item done">
                <img src={CheckmarkedCircle} className="img-fluid icon" alt="done" />
                <span>Zakat certificate*</span>
              </a>
            </li>
          )}
          <li>
            <a href="#" className={"item " + (CompanyDocument?.representativeFile && "done")}>
              <img
                src={CompanyDocument?.representativeFile ? CheckmarkedCircle : CheckmarkedCircle_empty}
                className="img-fluid icon"
                alt="done"
              />
              <span>Authorized company representation*</span>
            </a>
          </li>
        </ul>
        <h5 className="title">
          Additional documents <span className="optional">(optional)</span>
        </h5>
        <ul className="list-unstyled items_list">
          {CompanyDocument?.isSaudia && (
            <li>
              <a href="#" className={"item " + (AdditionalAttachments?.AOA && "done")}>
                <img
                  src={AdditionalAttachments?.AOA ? CheckmarkedCircle : CheckmarkedCircle_empty}
                  className="img-fluid icon"
                  alt="icon"
                />
                <span>Article of association</span>
              </a>
            </li>
          )}

          {CompanyDocument?.isSaudia && (
            <li>
              <a href="#" className={"item " + (AdditionalAttachments?.COCR && "done")}>
                <img
                  src={AdditionalAttachments?.COCR ? CheckmarkedCircle : CheckmarkedCircle_empty}
                  className="img-fluid icon"
                  alt="icon"
                />
                <span>chamber of commerce registration certificate</span>
              </a>
            </li>
          )}

          <li>
            <a href="#" className={"item " + (AdditionalAttachments?.QC && "done")}>
              <img
                src={AdditionalAttachments?.QC ? CheckmarkedCircle : CheckmarkedCircle_empty}
                className="img-fluid icon"
                alt="icon"
              />
              <span>Quality certificate</span>
            </a>
          </li>

          <li>
            <a href="#" className={"item " + (AdditionalAttachments?.LC && "done")}>
              <img
                src={AdditionalAttachments?.LC ? CheckmarkedCircle : CheckmarkedCircle_empty}
                className="img-fluid icon"
                alt="icon"
              />
              <span>License certificate</span>
            </a>
          </li>

          {CompanyDocument?.businessTypeId === 5711 && (
            <li>
              <a href="#" className={"item " + (AdditionalAttachments?.CCC && "done")}>
                <img
                  src={AdditionalAttachments?.CCC ? CheckmarkedCircle : CheckmarkedCircle_empty}
                  className="img-fluid icon"
                  alt="icon"
                />
                <span>Contractors classification certificate</span>
              </a>
            </li>
          )}

          {CompanyDocument?.businessTypeId === 5712 && (
            <li>
              <a href="#" className={"item " + (AdditionalAttachments?.COCC && "done")}>
                <img
                  src={AdditionalAttachments?.COCC ? CheckmarkedCircle : CheckmarkedCircle_empty}
                  className="img-fluid icon"
                  alt="icon"
                />
                <span>Consulting offices classification certificate</span>
              </a>
            </li>
          )}

          {CompanyDocument?.businessTypeId === 5709 && (
            <li>
              <a href="#" className={"item " + (AdditionalAttachments?.ILC && "done")}>
                <img
                  src={AdditionalAttachments?.ILC ? CheckmarkedCircle : CheckmarkedCircle_empty}
                  className="img-fluid icon"
                  alt="icon"
                />
                <span>Industrial Licensing certificate</span>
              </a>
            </li>
          )}

          <li>
            <a href="#" className={"item " + (AdditionalAttachments?.CPP && "done")}>
              <img
                src={AdditionalAttachments?.CPP ? CheckmarkedCircle : CheckmarkedCircle_empty}
                className="img-fluid icon"
                alt="icon"
              />
              <span>Company Presentation/Profile</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default RightSection;
