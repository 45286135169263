import React from "react";
import close from "../Styles/images/icons/ships_close.svg";
import uploaded from "../Styles/images/icons/chips_right_icon.svg";
function PreviewFile(props) {
  return (
    <div className="chips_upload">
      <div className="chips_upload__text">
        <div className="icon">
          <img src={uploaded} className="img-fluid" alt="right_icon" />
        </div>
        <div className="text">
          <p className="name text-truncate" style={{ width: "150px" }}>
            {props.fileName}
          </p>
          <span className="state">Uploaded</span>
        </div>
      </div>
      <span className="close" onClick={props.removeFile}>
        <img src={close} className="img-fluid" alt="close" />
      </span>
    </div>
  );
}

export default PreviewFile;
