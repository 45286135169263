import React from "react";

import TransferOwnerShipHead from "./TransferOwnerShipHeader";
import RightSteps from "../Components/RightSteps";

import StepOne from "../Registration/StepOne";
import StepTwo from "../Registration/StepTwo";
import RepresentativeDetails from "./RepresentativeDetails";
import AccountTranseferredCard from "./AccountTranseferredDoneCard";

import * as actions from "../Store/actions/registration";
import Loading from "../Components/Loading";
import {useDispatch, useSelector} from "react-redux";
import CloseSVG from "../Styles/images/dashboard/Close.svg";
import {useEffect} from "react";


const TransferOwnerShipForm = () => {

    const dispatch = useDispatch();
    const currentStep = useSelector((state) => state.registration.currentStep);
    const activeStep = window.localStorage.getItem('cs') || 1;
    const loadingFlag = useSelector((state) => state.registration.loadingFlag);
    const hasRepresentive = useSelector((state) => state.registration.hasRepresentive) || window.localStorage.getItem('hasRepresentive');
    const isTranferedOwnerDone = useSelector((state) => state.registration.isTranferedOwnerDone) || window.localStorage.getItem('isTranferedOwnerDone');

    useEffect(() => {
        dispatch(actions.SetTransferOwnerShip(true));
        //window.localStorage.setItem('cs',1);
        activeStep > 3 && dispatch(actions.setActiveStep(1))
    }, [])
    const closeModal = () => {
        dispatch(actions.SetTransferOwnerShip(false));
        dispatch(actions.SetTransferedModal(false));
    };

    return (
        <div className="transferOwnerShip_page approve">
            {!isTranferedOwnerDone ? <section className="register_page">
                    <div className="register_container">
                        <header className="header">
                            <div className="box">
                                <div className="account">
                                </div>
                                <div onClick={closeModal} className="close mt-5">
                                    <a href="#">
                                        <img src={CloseSVG} className="img-fluid" alt="close"/>
                                    </a>
                                </div>
                            </div>
                        </header>

                        <div className="register_content">
                            <TransferOwnerShipHead/>
                            {loadingFlag == true ? (
                                <Loading/>
                            ) : (
                                <>
                                    {activeStep == 1 ? <StepOne/> : null}
                                    {activeStep == 2 ? <StepTwo/> : null}
                                    {hasRepresentive && activeStep == 3 ? <RepresentativeDetails/> : null}
                                </>
                            )}
                        </div>

                    </div>

                    {!isTranferedOwnerDone && <RightSteps/>}
                </section>
                :
                <div className="">
                    <AccountTranseferredCard/>
                </div>}
            {/* <ClearDataModal ShowModal={this.state.ShowModal} hideModal={this.hideModal} /> */}
        </div>
    );
}

export default TransferOwnerShipForm;
