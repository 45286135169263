export default {
  documents: {
    documnetData: {},
  },
  registration: {
    isDashboard: true,
    verificationCode: "",
    user: {},
    company: { id: 0 },
    companyDetails: {
      vatCertificationFile: "",
      commercialRegisterNoFile: "",
      representativeFile: "",
      zakatcertificateFile: "",
      addressFile: "",

    },

    vatCertificationFile: "",
    commercialRegisterNoFile: "",
    representativeFile: "",
    zakatcertificateFile: "",
    addressFile: "",

    companyCategories: [],

    currentStep: 1,
    activeStep: localStorage.getItem("cs") || 1,

    stepMainTitle: "",
    stepSubTitle: "",
    timer: 120,
    companyId: 0,
    isLoading: false,
    isSaudia: false,
    hasSavedCode: false,
    prevSaved: false,
    Company_Summary: {},

    InvalidCode: false,
    isTransferOwnerShip: false,
    hasRepresentive: false,
    requestActivityId: null,
    transferedOwnerUser: localStorage.getItem('transferedOwnerUser') ? JSON.parse(localStorage.getItem('transferedOwnerUser')) : {} || {},
    representativeDetails: {},
    isTranferedOwnerDone: false,
    loadingFlag: false,
    transferedModal: false,
  },
  login: {
    // isLoading:false,
    userToken: ``,
  }
};
