import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import steperCheckmark from "../Styles/images/icons/steperCheckmark.svg";
import RightArrow_white from "../Styles/images/icons/RightArrow-white.svg";

const RightSteps = () => {
  const registerationItems = [
    " User Details",
    " Verification",
    " Account Details",
    "Registration Documents",
    " Bank Details",
    "Terms & Conditions",
  ];
  const transferedItems = [
    "New user details",
    " Verification",
    " Registration docs",
  ];
  const currentStep = useSelector((state) => state.registration.currentStep);
  const isTransferOwnerShip =
    useSelector((state) => state.registration.isTransferOwnerShip) ||
    window.localStorage.getItem("isTransferOwnerShip");
  const [items, setItems] = useState(registerationItems);

  useEffect(() => {
    isTransferOwnerShip
      ? setItems(transferedItems)
      : setItems(registerationItems);
  }, [isTransferOwnerShip]);
  return (
    <div className="steps">
      <div className="step_content">
        <h4 className="steps__name h3-bold">
          {isTransferOwnerShip ? "Transferring Steps" : "Registration Steps"}
        </h4>
        <ul className="list-unstyled stepper">
          {items.map((item, index) => {
            return (
              <li
                key={item}
                className={
                  "step " +
                  (currentStep == index + 1 ? "active" : "") +
                  (currentStep > index + 1 ? "done" : "")
                }
              >
                <span className="step__count">
                  <span className="num">{index + 1}</span>
                  <img
                    src={steperCheckmark}
                    className="img-fluid checked"
                    alt="done"
                  />
                </span>
                <p className="step__text">
                  <span className="step__name"> {item}</span>
                  <span className="step__status"> Done </span>
                  <span className="step__back">
                    <img
                      src={RightArrow_white}
                      className="img-fluid  "
                      alt="arrow"
                    />
                  </span>
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default RightSteps;
