import React from 'react';
import VendorImage from '../../Styles/images/company_profile/share_img.png';

const HeaderCompanyProfile = () => {
  return (
    <div className='share_sec'>
      <div className='text'>
        <h5 className='sh5-bold'>Your company details card</h5>
        <h2 className='title'>Share it anywhere</h2>
        <h5 className='sh5-bold'>FGC’s vendor engagement tools</h5>
        <h6 className='sh6-med'>
          FGC offers it’s vendors a simple tools to share their company details
          and documents with their customers in an elegant and smart way
        </h6>
      </div>
      <div className='img'>
        <img src={VendorImage} className='img-fluid' alt='img-fluiddd' />
      </div>
    </div>
  );
};

export default HeaderCompanyProfile;
