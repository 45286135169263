import React from "react";

import InfoSVG from "../../Styles/images/company_profile/info.svg";
import SaudiUsers from "../../Styles/images/company_profile/icons/saudi_users.svg";
import UsersSVG from "../../Styles/images/company_profile/icons/users.svg";
import DoneSVG from "../../Styles/images/company_profile/icons/done.svg";
import ProfitSVG from "../../Styles/images/company_profile/icons/profit.svg";
import CastleSVG from "../../Styles/images/company_profile/icons/castle.svg";
import ReloadSVG from "../../Styles/images/company_profile/icons/reload.svg";

import ComStateItem from "../ComStateItem/ComStateItem";
import CategoryItem from "./CategoryItem";

const numberWithCommas = (numbers) => {

    numbers = numbers == null ? "-" : numbers;
    return numbers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const CompanyInfo = ({dataInfo}) => {
    return (
        <div className="general_box com_details">
            <div className="main_info">
                <div className="img">
                    <img src={InfoSVG} className="img-fluid" alt="icon"/>
                </div>
                <div className="text">
                    <h3 className="title">Company info</h3>
                </div>
            </div>
            <p className="vendor_title">Vendor categories</p>
            <ul className="list-unstyled vendor_tags">
                {dataInfo?.vendorCategories.map((category, index) => (
                    <CategoryItem category={category} key={index}/>
                ))}
            </ul>
            <div className="sideByside">
                <ComStateItem
                    srcImage={CastleSVG}
                    title="Company type"
                    description={dataInfo.companyInfo?.companyTypeName ?? "-"}
                />

                <ComStateItem
                    srcImage={ReloadSVG}
                    title="Years in business"
                    description={dataInfo.companyInfo?.yearsOfBusniss ?? "-"}
                />

                <ComStateItem
                    srcImage={SaudiUsers}
                    title="# Employees inside KSA"
                    description={dataInfo.companyInfo?.numberEmployeeKsa ?? "-"}
                />

                <ComStateItem
                    srcImage={UsersSVG}
                    title="# Employees outside KSA"
                    description={dataInfo.companyInfo?.numberEmployeeOutsideKsa ?? "-"}
                />

                <ComStateItem
                    srcImage={DoneSVG}
                    title="Last year revenue (SAR)"
                    description={numberWithCommas(dataInfo.companyInfo?.lastYearRevenue)}
                    end={dataInfo.companyInfo?.lastYearRevenue ? "end" : ""}
                />

                <ComStateItem
                    srcImage={ProfitSVG}
                    title="Last year net profit (SAR)"
                    description={numberWithCommas(
                        dataInfo.companyInfo?.lastYearNetProfit)}
                    end={dataInfo.companyInfo?.lastYearNetProfit ? "end" : ""}
                />
            </div>
        </div>
    );
};

export default CompanyInfo;
