import React, { Fragment, useEffect, useState } from 'react';
import ArrowUpSVG from '../../Styles/images/company_profile/arrowUP.svg';
import dateFormat, { masks } from "dateformat";

import config from '../../IP_Configrations.json';
const host = config.download;


const DocumentItem = ({ doc }) => {

  const [newStringTitle, setNewStringTitle] = useState('')

  useEffect(() => {
    let myString = doc?.title

    let myArray = myString?.split(' ');
    const newString = myArray?.map((item) => {
      return item?.charAt(0)
    })

    setNewStringTitle(newString?.join('').toUpperCase())
  }, [doc?.title])

  return (
    <Fragment>
      {doc?.link && (
        <a className='document' href={host + doc?.link} rel='noreferrer' target='_blank'        >
          <span className='open'>
            open
            <img src={ArrowUpSVG} className='img-fluid' alt='open' />
          </span>
          <div className='img'>
            <svg xmlns='http://www.w3.org/2000/svg' width='72' height='72' viewBox='0 0 72 72' fill='none'            >
              <rect width='72' height='72' rx='36' fill='#101820' fillOpacity='0.04' />
              <path className='file' d='M43.1947 33.2766H31.0082C30.3915 33.2766 29.9305 33.7346 29.9305 34.3181C29.9305 34.9181 30.3915 35.3822 31.0082 35.3822H43.1947C43.7918 35.3822 44.2392 34.9181 44.2392 34.3181C44.2392 33.7346 43.7918 33.2766 43.1947 33.2766ZM43.1947 38.8565H31.0082C30.3915 38.8565 29.9305 39.3342 29.9305 39.9342C29.9305 40.5177 30.3915 40.9622 31.0082 40.9622H43.1947C43.7918 40.9622 44.2392 40.5177 44.2392 39.9342C44.2392 39.3342 43.7918 38.8565 43.1947 38.8565ZM28.4014 49H46.1182C49.6839 49 51.5196 47.1339 51.5196 43.5547V28.465C51.5196 26.1548 51.2223 25.0947 49.775 23.6201L41.0389 14.7477C39.6491 13.3276 38.4935 13 36.4182 13H28.4014C24.8524 13 23 14.8797 23 18.4589V43.5547C23 47.1475 24.8388 49 28.4014 49ZM28.6615 45.7415C27.0562 45.7415 26.2585 44.9015 26.2585 43.3522V18.6615C26.2585 17.1257 27.0562 16.2585 28.6751 16.2585H35.765V25.3797C35.765 27.7598 36.9265 28.9107 39.2959 28.9107H48.2611V43.3522C48.2611 44.9015 47.4635 45.7415 45.8475 45.7415H28.6615ZM39.6042 26.037C38.915 26.037 38.6386 25.744 38.6386 25.0578V16.7112L47.8084 26.037H39.6042Z' fill='#334252'
              />
              <path d='M43.1947 33.2766H31.0082C30.3915 33.2766 29.9305 33.7346 29.9305 34.3181C29.9305 34.9181 30.3915 35.3822 31.0082 35.3822H43.1947C43.7918 35.3822 44.2392 34.9181 44.2392 34.3181C44.2392 33.7346 43.7918 33.2766 43.1947 33.2766ZM43.1947 38.8565H31.0082C30.3915 38.8565 29.9305 39.3342 29.9305 39.9342C29.9305 40.5177 30.3915 40.9622 31.0082 40.9622H43.1947C43.7918 40.9622 44.2392 40.5177 44.2392 39.9342C44.2392 39.3342 43.7918 38.8565 43.1947 38.8565ZM28.4014 49H46.1182C49.6839 49 51.5196 47.1339 51.5196 43.5547V28.465C51.5196 26.1548 51.2223 25.0947 49.775 23.6201L41.0389 14.7477C39.6491 13.3276 38.4935 13 36.4182 13H28.4014C24.8524 13 23 14.8797 23 18.4589V43.5547C23 47.1475 24.8388 49 28.4014 49ZM28.6615 45.7415C27.0562 45.7415 26.2585 44.9015 26.2585 43.3522V18.6615C26.2585 17.1257 27.0562 16.2585 28.6751 16.2585H35.765V25.3797C35.765 27.7598 36.9265 28.9107 39.2959 28.9107H48.2611V43.3522C48.2611 44.9015 47.4635 45.7415 45.8475 45.7415H28.6615ZM39.6042 26.037C38.915 26.037 38.6386 25.744 38.6386 25.0578V16.7112L47.8084 26.037H39.6042Z'
                stroke='#334252' />
              <rect className='text_rect' x='14' y='43' width='44' height='18' rx='4' fill='#AA1E2E' />
            </svg>
            <span className='name'>{newStringTitle}</span>
          </div>
          <div className='text'>
            <h5 className='title'>{doc?.title}</h5>
            <h6 className='sh6-med'>#{doc?.documentNumber}</h6>
            <p className='expire'>{doc?.expireDate ? 'Exp. date:' : ''} {doc?.expireDate ? dateFormat(doc?.expireDate, 'mm/yyyy') : ''}</p>
          </div>
        </a>
      )}
    </Fragment>
  );
};

export default DocumentItem;
