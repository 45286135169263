import React, { useEffect, useState } from 'react'
import CloseSVG from "../../Styles/images/icons/popup_close.svg"


const ContactSupportModal = () => {

    const [closeModalSupport, setCloseModalSupport] = useState(false);

    useEffect(() => {
        const checkUserData = () => {
            const item = localStorage.getItem("contactModal");
            if (item) {
                setCloseModalSupport(item);
            } else {
                setCloseModalSupport(false);
            }
        }

        checkUserData();

        window.addEventListener("storage", checkUserData);
        return () => {
            window.removeEventListener("storage", checkUserData);
        };
    }, []);


    return (
        closeModalSupport ? <div className="popup active popup_contact_support">
            <div className="popup__content">
                <div className="popup__body">
                    <div className="head">
                        <h5 className="sh5-bold">Contact our support</h5>
                        <span className="close" onClick={() => {
                            window.localStorage.removeItem('contactModal')
                            setCloseModalSupport(false)
                        }}>
                            <img src={CloseSVG} className="img-fluid" alt="close" />
                        </span>
                    </div>
                    <div className="content">
                        <p className="desc">
                            For any assistance contact our procurement team, please send to:
                        </p>
                        <ul className="support_info  ">
                            <li>The Procurement & Logistics Director, Mr. Ahmad Osman on: <a href="mailto:ahmed.osman@fgc.sa" className="link">ahmed.osman@fgc.sa</a></li>
                            <li>The Procurement Manager, Mr. Rawad Geries – <a href="mailto:rawad.geries@fgc.sa" className="link">rawad.geries@fgc.sa</a></li>
                        </ul>
                        <p className="desc"> For any technical support regarding this portal please contact us on : <a href="mailto:support.portal@fgc.sa" className="link">support.portal@fgc.sa</a> </p>
                    </div>
                    <div className="footer_actions">
                        <button className="btn primaryBtn mediumBtn" onClick={() => {
                            window.localStorage.removeItem('contactModal')
                            setCloseModalSupport(false)
                        }}>OK, Thank you</button>
                    </div>
                </div>
            </div>
        </div> : null
    )
}

export default ContactSupportModal