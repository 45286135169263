import React from 'react';

const ItemDetailInfo = ({ title, infoDAta, addClassStyle }) => {
  return (
    <li className={`item sh5-med ${addClassStyle}`}>
      {title} <span>{infoDAta}</span>
    </li>
  );
};

export default ItemDetailInfo;
