import React from "react";

import CloseSVG from "../Styles/images/icons/popup_close.svg";
import DataServices from "../Services/DataServices";

function ClearDataModal({ ShowModal, hideModal }) {
  const handleClearData = () => {
    const userId = JSON.parse(window.localStorage.getItem("u"))?.id;

    let lastCompany =
      localStorage.getItem("thirdStep") === null
        ? {}
        : JSON.parse(window.localStorage.getItem("thirdStep")) || { id: 0 };

    const companyId = lastCompany.id;

    DataServices.addObject(`ClearDataForUserCompany`, { userId, companyId: companyId || 0 }).then((res) => {
      window.localStorage.clear();
      hideModal();
      window.location.reload();
    });
  };

  if (!ShowModal) return null;
  else
    return (
      <div className="popup active popup_contact_support">
        <div className="popup__content">
          <div className="popup__body">
            <div className="head">
              <h5 className="sh5-bold">Do you want to clear all data you’ve entered?</h5>
              <span className="close" onClick={hideModal}>
                <img src={CloseSVG} className="img-fluid" alt="close" />
              </span>
            </div>
            <div className="footer_actions">
              <button className="btn secondaryBtn mediumBtn mx-1" onClick={hideModal}>
                No, keep data
              </button>
              <button className="btn primaryBtn mediumBtn mx-1" onClick={handleClearData}>
                Yes, clear data
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ClearDataModal;
