import React, { useEffect, useState } from "react";
import JobTitle from "../../../../Styles/images/icons/JobTitle.svg";
import WarrningIcon from "../../../../Styles/images/icons/warrning-icon-22.svg";
import CloseSVG from "../../../../Styles/images/icons/popup_close.svg";
import ProfileCompletness from "../../../ProfileCompletness/ProfileCompletness";
import { useSelector, useDispatch } from "react-redux";
import { SetTabHeaders } from "../../../../Store/actions/registration";
import Select from "react-select";
import axios from "axios";
import Api from "../../../../Services/Api";
import Loading from "../../../Loading/index";
import DataServices from "../../../../Services/DataServices";
import config from "../../../../IP_Configrations.json";
import { toast } from "react-toastify";
import { Form } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import MUITextField from "../../../MUITextField";
import AddNewBank from "./AddNewBank";
const CharsDigsRege = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
const CharsDigsAcc =
  /^[a-zA-Z_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;
const CharsDigsRegex =
  /^[a-zA-Z0-9_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;
const NumDigsRegex = /^[0-9]/;
const phoneNumbersRegex = /^([0|\+[0-9]{1,5})?([0-9]{9})$/;

// const IBANregx = /^[a-zA-Z]{2}[0-9]{22}$/;
const IBANregx = /^[a-zA-Z]{2}[0-9a-zA-Z]{22}$/;
const host = config.static;
const FinancialContact = () => {
  const CompanyId =
    useSelector((state) => state.registration.companyId) ||
    localStorage.getItem("CompanyId");
  const userToken = localStorage.getItem("user_token");
  const [modalElements, setModalElements] = useState();
  const [showReqPending, setReqPendingFlag] = useState(false);
  const [requestId, setRequestId] = useState();
  const [reqMsg, setReqMsg] = useState("");
  const [reqStatus, setReqStatus] = useState("");
  const [showEditedBankDetails, setShowEditedBankDetailsFlag] = useState(false);
  const [isAnyChanges, setIsAnyChanges] = useState(true);
  const [FinancialContact, setFinancialContact] = useState({});

  const pendingMsg =
    "it has been sent to our support team, We’ll revise it and send you respond as soon as possible. In the mean time, the authorized company representative remains as it is.";

  useEffect(() => {
    const getBankDetailsData = async () => {
      await axios
        .get(`${host}GetCompanyFinancialContactPerson?companyId=${CompanyId}`, {
          headers: {
            Authorization: userToken,
          },
        })
        .then((res) => {
          // console.log(res);
          setFinancialContact(res.data[0]);
          if (!res.data[0].accountActivities) {
            //no requests
            setReqPendingFlag(false);
          } else {
            //pending
            setReqStatus(res.data[0].accountActivities.requestStatus);
            if (res.data[0].accountActivities.requestStatus === "Pending") {
              setReqPendingFlag(true);
              setRequestId(res.data[0].accountActivities.requestId);
              setModalElements(
                JSON.parse(res.data[0].accountActivities.newObject)
              );
              setReqMsg(pendingMsg);
            } else if (
              res.data[0].accountActivities.requestStatus === "Approved" ||
              res.data[0].accountActivities.requestStatus === "Rejected" ||
              res.data[0].accountActivities.requestStatus === "Cancelled"
            ) {
              setReqPendingFlag(false);
              setShowEditedBankDetailsFlag(true);
              setRequestId(res.data[0].accountActivities.requestId);
              setModalElements(
                JSON.parse(res.data[0].accountActivities.newObject)
              );
              setReqMsg(res.data[0].accountActivities.message);
            }
          }
        })
        .catch((err) => console.log(err));
    };
    getBankDetailsData();
  }, [CompanyId]);
  const cherryPick = (obj, keys, filter = 0) =>
    keys
      .filter((key) => (filter ? obj[key] : 1))
      .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});
  const handleEditBankDetails = async (e) => {
    // console.log("====================================");
    // console.log(getValues(), Object.keys(dirtyFields));
    // console.log("====================================");
    const elements = cherryPick(getValues(), Object.keys(dirtyFields));
    setModalElements(elements);
    setReqMsg(pendingMsg);
    setReqPendingFlag(true);
    axios
      .post(
        `${host}AddActivitiesForFinancialContactPerson?CompanyId=${parseInt(
          CompanyId
        )}&newObj=${JSON.stringify(elements)}`,
        {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setIsAnyChanges(true);
        setRequestId(res.data);
        reset(FinancialContact);
        //toast.success("Changes Saved");
      })
      .catch((err) => console.log(err));
  };
  const undoReqHandler = async () => {
    await axios
      .post(`${host}UndoAccountActivitiesByRequestId?requestId=${requestId}`, {
        headers: {
          Authorization: userToken,
        },
      })
      .then((res) => {
        setReqPendingFlag(false);
      })
      .catch((err) => console.log(err));
  };
  const editModalHandler = (flag) => {
    setShowEditedBankDetailsFlag(flag);
  };
  const userviewedReqsatausHandler = () => {
    if (reqStatus !== "Pending") {
      axios
        .post(
          `${host}UpdateViewForActivities?requestId=${parseInt(requestId)}`,
          {
            headers: {
              Authorization: userToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {})
        .catch((err) => console.log(err));
    }
  };
  const [form] = Form.useForm();

  const [count, setcount] = useState(0);

  let schema;
  schema = Yup.object().shape({
    financialContactPersonPosition: Yup.string()
      .matches(
        CharsDigsRegex,
        "Please enter valid job title and delete white space"
      )
      .required("Job title is required"),
    financialContactPersonName: Yup.string()
      .matches(CharsDigsRegex, "English characters")
      .max(30, "Max length of string is 30")
      .required(" name is required"),
    financialContactPersonPhone: Yup.string()
      .matches(
        phoneNumbersRegex,
        " phone must start with + and then enter the phone"
      )
      .test(
        "len",
        "Must not be less than 9 digits or more than 14 digits",
        (val) => val?.length >= 9 && val?.length <= 14
      )
      .required("Phone number is required"),

    financialContactPersonEmail: Yup.string()
      .email("Not valid email address")

      .required(" email is required"),
  });
  const {
    control,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {},
  });

  useEffect(() => {
    if (FinancialContact) {
      setValue(
        "financialContactPersonName",
        FinancialContact?.FinancialContactPersonName
      );
      setValue(
        "financialContactPersonPhone",
        FinancialContact?.FinancialContactPersonPhone
      );
      setValue(
        "financialContactPersonEmail",
        FinancialContact?.FinancialContactPersonEmail
      );
      setValue(
        "financialContactPersonPosition",
        FinancialContact?.FinancialContactPersonPosition
      );
    }
  }, [FinancialContact]);
  return (
    <div className="content with_main_head">
      <div className="main_head" style={{ marginBottom: "10px" }}>
        <div className="text">
          <h4 className="sh4-bold">Financial contact person</h4>
        </div>
        <span className="req">Required</span>
      </div>
      <div className="desc-div">
        Please tell us about the company’s financial representative that we can
        communicate with (Chief finance officer , Financial manager, Head of
        finance, Financial accountant, etc.)
      </div>
      <Form
        className="proForm"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(handleEditBankDetails)}
      >
        {showReqPending && (
          <div className="req-status-card">
            <div className="req-details">
              <span className="warning-icon">
                <img src={WarrningIcon} className=" icon" alt="warning" />
              </span>
              <p>
                Your request for changing 'Financial Contact Person' is pending
                approval <br />
                <span
                  className="seeDetails"
                  onClick={() => editModalHandler(true)}
                >
                  See details
                </span>
              </p>
            </div>
            <div className="action">
              <span className="" onClick={() => undoReqHandler()}>
                Undo
              </span>
            </div>
          </div>
        )}

        <div className="form-group">
          <div className={`input populated`}>
            <MUITextField
              name="financialContactPersonPosition"
              type="text"
              placeholder={"Position*"}
              id="financialContactPersonPosition"
              className={`form-control ${
                errors?.financialContactPersonPosition ? "has-error" : ""
              }`}
              errorMsg={errors?.financialContactPersonPosition?.message}
              validateStatus={
                errors?.financialContactPersonPosition ? "error" : ""
              }
              control={control}
              setcount={setcount}
            />
          </div>
        </div>
        <div className="form-group">
          <div className={`input populated`}>
            <MUITextField
              name="financialContactPersonName"
              type="text"
              placeholder={"Name*"}
              id="financialContactPersonName"
              className={`form-control ${
                errors?.financialContactPersonName ? "has-error" : ""
              }`}
              errorMsg={errors?.financialContactPersonName?.message}
              validateStatus={errors?.financialContactPersonName ? "error" : ""}
              control={control}
              setcount={setcount}
            />
          </div>
        </div>
        <div className="form-group">
          <div className={`input populated`}>
            <MUITextField
              name="financialContactPersonEmail"
              type="text"
              placeholder={"Email*"}
              id="financialContactPersonEmail"
              className={`form-control ${
                errors?.financialContactPersonEmail ? "has-error" : ""
              }`}
              errorMsg={errors?.financialContactPersonEmail?.message}
              validateStatus={
                errors?.financialContactPersonEmail ? "error" : ""
              }
              control={control}
              setcount={setcount}
            />
          </div>
        </div>
        <div className="form-group">
          <div className={`input populated`}>
            <MUITextField
              name="financialContactPersonPhone"
              type="text"
              placeholder={"Phone no.*"}
              id="financialContactPersonPhone"
              className={`form-control ${
                errors?.financialContactPersonPhone ? "has-error" : ""
              }`}
              errorMsg={errors?.financialContactPersonPhone?.message}
              validateStatus={
                errors?.financialContactPersonPhone ? "error" : ""
              }
              control={control}
              setcount={setcount}
            />
          </div>
        </div>
        <div className="form_actions">
          <button
            className={`btn primaryBtn largeBtn  ${
              !isAnyChanges || showReqPending ? "disabled" : ""
            }`}
          >
            Save changes
          </button>
        </div>
      </Form>
      {showEditedBankDetails && (
        <div className="popup active popup_contact_support change_company_account">
          <div className="popup__content">
            <div className="popup__body">
              <div className="head">
                <h5 className="sh5-bold">Change Financial Contact Person</h5>
                <span
                  className="close"
                  onClick={() => {
                    editModalHandler(false);
                    userviewedReqsatausHandler();
                  }}
                >
                  <img src={CloseSVG} className="img-fluid" alt="close" />
                </span>
              </div>
              <div className="content" style={{ padding: "32px 30px" }}>
                {modalElements &&
                  Object.entries(modalElements).map(([key, value], index) => {
                    return (
                      <div key={index} className="form-group mb-3">
                        <div className="input populated ant-form-item-control-input-content disable ">
                          <label className="control-label" htmlFor={key}>
                            {key === "currencyId" ? "currency" : key}*{" "}
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            defaultValue={value}
                            id={`edited ${key}`}
                            name="Country"
                            disabled
                          />
                        </div>
                      </div>
                    );
                  })}

                <p className="desc ">
                  Your request no. is <span className="bold"> {requestId}</span>
                  , {reqMsg}
                </p>
              </div>
              <div className="footer_actions">
                <button
                  className="btn primaryBtn mediumBtn"
                  onClick={() => {
                    editModalHandler(false);
                    userviewedReqsatausHandler();
                  }}
                >
                  Ok, done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default FinancialContact;
