import React, { Component, useEffect } from "react";

import arright_left_16 from "../Styles/images/icons/arright-left-16.svg";

import { connect } from "react-redux";
import * as actions from "../Store/actions/registration";
import { bindActionCreators } from "redux";
// const numbersRegex = /\d/;
// const validationSchema = Yup.object().shape({
//     verifyCode: Yup.string()
//         .matches(numbersRegex, "please Enter Valid Numbers for Phone")
//         .test(
//             'len',
//             'must be 4',
//             (val) => {
//                 if (val == undefined) {
//                     return true;
//                 }
//                 return ((val.length == 4))
//             })
//         .required("This phone number is not correct").nullable(),

// });
class StepTwo extends Component {
  email = "";
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: "",
      firstInput: "",
      secondInput: "",
      thirdInput: "",
      fourthInput: "",
      counter: 120,
    };
  }

  componentDidMount() {
    //console.log('transferedOwnerUser',this.props.transferedOwnerUser);
    if (this.props.isTransferOwnerShip) {
      this.email = this.props.transferedOwnerUser.email;
    } else {
      this.email = this.props.user.email;
    }
    let numbers = this.props.verificationCode.split("");
    if (numbers.length) {
      this.setState({
        firstInput: numbers[0],
        secondInput: numbers[1],
        thirdInput: numbers[2],
        fourthInput: numbers[3],
      });
    }
    this.props.actions.SetTabHeaders(
      "Verification",
      "Verify your email",
      this.props.currentStep
    );

    this.myInterval = setInterval(() => {
      const { counter } = this.state;
      if (counter > 0) {
        this.setState(({ counter }) => ({
          counter: counter - 1,
        }));
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  handleChange = (field, e, fieldIndex) => {
    let state = { ...this.state };
    e = e.replace(/[^0-9+]/g, "");
    state[field] = e;
    this.setState({
      ...state,
    });
    if (e && parseInt(fieldIndex) < 4) {
      // Get the next input field
      const nextSibling = document.querySelector(
        `input[name=ssn-${parseInt(fieldIndex) + 1}]`
      );
      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.verificationCode !== this.props.verificationCode) {
      let numbers = this.props.verificationCode.split("");
      if (numbers.length) {
        this.setState({
          firstInput: numbers[0],
          secondInput: numbers[1],
          thirdInput: numbers[2],
          fourthInput: numbers[3],
        });
      }
    }
  }

  ResendHandler = async() => {
    this.props.actions.SetLoading();
    if(this.props.isTransferOwnerShip){
      await this.props.actions.SendCodeAgainStepTwoForTransferedMail(
        this.props.transferedOwnerUser.ToAccountId || this.props.transferedOwnerUser.id
      )
      this.setState ({
        firstInput: "",
        secondInput: "",
        thirdInput: "",
        fourthInput: "",
        counter: 120,
      });
    } else {
      this.props.actions.SendCodeAgainStepTwo();
    }
  };

  async SubmitVerifyCode(e) {
    e.preventDefault();
    e.stopPropagation();

    let verifyCode =
      this.state.firstInput +
      this.state.secondInput +
      this.state.thirdInput +
      this.state.fourthInput;
    this.setState({
      verificationCode: verifyCode,
    });
    this.props.actions.SetLoading();
    if (this.props.isTransferOwnerShip) {
      await this.props.actions.VerifyCodeStepTwoTransferedmail(
        verifyCode,
        this.props.transferedOwnerUser.ToAccountId || this.props.transferedOwnerUser.id
      );
      // !this.props.InvalidCode &&
      //   !this.props.hasRepresentive &&
      //   this.props.actions.SetTranseferedDone(true);
    } else {
      
      this.props.actions.VerifyCodeStepTwo(verifyCode);
    }
  };


  handleBackSpace = (e, fieldIndex) => {
    if (e.key === "Backspace" && parseInt(fieldIndex) <= 4) {
      const nextSibling = document.querySelector(
        `input[name=ssn-${parseInt(fieldIndex) - 1}]`
      );
      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  render() {
    return (
      <>
        <form className="proForm">
          <div className="step2 text-center">
            <h3 className="sh3-bold">Verify your email</h3>
            <p className="verification secondary_text">
              We sent a verification code to:
            </p>
            <a href={"mailto:" + this.email} className="mail_link">
              {this.email}
            </a>
            <p className="verification secondary_text">
              to confirm account registration,
            </p>
            <h6 className="sh6-bold">please enter the code below</h6>

            {this.props.isLoading === true ? null : (
              <div className="form-group">
                <div className="input">
                  <input
                    pattern="[0-9\.]+"
                    maxLength="1"
                    autoComplete="off"
                    type="text"
                    placeholder="-"
                    className="form-control"
                    required
                    tabIndex={1}
                    name="ssn-1"
                    value={this.state.firstInput}
                    onChange={(e) =>
                      this.handleChange("firstInput", e.target.value, 1)
                    }
                    onKeyUp={(e) => this.handleBackSpace(e, 1)}
                  />

                  <input
                    pattern="[0-9\.]+"
                    maxLength="1"
                    autoComplete="off"
                    type="text"
                    placeholder="-"
                    className="form-control"
                    required
                    tabIndex={2}
                    name="ssn-2"
                    onChange={(e) =>
                      this.handleChange("secondInput", e.target.value, 2)
                    }
                    value={this.state.secondInput}
                    onKeyUp={(e) => this.handleBackSpace(e, 2)}
                  />

                  <input
                    pattern="[0-9\.]+"
                    maxLength="1"
                    autoComplete="off"
                    type="text"
                    placeholder="-"
                    name="ssn-3"
                    className="form-control"
                    required
                    tabIndex={3}
                    onChange={(e) =>
                      this.handleChange("thirdInput", e.target.value, 3)
                    }
                    value={this.state.thirdInput}
                    onKeyUp={(e) => this.handleBackSpace(e, 3)}
                  />

                  <input
                    pattern="[0-9\.]+"
                    maxLength="1"
                    autoComplete="off"
                    type="text"
                    placeholder="-"
                    className="form-control"
                    required
                    tabIndex={4}
                    name="ssn-4"
                    onChange={(e) =>
                      this.handleChange("fourthInput", e.target.value, 4)
                    }
                    value={this.state.fourthInput}
                    onKeyUp={(e) => this.handleBackSpace(e, 4)}
                  />
                </div>
                {this.props.InvalidCode && (
                  <p
                    className="time"
                    style={{ justifyContent: "center", marginTop: "20px" }}
                  >
                    <div
                      className="help-text"
                      style={{ justifyContent: "center" }}
                    >
                      <p className="text has-error">Invalid code</p>
                    </div>
                  </p>
                )}
              </div>
            )}
            <p className="time">
              {this.state.counter == 0 ? (
                <a
                  className={"resend "}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.ResendHandler(e)}
                >
                  Resend code
                </a>
              ) : (
                <a className={"resend  disabled"}>Resend code</a>
              )}
              {this.state.counter == 0 ||
              window.localStorage.getItem("cs") >= 3 ? null : (
                <span className="remain sh6-med">{this.state.counter}</span>
              )}
            </p>
          </div>
          <div className="form_actions">
            {this.props.isLoading == true ? (
              <button className="btn primaryBtn largeBtn disabled loading w-100">
                Loading...
              </button>
            ) : (
              <button
                type="submit"
                className={
                  "btn primaryBtn largeBtn " +
                  (this.state.firstInput &&
                  this.state.secondInput &&
                  this.state.thirdInput &&
                  this.state.fourthInput
                    ? ""
                    : "disabled") +
                  " w-100"
                }
                onClick={(e) => this.SubmitVerifyCode(e)}
              >
                Save and next step
              </button>
            )}

            { !this.props.isTransferOwnerShip && <button
              className="btn textBtn largeBtn icon-l w-100"
              onClick={(e) => this.props.actions.backAction()}
            >
              <img src={arright_left_16} className="icon" alt="arrow" /> Back
            </button>}
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.registration.user,
    currentStep: state.registration.currentStep,
    activeStep: state.registration.activeStep,
    isLoading: state.registration.isLoading,
    timer: state.registration.timer,
    verificationCode: state.registration.verificationCode,
    InvalidCode: state.registration.InvalidCode,
    isTransferOwnerShip: state.registration.isTransferOwnerShip,
    transferedOwnerUser: state.registration.transferedOwnerUser || window.localStorage.getItem('transferedOwnerUser'),
    hasRepresentive: state.registration.hasRepresentive,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
