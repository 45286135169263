/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import DashboardApproval from "../Components/DashboardComponents/DashboardApproval";
import DashboardBeforeApproval from "../Components/DashboardComponents/DashboardBeforeApproval";
import Loading from "../Components/Loading/index";
import DashboardAfterCloseModal from "../Components/DashboardComponents/DashboardAfterCloseModal";
import { SetTabHeaders } from "../Store/actions/registration";

import config from "../IP_Configrations.json";
import { useParams } from "react-router-dom";
import ModalContext from "../Store/ModalProvider";
import { useContext } from "react";
const host = config.static;
// const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const DashboardFromMenu = () => {
  const [openModal, setOpenModal] = useState(false);
  const [dataDashboard, setDataDashboard] = useState();

  const CompanyId = localStorage.getItem("CompanyId");
  const approvalModal = localStorage.getItem("modalApproval");
  const userToken = localStorage.getItem("user_token");

  const location = useParams();

  const disaptch = useDispatch();

  const { setModalComponent, setModalOpen, modalOpen, setchooseCompanyId } =
    useContext(ModalContext);

  //   useEffect(() => {
  //     if (window.localStorage.getItem("toChooseCompany")) {
  //       window.localStorage.setItem("toChooseCompany", false);
  //       setModalOpen(true);
  //     } else {
  //       setModalOpen(false);
  //     }
  //   }, []);

  useEffect(() => {
    disaptch(SetTabHeaders("Dashboard"));
    console.log('location?.id' , location?.id)

    if (location?.id && location?.id !== 0) {
      setchooseCompanyId(location?.id);
      const getDashboardData = async () => {
        await axios
          .get(`${host}GetCompanyDashboard?companyId=${location?.id}`, {
            headers: {
              Authorization: userToken,
            },
          })
          .then((res) => {
            setDataDashboard(res.data);
            //   console.log(res);
            if (res?.data?.vendorId === null && res?.data?.statusApproval) {
              getDashboardData();
            }
          })
          .catch((err) => console.log(err));
      };
      getDashboardData();
    } else {
      const getDashboardData = async () => {
        await axios
          .get(`${host}GetCompanyDashboard?companyId=${CompanyId}`, {
            headers: {
              Authorization: userToken,
            },
          })
          .then((res) => {
            setDataDashboard(res.data);
            //   console.log(res);
            if (res?.data?.vendorId === null && res?.data?.statusApproval) {
              getDashboardData();
            }
          })
          .catch((err) => console.log(err));
      };
      getDashboardData();
    }
  }, [location?.id]);

  // useEffect(() => {

  // }, [location?.id]);

  return (
    <>
      {!dataDashboard ? (
        <Loading />
      ) : (
        <Fragment>
          {dataDashboard?.statusApproval ? (
            <Fragment>
              {approvalModal ? (
                <DashboardAfterCloseModal dataDashboard={dataDashboard} />
              ) : (
                <DashboardApproval
                  dataDashboard={dataDashboard}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              )}
            </Fragment>
          ) : (
            <DashboardBeforeApproval dataDashboard={dataDashboard} />
          )}
        </Fragment>
      )}
    </>
  );
};

export default DashboardFromMenu;
