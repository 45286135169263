 
let IP_CONFIG = null; 

export default class Config {

    static getPublicConfiguartion() {
        
        return IP_CONFIG;
    }

    static SetConfigObject(info) {
        IP_CONFIG = info;
    } 
    
    static getPayload() {
        var payload ={};// window.localStorage.getItem("claims") ? CryptoJS.enc.Base64.parse(window.localStorage.getItem("claims")).toString(CryptoJS.enc.Utf8) : "";
        return payload ? JSON.parse(payload) : {};
    }

    static IsAuthorized() {
        let authorize = false;
        if (localStorage.getItem("userToken")) {
            authorize = true;
        }
        return authorize;
    }
    static IsAuthorizedLeftMenu() {
        let authorize = false;
        console.log('token', localStorage.getItem("user_token"))
        if (localStorage.getItem("user_token")) {
            authorize = true;
        }
        return authorize;
    }
 
}
