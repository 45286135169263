import React, { Component } from "react";

import { connect } from "react-redux";
import * as actions from "../Store/actions/registration";
import { bindActionCreators } from "redux";
import WarrningIcon from "../Styles/images/icons/warning-dark.svg";


class TransferOwnerShipHead extends Component {
  constructor(props) {
    super(props);
  }
  handlerNextStep() {
    if (this.props.currentStep < parseInt(window.localStorage.getItem("cs")))
      this.props.actions.NextAction();
  }
  handlerBackStep() {
    if (this.props.activeStep > 1) this.props.actions.backAction();
  }
  render() {
    return (
      <div className="register_step_head">
        <div className="text-center">
          <h2 className="fa-2x fw-normal fw-semibold mb-3">Transfer account ownership</h2>
          { this.props.currentStep == 1 && <div className="waring_box gray sm sh5-med">
            <img src={WarrningIcon} className="img-fluid icon" alt="warning" />
            <span className="text">Every company account has one user (account owner), which is the same contact person </span>
          </div>}
          {this.props.currentStep == 1 ? <p className="transferText mb-3">
            You can transfer account ownership by replacing the current owner with another one by entering the new owner’s data. but, you’ll need approval from FGC's admin. <br />
            If you need assistance, please contact our support team.
          </p>
          :
          <p className="transferText mb-3">
            Changing user info and account contact person, this process needs approval from FGC’s admins
          </p>
          }
        </div>
        

        <div className="step_title">
          <div className="step_text">
            <h4 className="h5-med">
              Step {this.props.activeStep}/3: {this.props.stepMainTitle}
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentStep: state.registration.currentStep,
    activeStep: state.registration.activeStep,
    isLoading: state.registration.isLoading,
    stepSubTitle: state.registration.stepSubTitle,
    stepMainTitle: state.registration.stepMainTitle,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferOwnerShipHead);
