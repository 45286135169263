import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import WarrningIcon from "../../../../Styles/images/icons/warrning-icon-22.svg";
import CloseSVG from "../../../../Styles/images/icons/popup_close.svg";

import config from "../../../../IP_Configrations.json";
import UploadFile from "../../../UploadFile";

import checkmarkedCircle_20 from "../../../../Styles/images/icons/checkmarkedCircle-20.svg";
import attached_file from "../../../../Styles/images/settings/attached_file.svg";
import DataServices from "../../../../Services/DataServices";
import example from "../../../../Styles/images/example.jpg";
import axios from "axios";
// import config from "../../../../IP_Configrations.json";

const numRegex = /^[0-9]+$/;

const animateInputs = () => {
  const inputs = document.querySelectorAll(".input");
  inputs.forEach((input) => {
    input.addEventListener(
      "focusin",
      function (e) {
        input.classList.add("focused");
      },
      false
    );
    input.addEventListener("focusout", function (e) {
      if (e.target.value == "") {
        input.classList.remove("focused");
      }
    });
  });
};
const host = config.static;

function NASection({ companyId }) {
  const [NationalAddress, setNationalAddress] = useState({});
  const [Saving, setSaving] = useState(false);
  const [Load, setLoad] = useState(false);
  const [Changed, setChanged] = useState(false);

  const [Cities, setCities] = useState([]);
  const [Districts, setDistricts] = useState([]);
  const CompanyId = companyId;
  const userToken = localStorage.getItem("user_token");
  const [modalElements, setModalElements] = useState();
  const [showReqPending, setReqPendingFlag] = useState(false);
  const [requestId, setRequestId] = useState();
  const [reqMsg, setReqMsg] = useState("");
  const [reqStatus, setReqStatus] = useState("");
  const [showEditedBankDetails, setShowEditedBankDetailsFlag] = useState(false);
  const [isAnyChanges, setIsAnyChanges] = useState(true);
  const pendingMsg =
    "it has been sent to our support team, We’ll revise it and send you respond as soon as possible. In the mean time, the authorized company representative remains as it is.";

  useEffect(() => {
    animateInputs();
    DataServices.GetRowById(
      `GetCompanyNationalAddressForEdit?companyId=${companyId}`
    ).then((res) => {
      setNationalAddress(res);
      if (!res.accountActivities) {
        //no requests
        setReqPendingFlag(false);
      } else {
        //pending
        setReqStatus(res.accountActivities.requestStatus);
        if (res.accountActivities.requestStatus === "Pending") {
          setReqPendingFlag(true);
          setRequestId(res.accountActivities.requestId);
          setModalElements(JSON.parse(res.accountActivities.newObject));
          setReqMsg(pendingMsg);
        } else if (
          res.accountActivities.requestStatus === "Approved" ||
          res.accountActivities.requestStatus === "Rejected" ||
          res.accountActivities.requestStatus === "Cancelled"
        ) {
          setReqPendingFlag(false);
          setShowEditedBankDetailsFlag(true);
          setRequestId(res.accountActivities.requestId);
          setModalElements(JSON.parse(res.accountActivities.newObject));
          setReqMsg(res.accountActivities.message);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (NationalAddress?.countryId) {
      DataServices.GetDataListDetailed(
        `GetCitiesList?countryId=${NationalAddress?.countryId}`,
        "title",
        "id"
      ).then((res) => setCities(res));
    }
  }, [NationalAddress?.countryId]);

  useEffect(() => {
    if (NationalAddress?.cityId) {
      DataServices.GetDataListDetailed(
        `GetDistrictByCityId?cityId=${NationalAddress?.cityId}`,
        "title",
        "id"
      ).then((res) => setDistricts(res));
    }
  }, [NationalAddress?.cityId]);

  const ChangeNationalAddress = (e) => {
    setChanged(true);
    handleChange(e);
  };

  const ChangeDropDownNA = (label, value) => {
    setChanged(true);
    setFieldValue(label, value);
  };

  const cherryPick = (obj, keys, filter = 0) =>
    keys
      .filter((key) => (filter ? obj[key] : 1))
      .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});
  const handleEditBankDetails = async () => {
    setSaving(true);
    let dirtyObjArray = [];
    if (NationalAddress.cityId !== values.cityId) {
      dirtyObjArray.push("cityId");
    }
    if (NationalAddress.countryId !== values.countryId) {
      dirtyObjArray.push("countryId");
    }
    if (NationalAddress.districtId !== values.districtId) {
      dirtyObjArray.push("districtId");
    }
    if (NationalAddress.shortAddress !== values.shortAddress) {
      dirtyObjArray.push("shortAddress");
    }
    if (NationalAddress.buildingNo !== values.buildingNo) {
      dirtyObjArray.push("buildingNo");
    }
    if (NationalAddress.street !== values.street) {
      dirtyObjArray.push("street");
    }
    if (NationalAddress.secondryNo !== values.secondryNo) {
      dirtyObjArray.push("secondryNo");
    }
    if (NationalAddress.zipCode !== values.zipCode) {
      dirtyObjArray.push("zipCode");
    }
    if (NationalAddress.addressLine1 !== values.addressLine1) {
      dirtyObjArray.push("addressLine1");
    }
    if (NationalAddress.addressLine2 !== values.addressLine2) {
      dirtyObjArray.push("addressLine2");
    }

    // console.log("====================================");
    // console.log(values, dirtyObjArray);
    // console.log("====================================");

    const elements = cherryPick(values, dirtyObjArray);
    setModalElements(elements);
    setReqMsg(pendingMsg);

    axios
      .post(
        `${host}AddActivitiesForEditNationalAddress?CompanyId=${parseInt(
          CompanyId
        )}&newObj=${JSON.stringify(elements)}`,
        {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setSaving(false);
        setChanged(false);
        setIsAnyChanges(true);
        setRequestId(res.data);
        setReqPendingFlag(true);
        // reset(FinancialContact);
        validateNationalAddress.resetForm();
        toast.success("Changes Saved");
      })
      .catch((err) => console.log(err));
  };

  const undoReqHandler = async () => {
    await axios
      .post(`${host}UndoAccountActivitiesByRequestId?requestId=${requestId}`, {
        headers: {
          Authorization: userToken,
        },
      })
      .then((res) => {
        setReqPendingFlag(false);
      })
      .catch((err) => console.log(err));
  };
  const editModalHandler = (flag) => {
    setShowEditedBankDetailsFlag(flag);
  };
  const userviewedReqsatausHandler = () => {
    if (reqStatus !== "Pending") {
      axios
        .post(
          `${host}UpdateViewForActivities?requestId=${parseInt(requestId)}`,
          {
            headers: {
              Authorization: userToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {})
        .catch((err) => console.log(err));
    }
  };
  const SaveNationalAddress = () => {
    setSaving(true);
    DataServices.addObject("UpdateCompanyNationalAddress", values).then(
      (res) => {
        toast.success("National Address Saved", { hideProgressBar: true });
        setSaving(false);
        setChanged(false);
        setNationalAddress(res);
        setFieldValue("edit", false);
      }
    );
  };
  // console.log("====================================");
  // console.log(NationalAddress);
  // console.log("====================================");
  const validateNationalAddress = useFormik({
    enableReinitialize: true,
    initialValues: { ...NationalAddress, edit: false },
    validationSchema: Yup.object().shape({
      shortAddress: Yup.string()
        .matches(
          /^[a-zA-Z]{4}[0-9]{4}$/g,
          "Invalid Short address. Please ensure that the number consists of 4 characters then 4 numbers with no spaces or any special characters."
        )
        .max(
          8,
          "Invalid Short address. Please ensure that the number consists of 4 characters then 4 numbers with no spaces or any special characters."
        )
        .min(
          8,
          "Invalid Short address. Please ensure that the number consists of 4 characters then 4 numbers with no spaces or any special characters."
        ),
      street: Yup.string().required("Street is required"),
      secondryNo: Yup.string()
        .required("Secondary no. is required")
        .matches(
          numRegex,
          "Invalid secondary no. Please ensure that the number consists of 4 digits with no spaces or any other characters."
        )
        .test(
          "len",
          "Invalid secondary no. Please ensure that the number consists of 4 digits with no spaces or any other characters.",
          (val) => val?.length === 4
        ),
      buildingNo: Yup.string()
        .required("Building no. is required")
        .matches(
          numRegex,
          "Invalid building no. Please ensure that the number consists of 4 digits with no spaces or any other characters."
        )
        .test(
          "len",
          "Invalid building no. Please ensure that the number consists of 4 digits with no spaces or any other characters.",
          (val) => val?.length === 4
        ),
      cityId: Yup.string().required("City is required"),
      districtId: Yup.string().required("District is required"),
      zipCode: Yup.string()
        .required("Zip / Postal code is required")
        .matches(
          numRegex,
          "Invalid zip code Please ensure that the number consists of 5 digits with no spaces or any other characters."
        )
        .test(
          "len",
          "Invalid zip code Please ensure that the number consists of 5 digits with no spaces or any other characters.",
          (val) => val?.length === 5
        ),
      addressFile: Yup.mixed().required(
        "National Address file copy is required"
      ),
    }),

    onSubmit: () => {
      handleEditBankDetails();

      // validateNationalAddress.dirty
    },
  });

  const {
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    setFieldTouched,
  } = validateNationalAddress;

  return (
    <div className="content" id="Companynationaladdress*">
      <div className="sub_head">
        <div className="top">
          <h4 className="title">
            <span>Company national address*</span>
            {!values.edit && (
              <img
                src={checkmarkedCircle_20}
                className="img-fluid icon"
                alt="checked"
              />
            )}
          </h4>
          {!values.edit && (
            <span
              className="edit"
              role="button"
              onClick={() => {
                setFieldValue("edit", true);
              }}
            >
              Edit
            </span>
          )}
        </div>
        <p className="desc">
          Making any change in your national address will require FGC’s admin
          approval
        </p>
      </div>
      {showReqPending && (
        <div className="financial_details">
          <div className="req-status-card">
            <div className="req-details">
              <span className="warning-icon">
                <img src={WarrningIcon} className=" icon" alt="warning" />
              </span>
              <p>
                Your request for changing 'National Address' is pending approval{" "}
                <br />
                <span
                  className="seeDetails"
                  onClick={() => editModalHandler(true)}
                >
                  See details
                </span>
              </p>
            </div>
            <div className="action">
              <span className="" onClick={() => undoReqHandler()}>
                Undo
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="form-group">
        <div className={`input ${values?.shortAddress ? `focused` : ""}`}>
          <label className="control-label" htmlFor="shortAddress">
            Short Address (optional)
          </label>
          <input
            autoComplete="off"
            type="text"
            className={
              "form-control " +
              (errors.shortAddress && touched.shortAddress && "has-error")
            }
            disabled={values.edit ? false : true}
            value={values?.shortAddress || ""}
            onChange={ChangeNationalAddress}
            id="shortAddress"
            name="shortAddress"
            onBlur={handleBlur}
          />
        </div>
        <div className="help-text">
          {!values.edit ? (
            false
          ) : errors.shortAddress && touched.shortAddress ? (
            <p className="text has-error">{errors.shortAddress}</p>
          ) : (
            <div className="row-field">
              <p className="text">Must be 4 characters then 4 numbers</p>

              <span className="mytooltip tooltip-effect-1">
                <span className="tooltip-item">example</span>
                <span className="tooltip-content clearfix">
                  <img src={example} />
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="sideByside">
        <div className="form-group">
          <div className={`input ${values?.buildingNo ? `focused` : ""}`}>
            <label className="control-label" htmlFor="buildingNo">
              Building no.*
            </label>
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control " +
                (errors.buildingNo && touched.buildingNo && "has-error")
              }
              disabled={values.edit ? false : true}
              value={values?.buildingNo || ""}
              onChange={ChangeNationalAddress}
              id="buildingNo"
              name="buildingNo"
              onBlur={handleBlur}
            />
          </div>
          <div className="help-text">
            {!values.edit ? (
              false
            ) : errors.buildingNo && touched.buildingNo ? (
              <p className="text has-error">{errors.buildingNo}</p>
            ) : (
              <p className="text">Must be 4 digits</p>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className={`input ${values?.street ? `focused` : ""}`}>
            <label className="control-label" htmlFor="street">
              Street*
            </label>
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control " +
                (errors.street && touched.street && "has-error")
              }
              disabled={values.edit ? false : true}
              value={values?.street || ""}
              onChange={ChangeNationalAddress}
              id="street"
              name="street"
              onBlur={handleBlur}
            />
          </div>
          <div className="help-text">
            {!values.edit
              ? false
              : errors.street &&
                touched.street && (
                  <p className="text has-error">{errors.street}</p>
                )}
          </div>
        </div>

        <div className="form-group">
          <div className={`input ${values.secondryNo ? `focused` : ""}`}>
            <label className="control-label" htmlFor="secondryNo">
              Secondary no.*
            </label>
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control " +
                (errors.secondryNo && touched.secondryNo && "has-error")
              }
              disabled={values.edit ? false : true}
              value={values.secondryNo || ""}
              onChange={ChangeNationalAddress}
              id="secondryNo"
              name="secondryNo"
              onBlur={handleBlur}
            />
          </div>
          <div className="help-text">
            {!values.edit ? (
              false
            ) : errors.secondryNo && touched.secondryNo ? (
              <p className="text has-error">{errors.secondryNo}</p>
            ) : (
              <p className="text">Must be 4 digits</p>
            )}
          </div>
        </div>
        <div className="form-group select_md">
          <Select
            className={
              "select_react " + (errors.cityId && touched.cityId && "has-error")
            }
            name="city"
            placeholder="Select City*"
            value={
              Cities
                ? Cities.find((option) => option.value === values.cityId)
                : ""
            }
            options={Cities}
            onChange={(e) => {
              ChangeDropDownNA("cityId", e.value);
              DataServices.GetDataListDetailed(
                `GetDistrictByCityId?cityId=${e.value}`,
                "title",
                "id"
              ).then((res) => setDistricts(res));
            }}
            classNamePrefix="select"
            onBlur={handleBlur}
            isDisabled={values.edit ? false : true}
          />
          <div className="help-text">
            {!values.edit
              ? false
              : errors.cityId &&
                touched.cityId && (
                  <p className="text has-error">{errors.cityId}</p>
                )}
          </div>
        </div>

        <div className="form-group">
          <div className={`input ${values.zipCode ? `focused` : ""}`}>
            <label className="control-label" htmlFor="zipCode">
              Zip/Postal code*
            </label>
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control " +
                (errors.zipCode && touched.zipCode && "has-error")
              }
              disabled={values.edit ? false : true}
              value={values.zipCode || ""}
              onChange={ChangeNationalAddress}
              id="zipCode"
              name="zipCode"
              onBlur={handleBlur}
            />
          </div>
          <div className="help-text">
            {!values.edit ? (
              false
            ) : errors.zipCode && touched.zipCode ? (
              <p className="text has-error">{errors.zipCode}</p>
            ) : (
              <p className="text">Must be 5 digits</p>
            )}
          </div>
        </div>
        <div className="form-group select_md">
          <Select
            className={
              "select_react " +
              (errors.districtId && touched.districtId && "has-error")
            }
            name="district"
            placeholder="Select District*"
            value={
              Districts
                ? Districts.find((option) => option.value === values.districtId)
                : ""
            }
            options={Districts}
            onChange={(e) => ChangeDropDownNA("districtId", e.value)}
            classNamePrefix="select"
            onBlur={handleBlur}
            isDisabled={values.edit ? false : true}
          />
          <div className="help-text">
            {!values.edit
              ? false
              : errors.districtId &&
                touched.districtId && (
                  <p className="text has-error">{errors.districtId}</p>
                )}
          </div>
        </div>
      </div>

      {values.edit && (
        <div className="upload_box">
          <h6 className="sh6-med">Valid copy of national address</h6>
          <UploadFile
            className=" sm "
            load={Load}
            setLoad={setLoad}
            fieldName="addressFile"
            title="National address"
            removeFile={() => setFieldValue("addressFile", null)}
            errors={errors.addressFile}
            touched={touched.addressFile}
            fileName={values?.addressFile?.split("/")[4] || values.addressFile}
            folderName="addressFile"
            docType="addressFile"
            companyId={companyId}
            Api="UploadFiles"
            handleBlur={() => setFieldTouched("addressFile")}
            handleChange={() => {
              setFieldValue("addressFile", "Done");
              setChanged(true);
            }}
          />
        </div>
      )}
      {!values.edit && (
        <div className="uploaded_file">
          <Link
            target="_blank"
            to={
              NationalAddress?.addressFile && config.download
                ? config.download + NationalAddress?.addressFile
                : ""
            }
            className="file"
          >
            <img src={attached_file} className="img-fluid icon" alt="icon" />
            <span>{NationalAddress?.addressFile?.split("/")[4]}</span>
          </Link>
          <span className="hint">You can view the file by clicking on it</span>
        </div>
      )}
      {values.edit && (
        <div className="form_actions d-flex">
          <button
            className={
              "btn primaryBtn largeBtn " +
              (!Changed || Saving || showReqPending ? "disabled" : "")
            }
            type="button"
            onClick={handleSubmit}
          >
            {Saving ? "Saving..." : "Save changes"}
          </button>
          <button
            className={"btn secondaryBtn largeBtn mx-3"}
            type="button"
            onClick={() => {
              setFieldValue("edit", false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
      {showEditedBankDetails && (
        <div className="popup active popup_contact_support change_company_account">
          <div className="popup__content">
            <div className="popup__body">
              <div className="head">
                <h5 className="sh5-bold">Change National Address</h5>
                <span
                  className="close"
                  onClick={() => {
                    editModalHandler(false);
                    userviewedReqsatausHandler();
                  }}
                >
                  <img src={CloseSVG} className="img-fluid" alt="close" />
                </span>
              </div>
              <div className="content" style={{ padding: "32px 30px" }}>
                {modalElements &&
                  Object.entries(modalElements).map(([key, value], index) => {
                    return (
                      <div key={index} className="form-group mb-3">
                        <div className="input populated ant-form-item-control-input-content disable ">
                          <label className="control-label" htmlFor={key}>
                            {key === "currencyId" ? "currency" : key}*{" "}
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            defaultValue={value}
                            id={`edited ${key}`}
                            name="Country"
                            disabled
                          />
                        </div>
                      </div>
                    );
                  })}

                <p className="desc ">
                  Your request no. is <span className="bold"> {requestId}</span>
                  , {reqMsg}
                </p>
              </div>
              <div className="footer_actions">
                <button
                  className="btn primaryBtn mediumBtn"
                  onClick={() => {
                    editModalHandler(false);
                    userviewedReqsatausHandler();
                  }}
                >
                  Ok, done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NASection;
