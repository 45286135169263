import React, {useEffect, useState} from "react";
import {Formik, Form, useFormik} from "formik";
import * as Yup from "yup";
import logo from "../Styles/images/FGC_Secondary_logo_EN.svg";
import email from "../Styles/images/icons/Email.svg";
import PasswordImg from "../Styles/images/icons/Password.svg";
import ShowPass from "../Styles/images/icons/ShowPass.svg";
import HidePass from "../Styles/images/icons/HidePass.svg";
import Phone_16 from "../Styles/images/icons/Phone_16.svg";
import email_16 from "../Styles/images/icons/email_16.svg";
import success_pass_icon from "../Styles/images/icons/success_pass_icon.svg";
import checkmarkedCircle_20 from "../Styles/images/icons/checkmarkedCircle-20.svg";
import error_24_updated from "../Styles/images/icons/error_24_updated.svg";
import error_24 from "../Styles/images/icons/error-24.svg";
import Warning from "../Styles/images/icons/Warning.svg";

import {useDispatch} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import {ResetPassword} from "../Store/actions/login";

const ChangePassword = () => {
    const [step, setStep] = useState(1);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const params = useParams();
    // console.log(params)
    const [Password, setPassword] = useState(``);
    const [PasswordConfirm, setPasswordConfirm] = useState(``);
    const [errorMessage, setErrorMessage] = useState(``);
    const [isLoading, setIsLoading] = useState(false);
    const [visualisePassword, setVisualisePassword] = useState(false);
    const [visualiseConfirmPassword, setVisualiseConfirmPassword] =
        useState(false);

    useEffect(() => {
        const inputs = document.querySelectorAll(".input.large");

        inputs.forEach((input) => {
            input.addEventListener(
                "focusin",
                function () {
                    input.classList.add("focused");
                },
                false
            );
            input.addEventListener("focusout", function () {
            });
        });
    }, []);

    const validationChangePassword = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            password: Password,
            passwordConfirmation: PasswordConfirm,
        },

        validationSchema: Yup.object().shape({
            password: Yup.string()
                .min(8, "Pasword must be 9 or more characters")
                .matches(
                    /(?=.*[a-z])(?=.*[A-Z])\w+/,
                    "Password should contain at least one uppercase and lowercase character"
                )
                .matches(/\d/, "Password should contain at least one number")
                .matches(
                    /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
                    "Password should contain at least one special character"
                )
                .required("the password is weak and does not meet the requirements"),

            passwordConfirmation: Yup.string()
                .required("Pasword must be 9 or more characters")
                .oneOf([Yup.ref("password")], "The passwords do not match")
                .required("Pasword must be 9 or more characters"),
        }),

        onSubmit: (values, actions) => {
            // console.log(values)
            setIsLoading(true);
            dispatch(
                ResetPassword(
                    {
                        password: values.password,
                        email: params.mail,
                        code: params.code,
                    },
                    (result) => {
                        console.log(`Result`, result);
                        if (result.data.status === 200) {
                            setIsLoading(false);
                            setStep(2);
                            setTimeout(() => {
                                navigate(`/Login`);
                            }, 3000);
                            setErrorMessage(``);
                        } else {
                            setIsLoading(false);
                            setErrorMessage(result.data.msg);
                        }
                    }
                )
            );
        },
    });

    const isLengthGreaterThan = (str, length) => {
        let word = str || "";

        return word.length >= length;
    };

    const isContainsUppercas = (str) => {
        return /[A-Z]/.test(str);
    };

    const isContainsLowercas = (str) => {
        return /[a-z]/.test(str);
    };

    const isContainsOneNumbe = (str) => {
        return /\d/.test(str);
    };

    const isContainSpecialChar = (str) => {
        return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str);
    };

    return (
        <>
            {step === 1 ? (
                <section className="register_page login_page">
                    <div className="register_container">
                        <header className="header">
                            <div className="box">
                                <a href="#" className="logo">
                                    <img src={logo} className="img-fluid"/>
                                </a>
                                <div className="account">
                                    <p className="text">
                                        Don’t have an account?{" "}
                                        <a href="#" className="link btn textBtn smallBtn">
                                            Create a new vendor account
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </header>
                        <div className="register_content">
                            <div className="sec_head text-center change_password">
                                <h3 className="h3-bold">Change password</h3>
                                <h6 className="sh6-med desc">
                                    Enter a new password below to change your password
                                </h6>
                            </div>
                            <div className="mb-80 proForm">
                                <div className="form-group text-center">
                                    <button
                                        className={`btn primaryBtn  icon-l largeBtn ${
                                            params.mail ? `disabled` : ``
                                        } d-inline-flex`}
                                    >
                                        <img src={email} className="img-fluid icon" alt="Email"/>
                                        {params.mail}
                                    </button>
                                </div>
                                {errorMessage !== `` ? (
                                    <div
                                        className=" sh5-med"
                                        style={{
                                            display: "flex",
                                            justifyContent: `center`,
                                            backgroundColor: `#FFF1ED`,
                                            alignItems: `center`,
                                            padding: 16,
                                            minHeight: 56,
                                            borderRadius: 8,
                                            border: `1px solid #E94225`,
                                            marginBottom: 24,
                                            color: `#84101D`,
                                        }}
                                    >
                                        <img
                                            src={Warning}
                                            style={{marginRight: 9}}
                                            className="img-fluid icon"
                                            alt="warning"
                                        />
                                        <span>{errorMessage}</span>
                                    </div>
                                ) : null}
                                <div className="form-group">
                                    <div className="input withIcon large">
                                        <label className="control-label" htmlFor="password">
                                            New password*
                                        </label>
                                        <img
                                            src={PasswordImg}
                                            className="img-fluid left_icon"
                                            alt="password"
                                        />
                                        {/* <img src={ShowPass} className="img-fluid showPass right_icon" alt="ShowPass" />
                                        <img src={HidePass} className="img-fluid showPass right_icon" alt="ShowPass" /> */}
                                        {visualisePassword ? (
                                            <img
                                                onClick={() => {
                                                    setVisualisePassword(!visualisePassword);
                                                }}
                                                src={HidePass}
                                                className="img-fluid showPass right_icon"
                                                alt="ShowPass"
                                            />
                                        ) : (
                                            <img
                                                onClick={() => {
                                                    setVisualisePassword(!visualisePassword);
                                                }}
                                                src={ShowPass}
                                                className="img-fluid showPass right_icon"
                                                alt="ShowPass"
                                            />
                                        )}
                                        <input
                                            autoComplete="off"
                                            type={visualisePassword ? "text" : "password"}
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            value={Password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            // onBlur={validationChangePassword.handleBlur}
                                        />
                                        {validationChangePassword.errors.password &&
                                        validationChangePassword.touched.password ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon2"
                                                alt="coPassword"
                                            />
                                        ) : !validationChangePassword.errors.password &&
                                        validationChangePassword.touched.password ? (
                                            <img
                                                src={checkmarkedCircle_20}
                                                className="img-fluid right_icon2"
                                                alt="coPassword"
                                            />
                                        ) : (
                                            " "
                                        )}
                                    </div>
                                    <div className="help-text password_help">
                                        <p
                                            className={
                                                "text " +
                                                (isLengthGreaterThan(Password, 9) == true
                                                    ? " has-success "
                                                    : " has-error ")
                                            }
                                        >
                                            <img
                                                src={
                                                    isLengthGreaterThan(Password, 9) == true
                                                        ? checkmarkedCircle_20
                                                        : error_24_updated
                                                }
                                                className="img-fluid"
                                                alt="close"
                                            />

                                            <span>Must be at least 9 numbers</span>
                                        </p>

                                        <p
                                            className={
                                                "text " +
                                                (isContainsUppercas(Password) == true
                                                    ? " has-success "
                                                    : " has-error ")
                                            }
                                        >
                                            <img
                                                src={
                                                    isContainsUppercas(Password) == true
                                                        ? checkmarkedCircle_20
                                                        : error_24_updated
                                                }
                                                className="img-fluid"
                                                alt="close"
                                            />

                                            <span>One uppercase letter (eg. XYZ)</span>
                                        </p>

                                        <p
                                            className={
                                                "text " +
                                                (isContainsLowercas(Password) == true
                                                    ? " has-success "
                                                    : " has-error ")
                                            }
                                        >
                                            <img
                                                src={
                                                    isContainsLowercas(Password) == true
                                                        ? checkmarkedCircle_20
                                                        : error_24_updated
                                                }
                                                className="img-fluid"
                                                alt="close"
                                            />
                                            <span>One lowercase letter (eg. xyz) </span>
                                        </p>

                                        <p
                                            className={
                                                "text " +
                                                (isContainsOneNumbe(Password) == true
                                                    ? " has-success "
                                                    : " has-error ")
                                            }
                                        >
                                            <img
                                                src={
                                                    isContainsOneNumbe(Password) == true
                                                        ? checkmarkedCircle_20
                                                        : error_24_updated
                                                }
                                                className="img-fluid"
                                                alt="close"
                                            />
                                            <span>One number (eg. 123) </span>
                                        </p>

                                        <p
                                            className={
                                                "text " +
                                                (isContainSpecialChar(Password) == true
                                                    ? " has-success "
                                                    : " has-error ")
                                            }
                                        >
                                            <img
                                                src={
                                                    isContainSpecialChar(Password) == true
                                                        ? checkmarkedCircle_20
                                                        : error_24_updated
                                                }
                                                className="img-fluid"
                                                alt="close"
                                            />

                                            <span>At least 1 special character (eg. @#)</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input withIcon large">
                                        <label
                                            className="control-label"
                                            htmlFor="passwordConfirmation"
                                        >
                                            Re-enter new password*
                                        </label>
                                        <img
                                            src={PasswordImg}
                                            className="img-fluid left_icon"
                                            alt="Password"
                                        />
                                        {/* <img src={ShowPass} className="img-fluid showPass right_icon" alt="ShowPass" />
                                        <img src={HidePass} className="img-fluid showPass right_icon" alt="ShowPass" /> */}
                                        {visualiseConfirmPassword ? (
                                            <img
                                                onClick={() => {
                                                    setVisualiseConfirmPassword(
                                                        !visualiseConfirmPassword
                                                    );
                                                }}
                                                src={HidePass}
                                                className="img-fluid showPass right_icon"
                                                alt="ShowPass"
                                            />
                                        ) : (
                                            <img
                                                onClick={() => {
                                                    setVisualiseConfirmPassword(
                                                        !visualiseConfirmPassword
                                                    );
                                                }}
                                                src={ShowPass}
                                                className="img-fluid showPass right_icon"
                                                alt="ShowPass"
                                            />
                                        )}
                                        <input
                                            autoComplete="off"
                                            type={visualiseConfirmPassword ? "text" : "password"}
                                            className="form-control"
                                            id="passwordConfirmation"
                                            name="passwordConfirmation"
                                            value={PasswordConfirm}
                                            onChange={(e) => {
                                                setPasswordConfirm(e.target.value);
                                            }}
                                            // onBlur={validationChangePassword.handleBlur}
                                        />
                                    </div>
                                    <div className="help-text">
                                        {validationChangePassword.errors.passwordConfirmation &&
                                        validationChangePassword.touched.passwordConfirmation ? (
                                            <p className="text has-error">
                                                {validationChangePassword.errors.passwordConfirmation}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="form_actions">
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        onClick={() => {
                                            validationChangePassword.handleSubmit();
                                        }}
                                        className={`btn primaryBtn ${
                                            isLoading ? `disabled` : ``
                                        } largeBtn  w-100`}
                                    >
                                        Change password
                                    </button>
                                    <p className="hint-text">
                                        You’ll be logged out of all active FGC sessions after your
                                        password is changed
                                    </p>
                                </div>
                            </div>

                            <div className="contact_info">
                                <p className="title">For any help contact our support at: </p>
                                <ul className="list-unstyled contact_info__list">
                                    <li>
                                        <a href="tel:+966 55263 98733" className="link">
                                            <img
                                                src={Phone_16}
                                                className="img-fluid icon"
                                                alt="phone"
                                            />
                                            <span className="text">+966 55263 98733</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:portal.support@fgc.sa" className="link">
                                            <img
                                                src={email_16}
                                                className="img-fluid icon"
                                                alt="email"
                                            />
                                            <span className="text">portal.support@fgc.sa</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="register_page login_page">
                    <div className="register_container">
                        <header className="header">
                            <div className="box">
                                <a href="#" className="logo">
                                    <img src={logo} className="img-fluid"/>
                                </a>
                                <div className="account">
                                    <p className="text">
                                        Don’t have an account?{" "}
                                        <a href="#" className="link btn textBtn smallBtn">
                                            Create a new vendor account
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </header>
                        <div className="register_content">
                            <div className="sec_head text-center">
                                <h3 className="h3-bold">
                                    Your password has been changes successfully
                                </h3>
                                <h6 className="sh6-bold desc">{params.mail}</h6>
                                <h6 className="sh6-med">
                                    You’ll be redirected to the login page
                                </h6>
                            </div>
                            <div className="mb-80 proForm">
                                <div className="text-center">
                                    <img
                                        src={success_pass_icon}
                                        className="img-fluid icon"
                                        alt="icon"
                                    />
                                </div>

                                <div className="form_actions text-center">
                                    <h6 className="sh6-med">Redirecting...</h6>
                                </div>
                            </div>

                            <div className="contact_info">
                                <p className="title">For any help contact our support at: </p>
                                <ul className="list-unstyled contact_info__list">
                                    <li>
                                        <a href="tel:+966 55263 98733" className="link">
                                            <img
                                                src={Phone_16}
                                                className="img-fluid icon"
                                                alt="phone"
                                            />
                                            <span className="text">+966 55263 98733</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:portal.support@fgc.sa" className="link">
                                            <img
                                                src={email_16}
                                                className="img-fluid icon"
                                                alt="email"
                                            />
                                            <span className="text">portal.support@fgc.sa</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default ChangePassword;
