import Api from "./Api.js";
import { axiosLoginApi } from "./Axios.js";

export default class DataServices {

    static GetDataList(url, label, value) {
        let Data = []
        return Api.get(url).then(result => {
            (result).forEach(item => {
                var obj = {};
                obj.label = item[label];
                obj.value = item[value];
                Data.push(obj);
            });
            return Data;
        }).catch(ex => Data);
    };

    static GetDataListDetailed(url, label, value) {
        let Data = []
        return Api.get(url).then(result => {
            (result).forEach(item => {
                var obj = {};
                obj.label = item[label];
                obj.value = item[value];
                Data.push({ ...obj, ...item });
            });
            return Data;
        }).catch(ex => Data);
    };

    static GenerateListDetailed(result, label, value) {
        let Data = []
        result.forEach(item => {
            var obj = {};
            obj.label = item[label];
            obj.value = item[value];
            Data.push({ ...obj});
        });
        return Data;
    };
    static addObject = (url, docObj) => {

        return Api.post(url, docObj).then(result => {
            return result;
        });
    };

    static addLogo = (url, docObj) => {

        return Api.UploadPhoto(url, docObj).then(result => {
            return result;
        });
    };

    static GetDataGrid = (url) => {

        return Api.get(url).then(result => {

            return result;
        }).catch(ex => []);
    };

    static GetDataGridPost = (url) => {

        return Api.post(url).then(result => {

            return result;
        }).catch(ex => []);
    };

    static GetRowById = (url) => {

        return Api.get(url).then(result => {

            return result;
        }).catch(ex => { });
    };
}