import React, {useEffect, useState} from "react";

import FGC_Secondary_logo_EN from "../Styles/images/FGC_Secondary_logo_EN.svg";
import checkmarkedCircle48 from "../Styles/images/icons/checkmarkedCircle-48.svg";
import steper_h_Checkmark from "../Styles/images/icons/steper_h_Checkmark.svg";
import phone_dark from "../Styles/images/icons/phone_dark.svg";
import Email_dark from "../Styles/images/icons/Email_dark.svg";
import clock_ from "../Styles/images/icons/clock_.svg";
import FGC from "../Styles/images/FGC.svg";
import castle from "../Styles/images/castle.svg";
import user_24 from "../Styles/images/icons/user_24.svg";
import location from "../Styles/images/icons/location.svg";
// import FlagSaudi_20 from "../Styles/images/icons/FlagSaudi_20.svg";
import phone_dark_24 from "../Styles/images/icons/phone_dark_24.svg";
import Doc2 from "../Styles/images/icons/Doc2.svg";
import castle_24 from "../Styles/images/icons/castle_24.svg";
import {useSelector, useDispatch} from "react-redux";
import DataServices from "../Services/DataServices.js";
import {GetCompanySummary} from "../Store/actions/registration";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import config from "../IP_Configrations.json";

const host = config.static;
const StepSeven = () => {
    const dispatch = useDispatch();
    const userToken = localStorage.getItem("user_token");

    const [UserCountry, setUserCountry] = useState(``);
    const [CompanyCountry, setCompanyCountry] = useState(``);
    const [CompanyType, setCompanyType] = useState(``);

    const user = useSelector((state) => state.registration.user);
    const company = useSelector((state) => state.registration.company);
    const companyId = useSelector((state) => state.registration.company.id);
    const navigate = useNavigate();

    const Company_Summary = useSelector(
        (state) => state.registration.Company_Summary
    );

    useEffect(() => {
        // window.scrollTo(0, 0);
        localStorage.removeItem("CompanyFinancialContactPerson");
        localStorage.removeItem("registeredCompanies");
        localStorage.removeItem("duplicates");
        localStorage.removeItem("continue registration")
    }, []);
    const [countryCodeList, setCountryCodeList] = useState([]);

    // useEffect(() => {
    //   const getCountriesCode = async () => {
    //     await axios
    //       .get(`${host}GetCountriesList`, {
    //         headers: {
    //           Authorization: userToken,
    //         },
    //       })
    //       .then((res) => {
    //         const countriesList = res.data.map((item) => {
    //           return {
    //             label: item.countryCode,
    //             value: item.id,
    //           };
    //         });

    //         setCountryCodeList(countriesList);
    //       })
    //       .catch((err) => console.log(err));
    //   };
    //   getCountriesCode();
    // }, []);
    const showCountryCodeList = () => {
        return countryCodeList.find(
            (option) => option.value === Company_Summary?.countryId
        );
    };

    useEffect(() => {
        DataServices.GetDataList(`GetCountriesList`, "title", "id").then((res) => {
            setUserCountry(
                res.filter((item) => item.value === user?.countryId)[0].label
            );
            setCompanyCountry(
                res.filter((item) => item.value === company?.countryId)[0].label
            );
        });
        // console.log(companyId);
        DataServices.GetDataList(`GetBusinessTypesList`, "title", "id").then(
            (res) => {
                console.log(res);
                setCompanyType(
                    res.filter((item) => item.value === company?.businessTypeId)[0].label
                );
            }
        );
    }, []);
    useEffect(() => {
        dispatch(GetCompanySummary(companyId));
    }, [dispatch]);
    return (
        <section className="register_page">
            <div className="register_container">
                <header className="header">
                    <div className="box">
                        <a href="#" className="logo">
                            <img src={FGC_Secondary_logo_EN} className="img-fluid"/>
                        </a>
                        <div className="account">
                            {/* <p className="text">
                Already have an account?
                <a href="#" className="link btn textBtn smallBtn">
                  Login
                </a>
              </p> */}
                        </div>
                    </div>
                </header>
                <div className="register_content">
                    <div className="step7">
                        <div className="step_head text-center">
                            <img
                                src={checkmarkedCircle48}
                                className="img-fluid icon"
                                alt="checkmarkedCircle"
                            />
                            <h2 className="h2-bold">
                                {user?.firstName} Thanks for your request
                            </h2>
                            <h4 className="sh4-med">
                                Your request is <span className="status">pending</span> our
                                revision
                            </h4>
                            <p className="desc">This isn’t a confirmation approval - yet</p>
                        </div>

                        <ul className="list-unstyled stepper_h">
                            <li className="step done">
                <span className="step__count">
                  <span className="num h5-med">1</span>
                  <img
                      src={steper_h_Checkmark}
                      className="img-fluid checked"
                      alt="done"
                  />
                </span>
                                <p className="step__text">
                                    <span className="step__name"> Registration Request</span>
                                </p>
                            </li>
                            <li className="step">
                <span className="step__count">
                  <span className="num h5-med">2</span>
                  <img
                      src={steper_h_Checkmark}
                      className="img-fluid checked"
                      alt="done"
                  />
                </span>
                                <p className="step__text">
                                    <span className="step__name"> Revision</span>
                                </p>
                            </li>
                            <li className="step">
                <span className="step__count">
                  <span className="num h5-med">3</span>
                  <img
                      src={steper_h_Checkmark}
                      className="img-fluid checked"
                      alt="done"
                  />
                </span>
                                <p className="step__text">
                                    <span className="step__name"> Approved</span>
                                </p>
                            </li>
                            <li className="step">
                <span className="step__count">
                  <span className="num h5-med">4</span>
                  <img
                      src={steper_h_Checkmark}
                      className="img-fluid checked"
                      alt="done"
                  />
                </span>
                                <p className="step__text">
                                    <span className="step__name"> Confirmation</span>
                                </p>
                            </li>
                        </ul>

                        <div className="step_text">
                            <h5 className="sh5-bold">Thanks for your registration request</h5>
                            <p className="desc secondary_text">
                                {/* Your registration request number is <span className="t_bold">{user?.registrationNo}</span> */}
                                Your registration request number is{" "}
                                <span className="t_bold">
                  {" "}
                                    {Company_Summary?.registrationNo || 7763456}
                </span>
                            </p>
                        </div>

                        <div className="step_text">
                            <h5 className="sh5-bold">
                                Your registration request has been sent to
                            </h5>
                            <p className="desc"> portal.support@fgc.sa </p>
                        </div>

                        <div className="step_text">
                            <p className="desc">
                                We’ll revise your company details and registration documents,
                                once your company gets approved, we’ll send you a response
                                within 72 hours. We’ll also send you an email to (
                                <a href="#" className="link">
                                    {Company_Summary?.contatPerson?.email || user?.email}
                                </a>
                                ) when your request is officially confirmed.
                            </p>
                        </div>

                        <div className="step_text">
                            <p className="desc">
                                In the meantime, no one else can make a new registration with
                                your company domain name
                            </p>
                        </div>

                        <div className="step_text">
                            <p className="desc">
                                you can login to your account to see the status of your request,
                                also you can see all your company details
                            </p>
                        </div>
                        <div className="step_text">
                            <button
                                onClick={() => {
                                    window.localStorage.setItem("cs", 1);
                                    localStorage.removeItem("userToken");
                                    localStorage.removeItem("Company_Details");
                                    localStorage.removeItem("CompanyBankDetail");
                                    localStorage.removeItem("fourthStep");
                                    localStorage.removeItem("thirdStep");
                                    localStorage.removeItem("u");
                                    localStorage.removeItem("user_phone");
                                    localStorage.removeItem("DN");

                                    navigate("/Login");
                                }}
                                className="btn primaryBtn largeBtn d-inline-flex "
                            >
                                Go to your dashboard
                            </button>
                        </div>
                        <div className="need_help">
                            <h5 className="h5-bold">Need assistance?</h5>
                            <p className="desc">Don’t worry, we can help</p>
                            <ul className="list-unstyled contact_info nbg">
                                <li>
                                    <a href="tel:+966 11 219 0888" className="link">
                                        <img
                                            src={phone_dark}
                                            className="img-fluid icon"
                                            alt="phone"
                                        />
                                        <span className="h6-reg">+966 11 219 0888</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:portal.support@fgc.sa" className="link">
                                        <img
                                            src={Email_dark}
                                            className="img-fluid icon"
                                            alt="email"
                                        />
                                        <span className="h6-reg">portal.support@fgc.sa</span>
                                    </a>
                                </li>
                                <li>
                                    <p className="link">
                                        <img src={clock_} className="img-fluid icon" alt="clock"/>
                                        <span className="h6-reg">
                      Working hours: Sun-Thu / 08:00 AM - 05:00 PM (GMT +1)
                    </span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="steps company_details">
                <div className="logo">
                    <img src={FGC} className="img-fluid" alt="FGC"/>
                </div>
                <h4 className="sh4-bold text-center">Your request summary</h4>

                <div className="comany_name">
                    <div className="icon">
                        <img src={castle} className="img-fluid" alt="castle"/>
                    </div>
                    <div className="text">
                        <h5 className="sh5-bold">
                            {Company_Summary?.companyName || company?.companyName}
                        </h5>
                        <h5 className="sh5-bold">
                            {Company_Summary?.companyNameAr || company?.companyNameAr}
                        </h5>
                    </div>
                </div>

                <div className="contact_info nbg">
                    <h5 className="sh5-bold">Contact person</h5>
                    <ul className="list-unstyled list">
                        <li>
                            <p className="link">
                                <img src={user_24} className="img-fluid icon" alt="user"/>
                                <span className="sh6-med">
                  {Company_Summary?.contactPerson?.contactName}
                </span>
                            </p>
                        </li>

                        <li>
                            <p className="link">
                                <img src={location} className="img-fluid icon" alt="user"/>
                                <span className="sh6-med">
                  {UserCountry !== ``
                      ? UserCountry
                      : Company_Summary?.contatPerson?.countryName}{" "}
                                    {/* <img src={FlagSaudi_20} className="img-fluid" alt="Saudi" /> */}
                </span>
                            </p>
                        </li>

                        <li>
                            <a href="mailto:portal.support@fgc.sa" className="link">
                                <img
                                    src={phone_dark_24}
                                    className="img-fluid icon"
                                    alt="phone"
                                />
                                <span className="sh6-med">
                  {/* {showCountryCodeList()?.label} + */}
                                    {window.localStorage.getItem("countryCode")}
                                    {Company_Summary?.contatPerson?.telephone || user?.phone}
                </span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="contact_info nbg">
                    <h5 className="sh5-bold">Company info</h5>
                    <ul className="list-unstyled list">
                        <li>
                            <p className="link">
                                <img src={location} className="img-fluid icon" alt="user"/>
                                <span className="sh6-med">
                  Country:{" "}
                                    {CompanyCountry !== ``
                                        ? CompanyCountry
                                        : Company_Summary?.countryName}{" "}
                                    {/* <img src={FlagSaudi_20} className="img-fluid" alt="Saudi" /> */}
                </span>
                            </p>
                        </li>
                        <li>
                            <a href="mailto:portal.support@fgc.sa" className="link">
                                <img
                                    src={phone_dark_24}
                                    className="img-fluid icon"
                                    alt="phone"
                                />
                                <span className="sh6-med">
                  VAT no: {Company_Summary?.vatNo || 55427618783371}{" "}
                </span>
                            </a>
                        </li>

                        <li>
                            <p className="link">
                                <img src={location} className="img-fluid icon" alt="user"/>
                                <span className="sh6-med">
                  Commercial registration number (CR#):{" "}
                                    {Company_Summary?.commercialRegisterNo || 7618786110}{" "}
                </span>
                            </p>
                        </li>

                        {company?.isSaudia && (
                            <li>
                                <p className="link">
                                    <img src={Doc2} className="img-fluid icon" alt="user"/>
                                    <span className="sh6-med">
                    {" "}
                                        Zakat certificate:{" "}
                                        {Company_Summary?.zakatcertificateNo || 8840329811}{" "}
                  </span>
                                </p>
                            </li>
                        )}

                        <li>
                            <p className="link">
                                <img src={castle_24} className="img-fluid icon" alt="user"/>
                                <span className="sh6-med">
                  Business type: {CompanyType !== `` && CompanyType}
                </span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default StepSeven;
