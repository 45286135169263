/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import DashboardApprovalModal from "./DashboardApprovalModal";
import DashboardAfterCloseModal from "./DashboardAfterCloseModal";

const DashboardApproval = ({ openModal, setOpenModal, dataDashboard }) => {
  const loginCounter = localStorage.getItem("loginCounter");
  return (
    <Fragment>
      {loginCounter != 0 && <DashboardAfterCloseModal dataDashboard={dataDashboard} />}
      {loginCounter == 0 &&
        (openModal ? (
          <DashboardApprovalModal dataDashboard={dataDashboard} openModal={openModal} setOpenModal={setOpenModal} />
        ) : (
          <DashboardAfterCloseModal dataDashboard={dataDashboard} />
        ))}
    </Fragment>
  );
};

export default DashboardApproval;
