import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import Login from './Login/Login';

import AsyncCompanyDetails from "./CompanyProfile/CompanyProfile";
import AsyncPrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import AsyncTermsOfUse from "./TermsOfUse/TermsOfUse";
import TermsAndCon from "./TermsAndConditions/TermsAndCon";
import AsyncDashboard from "./Dashboard/Dashboard";
import AsyncVendorCategories from "./SettingVendorCategories/SettingVendorCategories";
import CompanyInfo from "./Components/Settings/Account/CompanyInfo";
import ContactPerson from "./Components/Settings/Account/ContactPerson";
import BankDetails from "./Components/Settings/Account/BankDetails/BankDetails";
import CompanyDocuments from "./Components/Settings/Account/CompanyDocuments";
import PasswordSettings from "./Components/Settings/Password";
import UserSettings from "./Components/Settings/User";
import Preferences from "./Components/Settings/Preferences";
import Notifications from "./Components/Settings/Notifications/Notifications";
import CompanyProfileShare from "./CompanyProfile/CompanyProfileShare";
import AllCompaniesDashoard from "./Components/DashboardComponents/AllCompaniesDashoard";
import DashboardFromMenu from "./Dashboard/DashboardFromMenu";
import NotFoundPage from "./not-found-page/NotFoundPage";

import SecuritySettings from "./SecuritySettings/SecuritySettings";
import NotificationsSettings from "./Notifications/NotificationsSettings";
import GeneralPreferences from "./GeneralPreferences/GeneralPreferences";

const RedirectToDashboard = () => {
    const companyId = localStorage.getItem('CompanyId');
    return <Navigate to={`/Dashboard/${companyId}`} />;
};


let registeredRoutes = (
    <Routes>
        {/* <Route key="public-route-30" path={`/`} element={<AsyncDashboard />} />,  */}

        {/* <Route  key="registeredRoutes-route-000" exact path='/Login' element={<Login />} /> */}

        <Route path="/" key="registeredRoutes-route-000" element={<RedirectToDashboard />} />
        

        <Route key="registeredRoutes-route-001" path="/CompanyDetails" element={<AsyncCompanyDetails />} />
        <Route
            //   key="registeredRoutes-route-001"
            path="/CompanyShare/:id"
            element={<CompanyProfileShare />}
        />
        <Route key="registeredRoutes-route-002" path="/PrivacyPolicy" element={<AsyncPrivacyPolicy />} />
        <Route path="/SettingsContainer/TermsAndConditions" element={<TermsAndCon />} />
        <Route key="registeredRoutes-route-003" path="/TermsOfUse" element={<AsyncTermsOfUse />} />

        <Route key="registeredRoutes-route-004" path="/Dashboard/:id" element={<AsyncDashboard />} />
        <Route key="registeredRoutes-route-004" path="/Dashboardd/:id" element={<DashboardFromMenu />} />
        {/*  */}
        <Route key="registeredRoutes-route-004" path="/allCompanies" element={<AllCompaniesDashoard />} />
        <Route key="registeredRoutes-route-004" path="/CompanyDashboard" element={<AsyncDashboard />} />
        <Route
            key="private-route-2"
            path="/SettingsContainer/SettingVendorCategories"
            element={<AsyncVendorCategories />}
        />
        <Route key="private-route-3" path="/SettingsContainer/CompanyInfo" element={<CompanyInfo />} />
        <Route key="private-route-4" path="/SettingsContainer/ContactPerson" element={<ContactPerson />} />
        <Route key="private-route-5" path="/SettingsContainer/BankDetails" element={<BankDetails />} />
        <Route key="private-route-6" path="/SettingsContainer/CompanyDocuments" element={<CompanyDocuments />} />
        <Route key="private-route-7" path="/SettingsContainer/PasswordSettings" element={<PasswordSettings />} />
        <Route key="private-route-8" path="/SettingsContainer/UserSettings" element={<UserSettings />} />
        <Route key="private-route-9" path="/SettingsContainer/NotificationsSettings" element={<NotificationsSettings />} />
        <Route key="private-route-10" path="/SettingsContainer/AccountPreferences" element={<GeneralPreferences />} />
        <Route key="private-route-10" path="/SettingsContainer/SecuritySettings" element={<SecuritySettings />} />
        <Route path="*" element={<NotFoundPage />} />
    </Routes>
);
export default registeredRoutes;
