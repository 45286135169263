import { Link } from "react-router-dom";
import logo from "../Styles/images/FGC_Secondary_logo_EN.svg";
import CloseSVG from "../Styles/images/dashboard/Close.svg";
import {SetTransferedModal, SetTransferOwnerShip} from "../Store/actions/registration";
import { useDispatch , useSelector} from "react-redux";
import Phone_16 from "../Styles/images/icons/Phone_16.svg";
import email_16 from "../Styles/images/icons/email_16.svg";
import transeferedIcon from "../Styles/images/icons/transferedIcon.svg";
import arrows from "../Styles/images/icons/arrows-group.svg";



function AccountTranseferredCard (){
  let dispatch = useDispatch();

  const transferedEmail = window.localStorage.getItem('transferedEmail') ;
  const loggedUSer = window.localStorage.getItem('userEmail')

  const closeModal = () => {
    dispatch(SetTransferOwnerShip(false));
    dispatch(SetTransferedModal(false));
};

    return (
      <section className="register_page transfered_page login_page">
        <div className="register_container">
          <header className="header">
              <div className="box">
                  <Link
                      to={`/Dashboard/${window.localStorage.getItem("CompanyId")}`}
                      className="logo"
                  >
                      <img src={logo} className="img-fluid" />
                  </Link>
                  <div className="account">
                    <div 
                    onClick={()=> closeModal()}
                     className="close">
                        <a href="#" >
                        <img src={CloseSVG} className="img-fluid" alt="close" />
                        </a>
                    </div>
                  </div>
              </div>
          </header>
          <div className="register_content">
            <div className="text-center m-5">
              <div className={"d-flex justify-content-center"}>
                  <h2 className="w-75 heading2 mb-3">
                      Your account ownership transferring request has been sent to FGC’s admin successfully
                  </h2>
              </div>
              
              <p className="transferText mb-3 text-center">
                  We’ll revise your request and get back to you as soon as possible.
              </p>
            </div>

            <div className="containerBox text-center mt-5 mb-4">
              <div className="currentUserBox ">
              <span className="infoBox mr-3">
                <p>From</p>
                <h3>{loggedUSer}</h3>
              </span>
              <img src={arrows} className="img-fluid icon float-end mt-3" alt="arrows" />

              </div>
              <div className="ml-2 mr-2 iconBox">
                <img src={transeferedIcon} className="img-fluid icon" alt="transeferedIcon" />

              </div>
              <div className="currentUserBox">
              <img src={arrows} className="img-fluid icon float-start mt-3" alt="arrows" />
              <span className="infoBox ml-3">
                <p>To</p>
                <h3> { transferedEmail}</h3>
              </span>

              </div>


            </div>

            <div className="form_actions  mt-4 mb-80">
                <button className="transfer_btn btn primaryBtn largeBtn m-auto"  type="button" onClick={()=>closeModal()}>
                Ok, done
                </button>
            </div>

            <div className="register_page login_page">
              <div className="contact_info">
                  <p className="title">For any help contact our support at: </p>
                  <ul className="list-unstyled contact_info__list">
                      <li>
                          <a href="tel:+966 55263 98733" className="link">
                              <img src={Phone_16} className="img-fluid icon" alt="phone" />
                              <span className="text">+966 55263 98733</span>
                          </a>
                      </li>
                      <li>
                          <a href="mailto:portal.support@fgc.sa" className="link">
                              <img src={email_16} className="img-fluid icon" alt="email" />
                              <span className="text">portal.support@fgc.sa</span>
                          </a>
                      </li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      );

}
export default AccountTranseferredCard;