/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import CompanyLogo from "../../Styles/images//dashboard/company_logo.svg";
import DashboardEmpty from "../../Styles/images/dashboard/dashboard_empty.svg";
import CompanyLogo_2 from "../../Styles/images//dashboard/home.png";
import { CircularProgressbar } from "react-circular-progressbar";
import ModalContext from "../../Store/ModalProvider";

const DashboardAfterCloseModal = ({ dataDashboard }) => {
  const { setModalOpenProfile } = useContext(ModalContext);

  const firstName = dataDashboard?.contactName?.split(" ")[0];

  return (
    <section className="dashboard_page dashboard_page_approve">
      <div className=" company-complatess ">
        <div className="img-div">
          <img src={CompanyLogo_2} />
        </div>
        <h4 className="title">Complete your company profile</h4>
        <div className="footer">
          <p
            onClick={() => {
              setModalOpenProfile(true);
              console.log("jjjj");
            }}
          >
            Your profile completness
          </p>
          <CircularProgressbar
            value={dataDashboard?.DtoProfileCompleteness?.ProfileCompletenessRatio}
            text={`${dataDashboard?.DtoProfileCompleteness?.ProfileCompletenessRatio}%`}
          />
        </div>
      </div>
      <div className="main_title empty">
        <h1 className="name">Hello {firstName}</h1>
        <div className="company_name">
          <img src={CompanyLogo} className="img-fluid" alt="logo" />
          <h2 className="sh2-bold">{dataDashboard?.companyName}</h2>
        </div>

        <div className="imgage">
          <img src={DashboardEmpty} className="img-fluid" alt="dashboard" />
        </div>
        <h4 className="sh4-bold">This is your dashboard</h4>
        <h4 className="sh4-med">But! You don’t have any (data) to show yet</h4>
        <h6 className="sh6-med">When you do, they’ll show up here</h6>
      </div>
    </section>
  );
};

export default DashboardAfterCloseModal;
