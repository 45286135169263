import React from "react";

import HeaderDashboard from "./HeaderDashboard";
import DashboardLastChild from "./DashboardLastChild";
import DashboardSupport from "./DashboardSupport";
import RegistrationStatus from "../RegistrationStatus/RegistrationStatus";
import axios from "axios";
import dashboardImg from "../../Styles/images/dashboard/all.png";

import config from "../../IP_Configrations.json";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "../Loading";
import { SetTabHeaders } from "../../Store/actions/registration";
import { Fragment } from "react";
import CompanyLogo from "../../Styles/images//dashboard/company_logo.svg";
import { useContext } from "react";
import ModalContext from "../../Store/ModalProvider";

const host = config.static;

const AllCompaniesDashoard = () => {
  const [CompanyList, setCompanyList] = useState();
  const userToken = localStorage.getItem("user_token");
  const [companyId, setcompanyId] = useState(null);
  const disaptch = useDispatch();
  const { setchooseCompany, dataDashboard } = useContext(ModalContext);
  useEffect(() => {
    const getCompaniesList = async () => {
      if (userToken) {
        axios
          .get(`${host}GetCompaniesForList`, {
            headers: {
              Authorization: userToken,
            },
          })
          .then((res) => {
            setCompanyList(res.data.filter((res) => res.statusApproval === true));
            setcompanyId(res.data.filter((res) => res.statusApproval !== true)?.slice(-1));
          })
          .catch((err) => console.log(err));
      }
    };

    getCompaniesList();
  }, []);

  const firstName = dataDashboard?.contactName?.split(" ")[0];

  return (
    <>
      {!CompanyList ? (
        <Loading />
      ) : (
        <Fragment>
          <div className="dashboard_page ">
            <div className="custom_container">
              <div className="sec_container main">
                <div className="main_title">
                  <h1 className="name">Hello {firstName}</h1>
                  <div className="company_name">
                    <img src={CompanyLogo} className="img-fluid" alt="logo" />
                    <h2 className="sh2-bold">{dataDashboard?.companyName}</h2>
                  </div>
                  <h5 className="sh5-bold">This is your dashboard!</h5>
                  <h6 className="sh6-med">
                    All your stats will be shown here after approving your registration request
                  </h6>
                </div>

                {/* <div className="company-complatess support"> hjhhj</div> */}
              </div>
              <div className="sec_container">
                {companyId?.length > 0 ? (
                  <RegistrationStatus dataDashboard={dataDashboard} />
                ) : (
                  <div className="all-companies2 registeration_status">
                    <div className="two">
                      <div className="image-div">
                        {CompanyList?.map((item) => (
                          <div className="logo-div">
                            {item?.logo ? (
                              <img className="avatar" src={config.download + item?.logo} alt="Logo" />
                            ) : (
                              <div className="">
                                {item?.companyName?.length ? item?.companyName[0].toUpperCase() : "E"}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div>
                        <img src={dashboardImg} />
                      </div>
                    </div>
                    <div className="mess-div">
                      <h3>All your stats and graphs will show up here</h3>
                      <h4>But! You don’t have any data to show yet</h4>
                      <p>When you do, they’ll show up here</p>
                    </div>
                  </div>
                )}

                <DashboardSupport />
              </div>
              {companyId?.length > 0 ? (
                <div className="all-companies">
                  <div className="image-div">
                    {CompanyList?.map((item) => (
                      <div className="logo-div">
                        {item?.logo ? (
                          <img className="avatar" src={config.download + item?.logo} alt="Logo" />
                        ) : (
                          <div className="">{item?.companyName?.length ? item?.companyName[0].toUpperCase() : "E"}</div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div>
                    <img src={dashboardImg} />
                  </div>
                  <div className="mess-div">
                    <h3>All your stats and graphs will show up here</h3>
                    <h4>But! You don’t have any data to show yet</h4>
                    <p>When you do, they’ll show up here</p>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <DashboardLastChild />
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default AllCompaniesDashoard;
