import { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { SetTabHeaders } from "../../../Store/actions/registration";
import ProfileCompletness from '../../ProfileCompletness/ProfileCompletness';
import axios from "axios";
import config from '../../../IP_Configrations.json';
import Loading from '../../Loading';
import { toast } from 'react-toastify';
const host = config.static;

const Notifications = () => {
    const [loadingPage, setLoadingPage] = useState(false)
    const [communicationData, setCommunicationData] = useState()
    const [newCommunicationData, setNewCommunicationData] = useState({
        isEmail: communicationData?.comunicateWays?.isEmail,
        sms: communicationData?.comunicateWays?.sms,
        phoneCall: communicationData?.comunicateWays?.phoneCall
    })
    const [newEmailPreferences, setNewEmailPreferences] = useState({
        newVendorSubmission: communicationData?.emailPreferences?.newVendorSubmission,
        updateYourPO: communicationData?.emailPreferences?.updateYourPO
    })
    const [newMessages, setNewMessages] = useState({
        newMessage: communicationData?.message?.newMessage,
        stateUpdate: communicationData?.message?.stateUpdate
    })
    const [isCommunicationChanged, setIsCommunicationChanged] = useState(false)
    const [isEmailPreferencesChanged, setNewEmailPreferencesChanged] = useState(false)

    const dispatch = useDispatch();
    const CompanyId = localStorage.getItem('CompanyId')
    const userToken = localStorage.getItem('userToken')

    useEffect(() => {
        if (newCommunicationData) {
            if (communicationData?.comunicateWays?.isEmail !== newCommunicationData?.isEmail ||
                communicationData?.comunicateWays?.sms !== newCommunicationData?.sms ||
                communicationData?.comunicateWays?.phoneCall !== newCommunicationData?.phoneCall
            ) {
                setIsCommunicationChanged(true)
            } else {
                setIsCommunicationChanged(false)
            }
        }
    }, [communicationData, newCommunicationData])

    useEffect(() => {
        if (newEmailPreferences && newMessages) {
            if (communicationData?.emailPreferences?.newVendorSubmission !== newEmailPreferences?.newVendorSubmission ||
                communicationData?.emailPreferences?.updateYourPO !== newEmailPreferences?.updateYourPO ||
                communicationData?.message?.newMessage !== newMessages?.newMessage ||
                communicationData?.message?.stateUpdate !== newMessages?.stateUpdate
            ) {
                setNewEmailPreferencesChanged(true)
            } else {
                setNewEmailPreferencesChanged(false)
            }
        }
    }, [communicationData, newEmailPreferences, newMessages])

    useEffect(() => {
        const getCommunicationOptions = async () => {
            setLoadingPage(true)
            await axios.get(`${host}GetACCOUNTSNotificationsForEdit?companyId=${CompanyId}`,
                {
                    headers: {
                        Authorization: userToken,
                    },
                }
            ).then((res) => {
                setCommunicationData(res.data)
                setNewCommunicationData(res.data.comunicateWays)
                setNewEmailPreferences(res.data?.emailPreferences)
                setNewMessages(res.data?.message)
                setLoadingPage(false)
            }).catch((err) => console.log(err));
        }

        getCommunicationOptions()
    }, [])

    const animateInputs = () => {
        const inputs = document.querySelectorAll(".input");
        inputs.forEach((input) => {
            input.addEventListener(
                "focusin",
                function (e) {
                    input.classList.add("focused");
                },
                false
            );
            input.addEventListener("focusout", function (e) {
                if (e.target.value == "") {
                    input.classList.remove("focused");
                }
            });
        });
    };

    useEffect(() => {
        animateInputs();
        dispatch(SetTabHeaders("Notifications"));
    }, []);

    const handleEditNotifications = (e) => {
        e.preventDefault();
    }

    const handleEditEmailPreferences = () => {
        const returnedTarget = Object.assign(newEmailPreferences, newMessages);
        const editedData = {
            CompanyId,
            ...returnedTarget
        }

        axios
            .post(
                `${host}UpdateEmailPreferences`, editedData,
                {
                    headers: {
                        Authorization: userToken,
                        'Content-Type': 'application/json'
                    },
                }
            )
            .then((res) => {
                setNewEmailPreferencesChanged(false)
                toast.success('Changes Saved')
            })
            .catch((err) => console.log(err));
    }

    return (
        loadingPage ? <Loading /> : <div className="company_info  contact_person notifications">
            <div className="left_sec">
                <form className="proForm" onSubmit={handleEditNotifications}>

                    <div className="communication">
                        <h3 className="sh3-bold">Communication options</h3>
                        <p className="desc secondary_text">How would you prefer to communicate with you?</p>
                        <div className="communication__options">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultValue="" id="email" checked={newCommunicationData?.isEmail}
                                    onChange={(e) => {
                                        setNewCommunicationData({ ...newCommunicationData, isEmail: e.target.checked })
                                    }}
                                />
                                <label className="form-check-label" htmlFor="email"> Email </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultValue="" id="sms" checked={newCommunicationData?.sms}
                                    onChange={(e) => {
                                        setNewCommunicationData({ ...newCommunicationData, sms: e.target.checked })
                                    }}
                                />
                                <label className="form-check-label" htmlFor="sms"> SMS </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultValue="" id="calls" checked={newCommunicationData?.phoneCall}
                                    onChange={(e) => {
                                        setNewCommunicationData({ ...newCommunicationData, phoneCall: e.target.checked })
                                    }}
                                />
                                <label className="form-check-label" htmlFor="calls"> Phone calls </label>
                            </div>
                        </div>
                    </div>

                    <div className="details_sec">

                        <ul className="nav nav-pills  " id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link sh6-bold active" id="pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                    aria-selected="true">Email preferences</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link sh6-bold" id="pills-profile-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                    aria-selected="false">SMS preferences</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="communication ">
                                    <h3 className="sh3-bold">Email preferences</h3>
                                    <p className="desc secondary_text">Depending on your preferences, you can choose what types of email
                                        notifications to receive, and when. send me email notifications for:</p>
                                    <div className="sideByside">
                                        <div className="form-group">
                                            <h5 className="sh5-bold">Your new vendor submission</h5>
                                            <div className="communication__options">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="submission" checked={newEmailPreferences?.newVendorSubmission}
                                                        onChange={(e) => {
                                                            setNewEmailPreferences({ ...newEmailPreferences, newVendorSubmission: e.target.checked })
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="submission"> Updates on your submission </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <h5 className="sh5-bold">Your POs</h5>
                                            <div className="communication__options">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="POs" checked={newEmailPreferences?.updateYourPO}
                                                        onChange={(e) => {
                                                            setNewEmailPreferences({ ...newEmailPreferences, updateYourPO: e.target.checked })
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="POs"> Updates on your POs </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <h5 className="sh5-bold">Messages</h5>
                                            <div className="communication__options">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="Messages" checked={newMessages?.newMessage}
                                                        onChange={(e) => {
                                                            setNewMessages({ ...newMessages, newMessage: e.target.checked })
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="Messages"> New message </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <h5 className="sh5-bold">Stats</h5>
                                            <div className="communication__options">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultValue="" id="Stats" checked={newMessages?.stateUpdate}
                                                        onChange={(e) => {
                                                            setNewMessages({ ...newMessages, stateUpdate: e.target.checked })
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="Stats"> Your stats update </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form_actions">
                                    <button className={`btn primaryBtn largeBtn ${!isEmailPreferencesChanged ? 'disabled' : ''}`} onClick={handleEditEmailPreferences}>Save changes</button>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...
                            </div>
                        </div>

                    </div>

                    <div className="communication disturb proForm">
                        <h3 className="sh3-bold">Do not disturb</h3>
                        <p className="desc secondary_text">Depending on your preferences, you can choose when to receive email, and
                            SMS notifications.</p>
                        <div className="schedule">
                            <h5 className="sh5-bold">Schedule</h5>
                            <div className="communication__options  form-group">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue="" id="disturb" />
                                    <label className="form-check-label" htmlFor="disturb">
                                        Don’t disturb me from:
                                        <div className="input">
                                            <input type="text" className="form-control" defaultValue="15:00" />
                                        </div>
                                        to
                                        <div className="input">
                                            <input type="text" className="form-control" defaultValue="22:00" />
                                        </div>


                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="weeks">
                            <h5 className="sh5-bold">Don’t disturb me on my days off</h5>
                            <div className="communication__options  ">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue="" id="Sat" />
                                    <label className="form-check-label" htmlFor="Sat"> Sat </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue="" id="Sun" />
                                    <label className="form-check-label" htmlFor="Sun"> Sun </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue="" id="Mon" />
                                    <label className="form-check-label" htmlFor="Mon"> Mon </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue="" id="Tue" />
                                    <label className="form-check-label" htmlFor="Tue"> Tue </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue="" id="Wed" />
                                    <label className="form-check-label" htmlFor="Wed"> Wed </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue="" id="Thu" />
                                    <label className="form-check-label" htmlFor="Thu"> Thu </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue="" id="Fri" />
                                    <label className="form-check-label" htmlFor="Fri"> Fri </label>
                                </div>
                            </div>

                            <div className="form_actions">
                                <button className={`btn primaryBtn largeBtn ${!isCommunicationChanged ? 'disabled' : ''}`}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <ProfileCompletness />
        </div>
    )
}

export default Notifications