import Status from "../../Styles/images/icons/verfied.svg";
import UserGray from "../../Styles/images/icons/user_gray_24.svg";
import JobTitleSVG from "../../Styles/images/company_profile/JobTitle.svg";
import LocationGray from "../../Styles/images/icons/location_gray.svg";
import PhoneGray from "../../Styles/images/icons/phone_gray_24.svg";
import MailSVG from "../../Styles/images/company_profile/icons/Email.svg";
import WarningSVG from "../../Styles/images/icons/Warning_gray.svg";

import { useEffect, useState } from "react";
import config from "../../IP_Configrations.json";
import axios from "axios";
const host = config.download;

const SideSectionInfo = ({ companyInfoData, countryCodeList }) => {
  // console.log("====================================");
  // console.log(companyInfoData);
  // console.log("====================================");

  const showCountryCodeList = () => {
    return countryCodeList?.find(
      (option) => option.value === companyInfoData?.countryId
    );
  };
  // console.log("====================================");
  // console.log(countryCodeList);
  // console.log("====================================");
  return (
    <div className="company_info">
      <div className="main_info">
        {companyInfoData?.logo !== null ? (
          <div className="img">
            <img
              src={host + companyInfoData?.logo}
              className="img-fluid"
              alt="epic"
            />
          </div>
        ) : (
          <div className="img">
            <h1>{companyInfoData?.companyName.charAt(0).toUpperCase()}</h1>
          </div>
        )}
        <div className="text">
          <h4 className="title">{companyInfoData?.companyName}</h4>
          <h4 className="title ar">{companyInfoData?.companyNameAr}</h4>
          {companyInfoData?.vendorId && (
            <p className="num"> {companyInfoData?.vendorId}</p>
          )}
          <p className="sh6-med">{companyInfoData?.businessTypeName}</p>
          {companyInfoData?.isVerify ? (
            <p className="status">
              <img src={Status} className="img-fluid" alt="status" />
              <span>Verified</span>
            </p>
          ) : (
            <p className="status">
              <img src={WarningSVG} className="img-fluid" alt="status" />
              <span>Not Verified Yet</span>
            </p>
          )}
        </div>
      </div>
      <div className="about contact_person">
        <h5 className="sh5-bold">Contact person</h5>
        <ul className="list-unstyled " id="centUnstyledList">
          <li className="sh6-med">
            <img src={UserGray} className="img-fluid icon" alt="icon" />
            {companyInfoData?.contactPerson?.contactName}
          </li>
          <li className="sh6-med">
            <img src={JobTitleSVG} className="img-fluid icon" alt="icon" />
            {companyInfoData?.contactPerson?.jobTitle}
          </li>
          <li className="sh6-med">
            <img src={LocationGray} className="img-fluid icon" alt="icon" />
            {companyInfoData?.contactPerson?.countryName}{" "}
            {
              // <img src={FlagSaudi_20} alt='flag' />
            }
          </li>
          <li className="sh6-med">
            <img src={MailSVG} className="img-fluid icon" alt="icon" />
            {companyInfoData?.contactPerson?.email}
          </li>
          <li className="sh6-med">
            <img src={PhoneGray} className="img-fluid icon" alt="icon" />
            {showCountryCodeList()?.label}
            {companyInfoData?.contactPerson?.telephone}
          </li>
          <li className="sh6-med">
            <img src={PhoneGray} className="img-fluid icon" alt="icon" />
            {companyInfoData?.contactPerson?.extPhone}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideSectionInfo;
