import React, { Fragment } from "react";
import LocationSVG from "../../Styles/images/company_profile/location.svg";
import ItemDetailInfo from "./ItemDetailInfo";

const MainCompanyInfo = ({ mainInfo }) => {
  console.log(mainInfo);

  return (
    <div className="general_box location">
      <div className="main_info">
        <div className="img">
          <img src={LocationSVG} className="img-fluid" alt="icon" />
        </div>
        <div className="text">
          <h3 className="title">{mainInfo?.countryName}</h3>
          <p className="sh5-med">{mainInfo?.city}</p>
        </div>
      </div>

      {mainInfo.isSaudia ? (
        <ul className="list-unstyled details_info">
          <ItemDetailInfo
            infoDAta={mainInfo?.companyNationalAddress?.shortAddress}
            title="Short Address"
          />

          <ItemDetailInfo
            infoDAta={mainInfo?.companyNationalAddress?.buildingNo}
            title="Building No"
          />

          <ItemDetailInfo
            infoDAta={mainInfo?.companyNationalAddress?.street}
            title="Street"
          />

          <ItemDetailInfo
            infoDAta={mainInfo?.companyNationalAddress?.secondryNo}
            title="Secondary No"
          />

          <ItemDetailInfo
            infoDAta={mainInfo?.companyNationalAddress?.district}
            title="District"
          />

          <ItemDetailInfo
            infoDAta={mainInfo?.companyNationalAddress?.zipCode}
            title="Zip/Postal Code"
          />
        </ul>
      ) : (
        <Fragment>
          <ul className="list-unstyled details_info">
            <ItemDetailInfo
              infoDAta={mainInfo?.addressLine1}
              title="Address Line 1"
            />

            <ItemDetailInfo
              infoDAta={mainInfo?.addressLine2}
              addClassStyle="w-100"
              title="Address Line 2"
            />
            <ItemDetailInfo
              infoDAta={mainInfo?.zipCode}
              title="Zip/Postal Code"
            />
          </ul>
        </Fragment>
      )}
    </div>
  );
};

export default MainCompanyInfo;
