import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetTabHeaders, SetTransferedModal } from "../../../Store/actions/registration";
import ProfileCompletness from "../../ProfileCompletness/ProfileCompletness";
import WarningBlackBG from "../../../Styles/images/icons/Warning_blackBG_24.svg";
import EmailSVG from "../../../Styles/images/icons/Email.svg";
import JobTitleSVG from "../../../Styles/images/icons/JobTitle.svg";
import PhoneSVG from "../../../Styles/images/icons/Phone.svg";
import SignatureSVG from "../../../Styles/images/settings/singnature.png";
import SignatureSVGDefault from "../../../Styles/images/settings/defaultSignature.png";
import DataServices from "../../../Services/DataServices";
import Select from "react-select";
import axios from "axios";
import config from "../../../IP_Configrations.json";
import Loading from "../../Loading";
import { toast } from "react-toastify";
import Api from "../../../Services/Api";
import { Form } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import MUITextField from "../../MUITextField";
import TransferOwnerShipForm from "../../../TransferOwnerShip/TransferOwnerShipForm";

const host = config.static;
const hostImg = config.download;

const customStyles = {
  control: (base) => ({
    ...base,
    height: 52,
    minHeight: 52,
  }),
};

const customStylesCountryCode = {
  control: (base) => ({
    ...base,
    height: 52,
    minHeight: 52,
    width: 100,
  }),
};

const UserSettings = () => {
  const userToken = localStorage.getItem("user_token");
  const companyId = localStorage.getItem("CompanyId");

  const [countriesList, setCountriesList] = useState([]);
  const [countryChanged, setCountryChanged] = useState();
  const [citiesList, setCitiesList] = useState();
  const [cityChanged, setCityChanged] = useState();
  const [userData, setUserData] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [countryCodeChanged, setCountryCodeChanged] = useState();
  const [newObjectUserData, setNewObjectUserData] = useState();
  const [convImg, setConvImg] = useState();
  const [isAnyChanges, setIsAnyChanges] = useState(false);
  const [changedPhone, setChangedPhone] = useState();
  const [errorInput, setErrorInput] = useState("");
  const [signature, setSignature] = useState(false);
  const [saving, setSaving] = useState(false);
  const transferedModal = useSelector((state) => state.registration.transferedModal);
  const dispatch = useDispatch();

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const onChange = (file) => {
    fileToDataUri(file).then((file) => {
      setConvImg(file);
    });
  };

  const showCountryList = () => {
    if (countriesList) {
      return countriesList.find((option) => option.value === userData?.countryId);
    } else {
      return "";
    }
  };

  const showCitiesList = () => {
    if (citiesList) {
      return citiesList.find((option) => option.value === userData?.cityId) || "";
    } else {
      return "";
    }
  };

  const showCountryCodeList = () => {
    if (newObjectUserData) {
      if (newObjectUserData.countryId !== userData.countryId) {
        return countryCodeList.find((option) => option.value === newObjectUserData?.countryId);
      }
    } else {
      if (countryCodeList) {
        return countryCodeList.find((option) => option.value === userData?.countryId);
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    if (newObjectUserData) {
      if (
        userData.firstName !== newObjectUserData.firstName ||
        userData.lastName !== newObjectUserData.lastName ||
        userData.email !== newObjectUserData.email ||
        userData.jobTitle !== newObjectUserData.jobTitle ||
        userData.countryId !== newObjectUserData.countryId ||
        userData.cityId !== newObjectUserData.cityId ||
        userData.zipCode !== newObjectUserData.zipCode ||
        userData.companyAddress !== newObjectUserData.companyAddress ||
        userData.building !== newObjectUserData.building ||
        userData?.phone?.slice(showCountryCodeList()?.label?.length) !==
          newObjectUserData.phone.slice(showCountryCodeList()?.label?.length) ||
        (countryCodeChanged && showCountryCodeList()?.label !== countryCodeChanged?.label) ||
        userData.signatureFile !== newObjectUserData.signatureFile
      ) {
        setIsAnyChanges(true);
        if (
          userData.firstName === newObjectUserData.firstName &&
          userData.lastName === newObjectUserData.lastName &&
          userData.email === newObjectUserData.email &&
          userData.jobTitle === newObjectUserData.jobTitle &&
          userData.countryId === newObjectUserData.countryId &&
          userData.cityId === newObjectUserData.cityId &&
          userData.zipCode === newObjectUserData.zipCode &&
          userData.companyAddress === newObjectUserData.companyAddress &&
          userData.building === newObjectUserData.building &&
          userData.signatureFile === newObjectUserData.signatureFile &&
          userData?.phone?.slice(showCountryCodeList()?.label?.length) === newObjectUserData.phone
        ) {
          setIsAnyChanges(false);
        }
      } else {
        setIsAnyChanges(false);
      }

      if (newObjectUserData.jobTitle === "") {
        setErrorInput("jobTitle");
        setIsAnyChanges(false);
      } else if (newObjectUserData.countryId === "") {
        setErrorInput("countryId");
        setIsAnyChanges(false);
      } else if (newObjectUserData.cityId === "") {
        setErrorInput("cityId");
      } else if (newObjectUserData.zipCode === "" || newObjectUserData.zipCode.length !== 5) {
        setErrorInput("zipCode");
        setIsAnyChanges(false);
      } else if (newObjectUserData.companyAddress === "") {
        setErrorInput("companyAddress");
        setIsAnyChanges(false);
      } else if (newObjectUserData.building === "") {
        setErrorInput("building");
        setIsAnyChanges(false);
      } else if (newObjectUserData.phone === "" || newObjectUserData.phone.length < 9) {
        setErrorInput("phone");
        setIsAnyChanges(false);
      } else {
        setErrorInput("");
      }
    }
  }, [
    newObjectUserData,
    userData,
    isAnyChanges,
    countryCodeChanged?.label,
    changedPhone,
    showCountryCodeList,
    countryCodeChanged,
  ]);

  useEffect(() => {
    if (!newObjectUserData) {
      DataServices.GetDataList(`GetCountriesList`, "title", "id").then((res) => {
        setCountriesList(res);
        let countryId = userData?.countryId;
        if (countryId) {
          let selectedCountry = res.find((c) => c.value === countryId);
          DataServices.GetDataList(`GetCitiesList?countryId=` + selectedCountry.value, "title", "id").then((res) => {
            setCitiesList(res);
            //setNewObjectUserData({ ...newObjectUserData, cityId: '', zipCode: '', phone: '' })
          });
        }
      });
    } else {
      DataServices.GetDataList(`GetCountriesList`, "title", "id").then((res) => {
        setCountriesList(res);
        let countryId = newObjectUserData?.countryId;
        if (countryId) {
          let selectedCountry = res.find((c) => c.value === countryId);
          DataServices.GetDataList(`GetCitiesList?countryId=` + selectedCountry.value, "title", "id").then((res) => {
            setCitiesList(res);
            // setNewObjectUserData({ ...newObjectUserData, cityId: '', zipCode: '', phone: '' })
          });
        }
      });
    }
  }, [newObjectUserData, userData?.countryId]);

  const animateInputs = () => {
    const inputs = document.querySelectorAll(".input");
    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function (e) {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value == "") {
          input.classList.remove("focused");
        }
      });
    });
  };

  useEffect(() => {
    animateInputs();
    dispatch(SetTabHeaders("User settings"));
  }, []);

  useEffect(() => {
    const getCountriesCode = async () => {
      setLoadingPage(true);
      await axios
        .get(`${host}GetCountriesList`, {
          headers: {
            Authorization: userToken,
          },
        })
        .then((res) => {
          const countriesList = res.data.map((item) => {
            return {
              label: item.countryCode,
              value: item.id,
            };
          });

          setCountryCodeList(countriesList);
          setLoadingPage(false);
        })
        .catch((err) => console.log(err));
    };

    const getUserData = async () => {
      setLoadingPage(true);
      await axios
        .get(`${host}GetUserInfo`, {
          headers: {
            Authorization: userToken,
          },
        })
        .then((res) => {
          setUserData(res.data);
          setLoadingPage(false);
        })
        .catch((err) => console.log(err));
    };

    getUserData();
    getCountriesCode();
  }, [userData?.countryId, userToken]);

  const handleChangeCountry = (e) => {
    setCountryChanged(e);
    setCityChanged(false);
  };

  const handleChangeCity = (e) => {
    setCityChanged(e);
  };

  const handleChangeCountryCode = (e) => {
    setCountryCodeChanged(e);
  };

  const onSubmit = async (e) => {
    // e.preventDefault();

    // console.log("====================================");
    // console.log(e);
    // console.log("====================================");
    setSaving(true);
    let phone = e.phone;
    if (countryCodeChanged) {
      phone =
        (countryCodeChanged?.label ? countryCodeChanged?.label + e.phone : showCountryCodeList()?.label + e.phone) ||
        e.phone;
    }

    const userDataForEdit = {
      id: userData.id,
      // signatureFile: e.target.companyPic.files[0]?.name || '',
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      jobTitle: e?.jobTitle || userData?.jobTitle,
      countryId: countryChanged?.value || userData.countryId,
      cityId: cityChanged?.value || userData.cityId,
      zipCode: e.ZIP || userData.zipCode,
      companyAddress: e.companyAddress || userData.companyAddress,
      building: e.building || userData.building,
      phone: phone,
    };

    if (newObjectUserData?.signatureFile) {
      setSaving(true);
      if (!isAnyChanges) {
        setIsAnyChanges(true);
      }
      const formData = new FormData();
      formData.append("signature", signature);

      let header = {
        companyId: companyId,
        docType: "Signature",
      };

      Api.postFile("UploadUserFile", formData, header).then(() => {
        setSaving(false);
        setIsAnyChanges(false);
      });
    }

    axios
      .post(`${host}UpdateUserInfo`, userDataForEdit, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setUserData({
          ...newObjectUserData,
        });
        setIsAnyChanges(true);
        setSaving(false);
        toast.success("Changes Saved");
      })
      .catch((err) => console.log(err));
  };

  const [form] = Form.useForm();
  let schema;
  schema = Yup.object().shape({
    ZIP: Yup.string().required("Must be 5 digits").max(5, "Must be 5 digits").min(5, "Must be 5 digits"),
    companyAddress: Yup.string().required("company address must fill"),
    building: Yup.string().required("building must fill"),
    phone: Yup.string()
      .required("phone must fill")
      .min(9, "This phone no. is not correct, must be not less than 9 digits or more than 14 digits"),
    jobTitle: Yup.string().required("job title must fill"),
  });
  const {
    control,
    getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    if (userData) {
      setValue("ZIP", userData?.zipCode);
      setValue("companyAddress", userData?.companyAddress);
      setValue("building", userData?.building);
      setValue("phone", userData?.phone);
      setValue("jobTitle", userData?.jobTitle);
    }
  }, [userData]);
  //   const onSubmit = async (data) => {
  //     console.log(data);
  //   };
  const [count, setcount] = useState(0);
  useEffect(() => {
    console.log(getValues("companyAddress"), userData?.companyAddress);
    if (getValues("ZIP") !== userData?.zipCode) {
      setIsAnyChanges(true);
    }
    if (getValues("companyAddress") !== userData?.companyAddress) {
      setIsAnyChanges(true);
    }
    if (getValues("building") !== userData?.building) {
      setIsAnyChanges(true);
    }
    if (getValues("phone") !== userData?.phone) {
      setIsAnyChanges(true);
    }
    if (getValues("jobTitle") !== userData?.jobTitle) {
      setIsAnyChanges(true);
    }
  }, [count, userData]);

  const openTransferModal = () => {
    dispatch(SetTransferedModal(true));
  };

  useEffect(() => {
    dispatch(SetTransferedModal(false));
  }, []);

  return loadingPage || !countriesList || !citiesList ? (
    <Loading />
  ) : transferedModal ? (
    <TransferOwnerShipForm />
  ) : (
    <div className="company_info  contact_person user_Settings">
      <div className="left_sec">
        {/* <form className="proForm" onSubmit={handleEditUserSetting}> */}
        <Form className="proForm" form={form} layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <div className="ownership">
            <div className="waring_box sm black sh5-med">
              <img src={WarningBlackBG} className="img-fluid icon" alt="warning" />
              <span>Settings of the account owner (vendor’s contact person)</span>
            </div>
            <button className="btn mediumBtn textBtn" onClick={() => openTransferModal()}>
              Transfer ownership
            </button>
            {/* <Link to={"/TransferOwnerShip"} className="btn mediumBtn textBtn">
            </Link> */}
          </div>
          <div className="sideByside">
            <div className="form-group">
              <div className="input populated">
                <label className="control-label" htmlFor="f_name">
                  First name*
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  disabled
                  value={userData?.firstName}
                  id="f_name"
                  name="f_name"
                  onChange={(e) => {
                    setNewObjectUserData({
                      ...userData,
                      firstName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="help-text">
                <p className="text"></p>
              </div>
            </div>

            <div className="form-group">
              <div className="input populated">
                <label className="control-label" htmlFor="l_name">
                  Last name*
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  disabled
                  value={userData?.lastName}
                  id="l_name"
                  name="l_name"
                  onChange={(e) => {
                    setNewObjectUserData({
                      ...userData,
                      lastName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="help-text">
                <p className="text"></p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="input withIcon   populated">
              <label className="control-label" htmlFor="mail">
                Work email address*
              </label>
              <img src={EmailSVG} className="img-fluid left_icon" alt="mail" />
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                disabled
                value={userData?.email}
                id="mail"
                name="mail"
                onChange={(e) => {
                  setNewObjectUserData({ ...userData, email: e.target.value });
                }}
              />
            </div>
            <div className="help-text"></div>
          </div>

          <div className="form-group">
            <div className="input  populated">
              <MUITextField
                name="jobTitle"
                type="text"
                placeholder={"Job title / Position*"}
                id="jobTitle"
                setcount={setcount}
                className={`form-control ${errors?.jobTitle ? "has-error" : ""}`}
                errorMsg={errors?.jobTitle?.message}
                validateStatus={errors?.jobTitle ? "error" : ""}
                control={control}
              />
            </div>
            {/*<div className="help-text">*/}
            {/*    <p className="text"></p>*/}
            {/*</div>*/}
          </div>
          {/* jobTitle */}
          <div className="form-group sm">
            <Select
              className="select_react"
              styles={customStyles}
              options={countriesList}
              name="Country"
              placeholder="Country"
              classNamePrefix="select"
              defaultValue={countryChanged ? countryChanged : showCountryList()}
              value={countryChanged ? countryChanged : showCountryList()}
              onChange={(e) => {
                handleChangeCountry(e);
                setNewObjectUserData({ ...userData, countryId: e.value });
                // setUserData((userData) => ({
                //   ...userData,
                //   //   zipCode: "",
                //   phone: "",
                // }));
                setValue("ZIP", "");
                setValue("companyAddress", "");
                setValue("building", "");
                setValue("phone", "");
              }}
            />
            <div className="help-text">
              <p className="text"></p>
            </div>
          </div>

          <div className="sideByside">
            <div className="form-group sm">
              <Select
                className="select_react"
                styles={customStyles}
                options={citiesList}
                name="City"
                placeholder="City"
                classNamePrefix="select"
                defaultValue={cityChanged ? cityChanged : showCitiesList()}
                value={cityChanged ? cityChanged : showCitiesList()}
                onChange={(e) => {
                  handleChangeCity(e);
                  setNewObjectUserData({ ...userData, cityId: e.value });
                  setValue("ZIP", "");
                  setValue("companyAddress", "");
                  setValue("building", "");
                }}
              />
              <div className="help-text">
                <p className="text"></p>
              </div>
            </div>

            <div className="form-group">
              <div className={"input " + (userData?.zipCode && "populated")}>
                <MUITextField
                  name="ZIP"
                  type="text"
                  placeholder={"Zip / Postal code *"}
                  id="ZIP"
                  setcount={setcount}
                  className={`form-control ${errors?.ZIP ? "has-error" : ""}`}
                  errorMsg={errors?.ZIP?.message}
                  validateStatus={errors?.ZIP ? "error" : ""}
                  control={control}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="input populated">
              <MUITextField
                name="companyAddress"
                type="text"
                placeholder={"Your detailed address *"}
                id="companyAddress"
                className={`form-control ${errors?.companyAddress ? "has-error" : ""}`}
                errorMsg={errors?.companyAddress?.message}
                validateStatus={errors?.companyAddress ? "error" : ""}
                control={control}
                setcount={setcount}
              />
            </div>
            {/*<div className="help-text">*/}
            {/*    <p className="text"></p>*/}
            {/*</div>*/}
          </div>

          <div className="form-group">
            <div className="input populated">
              <MUITextField
                name="building"
                type="text"
                placeholder={"Building, floor, apartment"}
                id="building"
                className={`form-control ${errors?.building ? "has-error" : ""}`}
                errorMsg={errors?.building?.message}
                validateStatus={errors?.building ? "error" : ""}
                control={control}
                setcount={setcount}
              />
            </div>
            {/*<div className="help-text">*/}
            {/*    <p className="text"></p>*/}
            {/*</div>*/}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="form-group sm">
              <Select
                className="select_react"
                styles={customStylesCountryCode}
                options={countryCodeList}
                name="CountryCode"
                placeholder="CountryCode"
                classNamePrefix="select"
                defaultValue={countryCodeChanged ? countryCodeChanged : showCountryCodeList()}
                value={countryCodeChanged ? countryCodeChanged : showCountryCodeList()}
                onChange={(e) => {
                  handleChangeCountryCode(e);
                  setNewObjectUserData({ ...userData, countryCode: e.value });
                }}
              />
              {/*<div className="help-text">*/}
              {/*    <p className="text"></p>*/}
              {/*</div>*/}
            </div>

            <div className="form-group" style={{ width: "90%" }}>
              <div className={"input  " + (userData?.phone && "populated")}>
                <MUITextField
                  name="phone"
                  type="text"
                  placeholder={"Phone no.*"}
                  id="phone"
                  className={`form-control ${errors?.phone ? "has-error" : ""}`}
                  errorMsg={errors?.phone?.message}
                  validateStatus={errors?.phone ? "error" : ""}
                  control={control}
                  setcount={setcount}
                />
              </div>
            </div>
          </div>

          <div className="Signature">
            <div className="text">
              <h5 className="sh5-bold">E-Signature</h5>
              <p className="desc">
                Add an e-signature for your business. This signature will be applied to all business documents approved
                by you.
              </p>
            </div>
            <div className="form-group singnature_change">
              <div className="singnature_change__image">
                {signature ? (
                  <img src={URL.createObjectURL(signature)} className="img-fluid" alt="signature" />
                ) : userData?.signatureFile ? (
                  <img src={hostImg + userData?.signatureFile} className="img-fluid" alt="signature" />
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <img src={SignatureSVGDefault} alt="default" />
                    <p
                      style={{
                        fontFamily: "Raleway",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "rgba(16, 24, 32, 0.4)",
                        marginTop: "10px",
                      }}
                    >
                      Your signature will be shown here
                    </p>
                  </div>
                )}
              </div>
              <div className="singnature_change__content">
                <input
                  type="file"
                  className="inputfile"
                  id="companyPic"
                  name="companyPic"
                  onChange={(e) => {
                    setSignature(e.target.files[0]);
                    setNewObjectUserData({
                      ...userData,
                      signatureFile: e.target.files[0].name,
                    });
                  }}
                />
                <label htmlFor="companyPic" className="secondaryBtn mediumBtn btn">
                  Upload your signature
                </label>
                <p className="hint">Add a PNG, JPG (500x235px). The maximum size is 1MB.</p>
              </div>
            </div>
          </div>

          <div className="form_actions">
            <button className={`btn primaryBtn largeBtn ${!isAnyChanges ? "disabled" : ""}`} type="submit">
              {saving ? "saving..." : "Save changes"}
            </button>
          </div>
          {/* </form> */}
        </Form>
      </div>
      <ProfileCompletness />
    </div>
  );
};

export default UserSettings;
