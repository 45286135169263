import * as types from "../types";
import initialState from "../initialState";

export default function (state = initialState.registration, action) {
    switch (action.type) {
        case types.SET_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case types.User_Added:
            window.localStorage.setItem("cs", 2);
            window.localStorage.setItem("u", JSON.stringify(action.user));
            return {
                ...state,
                isLoading: false,
                currentStep: 2,
                verificationCode: "",
                user: action.user,
                isSaudia: action.user.isSaudia,
            };

        case types.User_Edited:
            window.localStorage.setItem("u", JSON.stringify(action.user));
            return {
                ...state,
                isLoading: false,
                verificationCode: "",
                user: action.user,
                isSaudia: action.user.isSaudia,
            };

        case types.ADD_NEW_COMPANY:
            window.localStorage.setItem("cs", 2);
            window.localStorage.setItem("u", JSON.stringify(action.user));
            return {
                ...state,
                isLoading: false,
                currentStep: 2,
                verificationCode: "",
                resend: true,
                user: action.user,
                isSaudia: action.user.isSaudia,
            };

        case types.User_Exist_Added:
            return {
                ...state,
                isLoading: false,
            };


        case types.Continue_Registration:
            let current = action.value
            return {
                ...state,
                currentStep: current,
                activeStep: current
            }


        case types.Change_Step:
            let currentStep = parseInt(state.currentStep);
            let activeStep = state.activeStep;
            if (activeStep < currentStep && action.value == 1) {
                activeStep = activeStep + action.value;
            } else if (
                activeStep == currentStep &&
                currentStep < parseInt(window.localStorage.getItem("cs")) &&
                action.value == 1
            ) {
                activeStep = activeStep + action.value;
                currentStep = currentStep + action.value;
            } else if (action.value == -1) {
                activeStep = activeStep + action.value;
                currentStep = activeStep;
            }
            return {
                ...state,
                isLoading: false,
                currentStep: currentStep,
                activeStep: activeStep,
            };

        case types.Resend_Code:
            return {
                ...state,
                isLoading: false,
            };

        case types.Verify_Email:
            window.localStorage.setItem("cs", parseInt(state.currentStep) + 1);
            return {
                ...state,
                isLoading: false,
                InvalidCode: false,
                currentStep: parseInt(state.currentStep) + 1,
            };
        case types.Verify_Transfered_Email:
            window.localStorage.setItem("cs", parseInt(state.currentStep) + 1);
            return {
                ...state,
                isLoading: false,
                InvalidCode: false,
                currentStep: parseInt(state.currentStep) + 1,
            };

        case types.Verify_Email_Invalid:
            return {
                ...state,
                InvalidCode: true,
                isLoading: false,
            };

        case types.Update_Redux_Store:
            return {
                ...state,
                isLoading: false,
                currentStep: parseInt(action.currentStep),
                user: action.user,
                company: action.company,
                prevSaved: action.prevSaved,
                companyId: action.companyId,
                isSaudia: action.isSaudia,
                verificationCode: action.verificationCode,
            };
        case types.Set_Tab_Headers:
            return {
                ...state,
                stepMainTitle: action.stepMainTitle,
                stepSubTitle: action.stepSubTitle,
                activeStep: action.activeStep,
            };

        case types.Company_Added:
            window.localStorage.setItem("cs", 4);
            return {
                ...state,
                isLoading: false,
                currentStep: 4,
                company: action.objectCompany,
                companyId: action.objectCompany.id,
            };

        case types.Company_Bank_Detail:
            window.localStorage.setItem("cs", 6);
            return {
                ...state,
                isLoading: false,
                currentStep: 6,
                activeStep: 6,
            };

        case types.TERMS_AND_POLICY:
            window.localStorage.setItem("cs", parseInt(state.currentStep) + 1);
            return {
                ...state,
                isLoading: false,
                currentStep: 7,
            };

        case types.FILE_UPLOAD:
            return {
                ...state,
                [action.fieldName]: action.file,
            };

        case types.Company_Details:
            window.localStorage.setItem("cs", parseInt(state.currentStep) + 1);
            window.localStorage.setItem(
                "Company_Details",
                JSON.stringify(action.companyDetails)
            );
            return {
                ...state,
                isLoading: false,
                currentStep: 5,
            };

        case types.Company_Summary:
            return {
                ...state,
                Company_Summary: action.payload,
            };

        case types.Set_Dashboard:
            // console.log(action.isDashboard, 'Set_Dashboard')
            return {
                ...state,
                isDashboard: action.isDashboard,
            };
        case types.Set_companyId:
            return {
                ...state,
                companyId: action.companyId,
            };
        case types.Set_TransferOwnerShip:
            window.localStorage.setItem(
                "isTransferOwnerShip",
                action.isTransferOwnerShip
            );
            return {
                ...state,
                isTransferOwnerShip: action.isTransferOwnerShip,
            };
        case types.Set_Representive_Flag:
            window.localStorage.setItem(
                "hasRepresentive",
                action.hasRepresentive
            );
            return {
                ...state,
                hasRepresentive: action.hasRepresentive,
            };
        case types.Set_RequestActivityId:
            return {
                ...state,
                requestActivityId: action.requestActivityId,
            };
        case types.Set_TransferedOwnerUser:
            window.localStorage.setItem("cs", 2);
            window.localStorage.setItem(
                "transferedOwnerUser",
                JSON.stringify(action.transferedOwnerUser)
            );
            window.localStorage.setItem(
                "transferedEmail",
                action.transferedOwnerUser.email
            );
            return {
                ...state,
                transferedOwnerUser: action.transferedOwnerUser,
            };
        case types.Set_RepresentativeDetails:
            window.localStorage.setItem(
                "representativeDetails",
                JSON.stringify(action.representativeDetails)
            );
            return {
                ...state,
                representativeDetails: action.representativeDetails,
            };
        case types.Set_transferedDone:
            window.localStorage.setItem(
                "isTranferedOwnerDone",
                action.isTranferedOwnerDone
            );
            return {
                ...state,
                isTranferedOwnerDone: action.isTranferedOwnerDone,
            };
        case types.Set_TransferModal:
            return {
                ...state,
                transferedModal: action.transferedModal,
            };
        default:
            return {...state};
    }
}
