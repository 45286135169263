import React from 'react';
import CastleSVG from '../../Styles/images/company_profile/icons/castle.svg';
import ReloadSVG from '../../Styles/images/company_profile/icons/reload.svg';
import FlagSVG from '../../Styles/images/company_profile/flag.svg';
import LocationFinancial from '../../Styles/images/company_profile/icons/location.svg';
import JobTitle from '../../Styles/images/company_profile/icons/joib_title.svg';
import RialSVG from '../../Styles/images/company_profile/icons/rial.svg';
import HashSVG from '../../Styles/images/company_profile/icons/hash.svg';
import SwiftSVG from '../../Styles/images/company_profile/icons/swift.svg';
import IbanSVG from '../../Styles/images/company_profile/icons/iban.svg';
import ComStateItem from '../ComStateItem/ComStateItem';

const FinancialDetails = ({dataInfoCompany}) => {
    return (
        <div className='general_box com_details'>
            <div className='main_info'>
                <div className='img'>
                    <img src={FlagSVG} className='img-fluid' alt='icon'/>
                </div>
                <div className='text'>
                    <h3 className='title'>Financial details</h3>
                    {
                        //   <p className='sh5-med'>
                        //   You can copy any certain info by hovering then clicking on the copy
                        //   button
                        // </p>
                    }
                </div>
            </div>

            <div className='sideByside'>
                <ComStateItem
                    srcImage={CastleSVG}
                    title='Bank'
                    description={dataInfoCompany?.financialDetails?.bankName ?? "-"}
                />

                <ComStateItem
                    srcImage={ReloadSVG}
                    title='Branch'
                    description={dataInfoCompany?.financialDetails?.branchName ?? "-"}
                />

                <ComStateItem
                    srcImage={LocationFinancial}
                    title='Country'
                    description={dataInfoCompany?.financialDetails?.countryName ?? "-"}
                />

                <ComStateItem
                    srcImage={JobTitle}
                    title='Beneficiary name'
                    description={dataInfoCompany?.financialDetails?.contactName ?? "-"}
                />

                <ComStateItem
                    srcImage={RialSVG}
                    title='Currency'
                    description={dataInfoCompany?.financialDetails?.currencyName ?? "-"}
                />

                <ComStateItem
                    srcImage={HashSVG}
                    title='Account no'
                    description={dataInfoCompany?.financialDetails?.accountNumber ?? "-"}
                />

                <ComStateItem
                    srcImage={SwiftSVG}
                    title='Swift code'
                    description={dataInfoCompany?.financialDetails?.swiftCode ?? "-"}
                />

                <ComStateItem
                    srcImage={IbanSVG}
                    title='IBAN'
                    description={dataInfoCompany?.financialDetails?.iban ?? "-"}
                />
            </div>
        </div>
    );
};

export default FinancialDetails;
