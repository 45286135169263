import React, { Fragment } from 'react';
import DocumentSVG from '../../Styles/images/company_profile/douments.svg';
import DocumentItem from './DocumentItem';

const CompanyDocuments = ({ dataInfoCompany }) => {
  return (
    <div className='general_box documents'>
      <div className='main_info'>
        <div className='img'>
          <img src={DocumentSVG} className='img-fluid' alt='icon' />
        </div>
        <div className='text'>
          <h3 className='title'>Company documents</h3>
          <p className='sh5-med'>
            You can view any certain file by clicking on it
          </p>
        </div>
      </div>

      <div className='sideByside'>
        {dataInfoCompany?.companyDocsUploads?.map((doc, index) => (
          <Fragment key={index} >
            {dataInfoCompany?.isSaudia ? <DocumentItem doc={doc} /> :
              doc?.title !== "Zakat certificate" && <DocumentItem key={index} doc={doc} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default CompanyDocuments;
