import React, {useEffect, useState} from "react";
import {Formik, Form, useFormik} from "formik";
// import pdf from "./../../public/Code_of_Condusct_signed.pdf"
import back from "../Styles/images/icons/arright-left-16.svg";
import * as Yup from "yup";
import {useSelector, useDispatch} from "react-redux";
import {
    AddTermsAndPolicy,
    SetTabHeaders,
} from "../Store/actions/registration";
import terms from "../Styles/images/terms_new.png";
import Warning from "../Styles/images/icons/Warning.svg";
import Previews from "../Components/UploadFile";

import config from "../IP_Configrations.json";

const host = config.static;
const download = config.download;

const StepSix = () => {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.registration.isLoading);
    const companyId = useSelector((state) => state.registration.companyId);
    const currentStep = useSelector((state) => state.registration.currentStep);

    const [alert, setAlert] = useState(false);
    const [load, setLoad] = useState(false);
    const validateTermsDetails = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            CodeOfConductFormSignitured: null,
            privacyPolicy: false,
            termsOfUse: false,
        },
        validationSchema: Yup.object().shape({
            privacyPolicy: Yup.boolean().oneOf([true], "Please Confirm"),
            termsOfUse: Yup.boolean().oneOf([true], "Please Confirm"),
            CodeOfConductFormSignitured: Yup.mixed().required(
                "Code of conduct form signature is required"
            ),
        }),
        onSubmit: (values, actions) => {
            if (
                window.localStorage.getItem(`CodeOfConductFormSignitured`) ===
                `uploadedSuccessfully`
            ) {
                dispatch(
                    AddTermsAndPolicy({
                        companyId: companyId,
                        terms: values.termsOfUse,
                        privacyPolicy: values.privacyPolicy,
                    })
                );
                window.scrollTo(0, 0);
            } else {
                setAlert(true);
            }
        },
    });

    useEffect(() => {
        const inputs = document.querySelectorAll(".input.large");

        inputs.forEach((input) => {
            input.addEventListener(
                "focusin",
                function () {
                    input.classList.add("focused");
                },
                false
            );
            input.addEventListener("focusout", function () {
            });
        });

        dispatch(
            SetTabHeaders(
                "Terms & Conditions",
                "As a final step in your vendor registration process, please read these documents carefully then approve it",
                currentStep
            )
        );
    }, [dispatch]);

    return (
        <div noValidate="novalidate" className="proForm">
            <div className="step4 step6">
                <div className="step_content">
                    <div className="company_terms">
                        <div className="img">
                            <img src={terms} className="img-fluid" alt="terms"/>
                        </div>
                        <div className="text">
                            <h3 className="h3-med">Code of conduct form</h3>
                            <p className="desc">
                                Please download our code of conduct form document, fill it out
                                and upload it back to us
                            </p>
                            <a
                                href={"/FGC-SuppliersCodeofConduct.pdf"}
                                target="_blank"
                                // download
                                // download="FGC-SuppliersCodeofConduct"
                                className="btn secondaryBtn mediumBtn"
                            >
                                Download
                            </a>
                        </div>
                    </div>

                    <div className="guid">
                        <div className="sec_head">
                            <h5 className="sh5-bold">How to use this document?</h5>
                            <p className="desc">
                                Please follow the below instructions below to fill-out our{" "}
                                <span className="t_bold">‘Code of conduct form document’</span>{" "}
                                successfully
                            </p>
                        </div>
                        <ul className="list-unstyled guid__list">
                            <li className="guid__list__item">
                                <span className="num">1</span>
                                <p className="text">
                                    Download the{" "}
                                    <span className="t_bold">‘Code of conduct form’</span>{" "}
                                    document from here ↑
                                </p>
                            </li>

                            <li className="guid__list__item">
                                <span className="num">2</span>
                                <p className="text">Open the document </p>
                            </li>

                            <li className="guid__list__item">
                                <span className="num">3</span>
                                <p className="text">Read the document carefully </p>
                            </li>

                            <li className="guid__list__item">
                                <span className="num">4</span>
                                <p className="text">Fill in your data </p>
                            </li>

                            <li className="guid__list__item">
                                <span className="num">5</span>
                                <p className="text">Add your signature </p>
                            </li>

                            <li className="guid__list__item">
                                <span className="num">6</span>
                                <p className="text">
                                    Upload the document back here ↓ after filling your data and
                                    signing it
                                </p>
                            </li>

                            <li className="guid__list__item">
                                <span className="num">7</span>
                                <p className="text">
                                    We’ll review your submission and send you a reply by approving
                                    or declining
                                </p>
                            </li>
                        </ul>
                    </div>

                    {/* <div className={"upload_box" } style={window.localStorage.getItem(`CodeOfConductFormSignitured`)!==`uploadedSuccessfully` ?{border:`1px solid red`} : {}}> */}
                    <div
                        className={"upload_box"}
                        style={alert === true ? {border: `1px solid red`} : {}}
                    >
                        <Previews
                            load={load}
                            setLoad={setLoad}
                            name="CodeOfConductFormSignitured"
                            id="CodeOfConductFormSignitured"
                            folderName="CodeOfConductFormSignitured"
                            docType="CodeOfConductFormSignitured"
                            companyId={companyId}
                            Api="UploadConductFile"
                            errors={validateTermsDetails.errors.CodeOfConductFormSignitured}
                            touched={validateTermsDetails.touched.CodeOfConductFormSignitured}
                            removeFile={() =>
                                validateTermsDetails.setFieldValue(
                                    "CodeOfConductFormSignitured",
                                    null
                                )
                            }
                            fileName={validateTermsDetails.values.CodeOfConductFormSignitured}
                            title="Code of conduct"
                            handleBlur={() =>
                                validateTermsDetails.setFieldTouched(
                                    "CodeOfConductFormSignitured"
                                )
                            }
                            handleChange={() =>
                                validateTermsDetails.setFieldValue(
                                    "CodeOfConductFormSignitured",
                                    "Done"
                                )
                            }
                        />
                    </div>

                    <div className="waring_box sh5-med">
                        <img src={Warning} className="img-fluid icon" alt="warning"/>
                        <span>
              Failure to provide the correct information will result in pending
              your submission
            </span>
                    </div>
                </div>
                <div className="terms_agree">
                    <div className="sec_head">
                        <h3 className="h3-med">Our policy and terms of use</h3>
                        <p className="desc secondary_text">
                            Please read the attached documents carefully, then check these
                            fields
                        </p>
                    </div>

                    <div className="form-group">
                        <div className="form-check">
                            <input
                                name="privacyPolicy"
                                onChange={validateTermsDetails.handleChange}
                                checked={validateTermsDetails.values.privacyPolicy || ""}
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault"
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                {" "}
                                I agree to FGC’s{" "}
                                <a href="/PrivacyPolicy" target="_blank" className="link">
                                    {" "}
                                    Privacy policy{" "}
                                </a>{" "}
                            </label>
                        </div>
                        <div className="help-text">
                            {validateTermsDetails.errors.privacyPolicy ? (
                                <p className="text has-error">
                                    {validateTermsDetails.errors.privacyPolicy}
                                </p>
                            ) : null}
                        </div>
                        <div className="form-check">
                            <input
                                name="termsOfUse"
                                onChange={validateTermsDetails.handleChange}
                                checked={validateTermsDetails.values.termsOfUse || ""}
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault2"
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault2">
                                {" "}
                                I agree to FGC’s{" "}
                                <a href="/TermsOfUse" target="_blank" className="link">
                                    {" "}
                                    Terms of use{" "}
                                </a>{" "}
                            </label>
                        </div>
                        <div className="help-text">
                            {validateTermsDetails.errors.termsOfUse ? (
                                <p className="text has-error">
                                    {validateTermsDetails.errors.termsOfUse}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="form_actions">
                {isLoading == true  || load === true? (
                    <button className="btn primaryBtn largeBtn disabled loading w-100">
                        Loading...
                    </button>
                ) : (
                    <button
                        type="submit"
                        onClick={() => {
                            validateTermsDetails.handleSubmit();
                        }}
                        className={"btn primaryBtn largeBtn  w-100"}
                    >
                        Save and next step
                    </button>
                )}
                <button className="btn textBtn largeBtn icon-l w-100">
                    <img src={back} className="icon" alt="arrow"/> Back
                </button>
            </div>
        </div>
    );
};

export default StepSix;
