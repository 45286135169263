/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, Fragment } from "react";
import axios from "axios";

import QRCode from "../Styles/images/company_profile/QRCode.png";
import HeaderCompanyProfile from "../Components/CompanyProfileComponents/HeaderCompanyProfile";
import SideSectionInfo from "../Components/CompanyProfileComponents/SideSectionInfo";
import MainCompanyInfo from "../Components/CompanyProfileComponents/MainCompanyInfo";
import CompanyInfo from "../Components/CompanyProfileComponents/CompanyInfo";
import FinancialDetails from "../Components/CompanyProfileComponents/FinancialDetails";
import CompanyDocuments from "../Components/CompanyProfileComponents/CompanyDocuments";
import Loading from "../Components/Loading/index";
import { useDispatch } from "react-redux";
import { ChangeLayout, SetTabHeaders } from "../Store/actions/registration";

import config from "../IP_Configrations.json";
import { useParams } from "react-router-dom";
const host = config.static;

const CompanyProfileShare = () => {
  const [companyData, setCompanyData] = useState();
  const userToken = localStorage.getItem("user_token");
  const [openModalChooseCompany, setOpenModalChooseCompany] = useState(false);
  const location = useParams();
  const dispatch = useDispatch();

  const [chosenCompany, setChosenCompany] = useState(window.localStorage.getItem("chosenCompany"));

  useEffect(() => {
    if (chosenCompany) {
      window.localStorage.setItem("toChooseCompany", true);
      setOpenModalChooseCompany(true);
    }
  }, [chosenCompany]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  useEffect(() => {
    // document.getElementsByClassName(".header").style.display = "none";
    const getCountriesCode = async () => {
      await axios
        .get(`${host}GetCountriesList`, {
          headers: {
            Authorization: userToken,
          },
        })
        .then((res) => {
          const countriesList = res.data.map((item) => {
            return {
              label: item.countryCode,
              value: item.id,
            };
          });

          setCountryCodeList(countriesList);
        })
        .catch((err) => console.log(err));
    };
    getCountriesCode();
  }, []);

  useEffect(() => {
    dispatch(ChangeLayout(true));
    dispatch(SetTabHeaders("Company details"));
    const divs = document.querySelectorAll(".setting_container.subNavOpen");

    divs.forEach((div) => {
      div.classList.remove("setting_container");
      div.classList.remove("subNavOpen");
    });

    const getCompanyProfileData = async () => {
      await axios
        .get(`${host}GetCompanyProfileForPublic?companyId=${location?.id}`, {
          headers: {},
        })
        .then((res) => {
          setCompanyData(res.data);
        })
        .catch((err) => console.log(err));
    };

    getCompanyProfileData();
  }, [location?.id]);

  return (
    <Fragment>
      {!companyData || openModalChooseCompany ? (
        <Loading />
      ) : (
        <section className="company_profile_page">
          <HeaderCompanyProfile />

          <div className="custom_container">
            <div className="sec_container">
              <SideSectionInfo companyInfoData={companyData} countryCodeList={countryCodeList} />

              <div className="company_details">
                <MainCompanyInfo mainInfo={companyData} />

                <CompanyInfo dataInfo={companyData} />

                <FinancialDetails dataInfoCompany={companyData} />

                <CompanyDocuments dataInfoCompany={companyData} />
              </div>
            </div>

            <div className="qrcode text-center">
              <img src={QRCode} className="img-fluid" alt="qr" />
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default CompanyProfileShare;
