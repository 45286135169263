import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";

import config from "../../../../IP_Configrations.json";
import UploadFile from "../../../UploadFile";

import checkmarkedCircle_20 from "../../../../Styles/images/icons/checkmarkedCircle-20.svg";
import attached_file from "../../../../Styles/images/settings/attached_file.svg";
import Calendar_disabled from "../../../../Styles/images/icons/Calendar_disabled.svg";
import calendar from "../../../../Styles/images/icons/calendar.svg";
import Warning_yellow_black from "../../../../Styles/images/icons/Warning_yellow_black.svg";
import Warning_20 from "../../../../Styles/images/icons/Warning_20.svg";
import Warning_white_24 from "../../../../Styles/images/icons/Warning_white_24.svg";
import DataServices from "../../../../Services/DataServices";

const numRegex = /^[0-9]+$/;
const animateInputs = () => {
  const inputs = document.querySelectorAll(".input");
  inputs.forEach((input) => {
    input.addEventListener(
      "focusin",
      function (e) {
        input.classList.add("focused");
      },
      false
    );
    input.addEventListener("focusout", function (e) {
      if (e.target.value == "") {
        input.classList.remove("focused");
      }
    });
  });
};

function CRNumSection({ CompanyDocument, SaveChanges, Saving, companyId }) {
  const [Changed, setChanged] = useState(false);
  const [Edit, setEdit] = useState(false);

  useEffect(() => {
    if (CompanyDocument?.commercialRegisterNoExpireDate) {
      const date1 = new Date();
      const date2 = new Date(CompanyDocument?.commercialRegisterNoExpireDate);
      const diffTime = date2 - date1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 1) {
        setEdit(`${diffDays}`);
      }
    }
  }, [CompanyDocument?.commercialRegisterNoExpireDate]);

  const validateCRNum = useFormik({
    enableReinitialize: true,
    initialValues: {
      commercialRegisterNo: Edit ? "" : CompanyDocument?.commercialRegisterNo,
      commercialRegisterNoExpireDate: Edit
        ? null
        : CompanyDocument?.commercialRegisterNoExpireDate,
      commercialRegisterNoFile: Edit
        ? null
        : CompanyDocument?.commercialRegisterNoFile,
      edit: Edit,
    },
    validationSchema: Yup.object().shape({
      commercialRegisterNo: CompanyDocument?.isSaudia
        ? Yup.string()
            .matches(
              numRegex,
              "Invalid company commercial registration number. Please ensure that the number consists of 10 digits with no spaces or other characters"
            )
            .test(
              "len",
              "Invalid company commercial registration number. Please ensure that the number consists of 10 digits with no spaces or other characters",
              (val) => val.length === 10
            )
            .required("Company commercial registration no. is required")
        : Yup.string()
            .matches(
              numRegex,
              "Invalid company commercial registration number. Please ensure that the number consists of digits with no spaces or other characters"
            )
            .required("Company commercial registration no. is required"),
      commercialRegisterNoExpireDate: Yup.date()
        .required(
          "Commercial registration certificate expiration date is required"
        )
        .typeError("Invalid commercial registration date."),
      commercialRegisterNoFile: Yup.mixed().required(
        "Commercial Register file copy is required"
      ),
    }),

    onSubmit: (values) => {
      SaveChanges(values, "Commercial registration");
      validateCRNum.setFieldValue("edit", false);
      setChanged(false);
    },
  });

  useEffect(() => {
    animateInputs();
  }, []);

  const [Warn, setWarn] = useState({});
  const [Load, setLoad] = useState(false);
  const _calendar = useRef();

  const [CheckCRNAvailability, setCheckCRNAvailability] = useState(false);

  const handlerCRNBlur = async (e) => {
    const num = e.target.value;
    if (num) {
      setCheckCRNAvailability(true);
      DataServices.GetRowById(`CheckCRNAvailability?CRNNumber=` + num).then(
        async (res) => {
          if (res === false) {
            setCheckCRNAvailability(false);
            await validateCRNum.setFieldTouched("commercialRegisterNo", true);
            await validateCRNum.setFieldError(
              "commercialRegisterNo",
              "this company registration license is already registered by another company"
            );
          } else {
            setCheckCRNAvailability(false);
            validateCRNum.setFieldTouched("commercialRegisterNo", true);
          }
        }
      );
    } else {
      await validateCRNum.setFieldTouched("commercialRegisterNo", true);
      await validateCRNum.setFieldError(
        "commercialRegisterNo",
        "Company commercial registration no. is required",
        true
      );
    }
  };

  const openDatepicker = () => _calendar.current.setOpen(true);
  return (
    <div className="content">
      <div className="sub_head">
        <div className="top">
          <h4 className="title">
            <span>
              {CompanyDocument?.isSaudia
                ? "Commercial Registration number*"
                : "Registration number*"}
            </span>
            {Edit ? (
              <img src={Warning_20} className="img-fluid icon" alt="info" />
            ) : validateCRNum.values.edit ? (
              ""
            ) : (
              <img
                src={checkmarkedCircle_20}
                className="img-fluid icon"
                alt="checked"
              />
            )}
          </h4>
          {!validateCRNum.values.edit && (
            <span
              className="edit"
              role="button"
              onClick={() => {
                validateCRNum.setFieldValue("edit", true);
              }}
            >
              Edit
            </span>
          )}
        </div>
        <p className="desc">
          {CompanyDocument?.isSaudia
            ? "Company Commercial registration number information will be included as an FGC registered vendor"
            : "Company registration number information will be included as an FGC registered vendor"}
        </p>
      </div>
      {Edit && (
        <div className="waring_box sm sh5-med">
          <img
            src={Warning_white_24}
            className="img-fluid icon"
            alt="warning"
          />
          <span>
            Your ‘Company commercial registration’ expired {Edit} days ago,
            please update your document
          </span>
        </div>
      )}
      <div className="sideByside">
        <div className="form-group">
          <div
            className={
              "input " +
              (validateCRNum.values.commercialRegisterNo ? "populated" : "")
            }
          >
            <label className="control-label" htmlFor="commercialRegisterNo">
              {CompanyDocument?.isSaudia
                ? "Company Commercial Registration number*"
                : "Registration number*"}
            </label>
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control " +
                (validateCRNum.errors.commercialRegisterNo &&
                  validateCRNum.touched.commercialRegisterNo &&
                  "has-error")
              }
              disabled={validateCRNum.values.edit ? false : true}
              value={validateCRNum.values?.commercialRegisterNo}
              onChange={(e) => {
                validateCRNum.handleChange(e);
                setChanged(true);
              }}
              onBlur={handlerCRNBlur}
              id="commercialRegisterNo"
              name="commercialRegisterNo"
            />
            {CheckCRNAvailability && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div>
          <div className="help-text">
            {validateCRNum.values.edit && (
              <ul>
                {validateCRNum.errors.commercialRegisterNo &&
                validateCRNum.touched.commercialRegisterNo ? (
                  <p className="text has-error">
                    {validateCRNum.errors.commercialRegisterNo}
                  </p>
                ) : CompanyDocument?.isSaudia ? (
                  <p className="text">Must be 10 numbers</p>
                ) : null}
              </ul>
            )}
          </div>
        </div>

        <div className="form-group">
          {validateCRNum.values.edit ? (
            <>
              <div className="input populated date">
                <label
                  className="control-label date"
                  htmlFor="commercialRegisterNoExpireDate"
                >
                  {CompanyDocument?.isSaudia
                    ? "CR certificate expiration date*"
                    : "Registration license expiration date*"}
                </label>
                <DatePicker
                  ref={(c) => (_calendar.current = c)}
                  placeholderText="Please select expiration date"
                  dateFormat={"dd/MM/yyyy"}
                  autoComplete="off"
                  showYearDropdown
                  className={
                    "form-control " +
                    (validateCRNum.errors.commercialRegisterNoExpireDate &&
                      validateCRNum.touched.commercialRegisterNoExpireDate &&
                      "has-error")
                  }
                  id="commercialRegisterNoExpireDate"
                  name="commercialRegisterNoExpireDate"
                  selected={
                    new Date(
                      validateCRNum.values?.commercialRegisterNoExpireDate
                    )
                  }
                  onChange={(date) => {
                    setWarn({});
                    const date1 = new Date();
                    const date2 = new Date(date);
                    const diffTime = date2 - date1;
                    const diffDays = Math.ceil(
                      diffTime / (1000 * 60 * 60 * 24)
                    );
                    if (diffDays < 1) {
                      setWarn({
                        expiredCommercialRegisterNoExpireDate: true,
                      });
                    } else if (diffDays < 30) {
                      setWarn({
                        failCommercialRegisterNoExpireDate: diffDays,
                      });
                    } else {
                      if (diffDays < 90) {
                        setWarn({
                          warnCommercialRegisterNoExpireDate: diffDays,
                        });
                      }
                      validateCRNum.setFieldValue(
                        "commercialRegisterNoExpireDate",
                        date
                      );
                      setChanged(true);
                    }
                  }}
                  onBlur={validateCRNum.handleBlur}
                />
                <img
                  src={calendar}
                  className="img-fluid right_icon"
                  alt="calendar"
                  onClick={openDatepicker}
                />
              </div>
              <div className="help-text">
                <ul>
                  {validateCRNum.errors.commercialRegisterNoExpireDate &&
                  validateCRNum.touched.commercialRegisterNoExpireDate ? (
                    <p className="text  has-error">
                      {validateCRNum.errors.commercialRegisterNoExpireDate}
                    </p>
                  ) : Warn.expiredCommercialRegisterNoExpireDate ? (
                    <p className="text has-error">{`this document expired, please renew your certificate and re-register`}</p>
                  ) : Warn.failCommercialRegisterNoExpireDate ? (
                    <p className="text has-error">{`this document Will expire in ${Warn.failCommercialRegisterNoExpireDate}  days, expiration date must be at least 30 days beyond the registration date, please renew your certificate and re-register`}</p>
                  ) : Warn.warnCommercialRegisterNoExpireDate ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={Warning_yellow_black}
                        className="img-fluid right_icon2"
                        alt=""
                        style={{ width: "14px", marginRight: "5px" }}
                      />
                      <p className="text" style={{ color: "#000" }}>
                        {`this document Will expire soon in ${Warn.warnCommercialRegisterNoExpireDate} days, don’t forget to renew it soon.`}
                      </p>
                    </div>
                  ) : (
                    <p className="text">
                      Must be valid, with an expiration date at least 30 days
                      beyond the registration date.
                    </p>
                  )}
                </ul>
              </div>
            </>
          ) : (
            <div className="input populated date">
              <label className="control-label" htmlFor="expiration">
                {CompanyDocument?.isSaudia
                  ? "CR certificate expiration date*"
                  : "Registration license exp. date*"}
              </label>
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                disabled
                value={new Date(
                  validateCRNum.values?.commercialRegisterNoExpireDate
                ).toLocaleDateString("en-GB")}
                id="expiration"
                name="expiration"
              />
              <img
                src={Calendar_disabled}
                className="img-fluid right_icon "
                alt="calendar"
              />
            </div>
          )}
          <div className="help-text">
            <p className="text"></p>
          </div>
        </div>
      </div>
      {validateCRNum.values.edit && (
        <div className="upload_box">
          <h6 className="sh6-med">
            {CompanyDocument?.isSaudia
              ? "Upload a copy of company commercial registration license"
              : "Upload a copy of company registration license"}
          </h6>
          <UploadFile
            className=" sm "
            load={Load}
            setLoad={setLoad}
            fieldName="commercialRegisterNoFile"
            title="CR#"
            removeFile={() =>
              validateCRNum.setFieldValue("commercialRegisterNoFile", null)
            }
            errors={validateCRNum.errors.commercialRegisterNoFile}
            touched={validateCRNum.touched.commercialRegisterNoFile}
            fileName={
              validateCRNum.values.commercialRegisterNoFile?.split("/")[4] ||
              validateCRNum.values.commercialRegisterNoFile
            }
            folderName="CRN"
            docType="CRN"
            companyId={companyId}
            Api="UploadFiles"
            handleBlur={() =>
              validateCRNum.setFieldTouched("commercialRegisterNoFile")
            }
            handleChange={() => {
              validateCRNum.setFieldValue("commercialRegisterNoFile", "Done");
              setChanged(true);
            }}
          />
        </div>
      )}
      {!validateCRNum.values.edit && (
        <div className="uploaded_file">
          <Link
            target="_blank"
            to={
              CompanyDocument?.commercialRegisterNoFile && config.download
                ? config.download + CompanyDocument?.commercialRegisterNoFile
                : ""
            }
            className="file"
          >
            <img src={attached_file} className="img-fluid icon" alt="icon" />
            <span>
              {CompanyDocument?.commercialRegisterNoFile?.split("/")[4]}
            </span>
          </Link>
          <span className="hint">You can view the file by clicking on it</span>
        </div>
      )}
      {validateCRNum.values.edit && (
        <div className="form_actions d-flex">
          <button
            className={
              "btn primaryBtn largeBtn " +
              (!Changed || Saving ? "disabled" : "")
            }
            type="button"
            onClick={validateCRNum.handleSubmit}
          >
            {Saving ? "Saving..." : "Save changes"}
          </button>
          <button
            className={"btn secondaryBtn largeBtn mx-3"}
            type="button"
            onClick={() => {
              validateCRNum.setFieldValue("edit", false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}

export default CRNumSection;
