import React from 'react';
import { useNavigate } from 'react-router-dom';

import ShareImg from '../../Styles/images/dashboard/dash.png';

const DashboardLastChild = () => {
  const navigate = useNavigate();

  return (
    <div className='share_sec'>
      <div className='text'>
        <h5 className='sh5-bold'>Your company details card</h5>
        <h2 className='title'>Share it anywhere</h2>
        <h6 className='sh5-bold'>FGC’s vendor engagement tools</h6>
        <h6 className='sh6-med'>
          FGC offers it’s vendors a simple tools to share their company details
          and documents with their customers in an elegant and smart way
        </h6>
        <button
          className='btn secondaryBtn largeBtn'
          onClick={() => navigate('/CompanyDetails')}
        >
          Explore it now{' '}
        </button>
      </div>
      <div className='img'>
        <img src={ShareImg} className='img-fluid' alt='img' />
      </div>
    </div>
  );
};

export default DashboardLastChild;
