import axios from 'axios';
import config from '../IP_Configrations.json'
const host = config.static;
const userToken = localStorage.getItem("user_token");



export const axiosApi = axios.create({
    baseURL: host
});
// For Auth
// axiosApi.defaults.headers.common["Authorization"] = `Bearer ${cookie.load('auction_token')}`;


export const axiosLoginApi = axios.create({
    baseURL: host
});
const API = axios.create({
    baseURL: host,
    headers: {
        'Authorization': `${userToken ? userToken.replace(/"/g, '') : ''}`,
        'Content-type': 'application/json',
        Accept: '*/*',
        "Access-Control-Allow-Credentials": true,
    },
});


export default API;