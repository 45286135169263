/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, Fragment } from "react";
import axios from "axios";

//import QRCode from "../Styles/images/company_profile/QRCode.png";
import HeaderCompanyProfile from "../Components/CompanyProfileComponents/HeaderCompanyProfile";
import SideSectionInfo from "../Components/CompanyProfileComponents/SideSectionInfo";
import MainCompanyInfo from "../Components/CompanyProfileComponents/MainCompanyInfo";
import CompanyInfo from "../Components/CompanyProfileComponents/CompanyInfo";
import FinancialDetails from "../Components/CompanyProfileComponents/FinancialDetails";
import CompanyDocuments from "../Components/CompanyProfileComponents/CompanyDocuments";
import Loading from "../Components/Loading/index";
import { useSelector, useDispatch } from "react-redux";
import { ChangeLayout, SetTabHeaders } from "../Store/actions/registration";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import CloseSVG from "../Styles/images/icons/popup_close.svg";
import EPCSVG from "../Styles/images/epic.png";
import CompanyProfileDownload from "./CompanyProfileDownload";
import config from "../IP_Configrations.json";
import SideSectionInfoDownload from "../Components/CompanyProfileComponents/SideSectionInfoDownload";
import QRCode from "react-qr-code";
import ModalContext from "../Store/ModalProvider";
import { useContext } from "react";

const host = config.static;

const CompanyProfile = () => {
  const [companyData, setCompanyData] = useState();
  //const CompanyId = localStorage.getItem("CompanyId");
  const CompanyId = useSelector((state) => state.registration.companyId) || localStorage.getItem("CompanyId");
  const userToken = localStorage.getItem("user_token");
  const [openModalChooseCompany, setOpenModalChooseCompany] = useState(false);

  const dispatch = useDispatch();

  const [chosenCompany, setChosenCompany] = useState(window.localStorage.getItem("chosenCompany"));
  const [CompanyList, setCompanyList] = useState();
  const { setModalComponent, setModalOpen, modalOpen, chooseCompany, setchooseCompany } = useContext(ModalContext);

  useEffect(() => {
    if (chooseCompany) {
      window.localStorage.setItem("toChooseCompany", false);
      setchooseCompany(false);
      setModalOpen(true);
    }
  }, []);
  // useEffect(() => {
  //   if (chosenCompany) {
  //     window.localStorage.setItem("toChooseCompany", true);
  //     setOpenModalChooseCompany(true);
  //   }
  // }, [chosenCompany]);
  const [countryCodeList, setCountryCodeList] = useState([]);

  useEffect(() => {
    const getCountriesCode = async () => {
      await axios
        .get(`${host}GetCountriesList`, {
          headers: {
            Authorization: userToken,
          },
        })
        .then((res) => {
          const countriesList = res.data.map((item) => {
            return {
              label: item.countryCode,
              value: item.id,
            };
          });

          setCountryCodeList(countriesList);
        })
        .catch((err) => console.log(err));
    };
    getCountriesCode();
  }, []);

  useEffect(() => {
    const getCompaniesList = async () => {
      if (userToken) {
        axios
          .get(`${host}GetCompaniesForList`, {
            headers: {
              Authorization: userToken,
            },
          })
          .then((res) => {
            setCompanyList(res.data.items);
          })
          .catch((err) => console.log(err));
      }
    };

    getCompaniesList();
  }, [userToken]);

  useEffect(() => {
    console.log("CompanyId", CompanyId);
    dispatch(ChangeLayout(true));
    dispatch(SetTabHeaders("Company details"));
    const divs = document.querySelectorAll(".setting_container.subNavOpen");

    divs.forEach((div) => {
      div.classList.remove("setting_container");
      div.classList.remove("subNavOpen");
    });

    const getCompanyProfileData = async () => {
      await axios
        .get(`${host}GetCompanyProfile?companyId=${CompanyId}`, {
          headers: {
            Authorization: userToken,
          },
        })
        .then((res) => {
          setCompanyData(res.data);
        })
        .catch((err) => console.log(err));
    };

    getCompanyProfileData();
  }, [CompanyId]);
  const [isPrint, setisPrint] = useState(false);
  const downloadPDF = () => {
    setisPrint(true);
  };
  var currentUrlWithoutParams = window.location.href.split("?")[0];
  const sharCompanyUrl = `${currentUrlWithoutParams.replace("CompanyDetails", "")}CompanyShare/${CompanyId}`;

  async function copyToClipboard(url) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(url);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = url;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand("copy");
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  }

  const handleSharePage = () => {
    copyToClipboard(sharCompanyUrl);
    toast.success("Link copied");
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  const handleQrScan = () => {
    const url = `${currentUrlWithoutParams.replace("CompanyDetails", "")}CompanyShare`;
    window.open(url, "_blank", "noopener,noreferrer");
    //copyToClipboard(url);
    //toast.success("Link copied");
  };

  return (
    <Fragment>
      {!companyData || openModalChooseCompany ? (
        <Loading />
      ) : (
        <section className="company_profile_page">
          <HeaderCompanyProfile />

          <div className="custom_container">
            {
              <ul className="list-unstyled share_btns" style={{ marginTop: "2rem" }}>
                <li>
                  <span className="btn link" onClick={handleSharePage}>
                    Share
                  </span>
                </li>
                <li>
                  <span href="#" className="btn link" onClick={downloadPDF}>
                    Download
                  </span>
                </li>
              </ul>
            }

            <div className="sec_container">
              <SideSectionInfo companyInfoData={companyData} countryCodeList={countryCodeList} />

              <div className="company_details">
                <MainCompanyInfo mainInfo={companyData} />

                <CompanyInfo dataInfo={companyData} />

                <FinancialDetails dataInfoCompany={companyData} />

                <CompanyDocuments dataInfoCompany={companyData} />
              </div>
            </div>

            <div className="qrcode text-center">
              {/* <img src={QRCode} className="img-fluid" alt="qr" /> */}
              <h3 style={{ marginBottom: "10px" }}>Scan & Share</h3>
              <QRCode
                size={150}
                style={{ height: "150", maxWidth: "100%", width: "100%" }}
                value={sharCompanyUrl}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
          <CompanyProfileDownload isPrint={isPrint} setisPrint={setisPrint} />
        </section>
      )}
      {openModalChooseCompany && (
        <div className="popup active popup_contact_support change_company_account">
          <div className="popup__content">
            <div className="popup__body">
              <div className="head">
                <h5 className="sh5-bold">Select a company</h5>
                <span
                  className="close"
                  onClick={() => {
                    window.localStorage.removeItem("toChooseCompany");
                    window.localStorage.removeItem("chosenCompany");
                    setOpenModalChooseCompany(false);
                  }}
                >
                  <img src={CloseSVG} className="img-fluid" alt="close" />
                </span>
              </div>
              <div className="content">
                <p className="desc">Please select a company to view its settings:</p>
                <div className="avatars">
                  {CompanyList?.map((item) => (
                    <div
                      className="avatar"
                      key={item.id}
                      onClick={() => {
                        window.localStorage.setItem("CompanyId", item.id);
                        window.localStorage.removeItem("toChooseCompany");
                        window.localStorage.removeItem("chosenCompany");
                        setOpenModalChooseCompany(false);
                      }}
                    >
                      <div className="img">
                        {item?.logo ? (
                          <img className="avatar" src={config.download + item?.logo} alt="Logo" />
                        ) : (
                          <div className="">{item?.companyName?.length ? item?.companyName[0].toUpperCase() : "E"}</div>
                        )}
                      </div>
                      <h6 className="sh6-med">{item?.companyName}</h6>
                      {item?.vendorId && <p className="num">Vendor# {item.vendorId}</p>}
                    </div>
                  ))}
                </div>
              </div>
              <div className="footer_actions">
                <button
                  className="btn primaryBtn mediumBtn"
                  onClick={() => {
                    window.localStorage.removeItem("toChooseCompany");
                    window.localStorage.removeItem("chosenCompany");
                    setOpenModalChooseCompany(false);
                  }}
                >
                  {" "}
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CompanyProfile;
