import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import transferOwner from "../../../../Styles/images/icons/Warning_blackBG_24.svg";
import checkmarkedCircle_32 from "../../../../Styles/images/icons/checkmarkedCircle-32.svg";
import DataServices from "../../../../Services/DataServices";
import {toast} from "react-toastify";
import {
    SetLoading,
    SetTabHeaders,
    SetTransferedModal
} from "../../../../Store/actions/registration";
import {useSelector, useDispatch} from "react-redux";
import ProfileCompletness from "../../../ProfileCompletness/ProfileCompletness";
import VerifyModal from "./VerifyModal";
import axios from "axios";
import config from "../../../../IP_Configrations.json";
import TransferOwnerShipForm from "../../../../TransferOwnerShip/TransferOwnerShipForm";


const host = config.static;

function ContactPerson() {
    const userToken = localStorage.getItem("user_token");

    const phoneNumbersRegex = /^[+]\d*$/;

    const blackListedDomains = [
        "facebook",
        "google",
        "amazon",
        "netflix",
        "hotmail",
        "microsoft",
        "gmail",
        "yahoo",
    ];
    const dispatch = useDispatch();

    const [Saving, setSaving] = useState(false);

    const [ShowVerify, setShowVerify] = useState(false);

    const companyId = localStorage.getItem("CompanyId");
    const [UserInfo, setUserInfo] = useState({});

    const [Loading, setLoading] = useState(false);
    const [LoadingVerify, setLoadingVerify] = useState(false);
    const [Changed, setChanged] = useState(false);

    const [countryCodeList, setCountryCodeList] = useState([]);
    const transferedModal = useSelector((state) => state.registration.transferedModal);


    const validationUSerInfo = useFormik({
        validateOnMount: true,
        enableReinitialize: true,
        initialValues: {
            otherEmail: UserInfo?.otherEmail || "",
            otherPhone: UserInfo?.otherPhone || "",
            isEmail: UserInfo?.isEmail || false,
            sms: UserInfo?.sms || false,
            phoneCall: UserInfo?.phoneCall || false,
        },

        validationSchema: Yup.object().shape({
            otherEmail: Yup.string()
                .email("Not valid email address")
                .required("Your work email address is required"),
            // .test("is-not-blacklist", "Not accepted email address , must be a work email address, usually your company email", (value) => {
            //   if (value) {
            //     const currentDomain = value.substring(value.indexOf("@") + 1, value.indexOf("."));
            //     return !blackListedDomains.includes(currentDomain);
            //   }
            // })
            otherPhone: Yup.string()
                .matches(
                    phoneNumbersRegex,
                    "other phone must start with + and then enter the phone"
                )
                .test(
                    "len",
                    "This phone no. is not correct, must be not less than 9 digits or more than 14 digits",
                    (val) => val.length >= 9 && val.length <= 14
                )
                .required(" Your phone no. is required"),

            // isEmail: Yup.boolean().oneOf([true], 'Please Confirm'),
            // sms: Yup.boolean().oneOf([true], 'Please Confirm'),
            // phoneCall: Yup.boolean().oneOf([true], 'Please Confirm'),
        }),
        onSubmit: (values, actions) => {
            setLoading(true);
            setSaving(true);

            DataServices.addObject("UpdateContactPersonForEdit", UserInfo).then(
                () => {
                    toast.success("Contact Person Saved", {hideProgressBar: true});
                    setSaving(false);

                    setLoading(false);
                    setChanged(false);
                }
            );
            actions.setSubmitting(false);
        },
    });

    const animateInputs = () => {
        const inputs = document.querySelectorAll(".input");
        inputs.forEach((input) => {
            input.addEventListener(
                "focusin",
                function (e) {
                    input.classList.add("focused");
                },
                false
            );
            input.addEventListener("focusout", function (e) {
                if (e.target.value == "") {
                    input.classList.remove("focused");
                }
            });
        });
    };
    useEffect(() => {
        const getCountriesCode = async () => {
            await axios
                .get(`${host}GetCountriesList`, {
                    headers: {
                        Authorization: userToken,
                    },
                })
                .then((res) => {
                    const countriesList = res.data.map((item) => {
                        return {
                            label: item.countryCode,
                            value: item.id,
                        };
                    });

                    setCountryCodeList(countriesList);
                })
                .catch((err) => console.log(err));
        };
        getCountriesCode();
    }, []);
    const showCountryCodeList = () => {
        return countryCodeList.find(
            (option) => option.value === UserInfo?.accountCountryId
        );
    };

    useEffect(() => {
        animateInputs();

        dispatch(SetTabHeaders("Contact Person"));

        DataServices.GetRowById(
            `GetContactPersonForEdit?companyId=${companyId}`
        ).then((res) => {
            setUserInfo(res);
        });
    }, []);

    const ChangeUserInfo = (e) => {
        setChanged(true);
        setUserInfo((UserInfo) => ({
            ...UserInfo,
            [e.target.name]: e.target.value,
        }));
        handleChange(e);
    };

    const handleChangeCheckBox = (e, field) => {
        setChanged(true);

        let fieldValue = UserInfo[field];

        setUserInfo((UserInfo) => ({
            ...UserInfo,
            [field]: !fieldValue,
        }));
        handleChange(e);
    };
    const updateRecoveryEmail = ()=>{
        setUserInfo((UserInfo) => ({...UserInfo, verifyRecoveryEmail: true}))
    }
    const handleVerify = () => {
        setLoadingVerify(true);
        DataServices.addObject(`SendVerificationCodeForRecoveryEmail`, {
            companyId: companyId,
        }).then((res) => {
            setLoadingVerify(false);
            setShowVerify(true);
        });
    };

    const openTransferModal = () => {
        dispatch(SetTransferedModal(true))
    }

    const {
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        isValid,
        values,
    } = validationUSerInfo;

    return (
        transferedModal ? <TransferOwnerShipForm/>
            :
            <div className="company_info  contact_person">
                <div className="left_sec">
                    <div className="ownership">
                        <div className="waring_box sm black sh5-med">
                            <img src={transferOwner} className="img-fluid icon" alt="warning"/>
                            <span>Contact person is the account owner</span>
                        </div>
                        <button className="btn mediumBtn textBtn" onClick={() => openTransferModal()}>Transfer
                            ownership
                        </button>
                    </div>

                    <form className="proForm">
                        <div className="sideByside">
                            <div className="form-group">
                                <div
                                    className={`input ${
                                        UserInfo?.firstName !== `` ? `focused` : ""
                                    }`}
                                >
                                    <label className="control-label" htmlFor="firstName">
                                        {" "}
                                        First Name{" "}
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className="form-control "
                                        value={UserInfo?.firstName}
                                        disabled
                                        id="firstName"
                                        name="firstName"
                                    />
                                </div>
                                <div className="help-text">
                                    <p className="text"></p>
                                </div>
                            </div>
                            <div className="form-group">
                                <div
                                    className={`input ${
                                        UserInfo?.lastName !== `` ? `focused` : ""
                                    }`}
                                >
                                    <label className="control-label" htmlFor="lastName">
                                        {" "}
                                        Last Name{" "}
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className="form-control "
                                        value={UserInfo?.lastName}
                                        disabled
                                        id="lastName"
                                        name="lastName"
                                    />
                                </div>
                                <div className="help-text">
                                    <p className="text"></p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className={`input ${UserInfo?.email !== `` ? `focused` : ""}`}>
                                <label className="control-label" htmlFor="email">
                                    {" "}
                                    Work Email Address{" "}
                                </label>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    className="form-control "
                                    value={UserInfo?.email}
                                    disabled
                                    id="email"
                                    name="email"
                                />
                            </div>
                            <div className="help-text">
                                <p className="text"></p>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className={`input ${UserInfo?.phone !== `` ? `focused` : ""}`}>
                                <label className="control-label" htmlFor="phone">
                                    {" "}
                                    Phone no.{" "}
                                </label>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    className="form-control "
                                    value={showCountryCodeList()?.label + UserInfo?.accountPhone}
                                    disabled
                                    id="phone"
                                    name="phone"
                                />
                            </div>
                            <div className="help-text">
                                <p className="text"></p>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className={`input ${UserInfo?.Ext !== `` ? `focused` : ""}`}>
                                <label className="control-label" htmlFor="Ext">
                                    {" "}
                                    Ext{" "}
                                </label>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    className="form-control "
                                    value={UserInfo?.extPhone}
                                    disabled
                                    id="Ext"
                                    name="Ext"
                                />
                            </div>
                            <div className="help-text">
                                <p className="text"></p>
                            </div>
                        </div>

                        <div className="verifiy">
                            <div className="form-group">
                                <div
                                    className={`input ${
                                        UserInfo?.otherEmail !== `` ? `focused` : ""
                                    }`}
                                >
                                    <label className="control-label" htmlFor="otherEmail">
                                        Other email address* (for recovery issues)
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.otherEmail && touched.otherEmail
                                                ? " has-error"
                                                : "")
                                        }
                                        value={UserInfo?.otherEmail}
                                        onChange={ChangeUserInfo}
                                        onBlur={handleBlur}
                                        id="otherEmail"
                                        name="otherEmail"
                                    />
                                </div>
                                <div className="help-text">
                                    {errors.otherEmail && touched.otherEmail && (
                                        <p className="text has-error">{errors.otherEmail}</p>
                                    )}
                                </div>
                            </div>
                            {UserInfo?.verifyRecoveryEmail ? (
                                <div className="verified done">
                                    <p className="title">Verified</p>
                                    <div className="img_done">
                                        <img
                                            src={checkmarkedCircle_32}
                                            className="img-fluid icon"
                                            alt="Verified"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="verified">
                                    <p className="title">Not verified yet</p>
                                    <button
                                        className={
                                            "btn smallBtn secondaryBtn " + (LoadingVerify && "disabled")
                                        }
                                        type="button"
                                        onClick={handleVerify}
                                    >
                                        {LoadingVerify ? "Loading ..." : "Verify now"}
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <div
                                className={`input ${
                                    UserInfo?.otherPhone !== `` ? `focused` : ""
                                }`}
                            >
                                <label className="control-label" htmlFor="otherPhone">
                                    {" "}
                                    Other phone no* (for recovery issues){" "}
                                </label>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    className={
                                        "form-control" +
                                        (errors.otherPhone && touched.otherPhone ? " has-error" : "")
                                    }
                                    value={UserInfo?.otherPhone}
                                    onChange={ChangeUserInfo}
                                    onBlur={handleBlur}
                                    id="otherPhone"
                                    name="otherPhone"
                                />
                            </div>
                            <div className="help-text">
                                {errors.otherPhone && touched.otherPhone && (
                                    <p className="text has-error">{errors.otherPhone}</p>
                                )}
                            </div>
                        </div>

                        <div className="communication">
                            <h5 className="sh5-bold">Communication options</h5>
                            <p className="desc secondary_text">
                                How would you prefer to communicate with you?
                            </p>
                            <div className="communication__options">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="isEmail"
                                        onChange={(e) => handleChangeCheckBox(e, "isEmail")}
                                        checked={UserInfo?.isEmail}
                                    />
                                    <label className="form-check-label" htmlFor="isEmail">
                                        {" "}
                                        Email{" "}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input "
                                        type="checkbox"
                                        value=""
                                        id="sms"
                                        onChange={(e) => handleChangeCheckBox(e, "sms")}
                                        checked={UserInfo?.sms}
                                    />
                                    <label className="form-check-label" htmlFor="sms">
                                        {" "}
                                        SMS{" "}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="phoneCall"
                                        onChange={(e) => handleChangeCheckBox(e, "phoneCall")}
                                        checked={UserInfo?.phoneCall}
                                    />
                                    <label className="form-check-label" htmlFor="phoneCall">
                                        {" "}
                                        Phone calls{" "}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="form_actions">
                            <button
                                className={
                                    "btn primaryBtn largeBtn " +
                                    (!Changed || Saving ? "disabled" : "")
                                }
                                type="button"
                                onClick={handleSubmit}
                            >
                                {Saving ? "Saving..." : "Save changes"}
                            </button>
                        </div>
                    </form>
                </div>

                <ProfileCompletness/>

                {ShowVerify && (
                    <VerifyModal
                        setShowVerify={setShowVerify}
                        Email={UserInfo?.otherEmail}
                        companyId={companyId}
                        updateRecoveryEmail = {() =>updateRecoveryEmail()}
                        
                    />
                )}
            </div>
    );
}

export default ContactPerson;
