/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, Fragment } from "react";
import axios from "axios";

import QRCode from "../Styles/images/company_profile/QRCode.png";
import HeaderCompanyProfile from "../Components/CompanyProfileComponents/HeaderCompanyProfile";
import SideSectionInfo from "../Components/CompanyProfileComponents/SideSectionInfo";
import MainCompanyInfo from "../Components/CompanyProfileComponents/MainCompanyInfo";
import CompanyInfo from "../Components/CompanyProfileComponents/CompanyInfo";
import FinancialDetails from "../Components/CompanyProfileComponents/FinancialDetails";
import CompanyDocuments from "../Components/CompanyProfileComponents/CompanyDocuments";
import Loading from "../Components/Loading/index";
import { useDispatch } from "react-redux";
import { ChangeLayout, SetTabHeaders } from "../Store/actions/registration";
import {
    useParams
} from "react-router-dom";
import "../Styles/css/compInfDetails.css"
import config from '../IP_Configrations.json';
const host = config.static;

const SharedCompanyProfile = () => {
    const [companyData, setCompanyData] = useState();

    let { companyId } = useParams()

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ChangeLayout(true));
        dispatch(SetTabHeaders("Company details"));
        const divs = document.querySelectorAll(".setting_container.subNavOpen");

        divs.forEach((div) => {
            div.classList.remove("setting_container");
            div.classList.remove("subNavOpen");
        });

        const getCompanyProfileData = async () => {
            await axios.get(`${host}GetCompanyProfileForPublic?companyId=${companyId}`)
                .then((res) => {
                    setCompanyData(res.data);
                    console.log(res.data);
                }).catch((err) => console.log(err));
        };

        getCompanyProfileData();
    }, []);

    return (
        <Fragment>
            {!companyData ? (
                <Loading />
            ) : (
                <section className="company_profile_page">
                    <HeaderCompanyProfile />

                    <div className="custom_container">
                        <div className="sec_container">
                            <SideSectionInfo companyInfoData={companyData} />

                            <div className="company_details">
                                <MainCompanyInfo mainInfo={companyData} />

                                <CompanyInfo dataInfo={companyData} />

                                <FinancialDetails dataInfoCompany={companyData} />

                                <CompanyDocuments dataInfoCompany={companyData} />
                            </div>
                        </div>

                        <div className="qrcode text-center">
                            <img src={QRCode} className="img-fluid" alt="qr" />
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    );
};

export default SharedCompanyProfile;
