import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadFile from "../../../UploadFile";

import config from "../../../../IP_Configrations.json";
import checkmarkedCircle_20 from "../../../../Styles/images/icons/checkmarkedCircle-20.svg";
import attached_file from "../../../../Styles/images/settings/attached_file.svg";
import DataServices from "../../../../Services/DataServices";

const numRegex = /^[0-9]+$/;

function VATSection({ CompanyDocument, SaveChanges, Saving, companyId }) {
    const [Changed, setChanged] = useState(false);
    const validateVAT = useFormik({
        enableReinitialize: true,
        initialValues: { vatNo: CompanyDocument?.vatNo, vatCertificationFile: CompanyDocument?.vatCertificationFile, edit: false },
        validationSchema: Yup.object().shape({
            vatNo: Yup.string()
                .matches(numRegex, "Invalid company VAT number, Please ensure that the number consists of 15 digits with no spaces or other characters.")
                .test("len", "Invalid company VAT number, Please ensure that the number consists of 15 digits with no spaces or other characters.", (val) => val.length === 15)
                .required("Company VAT number is required"),
            vatCertificationFile: Yup.mixed().required("A vat Certification File is required"),
        }),

        onSubmit: (values) => {
            SaveChanges(values, "VAT Number");
            validateVAT.setFieldValue("edit", false);
            setChanged(false);
        },
    });
    const [Load, setLoad] = useState(false);
    const [CheckVatAvailability, setCheckVatAvailability] = useState(false);

    const handlerVatBlur = async (e) => {
        const vat = e.target.value;
        if (vat) {
            setCheckVatAvailability(true);
            DataServices.GetRowById(`CheckVATAvailability?VatNumber=` + vat).then(async (res) => {
                if (res === false) {
                    setCheckVatAvailability(false);
                    await validateVAT.setFieldTouched("vatNo", true);
                    await validateVAT.setFieldError("vatNo", "this VAT no. is already registered by another company");
                } else {
                    setCheckVatAvailability(false);
                    validateVAT.setFieldTouched("vatNo", true);
                }
            });
        } else {
            await validateVAT.setFieldTouched("vatNo", true);
            await validateVAT.setFieldError("vatNo", "Company VAT number is required", true);
        }
    };

    return (
        <>
            <div className="sub_head">
                <div className="top">
                    <h4 className="title">
                        <span>VAT number*</span>
                        {validateVAT.values.edit ? "" : <img src={checkmarkedCircle_20} className="img-fluid icon" alt="checked" />}
                    </h4>
                    {!validateVAT.values.edit && (
                        <span
                            className="edit"
                            role="button"
                            onClick={() => {
                                validateVAT.setFieldValue("edit", true);
                            }}
                        >
                            Edit
                        </span>
                    )}
                </div>
                <p className="desc">Company VAT number information will be included as an FGC registered vendor, and will be a part of FGC purchase process. This number will be displayed on invoices, POs.</p>
            </div>
            <div className="sideByside">
                <div className="form-group">
                    <div className={"input " + (validateVAT.values.vatNo ? "populated" : "")}>
                        <label className="control-label" htmlFor="vatNo">
                            Company VAT number*
                        </label>
                        <input
                            autoComplete="off"
                            type="text"
                            className={"form-control " + (validateVAT.errors.vatNo && validateVAT.touched.vatNo && "has-error")}
                            disabled={validateVAT.values.edit ? false : true}
                            value={validateVAT.values.vatNo}
                            onChange={(e) => {
                                validateVAT.handleChange(e);
                                setChanged(true);
                            }}
                            onBlur={handlerVatBlur}
                            id="vatNo"
                            name="vatNo"
                        />
                        {CheckVatAvailability && (
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        )}
                    </div>
                    <div className="help-text">
                        <ul>{!validateVAT.values.edit ? false : validateVAT.errors.vatNo && validateVAT.touched.vatNo ? <p className="text has-error">{validateVAT.errors.vatNo}</p> : <p className="text">Must be 15 numbers</p>}</ul>
                    </div>
                    {console.log("errors: ", validateVAT.errors.vatNo)}
                    {console.log("touched: ", validateVAT.touched.vatNo)}
                </div>
            </div>

            {validateVAT.values.edit && (
                <div className="upload_box">
                    <h6 className="sh6-med">Valid copy of VAT certificate</h6>
                    <UploadFile
                        className=" sm "
                        load={Load}
                        setLoad={setLoad}
                        fieldName="vatCertificationFile"
                        title="VAT Certification"
                        removeFile={() => validateVAT.setFieldValue("vatCertificationFile", null)}
                        errors={validateVAT.errors.vatCertificationFile}
                        touched={validateVAT.touched.vatCertificationFile}
                        fileName={validateVAT.values?.vatCertificationFile?.split("/")[4] || validateVAT.values.vatCertificationFile}
                        folderName="VAT"
                        docType="vat"
                        companyId={companyId}
                        Api="UploadFiles"
                        handleBlur={() => validateVAT.setFieldTouched("vatCertificationFile")}
                        handleChange={() => {
                            validateVAT.setFieldValue("vatCertificationFile", "Done");
                            setChanged(true);
                        }}
                    />
                </div>
            )}
            {validateVAT.values.vatCertificationFile && !validateVAT.values.edit && (
                <div className="uploaded_file">
                    <Link target="_blank" to={CompanyDocument?.vatCertificationFile && config.download ? config.download + CompanyDocument?.vatCertificationFile : ""} className="file">
                        <img src={attached_file} className="img-fluid icon" alt="icon" />
                        <span>{CompanyDocument?.vatCertificationFile?.split("/")[4]}</span>
                    </Link>
                    <span className="hint">You can view the file by clicking on it</span>
                </div>
            )}
            {validateVAT.values.edit && (
                <div className="form_actions d-flex">
                    <button className={"btn primaryBtn largeBtn " + (!Changed || Saving ? "disabled" : "")} type="button" onClick={validateVAT.handleSubmit}>
                        {Saving ? "Saving..." : "Save changes"}
                    </button>
                    <button
                        className={"btn secondaryBtn largeBtn mx-3"}
                        type="button"
                        onClick={() => {
                            validateVAT.setFieldValue("edit", false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </>
    );
}

export default VATSection;
