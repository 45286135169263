import React, { useState, createContext, useEffect } from 'react';
import DataServices from '../Services/DataServices';
import API from '../Services/Axios';

const INITIAL_VALUES = {
  loadingModal: false,
  setLoadingModal: (v) => {},
  modalOpen: false,
  setModalOpen: (v) => {},
  modalComponent: null,
  setModalComponent: (v) => {},
};

const ModalContext = createContext(INITIAL_VALUES);
const companyId = localStorage.getItem("CompanyId");

export const ModalProvider = ({ children }) => {
  const [loadingModal, setLoadingModal] = useState(INITIAL_VALUES.loadingModal);
  const [modalComponent, setModalComponent] = useState(INITIAL_VALUES.modalComponent);
  const [modalOpen, setModalOpen] = useState(INITIAL_VALUES.modalOpen);
  const [modalOpenProfile, setModalOpenProfile] = useState(INITIAL_VALUES.modalOpen);
  const [chooseCompany, setchooseCompany] = useState(INITIAL_VALUES.modalOpen);
  const [chooseCompanyId, setchooseCompanyId] = useState(0);
  const [CompanyCompeltenes, setCompanyCompeltenes] = useState(0);
  const [dataDashboard, setDataDashboard] = useState();

  useEffect(() => {
    DataServices.GetRowById(`GetCompanyDashboard?companyId=${companyId}`).then(
      (res) => {
        setCompanyCompeltenes(
          res?.DtoProfileCompleteness?.ProfileCompletenessRatio
        );
        setDataDashboard(res)
      }
    );
  }, []);
  // 
  const [modelWidth, setModelWidth] = useState('500px');
  return (
    <ModalContext.Provider
      value={{
        loadingModal,
        setLoadingModal,
        modalComponent,
        setModalComponent,
        modalOpen,
        setModalOpen,
        modelWidth,
        setModelWidth,
        modalOpenProfile,
        setModalOpenProfile,
        chooseCompany, setchooseCompany,
        chooseCompanyId, setchooseCompanyId,
        CompanyCompeltenes, setCompanyCompeltenes,
        dataDashboard 
      }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
