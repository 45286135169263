import React, { useEffect, useState } from "react";

import { Link, redirect, useLocation } from "react-router-dom";
import SideMenuLogo from "../Styles/images/side_menu_logo.svg";
import SidePanel_icon from "../Styles/images/icons/SidePanel_icon.svg";

import SmallArrowDown from "../Styles/images/icons/SmallArrow-Down_16.svg";
import ArrowRightGray from "../Styles/images/icons/arrow-right-gray-16.svg";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Store/actions/registration";

import {
  accountSetting,
  userSetting,
  passwordSetting,
  securitySettig,
} from "../settingDefinition";

function LeftMenu({ isColapsed, setIsColapsed }) {
  var currentUrlWithoutParams = window.location.href.split("?")[0];

  const [isAccSetting, setIsAccSetting] = useState(false);
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(()=>{
    setWidth(window.innerWidth)
  },[window.innerWidth])
  const isDashboard = useSelector((state) => state.registration.isDashboard);
  const dispatch = useDispatch();

  const clickMenuHandler = (colapsed) => {
    setIsColapsed(colapsed);
    if (colapsed == true) {
      dispatch(actions.ChangeLayout(false));
      redirect("/SettingsContainer/CompanyInfo");
      // const divs = document.querySelectorAll(".setting_page");

      // divs.forEach((div) => {
      //     div.classList.remove("setting_container");
      //     div.classList.remove("subNavOpen");
      // });
    }
  };

  const clickHandlerSetting = (colapsed) => {
    setIsColapsed(colapsed);
    dispatch(actions.ChangeLayout(false));
  };

  const clickSubMenuHandler = (colapsed) => {
    dispatch(actions.ChangeLayout(colapsed));
  };

  const clickShowDashboard = (isDashBoard) => {
    dispatch(actions.ChangeLayout(isDashBoard));
  };

  const { pathname } = useLocation();

  if (!currentUrlWithoutParams.includes("CompanyShare")) {
    return (
      <aside>
        <div className="side_menu">
          <Link
            className="logo"
            to={`/Dashboard/${window.localStorage.getItem("CompanyId")}`}
            replace
            onClick={() => clickShowDashboard(true)}
          >
            <img src={SideMenuLogo} className="img-fluid" alt="FGC" />
          </Link>

          <ul className="list-unstyled side_menu__links">
            <li onClick={() => clickMenuHandler(false)}>
              <Link
                className={
                  "link " + (pathname.includes("Dashboard") && "active")
                }
                to={`/Dashboard/${window.localStorage.getItem("CompanyId")}`}
                replace
                onClick={() => clickShowDashboard(true)}
              >
                <svg
                  className="icon"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3333 26H9.11111C7.95 26 7 25.05 7 23.8889V9.11111C7 7.95 7.95 7 9.11111 7H13.3333C14.4944 7 15.4444 7.95 15.4444 9.11111V23.8889C15.4444 25.05 14.4944 26 13.3333 26ZM19.6667 26H23.8889C25.05 26 26 25.05 26 23.8889V18.6111C26 17.45 25.05 16.5 23.8889 16.5H19.6667C18.5056 16.5 17.5556 17.45 17.5556 18.6111V23.8889C17.5556 25.05 18.5056 26 19.6667 26ZM26 12.2778V9.11111C26 7.95 25.05 7 23.8889 7H19.6667C18.5056 7 17.5556 7.95 17.5556 9.11111V12.2778C17.5556 13.4389 18.5056 14.3889 19.6667 14.3889H23.8889C25.05 14.3889 26 13.4389 26 12.2778Z"
                    fill="#334252"
                  />
                </svg>
              </Link>
            </li>
            {/* 
          <li onClick={() => clickMenuHandler(false)}>
            <a href="#" className="link">
              <svg
                className="icon"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2222 21.5556H10.4444V19.3333H18.2222M21.5556 17.1111H10.4444V14.8889H21.5556M21.5556 12.6667H10.4444V10.4444H21.5556M23.7778 6H8.22222C6.98889 6 6 6.98889 6 8.22222V23.7778C6 24.3671 6.23413 24.9324 6.65087 25.3491C7.06762 25.7659 7.63285 26 8.22222 26H23.7778C24.3671 26 24.9324 25.7659 25.3491 25.3491C25.7659 24.9324 26 24.3671 26 23.7778V8.22222C26 7.63285 25.7659 7.06762 25.3491 6.65087C24.9324 6.23413 24.3671 6 23.7778 6Z"
                  fill="#334252"
                />
              </svg>
            </a>
          </li> */}

            {/* <li onClick={() => clickMenuHandler(false)}>
            <a href="#" className="link">
              <svg
                className="icon"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.2778 17.0833L15.7222 18.5278C15.9259 18.7315 16.1852 18.8333 16.5 18.8333C16.8148 18.8333 17.0741 18.7315 17.2778 18.5278L20.4444 15.3611V16.0278C20.4444 16.3426 20.5511 16.6019 20.7644 16.8056C20.9778 17.0093 21.2415 17.1111 21.5556 17.1111C21.8704 17.1111 22.1344 17.0048 22.3478 16.7922C22.5611 16.5796 22.6674 16.3156 22.6667 16V12.6667C22.6667 12.3519 22.56 12.0878 22.3467 11.8744C22.1333 11.6611 21.8696 11.5548 21.5556 11.5556H18.1944C17.8796 11.5556 17.6204 11.6622 17.4167 11.8756C17.213 12.0889 17.1111 12.3526 17.1111 12.6667C17.1111 12.9815 17.2178 13.2456 17.4311 13.4589C17.6444 13.6722 17.9081 13.7785 18.2222 13.7778H18.8611L16.5 16.1667L15.0556 14.7222C14.8519 14.5 14.5926 14.3889 14.2778 14.3889C13.963 14.3889 13.7037 14.5 13.5 14.7222L10.1111 18.1111C9.88889 18.3148 9.77778 18.5741 9.77778 18.8889C9.77778 19.2037 9.88889 19.463 10.1111 19.6667C10.3148 19.8889 10.5741 20 10.8889 20C11.2037 20 11.463 19.8889 11.6667 19.6667L14.2778 17.0833ZM8.22222 26C7.61111 26 7.08778 25.7822 6.65222 25.3467C6.21667 24.9111 5.99926 24.3881 6 23.7778V8.22222C6 7.61111 6.21778 7.08778 6.65334 6.65222C7.08889 6.21667 7.61185 5.99926 8.22222 6H23.7778C24.3889 6 24.9122 6.21778 25.3478 6.65334C25.7833 7.08889 26.0007 7.61185 26 8.22222V23.7778C26 24.3889 25.7822 24.9122 25.3467 25.3478C24.9111 25.7833 24.3881 26.0007 23.7778 26H8.22222Z"
                  fill="#334252"
                />
              </svg>
            </a>
          </li> */}

            <li onClick={() => clickMenuHandler(false)}>
              <Link
                className={
                  "link " + (pathname.includes("CompanyDetails") && "active")
                }
                to="/CompanyDetails"
                replace
                onClick={() => clickShowDashboard(true)}
              >
                <svg
                  className="icon"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 9.12828C6 8.33864 6.31719 7.58133 6.88178 7.02297C7.44638 6.46461 8.21213 6.15092 9.01059 6.15092L22.9894 6C23.7879 6 24.5536 6.31369 25.1182 6.87205C25.6828 7.43041 26 8.18772 26 8.97736V22.8717C26 23.6614 25.6828 24.4187 25.1182 24.977C24.5536 25.5354 23.7879 25.8491 22.9894 25.8491L9.01059 26C8.21213 26 7.44638 25.6863 6.88178 25.128C6.31719 24.5696 6 23.8123 6 23.0226V9.12828ZM11.0177 9.12828V16.0755L13.3187 13.7998C13.5069 13.6137 13.7621 13.5092 14.0282 13.5092C14.2943 13.5092 14.5496 13.6137 14.7377 13.7998L17.0388 16.0755V9.12828C17.0388 8.86507 16.9331 8.61263 16.7449 8.42651C16.5567 8.24039 16.3015 8.13583 16.0353 8.13583H12.0212C11.755 8.13583 11.4998 8.24039 11.3116 8.42651C11.1234 8.61263 11.0177 8.86507 11.0177 9.12828Z"
                    fill="#334252"
                  />
                </svg>
              </Link>
            </li>

            {/* <li onClick={() => clickMenuHandler(false)}>
            <a href="#" className="link">
              <svg
                className="icon"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.975 6C8.5706 6 5 9.63672 5 14.1227C5 24.206 18.2 26.1667 18.2 26.1667V22.2454H19.025C23.4294 22.2454 27 18.6086 27 14.1227C27 9.63672 23.4294 6 19.025 6H12.975ZM16 15.8032C16.3647 15.8032 16.7144 15.6557 16.9723 15.3931C17.2301 15.1304 17.375 14.7742 17.375 14.4028C17.375 14.0314 17.2301 13.6751 16.9723 13.4125C16.7144 13.1499 16.3647 13.0023 16 13.0023C15.6353 13.0023 15.2856 13.1499 15.0277 13.4125C14.7699 13.6751 14.625 14.0314 14.625 14.4028C14.625 14.7742 14.7699 15.1304 15.0277 15.3931C15.2856 15.6557 15.6353 15.8032 16 15.8032ZM21.775 14.4028C21.775 14.7742 21.6301 15.1304 21.3723 15.3931C21.1144 15.6557 20.7647 15.8032 20.4 15.8032C20.0353 15.8032 19.6856 15.6557 19.4277 15.3931C19.1699 15.1304 19.025 14.7742 19.025 14.4028C19.025 14.0314 19.1699 13.6751 19.4277 13.4125C19.6856 13.1499 20.0353 13.0023 20.4 13.0023C20.7647 13.0023 21.1144 13.1499 21.3723 13.4125C21.6301 13.6751 21.775 14.0314 21.775 14.4028ZM11.6 15.8032C11.9647 15.8032 12.3144 15.6557 12.5723 15.3931C12.8301 15.1304 12.975 14.7742 12.975 14.4028C12.975 14.0314 12.8301 13.6751 12.5723 13.4125C12.3144 13.1499 11.9647 13.0023 11.6 13.0023C11.2353 13.0023 10.8856 13.1499 10.6277 13.4125C10.3699 13.6751 10.225 14.0314 10.225 14.4028C10.225 14.7742 10.3699 15.1304 10.6277 15.3931C10.8856 15.6557 11.2353 15.8032 11.6 15.8032Z"
                  fill="#334252"
                />
              </svg>
            </a>
          </li> */}

            <li onClick={() => clickHandlerSetting(true)}>
              <Link
                className={
                  "link setting " +
                  (pathname.includes("SettingsContainer") && "active")
                }
                to="/SettingsContainer/CompanyInfo"
                replace
              >
                <svg
                  className="icon"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2391 5.23295C14.7297 4.92309 16.2689 4.92234 17.7598 5.23076C17.8591 5.88065 18.1049 6.5 18.4789 7.04277C18.8529 7.58554 19.3455 8.03776 19.9201 8.36584C20.4942 8.69504 21.1359 8.89171 21.7972 8.94121C22.4586 8.9907 23.1227 8.89176 23.7402 8.65174C24.7549 9.77789 25.5236 11.1 25.9978 12.535C25.4799 12.9453 25.062 13.466 24.775 14.0585C24.488 14.6509 24.3394 15.2999 24.3402 15.9571C24.3395 16.6145 24.4883 17.2636 24.7757 17.8561C25.063 18.4486 25.4815 18.9692 26 19.3791C25.523 20.8129 24.7534 22.134 23.7391 23.2602C23.1218 23.0204 22.4579 22.9215 21.7967 22.971C21.1355 23.0205 20.4941 23.2171 19.9201 23.5461C19.346 23.8741 18.8538 24.326 18.48 24.8684C18.1062 25.4107 17.8604 26.0296 17.7609 26.679C16.2704 26.9896 14.7313 26.9911 13.2402 26.6834C13.1414 26.0329 12.8958 25.4129 12.5217 24.8695C12.1477 24.3262 11.6549 23.8734 11.0799 23.545C10.5057 23.216 9.86401 23.0195 9.20264 22.9702C8.54127 22.9209 7.87719 23.02 7.25979 23.2602C6.24493 22.1337 5.47627 20.8112 5.00221 19.3759C5.5198 18.9658 5.93757 18.4454 6.22452 17.8534C6.51147 17.2613 6.66023 16.6128 6.65976 15.956C6.6603 15.2986 6.51136 14.6495 6.22401 14.0571C5.93666 13.4646 5.51829 12.944 5 12.5339C5.47701 11.1001 6.2466 9.77903 7.26089 8.65283C7.87822 8.89269 8.54214 8.99154 9.20331 8.94204C9.86448 8.89254 10.5059 8.69597 11.0799 8.36693C11.654 8.03896 12.1462 7.58702 12.52 7.04466C12.8938 6.5023 13.1396 5.88235 13.2391 5.23295ZM15.5 19.2433C16.3792 19.2433 17.2224 18.8971 17.8441 18.2808C18.4658 17.6645 18.8151 16.8286 18.8151 15.9571C18.8151 15.0855 18.4658 14.2496 17.8441 13.6333C17.2224 13.017 16.3792 12.6708 15.5 12.6708C14.6208 12.6708 13.7776 13.017 13.1559 13.6333C12.5342 14.2496 12.1849 15.0855 12.1849 15.9571C12.1849 16.8286 12.5342 17.6645 13.1559 18.2808C13.7776 18.8971 14.6208 19.2433 15.5 19.2433Z"
                    fill="#334252"
                  />
                </svg>
              </Link>
            </li>
          </ul>
        </div>

        {width <= 600 
          
          &&
          <span
            className="menu_collaps"
            onClick={() => clickMenuHandler(!isColapsed)}
          >
            <img
              src={SidePanel_icon}
              className={
                "img-fluid icon" + (isColapsed == false ? " rotate" : "")
              }
              alt="arrow"
            />
          </span>
        }

        {isDashboard == true ? null : (
          <div
            className={"sub_menu " + (isColapsed == true ? " colapsed" : "")}
          > 
          
          {width > 600 
          
            &&
            <span
              className="menu_collaps"
              onClick={() => clickMenuHandler(!isColapsed)}
            >
              <img
                src={SidePanel_icon}
                className={
                  "img-fluid icon" + (isColapsed == false ? " rotate" : "")
                }
                alt="arrow"
              />
            </span>
          }


            <div className="content">
              <h3 className="sh3-bold">Settings</h3>
              <ul className="list-unstyled sub_menu__items">
                <li className="item">
                  <a
                    className={"link sub " + (isAccSetting ? "" : "collapsed")}
                    data-bs-toggle="collapse"
                    data-bs-target="#layout-collapse"
                    aria-expanded="true"
                    onClick={() => {
                      setIsAccSetting(!isAccSetting);
                    }}
                  >
                    <span>Account settings</span>
                    <img
                      src={SmallArrowDown}
                      className="img-fluid arrow"
                      alt="arrow"
                    />
                  </a>
                  <div className="collapse" id="layout-collapse">
                    <ul className="list-unstyled sub_list">
                      {accountSetting.map((element, index) => {
                        return (
                          <li key={index} className={"item "}>
                            <Link
                              to={element.route}
                              replace
                              onClick={() => clickSubMenuHandler(false)}
                              className={
                                "link " +
                                (pathname.includes(element.active) && "active")
                              }
                            >
                              <span>{element.label}</span>
                              <img
                                src={ArrowRightGray}
                                className="img-fluid icon"
                                alt="arrow"
                              />
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>

                <li className="item">
                  <Link
                    to={"/SettingsContainer/UserSettings"}
                    replace
                    className={`link ${
                      pathname.includes("UserSetting") && "active"
                    }`}
                  >
                    <span>User Setting</span>
                    <img
                      src={ArrowRightGray}
                      className="img-fluid icon"
                      alt="arrow"
                    />
                  </Link>
                </li>

                <li className="item">
                  <Link
                    to={"/SettingsContainer/PasswordSettings"}
                    className={`link ${
                      pathname.includes("PasswordSettings") && "active"
                    }`}
                  >
                    <span>Change password</span>
                    <img
                      src={ArrowRightGray}
                      className="img-fluid icon"
                      alt="arrow"
                    />
                  </Link>
                </li>

                <li className="item">
                  <Link
                    to={"/SettingsContainer/SecuritySettings"}
                    className={`link ${
                      pathname.includes("SecuritySettings") && "active"
                    }`}
                  >
                    <span>Security settings</span>
                    <img
                      src={ArrowRightGray}
                      className="img-fluid icon"
                      alt="arrow"
                    />
                  </Link>
                </li>
                <li className="item">
                  <Link
                    to={"/SettingsContainer/AccountPreferences"}
                    className={`link ${
                      pathname.includes("AccountPreferences") && "active"
                    }`}
                  >
                    <span>Account preferences</span>
                    <img
                      src={ArrowRightGray}
                      className="img-fluid icon"
                      alt="arrow"
                    />
                  </Link>
              </li>
              <li className="item">
                <Link 
                  to={'SettingsContainer/NotificationsSettings'}
                  className={`link ${
                    pathname.includes("NotificationsSettings") && "active"
                  }`}
                >
                  <span>Notifications</span>
                  <img
                    src={ArrowRightGray}
                    className="img-fluid icon"
                    alt="arrow"
                  />
                </Link>
              </li>
              </ul>
            </div>
          </div>
        )}
      </aside>
    );
  } 
}

export default LeftMenu;

// else if (!currentUrlWithoutParams.includes("CompanyShare") && width < 600){
//   return(
//     <div className="aside_alternative">
//       <Link
//           className="logo"
//           to={`/Dashboard/${window.localStorage.getItem("CompanyId")}`}
//           replace
//           onClick={() => clickShowDashboard(true)}
//         >
//           <img src={SideMenuLogo} className="img-fluid" alt="FGC" />
//         </Link>
//     </div>
//   )
// }