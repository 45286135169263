export const DELETE_DOCTYPE_PROFILE = "DELETE_DOCTYPE_PROFILE";
export const SET_LOADING = "SET_LOADING";
export const ADD_CATEGORY_PROFILE = "ADD_CATEGORY_PROFILE";
export const DELETE_FILE = "DELETE_FILE";
export const GET_FILES = "GET_FILES";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const Document_Adding = "Document_Adding";

export const User_Exist_Added = "User_Exist_Added";
export const User_Added = "User_Added";
export const User_Edited = "User_Edited";
export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY";
export const Verify_Email = "Verify_Email";
export const Verify_Email_Invalid = "Verify_Email_Invalid";
export const Resend_Code = "Resend_Code";
export const Change_Step = "Change_Step";
export const Continue_Registration = "Continue_Registration"
export const Update_Redux_Store = "Update_Redux_Store";
export const Set_Tab_Headers = "Set_Tab_Headers";

export const Company_Added = "Company_Added";
export const Set_TransferOwnerShip = 'Set_TransferOwnerShip';
export const Set_Representive_Flag = 'Set_Representive_Flag';
export const Set_RequestActivityId = 'Set_RequestActivityId';
export const Set_TransferedOwnerUser = 'Set_TransferedOwnerUser';
export const Verify_Transfered_Email = 'Verify_Transfered_Email';
export const Set_RepresentativeDetails = 'Set_RepresentativeDetails';
export const Set_transferedDone = 'Set_transferedDone';
export const Set_LoadingFlag = 'Set_LoadingFlag';
export const Set_TransferModal= 'Set_TransferModal';


export const Company_Bank_Detail = "Company_Bank_Detail";
export const Company_financial_Contact_Person =
  "Company_financial_Contact_Person";

export const Company_Details = "Company_Details";

export const TERMS_AND_POLICY = "TERMS_AND_POLICY";

export const Company_Summary = "Company_Summary";
export const Set_Dashboard = "Set_Dashboard";


export const Set_companyId = "Set_companyId";