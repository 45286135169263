import React from "react";

const ComStateItem = ({ srcImage, title, description, end }) => {
  return (
    <div className="com_state">
      <div className="img">
        <img src={srcImage} className="img-fluid" alt="icon" />
      </div>
      <div className="text">
        <h5 className="title">{title}</h5>
        <h6 className="sh6-med" style={{ textAlign: end }}>
          {description}
        </h6>
      </div>
    </div>
  );
};

export default ComStateItem;
