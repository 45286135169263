import React, { useEffect, useState } from "react";
import checkmarkedCircle_24 from "../Styles/images/icons/checkmarkedCircle-24.svg";
import JobTitle from "../Styles/images/icons/JobTitle.svg";
import error_24 from "../Styles/images/icons/error-24.svg";
import Warning from "../Styles/images/icons/Warning_yellow.svg";
import email from "../Styles/images/icons/Email.svg";
import Previews from "../Components/UploadFile";

import Select from "react-select";
import InputMask from "react-input-mask";
import Phone from "../Styles/images/icons/Phone.svg";

import back from "../Styles/images/icons/arright-left-16.svg";
import DataServices from "../Services/DataServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  SetLoading,
  SetTabHeaders,
  AddCompanyBankDetail,
  backAction,
  AddCompanyFinancialContactPerson,
} from "../Store/actions/registration";

import { useSelector, useDispatch } from "react-redux";

const CharsDigsRege = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
const CharsDigsAcc =
  /^[a-zA-Z_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;
const CharsDigsRegex =
  /^[a-zA-Z0-9_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;
const NumDigsRegex = /^[0-9]/;
// const IBANregx = /^[a-zA-Z]{2}[0-9]{22}$/;
const IBANregx = /^[a-zA-Z]{2}[0-9a-zA-Z]{22}$/;
const numbersRegex = /^\d+$/;
const phoneNumbersRegex = /^([0|\+[0-9]{1,5})?([0-9]{9})$/;
const blackListedDomains = [
  "facebook",
  "google",
  "amazon",
  "netflix",
  "hotmail",
  "microsoft",
  "gmail",
  "yahoo",
];
// const emailNotMatch = /^(?!.*(gmail|yahoo|outlook|hotmail)).*$/;
const StepFive = () => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false)
  const currentStep = useSelector((state) => state.registration.currentStep);
  const isLoading = useSelector((state) => state.registration.isLoading);
  const IsSaudia = useSelector((state) => state.registration.company.isSaudia);
  const [IsSaudi, setIsSaudia] = useState(
    useSelector((state) => state.registration.company.isSaudia)
  );

  const companyId = useSelector((state) => state.registration.companyId);
  const countryId = useSelector(
    (state) => state.registration.company.countryId
  );

  const [Banks, setBanks] = useState([]);
  const [Currency, setCurrency] = useState([]);
  const [countryName, setCountryName] = useState(``);

  const StoredBankData = localStorage.getItem("CompanyBankDetail");
  const CompanyFinancialContactPerson = localStorage.getItem(
    "CompanyFinancialContactPerson"
  );
  const [countriesList, setCountriesList] = useState([]);

  // console.log(CompanyFinancialContactPerson);
  const validationBankDetails = useFormik({
    enableReinitialize: true,
    initialValues: {
      bankName:
        (IsSaudi == false &&
          JSON.parse(StoredBankData) !== null &&
          JSON.parse(StoredBankData).bankName) ||
        "",
      selectedBank:
        (IsSaudi &&
          JSON.parse(StoredBankData) !== null &&
          JSON.parse(StoredBankData).bankId) ||
        "",

      branch:
        (JSON.parse(StoredBankData) !== null &&
          JSON.parse(StoredBankData).branchName) ||
        "",
      beneficiaryName:
        (JSON.parse(StoredBankData) !== null &&
          JSON.parse(StoredBankData).contactName) ||
        "",
      country: countryName,
      currency:
        (JSON.parse(StoredBankData) !== null &&
          JSON.parse(StoredBankData).currencyId) ||
        "",
      accountNumber:
        (JSON.parse(StoredBankData) !== null &&
          JSON.parse(StoredBankData).accountNumber) ||
        "",
      IBAN:
        JSON.parse(StoredBankData) !== null
          ? JSON.parse(StoredBankData).iban
          : IsSaudi === true && JSON.parse(StoredBankData) === null
            ? "SA"
            : "",
      swiftCode:
        (JSON.parse(StoredBankData) !== null &&
          JSON.parse(StoredBankData).swiftCode) ||
        "",
      otherDetails:
        (JSON.parse(StoredBankData) !== null &&
          JSON.parse(StoredBankData).description) ||
        "",
      name:
        (JSON.parse(CompanyFinancialContactPerson) !== null &&
          JSON.parse(CompanyFinancialContactPerson)
            .FinancialContactPersonName) ||
        "",
      phone:
        (JSON.parse(CompanyFinancialContactPerson) !== null &&
          JSON.parse(CompanyFinancialContactPerson)
            .FinancialContactPersonPhone) ||
        "",
      position:
        (JSON.parse(CompanyFinancialContactPerson) !== null &&
          JSON.parse(CompanyFinancialContactPerson)
            .FinancialContactPersonPosition) ||
        "",
      email:
        (JSON.parse(CompanyFinancialContactPerson) !== null &&
          JSON.parse(CompanyFinancialContactPerson)
            .FinancialContactPersonEmail) ||
        "",
      countryId:
        (JSON.parse(StoredBankData) !== null &&
          JSON.parse(StoredBankData).countryId) ||
        countriesList?.filter((item2) => item2.value === countryId)[0]?.value,
      financialCertificate: null
    },
    validationSchema: Yup.object().shape({
      financialCertificate :  Yup.mixed().required("A financial Certificate File is required"),
      bankName:
        IsSaudi === true
          ? Yup.string().nullable()
          : Yup.string()
            .matches(
              CharsDigsRegex,
              "Invalid bank name Please ensure that the name in english with no spaces or any special characters"
            )
            .required("Bank name is required"),

      selectedBank:
        IsSaudi === true
          ? Yup.string().required("This Bank is Required")
          : Yup.string().nullable(),

      branch: Yup.string()
        .matches(
          CharsDigsRegex,
          "Invalid branch name Please ensure that the name in english with no spaces or any special characters"
        )
        .required("Branch name is required"),

      beneficiaryName: Yup.string()
        .matches(
          CharsDigsAcc,
          "Invalid beneficiary name Please ensure that the name in english with no spaces, numbers or any special characters"
        )
        .required("Beneficiary name is required"),
      country: Yup.string().required("Country is required"),
      currency: Yup.string().required("Currency is required"),
      accountNumber: Yup.string()
        .required("Account number is required")
        .matches(
          CharsDigsRege,
          "Invalid account no. Please ensure that the number consists of digits only with no spaces or any other characters"
        ),

      IBAN:
        IsSaudi === true
          ? Yup.string()
            .required("IBAN is required")
            .matches(IBANregx, "Invalid IBAN, ")
          : //   .test(
          //     "len",
          //     "Invalid IBAN, Please ensure that the number consists of 24 characters with no spaces or any special characters.",
          //     (val) => {
          //       const less = val?.replace("_", "");
          //       return less?.length === 24;
          //     }
          //   )
          Yup.string().matches(
            CharsDigsRege,
            "Invalid IBAN, Please ensure that the number consists of characters with no spaces or any special characters."
          ),
      swiftCode:
        IsSaudi === false
          ? Yup.string()
            .matches(
              CharsDigsRegex,
              "Invalid swift code Please ensure that the name in english with no spaces or any special characters"
            )
            .required("Swift code is required")
          : Yup.string().nullable(),
      position: Yup.string()
        .matches(
          CharsDigsRegex,
          "Please enter valid job title and delete white space"
        )
        .required("Job title is required"),
      name: Yup.string()
        .matches(CharsDigsRegex, "English characters")
        .max(30, "Max length of string is 30")
        .required(" name is required"),
      phone: Yup.string()
        .matches(
          phoneNumbersRegex,
          "other phone must start with + and then enter the phone"
        )
        .test(
          "len",
          "Must not be less than 9 digits or more than 14 digits",
          (val) => val?.length >= 9 && val?.length <= 14
        )
        .required("Phone number is required"),

      email: Yup.string()
        .email("Not valid email address")
        // .test(
        //   "email_same",
        //   "Please add a different email, not your registration email",
        //   (value) => {
        //     if (value == undefined) {
        //       return true;
        //     }
        //     return value !== u.email;
        //   }
        // )
        .required(" email is required"),

      // .matches(
      //   emailNotMatch,
      //   "Not accepted email address, must be a work email address, If you want to register with a personal email (eg. Gmail, Hotmail) please contact ahmad.osman@fgc.sa"
      // ),
    }),

    onSubmit: (values, actions) => {
      dispatch(SetLoading());

      dispatch(
        AddCompanyBankDetail({
          companyId: companyId,
          bankId: parseInt(values.selectedBank),
          bankName: values.bankName,
          branchName: values.branch,
          contactName: values.beneficiaryName,
          currencyId: parseInt(values.currency),
          accountNumber: values.accountNumber,
          iban: values.IBAN,
          swiftCode: values.swiftCode,
          description: values.otherDetails,
          IsPrimary: true,
          countryId: values.countryId,
        })
      );
      dispatch(
        AddCompanyFinancialContactPerson({
          companyId: companyId,
          FinancialContactPersonName: values.name,
          FinancialContactPersonPosition: values.position,
          FinancialContactPersonPhone: values.phone,
          FinancialContactPersonEmail: values.email,
        })
      );

      actions.setSubmitting(false);
    },
  });

  useEffect(() => {
    const inputs = document.querySelectorAll(".input.large");
    if (IsSaudi === true) {
    }
    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function () {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value == "") {
          input.classList.remove("focused");
        }
      });
    });

    dispatch(
      SetTabHeaders(
        "Bank details",
        "In order to complete your vendor registration process, please provide your us with these required documents",
        currentStep
      )
    );

    DataServices.GetDataListDetailed(`GetBankForList`, "title", "id").then(
      (res) => setBanks(res)
    );

    DataServices.GetDataList(`GetCurrency`, "title", "id").then((res) =>
      setCurrency(res)
    );

    DataServices.GetDataList(`GetCountriesList`, "title", "id").then((res) => {
      const country_name = res.filter((item) => item.value === countryId)?.[0]?.label || ''
      setCountryName(country_name);
      setCountriesList(res);
    });
  }, []);

  const {
    errors,
    touched,
    handleBlur,
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
  } = validationBankDetails;
  // console.log("====================================");
  // console.log(IsSaudi);
  // console.log("====================================");
  return (
    <>
      <div noValidate="novalidate" className="proForm">
        <div className="step5">
          <div className="waring_box sh5-med">
            <img src={Warning} className="img-fluid icon" alt="warning" />
            <span>This info needs to be accurate</span>
          </div>
          <div className="desc-div">
            This will be your primary bank account, you can add other bank
            accounts later from the FGC vendor portal (under your account
            settings)
          </div>
          <div className="sideByside">
            <div className="form-group">
              <div
                className={`input  large ${values.country ? `focused` : ""}`}
              >
                <label className="control-label" htmlFor="Country">
                  Country*
                </label>
                {IsSaudia ? (
                  <input
                    name="country"
                    id="Country"
                    autoComplete="off"
                    type="text"
                    className={
                      "form-control" +
                      (errors.country && touched.country
                        ? " has-error"
                        : !errors.country && touched.country
                          ? " has-success"
                          : " ")
                    }
                    onChange={handleChange}
                    value={values.country || ""}
                    onBlur={handleBlur}
                    disabled={true}
                  />
                ) : (
                  <Select
                    className={
                      "select_react large " +
                      (errors.country && touched.country
                        ? " has-error"
                        : !errors.country && touched.country
                          ? " has-success"
                          : " ")
                    }
                    classNamePrefix="select"
                    placeholder="Select country*"
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    options={countriesList}
                    // defaultValue={
                    //   countriesList
                    //     ? countriesList.find(
                    //         (option) => option.value === values.countryId
                    //       )
                    //     : ""
                    // }
                    value={countriesList.find((option) => {
                      return option.value === values.countryId;
                    })}
                    name="country"
                    id="country"
                    onChange={(option) => {
                      setFieldValue("country" , option.label)
                      setFieldValue("countryId", option.value);
                      if (option.label?.includes("audi")) {
                        setIsSaudia(true);
                      } else {
                        setIsSaudia(false);
                      }
                    }}
                    onBlur={() => setFieldTouched("countryId", true)}
                  //   onBlur={() => setFieldTouched("currency", true)}
                  // disabled={JSON.parse(StoredBankData) !== null ? true : false}
                  />
                )}
              </div>
              <div className="help-text">
                {errors.country && touched.country ? (
                  <p className="text has-error">{errors.country}</p>
                ) : null}
              </div>
            </div>

            <div className="form-group">
              <Select
                className={
                  "select_react large " +
                  (errors.currency && touched.currency
                    ? " has-error"
                    : !errors.currency && touched.currency
                      ? " has-success"
                      : " ")
                }
                classNamePrefix="select"
                placeholder="Select Currency*"
                defaultValue={
                  Currency
                    ? Currency.find(
                      (option) => option.value === values.currency
                    )
                    : ""
                }
                value={
                  Currency
                    ? Currency.find(
                      (option) => option.value === values.currency
                    )
                    : ""
                }
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                options={Currency}
                name="currency"
                id="currency"
                onChange={(option) => setFieldValue("currency", option.value)}
                onBlur={() => setFieldTouched("currency", true)}
              // disabled={JSON.parse(StoredBankData) !== null ? true : false}
              />
              <div className="help-text">
                {errors.currency && touched.currency ? (
                  <p className="text has-error">{errors.currency}</p>
                ) : null}
              </div>
            </div>
          </div>
          {IsSaudi ? (
            <div className="form-group">
              <Select
                className={
                  "select_react large " +
                  (errors.selectedBank && touched.selectedBank
                    ? " has-error"
                    : !errors.selectedBank && touched.selectedBank
                      ? " has-success"
                      : " ")
                }
                classNamePrefix="select"
                placeholder="Select Bank*"
                value={Banks.find((option) => {
                  return option.value === values.selectedBank;
                })}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                options={Banks}
                name="selectedBank"
                id="selectedBank"
                onChange={(option) => {
                  setFieldValue("selectedBank", option.value);
                  setFieldValue("swiftCode", option.swiftCode);
                  setFieldValue("bankName", option.label);
                }}
                onBlur={() => setFieldTouched("selectedBank", true)}
              // disabled={JSON.parse(StoredBankData) !== null ? true : false}
              />
              <div className="help-text">
                {errors.selectedBank && touched.selectedBank ? (
                  <p className="text has-error">{errors.selectedBank}</p>
                ) : null}{" "}
              </div>
            </div>
          ) : (
            <div className="form-group">
              <div
                className={`input large ${values.bankName ? `focused` : ""}`}
              >
                <label className="control-label" htmlFor="bankName">
                  Bank name*
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  className={
                    "form-control" +
                    (errors.bankName && touched.bankName
                      ? " has-error"
                      : !errors.bankName && touched.bankName
                        ? " has-success"
                        : " ")
                  }
                  value={values.bankName || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="bankName"
                  name="bankName"
                // disabled={JSON.parse(StoredBankData) !== null ? true : false}
                />
                {errors.bankName && touched.bankName ? (
                  <img src={error_24} className="img-fluid right_icon" alt="" />
                ) : !errors.bankName && touched.bankName ? (
                  <img
                    src={checkmarkedCircle_24}
                    className="img-fluid right_icon"
                    alt=""
                  />
                ) : (
                  " "
                )}
              </div>
              <div className="help-text">
                {errors.bankName && touched.bankName ? (
                  <p className="text has-error">{errors.bankName}</p>
                ) : null}
              </div>
            </div>
          )}

          <div className="form-group">
            <div className={`input large ${values.branch ? `focused` : ""}`}>
              <label className="control-label" htmlFor="branch">
                Branch*
              </label>
              <input
                autoComplete="off"
                type="text"
                className={
                  "form-control" +
                  (errors.branch && touched.branch
                    ? " has-error"
                    : !errors.branch && touched.branch
                      ? " has-success"
                      : " ")
                }
                value={values.branch || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                id="branch"
                name="branch"
              // disabled={JSON.parse(StoredBankData) !== null ? true : false}
              />

              {errors.branch && touched.branch ? (
                <img src={error_24} className="img-fluid right_icon" alt="" />
              ) : !errors.branch && touched.branch ? (
                <img
                  src={checkmarkedCircle_24}
                  className="img-fluid right_icon"
                  alt=""
                />
              ) : (
                " "
              )}
            </div>

            <div className="help-text">
              {errors.branch && touched.branch ? (
                <p className="text has-error">{errors.branch}</p>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <div
              className={`input withIcon large ${values.beneficiaryName ? `focused` : ""
                }`}
            >
              <label className="control-label" htmlFor="Beneficiary">
                Beneficiary name*
              </label>
              <img src={JobTitle} className="img-fluid left_icon" alt="mail" />
              <input
                name="beneficiaryName"
                autoComplete="off"
                className={
                  "form-control" +
                  (errors.beneficiaryName && touched.beneficiaryName
                    ? " has-error"
                    : !errors.beneficiaryName && touched.beneficiaryName
                      ? " has-success"
                      : " ")
                }
                type="text"
                id="Beneficiary"
                onChange={handleChange}
                value={values.beneficiaryName || ""}
                onBlur={handleBlur}
              // disabled={JSON.parse(StoredBankData) !== null ? true : false}
              />
              {errors.beneficiaryName && touched.beneficiaryName ? (
                <img src={error_24} className="img-fluid right_icon" alt="" />
              ) : !errors.beneficiaryName && touched.beneficiaryName ? (
                <img
                  src={checkmarkedCircle_24}
                  className="img-fluid right_icon"
                  alt=""
                />
              ) : (
                " "
              )}
            </div>
            <div className="help-text">
              {errors.beneficiaryName && touched.beneficiaryName ? (
                <p className="text has-error">{errors.beneficiaryName}</p>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <div
              className={`input large ${values.accountNumber ? `focused` : ""}`}
            >
              <label className="control-label" htmlFor="Account_number">
                Account number*
              </label>
              <input
                autoComplete="off"
                type="text"
                className={
                  "form-control" +
                  (errors.accountNumber && touched.accountNumber
                    ? " has-error"
                    : !errors.accountNumber && touched.accountNumber
                      ? " has-success"
                      : " ")
                }
                name="accountNumber"
                id="Account_number"
                onChange={handleChange}
                value={values.accountNumber || ""}
                onBlur={handleBlur}
              // disabled={JSON.parse(StoredBankData) !== null ? true : false}
              />
              {errors.accountNumber && touched.accountNumber ? (
                <img src={error_24} className="img-fluid right_icon" alt="" />
              ) : !errors.accountNumber && touched.accountNumber ? (
                <img
                  src={checkmarkedCircle_24}
                  className="img-fluid right_icon"
                  alt=""
                />
              ) : (
                " "
              )}
            </div>
            <div className="help-text">
              {errors.accountNumber && touched.accountNumber ? (
                <p className="text has-error">{errors.accountNumber}</p>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <div className={`input large ${values.IBAN ? `focused` : ""}`}>
              <label className="control-label" htmlFor="IBAN">
                {IsSaudi ? "IBAN*" : "IBAN (Optional)"}
              </label>
              {IsSaudi ? (
                <input
                  autoComplete="off"
                  type="text"
                  className={
                    "form-control" +
                    (errors.IBAN && touched.IBAN
                      ? " has-error"
                      : values.IBAN && !errors.IBAN && touched.IBAN
                        ? " has-success"
                        : " ")
                  }
                  name="IBAN"
                  id="IBAN"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.IBAN}
                />
              ) : (
                <input
                  autoComplete="off"
                  type="text"
                  className={
                    "form-control" +
                    (errors.IBAN && touched.IBAN
                      ? " has-error"
                      : values.IBAN && !errors.IBAN && touched.IBAN
                        ? " has-success"
                        : " ")
                  }
                  name="IBAN"
                  id="IBAN"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.IBAN}
                />
              )}
              {IsSaudi ? (
                errors.IBAN && touched.IBAN ? (
                  <img src={error_24} className="img-fluid right_icon" alt="" />
                ) : !errors.IBAN && touched.IBAN ? (
                  <img
                    src={checkmarkedCircle_24}
                    className="img-fluid right_icon"
                    alt=""
                  />
                ) : (
                  " "
                )
              ) : errors.IBAN && touched.IBAN ? (
                <img src={error_24} className="img-fluid right_icon" alt="" />
              ) : values.IBAN && !errors.IBAN && touched.IBAN ? (
                <img
                  src={checkmarkedCircle_24}
                  className="img-fluid right_icon"
                  alt=""
                />
              ) : (
                " "
              )}
            </div>

            <div className="help-text">
              <ul>
                {errors.IBAN && touched.IBAN ? (
                  <p className="text has-error">{errors.IBAN}</p>
                ) : IsSaudi ? (
                  <p className="text">must be 24 characters</p>
                ) : null}
              </ul>
            </div>
          </div>

          <div className="form-group">
            <div className={`input large ${values.swiftCode ? `focused` : ""}`}>
              <label className="control-label" htmlFor="Swift">
                {!IsSaudi ? "Swift code*" : "Swift code"}
              </label>
              <input
                autoComplete="off"
                type="text"
                className={
                  !IsSaudi
                    ? "form-control" +
                    (errors.swiftCode && touched.swiftCode
                      ? " has-error"
                      : !errors.swiftCode && touched.swiftCode
                        ? " has-success"
                        : " ")
                    : "form-control" +
                    (errors.swiftCode && touched.swiftCode
                      ? " has-error"
                      : !errors.swiftCode &&
                        touched.swiftCode &&
                        values.swiftCode
                        ? " has-success"
                        : " ")
                }
                id="Swift"
                name="swiftCode"
                onChange={handleChange}
                value={values.swiftCode || ""}
                onBlur={handleBlur}
                disabled={IsSaudi ? true : false}
              />
              {!IsSaudi ? (
                errors.swiftCode && touched.swiftCode ? (
                  <img src={error_24} className="img-fluid right_icon" alt="" />
                ) : !errors.swiftCode && touched.swiftCode ? (
                  <img
                    src={checkmarkedCircle_24}
                    className="img-fluid right_icon"
                    alt=""
                  />
                ) : (
                  " "
                )
              ) : errors.swiftCode && touched.swiftCode ? (
                <img src={error_24} className="img-fluid right_icon" alt="" />
              ) : !errors.swiftCode && touched.swiftCode && values.swiftCode ? (
                <img
                  src={checkmarkedCircle_24}
                  className="img-fluid right_icon"
                  alt=""
                />
              ) : (
                " "
              )}
            </div>
            <div className="help-text">
              {errors.swiftCode && touched.swiftCode ? (
                <p className="text has-error">{errors.swiftCode}</p>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <div
              className={`input large ${values.otherDetails ? `focused` : ""}`}
            >
              <label className="control-label" htmlFor="details">
                Other details (Optional)
              </label>
              {
                <input
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="details"
                  name="otherDetails"
                  value={values.otherDetails || ""}
                  onChange={handleChange}
                  disabled={JSON.parse(StoredBankData) !== null ? true : false}
                />
              }
            </div>
          </div>
        </div>
        <div className="main_head" style={{ marginBottom: "10px" }}>
          <div className="text">
            <h4 className="sh4-bold">Financial contact person</h4>
          </div>
          {/* <span className="req">Required</span> */}
        </div>
        <div className="desc-div">
          Please tell us about the company’s financial representative that we
          can communicate with (Chief finance officer , Financial manager, Head
          of finance, Financial accountant, etc.)
        </div>
        <div className="form-group">
          <div
            className={`input withIcon large ${values.position ? `focused` : ""
              }`}
          >
            <label className="control-label" htmlFor="position">
              Position*
            </label>
            <img
              src={JobTitle}
              className="img-fluid left_icon"
              alt="position"
            />
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control" +
                (errors.position && touched.position
                  ? " has-error"
                  : !errors.position && touched.position
                    ? " has-success"
                    : " ")
              }
              id="position"
              name="position"
              value={values.position}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.position && touched.position ? (
              <img src={error_24} className="img-fluid right_icon" alt="" />
            ) : !errors.position && touched.position ? (
              <img
                src={checkmarkedCircle_24}
                className="img-fluid right_icon"
                alt=""
              />
            ) : (
              " "
            )}
          </div>
          <div className="help-text">
            {errors.position && touched.position ? (
              <p className="text has-error">{errors.position}</p>
            ) : null}
          </div>
        </div>
        <div className="form-group">
          <div
            className={`input withIcon large ${values.name ? `focused` : ""}`}
          >
            <label className="control-label" htmlFor="name">
              name*
            </label>
            <img
              src={JobTitle}
              className="img-fluid left_icon"
              alt="position"
            />
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control" +
                (errors.name && touched.name
                  ? " has-error"
                  : !errors.name && touched.name
                    ? " has-success"
                    : " ")
              }
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.name && touched.name ? (
              <img src={error_24} className="img-fluid right_icon" alt="" />
            ) : !errors.name && touched.name ? (
              <img
                src={checkmarkedCircle_24}
                className="img-fluid right_icon"
                alt=""
              />
            ) : (
              " "
            )}
          </div>
          <div className="help-text">
            {errors.name && touched.name ? (
              <p className="text has-error">{errors.name}</p>
            ) : null}
          </div>
        </div>
        <div className="form-group">
          <div
            className={`input withIcon large ${values.email ? `focused` : ""}`}
          >
            <label className="control-label" htmlFor="email">
              email
            </label>
            <img src={email} className="img-fluid left_icon" alt="mail" />
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control" +
                (errors.email && touched.email
                  ? " has-error"
                  : !errors.email && touched.email
                    ? " has-success"
                    : " ")
              }
              id="email"
              name="email"
              // disabled={this.props.user.id > 0 ? true : false}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email ? (
              <img src={error_24} className="img-fluid right_icon" alt="" />
            ) : !errors.email && touched.email ? (
              <img
                src={checkmarkedCircle_24}
                className="img-fluid right_icon"
                alt=""
              />
            ) : (
              " "
            )}
          </div>
          <div className="help-text">
            {errors.email && touched.email ? (
              <p className="text has-error">{errors.email}</p>
            ) : null}
          </div>
        </div>
        <div className="form-group">
          <div
            className={`input withIcon large ${values.phone ? `focused` : ""}`}
          >
            <label className="control-label" htmlFor="phone">
              {" "}
              Phone no.*{" "}
            </label>
            <img src={Phone} className="img-fluid left_icon" alt="phone" />
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control " +
                (errors.phone && touched.phone
                  ? " has-error"
                  : !errors.phone && touched.phone
                    ? " has-success"
                    : " ")
              }
              id="phone"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.phone && touched.phone ? (
              <img src={error_24} className="img-fluid right_icon" alt="" />
            ) : !errors.phone && touched.phone ? (
              <img
                src={checkmarkedCircle_24}
                className="img-fluid right_icon"
                alt=""
              />
            ) : (
              " "
            )}
          </div>
          <div className="help-text">
            {errors.phone && touched.phone ? (
              <p className="text has-error">{errors.phone}</p>
            ) : null}
          </div>
        </div>
        <div className="step_content">
          <div className="sec_head">
            <h3 className="h3-med">Financial Document*</h3>
          </div>

          <div
            className={
              "upload_box" +
              (touched.financialCertificate &&
                errors.financialCertificate
                ? " has-error"
                : " ")
            }
          >
            <h5 className="sh5-med">
              Upload a copy of financial certificate
            </h5>
            <Previews
              load={load}
              setLoad={setLoad}
              title="Financial Certificate"
              name="financialCertificate"
              id="financialCertificate"
              removeFile={() =>
                setFieldValue("financialCertificate", null)
              }
              fileName={values?.financialCertificate}
              errors={errors.financialCertificate}
              touched={touched.financialCertificate}
              folderName="financialCertificateFile"
              docType="financialCertificateFile"
              companyId={companyId}
              Api="UploadFiles"
              handleBlur={() =>
                setFieldTouched("financialCertificate")
              }
              handleChange={() =>
                setFieldValue("financialCertificate", "Done uploading debug")
              }
            />
          </div>
        </div>
        <div className="mesg">
          <ul>
            <li>
              This information may be visible in payment statements, invoices,
              and receipts.
            </li>
            <li>
              {" "}
              By clicking ‘Save and next’ button, you agree that FGC’s financial
              department has the rights to check the data accuracy with your
              entered bank, and you confirm that all the entered data is correct
              and any error in this data is your responsibility.
            </li>
          </ul>
        </div>
        <div className="form_actions">
          {isLoading == true || load ? (
            <button className="btn primaryBtn largeBtn disabled loading w-100">
              Loading...
            </button>
          ) : (
            <button
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              className={
                "btn primaryBtn largeBtn " +
                (isSubmitting === true || JSON.parse(StoredBankData) !== null
                  ? " "
                  : " ") +
                " w-100"
              }
              disabled={isSubmitting}
            >
              Save and next step
            </button>
          )}
          <button
            className="btn textBtn largeBtn icon-l w-100"
            type="button"
            onClick={(e) => dispatch(backAction())}
          >
            <img src={back} className="icon" alt="arrow" /> Back
          </button>
        </div>
      </div>
    </>
  );
};

export default StepFive;
