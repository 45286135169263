import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";

import config from "../../../../IP_Configrations.json";
import UploadFile from "../../../UploadFile";

import checkmarkedCircle_20 from "../../../../Styles/images/icons/checkmarkedCircle-20.svg";
import attached_file from "../../../../Styles/images/settings/attached_file.svg";
import Calendar_disabled from "../../../../Styles/images/icons/Calendar_disabled.svg";
import calendar from "../../../../Styles/images/icons/calendar.svg";
import Warning_yellow_black from "../../../../Styles/images/icons/Warning_yellow_black.svg";
import Warning_20 from "../../../../Styles/images/icons/Warning_20.svg";
import Warning_white_24 from "../../../../Styles/images/icons/Warning_white_24.svg";

const numRegex = /^[0-9]+$/;

function ZakatSection({ CompanyDocument, SaveChanges, Saving, companyId }) {
  const [Changed, setChanged] = useState(false);
  const [Edit, setEdit] = useState(false);
  useEffect(() => {
    if (CompanyDocument?.zakatcertificateExpireDate) {
      const date1 = new Date();
      const date2 = new Date(CompanyDocument?.zakatcertificateExpireDate);
      const diffTime = date2 - date1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 1) {
        setEdit(`${diffDays}`);
      }
    }
  }, [CompanyDocument?.zakatcertificateExpireDate]);

  const validateZakat = useFormik({
    enableReinitialize: true,
    initialValues: {
      zakatcertificateNo: Edit
        ? ""
        : CompanyDocument?.zakatCertificateNo ||
          CompanyDocument?.zakatcertificateNo,
      zakatcertificateExpireDate: Edit
        ? null
        : CompanyDocument?.zakatCertificateExpireDate ||
          CompanyDocument?.zakatcertificateExpireDate,
      zakatcertificateFile: Edit
        ? null
        : CompanyDocument?.zakatCertificateFile ||
          CompanyDocument?.zakatcertificateFile,
      edit: Edit,
    },
    validationSchema: Yup.object().shape({
      zakatcertificateNo: Yup.string()
        .matches(
          numRegex,
          "Invalid company zakat certificate number. Please ensure that the number consists of 10 digits with no spaces or other characters"
        )
        .test(
          "len",
          "Invalid company zakat certificate number. Please ensure that the number consists of 10 digits with no spaces or other characters",
          (val) => val.length === 10
        )
        .required("Company zakat certificate no. is required"),
      zakatcertificateExpireDate: Yup.date()
        .required("Zakat certificate certificate expiration date is required")
        .typeError("Invalid zakat certificate date."),
      zakatcertificateFile: Yup.mixed().required("file copy is required"),
    }),

    onSubmit: (values) => {
      SaveChanges(values, "Zakat certificate");
      validateZakat.setFieldValue("edit", false);
      setChanged(false);
    },
  });

  const [Warn, setWarn] = useState({});
  const [Load, setLoad] = useState(false);
  const _calendar = useRef();

  const openDatepicker = () => _calendar.current.setOpen(true);
  return (
    <div className="content">
      <div className="sub_head">
        <div className="top">
          <h4 className="title">
            <span>Valid zakat certificate</span>
            {Edit ? (
              <img src={Warning_20} className="img-fluid icon" alt="info" />
            ) : validateZakat.values.edit ? (
              ""
            ) : (
              <img
                src={checkmarkedCircle_20}
                className="img-fluid icon"
                alt="checked"
              />
            )}
          </h4>
          {!validateZakat.values.edit && (
            <span
              className="edit"
              role="button"
              onClick={() => {
                validateZakat.setFieldValue("edit", true);
              }}
            >
              Edit
            </span>
          )}
        </div>
        <p className="desc">
          Making any change in your zakat certificate will require FGC’s admin
          approval
        </p>
      </div>
      {Edit && (
        <div className="waring_box sm sh5-med">
          <img
            src={Warning_white_24}
            className="img-fluid icon"
            alt="warning"
          />
          <span>
            Your ‘zakat certificate’ expired {Edit} days ago, please update your
            document
          </span>
        </div>
      )}
      <div className="sideByside">
        <div className="form-group">
          <div
            className={
              "input " +
              (validateZakat.values.zakatcertificateNo ? "populated" : "")
            }
          >
            <label className="control-label" htmlFor="zakatcertificateNo">
              Zakat certificate no.*
            </label>
            <input
              autoComplete="off"
              type="text"
              className={
                "form-control " +
                (validateZakat.errors?.zakatcertificateNo &&
                  validateZakat.touched?.zakatcertificateNo &&
                  "has-error")
              }
              disabled={validateZakat.values.edit ? false : true}
              value={validateZakat.values?.zakatcertificateNo}
              onChange={(e) => {
                validateZakat.handleChange(e);
                setChanged(true);
              }}
              onBlur={validateZakat.handleBlur}
              id="zakatcertificateNo"
              name="zakatcertificateNo"
            />
          </div>
          <div className="help-text">
            {validateZakat.values.edit && (
              <ul>
                {validateZakat.errors.zakatcertificateNo &&
                validateZakat.touched.zakatcertificateNo ? (
                  <p className="text has-error">
                    {validateZakat.errors.zakatcertificateNo}
                  </p>
                ) : (
                  <p className="text">Must be 10 numbers</p>
                )}
              </ul>
            )}
          </div>
        </div>

        <div className="form-group">
          {validateZakat.values.edit ? (
            <>
              <div className="input date populated">
                <label
                  className="control-label date"
                  htmlFor="zakatcertificateExpireDate"
                >
                  Zakat certificate expiration date*
                </label>
                <DatePicker
                  ref={(c) => (_calendar.current = c)}
                  placeholderText="Please select expiration date"
                  dateFormat={"dd/MM/yyyy"}
                  autoComplete="off"
                  showYearDropdown
                  className={
                    "form-control " +
                    (validateZakat.errors?.zakatcertificateExpireDate &&
                      validateZakat.touched?.zakatcertificateExpireDate &&
                      "has-error")
                  }
                  id="zakatcertificateExpireDate"
                  name="zakatcertificateExpireDate"
                  selected={
                    new Date(validateZakat.values?.zakatcertificateExpireDate)
                  }
                  onChange={(date) => {
                    setWarn({});
                    const date1 = new Date();
                    const date2 = new Date(date);
                    const diffTime = date2 - date1;
                    const diffDays = Math.ceil(
                      diffTime / (1000 * 60 * 60 * 24)
                    );
                    if (diffDays < 1) {
                      setWarn({
                        expiredCommercialRegisterNoExpireDate: true,
                      });
                    } else if (diffDays < 30) {
                      setWarn({
                        failCommercialRegisterNoExpireDate: diffDays,
                      });
                    } else {
                      if (diffDays < 90) {
                        setWarn({
                          warnCommercialRegisterNoExpireDate: diffDays,
                        });
                      }
                      validateZakat.setFieldValue(
                        "zakatcertificateExpireDate",
                        date
                      );
                      setChanged(true);
                    }
                  }}
                  onBlur={validateZakat.handleBlur}
                />
                <img
                  src={calendar}
                  className="img-fluid right_icon"
                  alt="calendar"
                  onClick={openDatepicker}
                />
              </div>
              <div className="help-text">
                <ul>
                  {validateZakat.errors.zakatcertificateExpireDate &&
                  validateZakat.touched.zakatcertificateExpireDate ? (
                    <p className="text  has-error">
                      {validateZakat.errors.zakatcertificateExpireDate}
                    </p>
                  ) : Warn.expiredCommercialRegisterNoExpireDate ? (
                    <p className="text has-error">{`this document expired, please renew your certificate and re-register`}</p>
                  ) : Warn.failCommercialRegisterNoExpireDate ? (
                    <p className="text has-error">{`this document Will expire in ${Warn.failCommercialRegisterNoExpireDate}  days, expiration date must be at least 30 days beyond the registration date, please renew your certificate and re-register`}</p>
                  ) : Warn.warnCommercialRegisterNoExpireDate ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={Warning_yellow_black}
                        className="img-fluid right_icon2"
                        alt=""
                        style={{ width: "14px", marginRight: "5px" }}
                      />
                      <p className="text" style={{ color: "#000" }}>
                        {`this document Will expire soon in ${Warn.warnCommercialRegisterNoExpireDate} days, don’t forget to renew it soon.`}
                      </p>
                    </div>
                  ) : (
                    <p className="text">
                      Must be valid, with an expiration date at least 30 days
                      beyond the registration date.
                    </p>
                  )}
                </ul>
              </div>
            </>
          ) : (
            <div className="input date populated">
              <label className="control-label" htmlFor="expiration">
                Zakat certificate expiration date*
              </label>
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                disabled
                value={new Date(
                  validateZakat.values?.zakatcertificateExpireDate
                ).toLocaleDateString("en-GB")}
                id="expiration"
                name="expiration"
              />
              <img
                src={Calendar_disabled}
                className="img-fluid right_icon "
                alt="calendar"
              />
            </div>
          )}
          <div className="help-text">
            <p className="text"></p>
          </div>
        </div>
      </div>
      {validateZakat.values.edit && (
        <div className="upload_box">
          <h6 className="sh6-med">Valid copy of zakat certificate</h6>
          <UploadFile
            className=" sm "
            load={Load}
            setLoad={setLoad}
            fieldName="zakatcertificateFile"
            title="Zakat Certificate"
            removeFile={() =>
              validateZakat.setFieldValue("zakatcertificateFile", null)
            }
            errors={validateZakat.errors.zakatcertificateFile}
            touched={validateZakat.touched.zakatcertificateFile}
            fileName={
              validateZakat.values.zakatcertificateFile?.split("/")[4] ||
              validateZakat.values.zakatcertificateFile
            }
            folderName="zakaatCertificate"
            docType="zakaatCertificate"
            companyId={companyId}
            Api="UploadFiles"
            handleBlur={() =>
              validateZakat.setFieldTouched("zakatcertificateFile")
            }
            handleChange={() => {
              validateZakat.setFieldValue("zakatcertificateFile", "Done");
              setChanged(true);
            }}
          />
        </div>
      )}
      {!validateZakat.values.edit && (
        <div className="uploaded_file">
          <Link
            target="_blank"
            to={
              (CompanyDocument?.zakatCertificateFile ||
                CompanyDocument.zakatcertificateFile) &&
              config.download
                ? config.download +
                  (CompanyDocument?.zakatCertificateFile ||
                    CompanyDocument.zakatcertificateFile)
                : ""
            }
            className="file"
          >
            <img src={attached_file} className="img-fluid icon" alt="icon" />
            <span>
              {
                (
                  CompanyDocument?.zakatCertificateFile ||
                  CompanyDocument.zakatcertificateFile
                )?.split("/")[4]
              }
            </span>
          </Link>
          <span className="hint">You can view the file by clicking on it</span>
        </div>
      )}
      {validateZakat.values.edit && (
        <div className="form_actions d-flex">
          <button
            className={
              "btn primaryBtn largeBtn " +
              (!Changed || Saving ? "disabled" : "")
            }
            type="button"
            onClick={validateZakat.handleSubmit}
          >
            {Saving ? "Saving..." : "Save changes"}
          </button>
          <button
            className={"btn secondaryBtn largeBtn mx-3"}
            type="button"
            onClick={() => {
              validateZakat.setFieldValue("edit", false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}

export default ZakatSection;
