import { useEffect, useState } from "react";
import CloseSVG from "../../Styles/images/icons/popup_close.svg";
import EPCSVG from "../../Styles/images/epic.png";
import ModalContext from "../../Store/ModalProvider";
import { useContext } from "react";
import axios from "axios";
import config from "../../IP_Configrations.json";

const host = config.static;

const ChangeCompanyAccountModal = () => {
  const userToken = localStorage.getItem("user_token");
  const { setModalComponent, setModalOpen, modalOpen } =
    useContext(ModalContext);
  const [CompanyList, setCompanyList] = useState();

  useEffect(() => {}, []);
  useEffect(() => {
    const getCompaniesList = async () => {
      if (userToken) {
        axios
          .get(`${host}GetCompaniesForList`, {
            headers: {
              Authorization: userToken,
            },
          })
          .then((res) => {
            setCompanyList(res.data.items);
          })
          .catch((err) => console.log(err));
      }

      getCompaniesList();
    };
  }, [userToken]);
  return (
    modalOpen && (
      <div className="popup active popup_contact_support change_company_account">
        <div className="popup__content">
          <div className="popup__body">
            <div className="head">
              <h5 className="sh5-bold">Select a company</h5>
              <span
                className="close"
                onClick={() => {
                  window.localStorage.setItem("toChooseCompany", false);
                  window.localStorage.removeItem("chosenCompany");
                  setModalOpen(false);
                }}
              >
                <img src={CloseSVG} className="img-fluid" alt="close" />
              </span>
            </div>
            <div className="content">
              <p className="desc">
                Please select a company to view its settings:
              </p>
              <div className="avatars">
                {CompanyList?.map((item) => (
                  <div
                    className="avatar"
                    key={item.id}
                    onClick={() => {
                      window.localStorage.setItem("CompanyId", item.id);
                      window.localStorage.setItem("toChooseCompany", false);
                      window.localStorage.removeItem("chosenCompany");
                      setModalOpen(false);
                    }}
                  >
                    <div className="img">
                      {item?.logo ? (
                        <img
                          className="avatar"
                          src={config.download + item?.logo}
                          alt="Logo"
                        />
                      ) : (
                        <div className="">
                          {item?.companyName?.length
                            ? item?.companyName[0].toUpperCase()
                            : "E"}
                        </div>
                      )}
                    </div>
                    <h6 className="sh6-med">{item?.companyName}</h6>
                    {item?.vendorId && (
                      <p className="num">Vendor ID: ({item.vendorId})</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="footer_actions">
              <button
                className="btn primaryBtn mediumBtn"
                onClick={() => {
                  window.localStorage.setItem("toChooseCompany", false);

                  window.localStorage.removeItem("chosenCompany");
                  setModalOpen(false);
                }}
              >
                {""}
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ChangeCompanyAccountModal;
