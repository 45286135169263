import React from "react";
import Castle_BG from "../../Styles/images/icons/castle_bg.svg";
import ModalContext from "../../Store/ModalProvider";
import { useContext } from "react";
const ProfileCompletness = () => {
  const { CompanyCompeltenes, setModalOpenProfile } =
    useContext(ModalContext);
  return (
    // <span></span>
    <div className="right_sec">
      <div className="your_progress">
        <div className="company_logo">
          <img src={Castle_BG} className="img-fluid" alt="castle_bg" />
          <h6 className="sh6-med link"
            onClick={() => {
              setModalOpenProfile(true);
            }}
          >Your profile completeness</h6>
        </div>
        <div className="progress_box">
          <div className="progress"   onClick={() => {
              setModalOpenProfile(true);
            }} data-percentage={CompanyCompeltenes}>
            <span className="progress-left">
              <span className="progress-bar"></span>
            </span>
            <span className="progress-right">
              <span className="progress-bar"></span>
            </span>
            <div className="progress-value"> {CompanyCompeltenes}% </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCompletness;
