import React, { Component } from "react";

import email from "../Styles/images/icons/Email.svg";
import checkmarkedCircle_20 from "../Styles/images/icons/checkmarkedCircle-20.svg";
import checkmarkedCircle_24 from "../Styles/images/icons/checkmarkedCircle-24.svg";
import JobTitle from "../Styles/images/icons/JobTitle.svg";
import Phone from "../Styles/images/icons/Phone.svg";
import Password from "../Styles/images/icons/Password.svg";
import ShowPass from "../Styles/images/icons/ShowPass.svg";
import HidePass from "../Styles/images/icons/HidePass.svg";

import error_24 from "../Styles/images/icons/error-24.svg";
import error_24_updated from "../Styles/images/icons/error_24_updated.svg";
import error_24_gray from "../Styles/images/icons/error_24_gray.svg";
import email_16 from "../Styles/images/icons/email_16.svg";
import mdi_warning from "../Styles/images/icons/mdi_warning.svg";

import Select from "react-select";
import DataServices from "../Services/DataServices";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import * as actions from "../Store/actions/registration";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import config from "../IP_Configrations.json";

const logedUserMail = window.localStorage.getItem("userEmail");
const phoneNumbersRegex = /^([0|\[0-9]{1,5})?([0-9]{9})$/;
const numbersRegex = /^\d+$/;
const CharsDigsRegex =
    /^[a-zA-Z0-9_.!@#$%^&*,<>)(-+=)'"-/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)'"-(-+=/\[\]/\{\}]+)*$/;
const CharsDigsRegex22 =
    /^[a-zA-Z_.!@#$%^&*,<>)(-+=)'"-/\[\]/\{\}]+( [a-zA-Z_.!@#$%^&*,<>)'"-(-+=/\[\]/\{\}]+)*$/;
const blackListedDomains = [
    "facebook",
    "google",
    "amazon",
    "netflix",
    "hotmail",
    "microsoft",
    "gmail",
    "yahoo",
];
const emailNotMatch = /^(?!.*(gmail|yahoo|outlook|hotmail)).*$/;

const validationSchema = Yup.object().shape({
    zipCode: Yup.string()
        .matches(numbersRegex, "Enter valid numbers for zip code")
        .test(
            "len",
            "Not valid zip code, only 5 digits",
            (val) => val?.length === 5
        )
        .required("Please enter zip code"),

    companyAddress: Yup.string()
        .matches(
            CharsDigsRegex,
            "Please enter valid address and delete white space on the end"
        )
        .required("Your address is required"),
    building: Yup.string()
        .matches(
            CharsDigsRegex,
            "Please enter valid address details and delete white space"
        )
        .required(" Your address details is required"),
    jobTitle: Yup.string()
        .matches(
            CharsDigsRegex,
            "Please enter valid job title and delete white space"
        )
        .required("Job title is required"),

    firstName: Yup.string()
        .matches(
            CharsDigsRegex22,
            "Please enter valid first name and delete white space and Arabic letters "
        )
        .required("First name is required"),

    lastName: Yup.string()
        .matches(
            CharsDigsRegex22,
            "Please enter valid last name and delete white space and Arabic letters "
        )
        .required("Last name is required"),

    phone: Yup.string()
        .matches(
            phoneNumbersRegex,
            "This phone no. is not correct, must be not less than 9 digits or more than 14 digits"
        )
        .test(
            "len",
            "This phone no. is not correct, must be not less than 9 digits or more than 14 digits",
            (val) => val?.length >= 9 && val?.length <= 14
        )
        .required(" Your phone no. is required"),

    email: Yup.string()
        .email("Not valid email address")
        .required("Your work email address is required")
        .test(
            "is-not-blacklist",
            "Not accepted email address, must be a work email address, If you want to register with a personal email (eg. Gmail, Hotmail) please contact ahmad.osman@fgc.sa",
            (value) => {
                if (value) {
                    const currentDomain = value.substring(
                        value.indexOf("@") + 1,
                        value.indexOf(".")
                    );
                    return !blackListedDomains.includes(currentDomain);
                }
            }
        )
        .test(
            "same logged user",
            "You must transfer to another email not your email",
            (value) => {
                if (value == logedUserMail) {
                    return false;
                } else {
                    return true;
                }
            }
        )
        .matches(
            emailNotMatch,
            "Not accepted email address, must be a work email address, If you want to register with a personal email (eg. Gmail, Hotmail) please contact ahmad.osman@fgc.sa"
        ),

    password: Yup.string()
        .min(8, "Password must be 8 or more characters")
        .matches(
            /(?=.*[a-z])(?=.*[A-Z])\w+/,
            "Password should contain at least one uppercase and lowercase character"
        )
        .matches(/\d/, "Password should contain at least one number")
        .matches(
            /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
            "Password should contain at least one special character"
        )
        .required("Password is required"),

    passwordConfirmation: Yup.string()
        .required("Password must be 8 or more characters")
        .when("password", (password, field) => {
            if (password) {
                return field
                    .required("Password confirmation required")
                    .oneOf([Yup.ref("password")], "Password didn't match");
            }
        }),
    countryId: Yup.string().required("Your country is required"),
    countryCode: Yup.string().required("Your country Code is required"),
    cityId: Yup.string().required("Your city is required"),
});

class StepOne extends Component {
    initialVals = {};

    constructor(props) {
        super(props);
        // console.log(this.props.currentStep);
        // console.log(this.props.activeStep);
        // console.log(localStorage.getItem("duplicatesDomain"));
        this.state = {
            isAvailable: true,
            registeredCompanies:
                localStorage.getItem("registeredCompanies") != null
                    ? JSON.parse(localStorage.getItem("registeredCompanies")) || []
                    : [],
            duplicates:
                localStorage.getItem("duplicates") != null
                    ? JSON.parse(localStorage.getItem("duplicates")) || undefined
                    : undefined,
            duplicatesDomain: undefined,
            hasBendingCompanies: false,
            invalidEmail: false,
            existMail: "",
            dublicatedUser: {},
            userObject: {
                id: 0,
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                password: "",
                jobTitle: "",
                zipCode: "",
                companyAddress: "",
                building: "",
                passwordConfirmation: "",
                countryId: null,
                cityId: null,
                countryCode: "",
                isLoading: false,
            },
            countriesCode: [],
            selectedCountryCode: { label: "Code*", value: "0" },
            countries: [],
            selectedCountry: { label: "Please select country*", value: "0" },
            cities: [],
            selectedCity: { label: "Please select city*", value: "0" },
            visualisePassword: false,
            visualiseConfirmPassword: false,
        };
        this.initialVals = this.props.isTransferOwnerShip
            ? this.props.transferedOwnerUser
            : this.props.userObject;
        this.formRef = React.createRef();
    }

    animateInputs() {
        const inputs = document.querySelectorAll(".input.large");
        inputs.forEach((input) => {
            input.addEventListener(
                "focusin",
                function (e) {
                    input.classList.add("focused");
                },
                false
            );
            input.addEventListener("focusout", function (e) {
                if (e.target.value == "") {
                    input.classList.remove("focused");
                }
            });
        });
    }

    componentDidMount() {
        this.props.isTransferOwnerShip
            ? this.props.actions.SetTabHeaders(
                "New user details",
                "",
                this.props.currentStep
            )
            : this.props.actions.SetTabHeaders(
                "User Details",
                "Some details about you for creating your account",
                this.props.currentStep
            );
        if (this.props.user.id > 0) {
            let savedUser = this.props.user;
            this.setState({
                userObject: { ...savedUser, countryCode: savedUser?.countryId },
            });
            this.fillDropDown(true);
        } else {
            this.fillDropDown(false);
        }

        this.animateInputs();
    }

    fillDropDown(isEdit) {
        DataServices.GetDataListDetailed(`GetCountriesList`, "title", "id").then(
            (res) => {
                let countriesCode = DataServices.GenerateListDetailed(
                    res,
                    "countryCode",
                    "id"
                ).filter((data) => data.label !== null);
                if (isEdit == true) {
                    let countryId = this.props.user.countryId;
                    if (countryId) {
                        let selectedCountry = res.find((c) => c.value == countryId);
                        let countryCode = selectedCountry.countryCode;
                        let currentUser = this.state.userObject;
                        currentUser.phone = currentUser.phone.replace(countryCode, "");
                        this.setState({
                            selectedCountry: selectedCountry,
                            countries: res,
                            countriesCode: countriesCode,
                            userObject: currentUser,
                        });

                        this.fillSubDropDownInEdit(countryId, true);
                    } else {
                        this.setState({
                            countries: res,
                            countriesCode: countriesCode,
                        });
                    }
                } else
                    this.setState({
                        countries: res,
                        countriesCode: countriesCode,
                    });
            }
        );
    }

    fillSubDropDownInEdit(countryId, isEdit) {
        DataServices.GetDataList(
            `GetCitiesList?countryId=` + countryId,
            "title",
            "id"
        ).then((res) => {
            if (isEdit == true) {
                let cityId = this.props.user.cityId;
                if (cityId) {
                    let selectedCity = res.find((c) => c.value == cityId);
                    this.setState({
                        selectedCity: selectedCity,
                    });
                }
            }
            this.setState({
                cities: res,
            });
        });
    }

    selectCountryHandler(e, setFieldValue, setFieldTouched) {
        if (e) {
            this.setState({
                isLoading: true,
            });
            let original_document = { ...this.state.userObject };
            let updated_document = {};
            setFieldValue("countryId", e.value);
            setFieldValue("countryCode", e.value);
            setFieldValue("cityId", null);
            setFieldValue("phone", "");
            setFieldValue("companyAddress", "");
            setFieldValue("building", "");
            setFieldTouched("phone", false);
            setFieldValue("zipCode", "");
            setFieldTouched("zipCode", false);

            if (e.action == 0) {
                setFieldValue("isSaudia", true);
                setFieldValue("isArabic", true);
            } else if (e.action == 1) {
                setFieldValue("isSaudia", false);
                setFieldValue("isArabic", true);
            } else {
                setFieldValue("isSaudia", false);
                setFieldValue("isArabic", false);
            }

            window.localStorage.setItem("countryCode", e.countryCode);

            updated_document = Object.assign(original_document, updated_document);
            DataServices.GetDataList(
                `GetCitiesList?countryId=` + e.value,
                "title",
                "id"
            ).then((res) => {
                this.setState({
                    selectedCountry: e,
                    selectedCountryCode: e,
                    selectedCity: { label: "Please select city*", value: "0" },
                    cities: res,
                    isLoading: false,
                });
            });

            this.animateInputs();
        } else {
            this.setState({
                selectedCountry: { label: "Please select country*", value: "0" },
            });
        }
    }

    selectCityHandler(e, setFieldValue, setFieldTouched) {
        setFieldValue("zipCode", "");
        setFieldTouched("zipCode", false);
        if (e) {
            this.setState({
                isLoading: true,
            });
            let original_document = { ...this.state.userObject };
            let updated_document = {};
            setFieldValue("cityId", e.value);
            updated_document = Object.assign(original_document, updated_document);
            this.setState({
                selectedCity: e,
                isLoading: false,
            });
        } else {
            this.setState({
                selectedCity: { label: " Select City", value: "0" },
            });
        }
    }

    selectCountryCodeHandler(e, setFieldValue) {
        if (e) {
            this.setState({
                isLoading: true,
            });
            let original_document = { ...this.state.userObject };
            let updated_document = {};
            updated_document.countryCode = e.value;
            setFieldValue("countryCode", e.value);
            updated_document = Object.assign(original_document, updated_document);
            this.setState({
                selectedCountryCode: e,
                isLoading: false,
            });
        } else {
            this.setState({
                selectedCountryCode: { label: "Code", value: "0" },
            });
        }
    }

    AddEditUser = (values) => {
        let userObject = { ...values };
        this.setState({ isLoading: true });
        this.props.actions.SetLoading();
        // userObject.phone =
        //   this.catchCountryCode(values.countryCode) + userObject.phone;
        this.props.actions.addUserStepOne(userObject);
    };

    EditUser = (values) => {
        let userObject = { ...values };
        this.setState({ isLoading: true });
        this.props.actions.SetLoading();
        userObject.id = this.props.user.id;
        // userObject.phone =
        //   this.catchCountryCode(values.countryCode) + userObject.phone;
        this.props.actions.editUserStepOne(userObject);
    };

    catchCountryCode(countryId) {
        let countries = this.state.countries;
        let selectedCountry = countries.find((c) => c.value == countryId);
        return selectedCountry.countryCode;
    }

    AddNewCompany = async () => {
        await this.setState({ isLoading: true });
        await this.props.actions.SetLoading();

        let AddNew = { ...this.state.AddNew };
        let userObject = { ...AddNew.user };

        await window.localStorage.setItem(
            "registeredCompanies",
            JSON.stringify(this.state.registeredCompanies)
        );
        await window.localStorage.setItem(
            "duplicates",
            JSON.stringify(this.state.duplicates)
        );
        await window.localStorage.setItem(
            "duplicatesDomain",
            JSON.stringify(this.state.duplicatesDomain)
        );
        await window.localStorage.setItem(
            "DN",
            userObject.email.split("@")[1].split(".")[0]
        );
        await window.localStorage.setItem("user_phone", userObject.phone);
        await window.localStorage.setItem(
            "userToken",
            AddNew.data.access_token
        );

        await DataServices.addObject("ReSendVerifyEmailForCode", {}).then((res) => {
            //console.log(res);
        });

        await this.props.actions.addNewCompany(userObject);
    };

    Reset = () => {
        window.localStorage.clear();
        window.location.reload();
    };

    handlerEmailBlur = async (e, setFieldError, setFieldTouched) => {
        let email = e.target.value;
        if (email) {
            let checkDomain = email.substring(
                email.indexOf("@") + 1,
                email.indexOf(".")
            );

            if (!blackListedDomains.includes(checkDomain)) {
                let emailFormal = email.split("@");
                if (emailFormal?.length > 1) {
                    this.setState({
                        CheckEmailAvailability: true,
                        registeredCompanies: [],
                        duplicates: undefined,
                        duplicatesDomain: undefined,
                    });

                    if (this.props.isTransferOwnerShip && logedUserMail == email) {
                        this.setState({
                            CheckEmailAvailability: false,
                            duplicatesDomain: false,
                            duplicates: false,
                            hasBendingCompanies: false,
                        });
                        setFieldError(
                            "email",
                            "You must transfer to another email not your email",
                            false
                        );
                        setFieldTouched("email", true);
                    } else {
                        DataServices.GetRowById(
                            `CheckEmailAvailablity?email=` + email
                        ).then((res) => {
                            localStorage.removeItem("registeredCompanies");
                            localStorage.removeItem("duplicates");
                            localStorage.removeItem("duplicatesDomain");
                            this.setState({
                                hasBendingCompanies: false,
                                invalidEmail: false,
                                isLoading: false,
                            });
                            //
                            if (res === false) {
                                DataServices.GetRowById(
                                    `CheckEmailAvailability?email=` + email
                                ).then(async (res) => {
                                    this.setState({
                                        existMail: email,
                                    });
                                    if (res.data) {
                                        // console.log("jjkj");
                                        console.log("res 1");
                                        this.setState({
                                            duplicates: res.data,
                                            dublicatedUser: res.user,
                                            registeredCompanies: res.registeredCompanies,
                                            AddNew: res,
                                            // hasBendingCompanies: res.hasBendingCompanies,
                                            invalidEmail: true,
                                            isLoading: false,
                                        });
                                        await setFieldError(
                                            "email",
                                            "Not accepted email address, must be a work email",
                                            false
                                        );
                                        await setFieldTouched("email", true);
                                    } else if (
                                        !res.data &&
                                        res.hasBendingCompanies &&
                                        res.sameEmail
                                    ) {
                                        console.log("res 2");
                                        this.setState({
                                            duplicates: { sub: email },
                                            registeredCompanies: res.registeredCompanies,
                                            AddNew: res,
                                            hasBendingCompanies: res.hasBendingCompanies,
                                            invalidEmail: true,
                                        });
                                        await setFieldError(
                                            "email",
                                            "Not accepted email address, must be a work email",
                                            false
                                        );
                                        await setFieldTouched("email", true);
                                    } else if (
                                        res.registeredCompanies?.length > 0 &&
                                        !res.data &&
                                        !res.sameEmail
                                    ) {
                                        if (!this.props.isTransferOwnerShip) {
                                            this.setState({
                                                duplicatesDomain: true,
                                                registeredCompanies: res.registeredCompanies,
                                                invalidEmail: false,
                                            });
                                            await setFieldError(
                                                "email",
                                                "Not accepted email address",
                                                true
                                            );
                                            await setFieldTouched("email", true);
                                        } else {
                                            this.setState({
                                                duplicatesDomain: false,
                                                invalidEmail: true,
                                            });
                                            setFieldError(
                                                "email",
                                                "Not accepted email address",
                                                true
                                            );
                                            await setFieldTouched("email", true);
                                        }
                                    }
                                });
                                let original_document = { ...this.state.userObject };
                                let updated_document = {};
                                updated_document = Object.assign(
                                    original_document,
                                    updated_document
                                );
                                this.setState({
                                    userObject: updated_document,
                                    isAvailable: res,
                                    CheckEmailAvailability: false,
                                });
                            } else {
                                window.localStorage.setItem(
                                    "DN",
                                    email.split("@")[1].split(".")[0]
                                );
                                this.setState({
                                    CheckEmailAvailability: false,
                                });
                                setFieldTouched("email", true);
                            }
                        });
                    }
                } else {
                    setFieldError(
                        "email",
                        "A corporate email address is required",
                        false
                    );
                    setFieldTouched("email", true);
                }
            } else {
                setFieldError("email", "A corporate email address is required", false);
                setFieldTouched("email", true);
            }
        } else {
            setFieldError("email", "A corporate email address is required", false);
            setFieldTouched("email", true);
        }
    }

    isLengthGreaterThan(str, length) {
        let word = str || "";

        return word?.length >= length;
    }

    isContainsUppercas(str) {
        return /[A-Z]/.test(str);
    }

    isContainsLowercas(str) {
        return /[a-z]/.test(str);
    }

    isContainsOneNumbe(str) {
        return /\d/.test(str);
    }

    isContainSpecialChar(str) {
        return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str);
    }

    submitHandler(values) {
        if (this.props.isTransferOwnerShip) {
            this.TranferOwnerShip(values, false);
        } else if (this.props.user.id > 0) {
            this.EditUser(values);
        } else {
            this.AddEditUser(values);
        }
    }

    TranferOwnerShip = (values, TransferToExistAccount) => {
        window.localStorage.removeItem("transferedOwnerUser");
        let userObject = { ...values };
        this.setState({ isLoading: true });
        this.props.actions.SetLoading();
        userObject.FromAccountId =
            this.props.user.id || window.localStorage.getItem("userId");
        userObject.CompanyId =
            this.props.companyId || window.localStorage.getItem("CompanyId");
        if (TransferToExistAccount) {
            userObject.ToAccountId = this.state?.duplicates?.aci
        }     
        userObject.email = TransferToExistAccount
            ? this.state.existMail
            : values.email;
        userObject.password = TransferToExistAccount ? "Pass@123" : values.password;
        this.props.actions.addTransferOwnerShipStep1(
            userObject,
            TransferToExistAccount
        );
    };

    render() {
        return (
            <>
                <Formik
                    initialValues={{ ...this.state.userObject }}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => this.submitHandler(values)}
                    innerRef={this.formRef}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError,
                        isValid,
                    }) => (
                        <Form className="proForm">
                            <div className="step1">
                                <div className="sideByside">
                                    <div className="form-group">
                                        <div
                                            className={`input large ${values.firstName ? `focused` : ""
                                                }`}
                                        >
                                            <label className="control-label" htmlFor="firstName">
                                                First name*
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className={
                                                    "form-control" +
                                                    (errors.firstName && touched.firstName
                                                        ? " has-error"
                                                        : !errors.firstName && touched.firstName
                                                            ? " has-success"
                                                            : " ")
                                                }
                                                id="firstName"
                                                name="firstName"
                                                value={values.firstName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.firstName && touched.firstName ? (
                                                <img
                                                    src={error_24}
                                                    className="img-fluid right_icon"
                                                    alt=""
                                                />
                                            ) : !errors.firstName && touched.firstName ? (
                                                <img
                                                    src={checkmarkedCircle_24}
                                                    className="img-fluid right_icon"
                                                    alt=""
                                                />
                                            ) : (
                                                " "
                                            )}
                                        </div>
                                        <div className="help-text">
                                            {errors.firstName && touched.firstName ? (
                                                <p className="text has-error">{errors.firstName}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div
                                            className={`input large ${values.lastName ? `focused` : ""
                                                }`}
                                        >
                                            <label className="control-label" htmlFor="lastName">
                                                Last name*
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className={
                                                    "form-control" +
                                                    (errors.lastName && touched.lastName
                                                        ? " has-error"
                                                        : !errors.lastName && touched.lastName
                                                            ? " has-success"
                                                            : " ")
                                                }
                                                id="lastName"
                                                name="lastName"
                                                value={values.lastName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.lastName && touched.lastName ? (
                                                <img
                                                    src={error_24}
                                                    className="img-fluid right_icon"
                                                    alt=""
                                                />
                                            ) : !errors.lastName && touched.lastName ? (
                                                <img
                                                    src={checkmarkedCircle_24}
                                                    className="img-fluid right_icon"
                                                    alt=""
                                                />
                                            ) : (
                                                " "
                                            )}
                                        </div>

                                        <div className="help-text">
                                            {errors.lastName && touched.lastName ? (
                                                <p className="text has-error">{errors.lastName}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div
                                        className={`input withIcon large ${values.email ? `focused` : ""
                                            }`}
                                    >
                                        <label className="control-label" htmlFor="email">
                                            Work email address *
                                        </label>
                                        <img
                                            src={email}
                                            className="img-fluid left_icon"
                                            alt="mail"
                                        />
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className={
                                                "form-control" +
                                                (errors.email && touched.email
                                                    ? " has-error"
                                                    : this.state?.duplicates
                                                        ? " has-error"
                                                        : this.state?.duplicatesDomain || this.state.invalidEmail
                                                            ? " has-error"
                                                            : !errors.email && touched.email
                                                                ? " has-success"
                                                                : " ")
                                            }
                                            id="email"
                                            name="email"
                                            disabled={this.props.user.id > 0 ? true : false}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                this.handlerEmailBlur(
                                                    e,
                                                    setFieldError,
                                                    setFieldTouched
                                                );
                                            }}
                                        />
                                        {this.state?.CheckEmailAvailability ? (
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : errors.email && touched.email ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon"
                                                alt="coPassword"
                                            />
                                        ) : this.state?.duplicates ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon"
                                                alt="coPassword"
                                            />
                                        ) : this.state?.duplicatesDomain || this.state.invalidEmail ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon"
                                                alt="coPassword"
                                            />
                                        ) : !errors.email && touched.email ? (
                                            <img
                                                src={checkmarkedCircle_24}
                                                className="img-fluid right_icon"
                                                alt="coPassword"
                                            />
                                        ) : (
                                            " "
                                        )}
                                    </div>
                                    <div className="help-text">
                                        <ul style={{ paddingLeft: 0 }}>
                                            {!this.state.hasBendingCompanies && (
                                                <>
                                                    {errors.email && touched.email ? (
                                                        <p className="text has-error">{errors.email}</p>
                                                    ) : (
                                                        <>
                                                            <li className="text">
                                                                Must be a work address (Usually your company
                                                                email)
                                                            </li>
                                                            <li className="text">
                                                                If you want to register with a personal email
                                                                (eg. Gmail, Hotmail) please contact
                                                                <a
                                                                    href="mailto:ahmad.osman@fgc.sa"
                                                                    className="link"
                                                                >
                                                                    <span
                                                                        className="h3-reg"
                                                                        style={{ "textDecoration": "underline" }}
                                                                    >
                                                                        {" "}
                                                                        ahmad.osman@fgc.sa
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {!this.props.isTransferOwnerShip &&
                                                this.state?.duplicatesDomain && (
                                                    <p className="text has-error">
                                                        {" "}
                                                        This domain ({values.email.split("@")[1]}) is
                                                        already associated with a registered vendor account.{" "}
                                                    </p>
                                                )}
                                        </ul>
                                    </div>
                                </div>
                                {this.state?.duplicates && !this.props.isTransferOwnerShip && (
                                    <div className="waring_box">
                                        <div className="waring_box__head">
                                            <div className="icon">
                                                <img
                                                    src={mdi_warning}
                                                    className="img-fluid"
                                                    alt="icon"
                                                />
                                            </div>
                                            <div className="text">
                                                <h3 className="title">
                                                    There’s a vendor account{" "}
                                                    <span>
                                                        ‘{values.email?.split("@")[1]?.split(".")[0]}’
                                                    </span>{" "}
                                                    associated with this email address!
                                                </h3>
                                                <p className="desc">Is this your account</p>
                                            </div>
                                        </div>
                                        {this.state?.registeredCompanies?.map((company, index) => (
                                            <div className="company_card" key={index}>
                                                <div className="company_logo">
                                                    <div className="logo">
                                                        <img
                                                            src={config?.download + company?.logo}
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <span className="company_name">
                                                        {company?.companyName}
                                                    </span>
                                                </div>
                                                <div className="info">
                                                    <div className="country">
                                                        <span className="country_name">
                                                            {company?.countryName}
                                                        </span>
                                                    </div>
                                                    <div className="account">
                                                        <div className="icon"></div>
                                                        <div className="text">
                                                            <p className="name">
                                                                {company?.contactName?.at(0) +
                                                                    company?.contactName?.at(1) +
                                                                    "**** ****" +
                                                                    company?.contactName?.at(-2) +
                                                                    company?.contactName?.at(-1)}
                                                            </p>
                                                            <span className="email">
                                                                {values.email.split("@")[0]?.substring(0, 1) +
                                                                    "************@" +
                                                                    values.email.split("@")[1]}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {this.state.hasBendingCompanies ? (
                                            <p className="desc">
                                                It is not allowed to add a new company at the moment,
                                                you have a new pending company that hasn't been approved
                                                by FGC yet, you can add a new company later after your
                                                pending company has been approved.
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                        {!this.state.hasBendingCompanies ? (
                                            <p className="option_text">
                                                What would you prefer to do?
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="sideByside">
                                            <div className="form-group">
                                                {!this.state.hasBendingCompanies ? (
                                                    <>
                                                        <button
                                                            className="btn primaryBtn largeBtn"
                                                            type="button"
                                                            onClick={this.AddNewCompany}
                                                        >
                                                            Add new company
                                                        </button>
                                                        <p className="desc">
                                                            Adding another company account with the same login
                                                            info, so you can see all data of multiple
                                                            companies accounts from one place
                                                        </p>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>

                                            {!this.state.hasBendingCompanies ? (
                                                <div className="form-group">
                                                    <button
                                                        className="btn secondaryBtn largeBtn"
                                                        type="button"
                                                        onClick={() =>
                                                            this.TranferOwnerShip(
                                                                this.state.dublicatedUser,
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Transfer account ownership
                                                    </button>

                                                    <p className="desc">
                                                        You can transfer account ownership by replacing the
                                                        current owner with another one by entering the new
                                                        one’s data. but, you’’ need approval from FGC’s
                                                        admin. for assistance, please contact our support
                                                        at:
                                                    </p>
                                                    <ul className="list-unstyled contact_info nbg">
                                                        <li>
                                                            <a href="tel:+966 11 219 0888" className="link">
                                                                <img
                                                                    src={Phone}
                                                                    className="img-fluid icon"
                                                                    alt="phone"
                                                                />
                                                                <span className="h6-reg">+966 11 219 0888</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="mailto:portal.support@fgc.sa"
                                                                className="link"
                                                            >
                                                                <img
                                                                    src={email_16}
                                                                    className="img-fluid icon"
                                                                    alt="email"
                                                                />
                                                                <span className="h6-reg">
                                                                    portal.support@fgc.sa
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div className="account_loging text-center">
                                            <Link to="/Login">
                                                <button
                                                    className="btn secondaryBtn largeBtn mx-auto"
                                                    type="button"
                                                >
                                                    Login to your account
                                                </button>
                                            </Link>
                                            <button
                                                className="btn textBtn largeBtn mx-auto"
                                                type="button"
                                                onClick={this.Reset}
                                            >
                                                Cancel registration
                                            </button>
                                        </div>
                                        <p className="support_text desc">
                                            For any other info. or inquiries, please contact our
                                            customer support: portal.support@fgc.sa
                                        </p>
                                    </div>
                                )}
                                {this.state?.duplicates && this.props.isTransferOwnerShip && (
                                    <div className="waring_box">
                                        <div className="waring_box__head">
                                            <div className="icon">
                                                <img
                                                    src={mdi_warning}
                                                    className="img-fluid"
                                                    alt="icon"
                                                />
                                            </div>
                                            <div className="text">
                                                <h3 className="title">
                                                    There’s a user account associated with email address!{" "}
                                                    <span>
                                                        ‘
                                                        {
                                                            this.state?.duplicates?.sub
                                                                ?.split("@")[1]
                                                                ?.split(".")[0]
                                                        }
                                                        ’
                                                    </span>{" "}
                                                    associated with this email address!
                                                </h3>
                                            </div>
                                        </div>

                                        {this.state?.registeredCompanies?.map((company, index) => (
                                            <div className="company_card" key={index}>
                                                <div className="company_logo">
                                                    <div className="logo">
                                                        <img
                                                            src={config?.download + company?.logo}
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <span className="company_name">
                                                        {company?.companyName}
                                                    </span>
                                                </div>
                                                <div className="info">
                                                    <div className="country">
                                                        <span className="country_name">
                                                            {company?.countryName}
                                                        </span>
                                                    </div>
                                                    <div className="account">
                                                        <div className="icon"></div>
                                                        <div className="text">
                                                            <p className="name">
                                                                {company?.contactName?.at(0) +
                                                                    company?.contactName?.at(1) +
                                                                    "**** ****" +
                                                                    company?.contactName?.at(-2) +
                                                                    company?.contactName?.at(-1)}
                                                            </p>
                                                            <span className="email">
                                                                {this.state?.duplicates?.sub
                                                                    .split("@")[0]
                                                                    ?.substring(0, 1) +
                                                                    "************@" +
                                                                    this.state?.duplicates?.sub.split("@")[1]}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <p className="option_text">
                                            Do you want to proceed in transferring{" "}
                                            {this.state?.duplicates?.sub}?{" "}
                                        </p>

                                        <div className="form_action">
                                            <div className="form-group">
                                                <button
                                                    className="btn primaryBtn largeBtn  w-100  "
                                                    type="button"
                                                    onClick={() =>
                                                        this.TranferOwnerShip(
                                                            this.state.dublicatedUser,
                                                            true
                                                        )
                                                    }
                                                >
                                                    Transfer account ownership
                                                </button>
                                            </div>
                                        </div>
                                        <p className="support_text desc">
                                            Transferring company account to another user will need
                                            approval from FGC's admin.
                                        </p>
                                    </div>
                                )}
                                {!this.props.isTransferOwnerShip &&
                                    this.state?.duplicatesDomain && (
                                        <>
                                            {/* .proForm .form-group */}
                                            {/* <div className="form-group">
                      <div className="help-text">
                        <p className="text has-error">
                       
                        </p>
                      </div>
                    </div> */}
                                            <div className="waring_box">
                                                <div className="waring_box__head">
                                                    <div className="icon">
                                                        <img
                                                            src={mdi_warning}
                                                            className="img-fluid"
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div className="text">
                                                        <h3 className="title">
                                                            There’s a vendor account associated with this
                                                            domain name!
                                                        </h3>
                                                        {/* <p className="desc">Is this your account</p> */}
                                                    </div>
                                                </div>

                                                {this.state?.registeredCompanies?.map(
                                                    (company, index) => (
                                                        <div className="company_card" key={index}>
                                                            <div className="company_logo">
                                                                <div className="logo">
                                                                    <img
                                                                        src={config?.download + company?.logo}
                                                                        className="img-fluid"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <span className="company_name">
                                                                    {company?.companyName}
                                                                </span>
                                                            </div>
                                                            <div className="info">
                                                                <div className="country">
                                                                    <span className="country_name">
                                                                        {company?.countryName}
                                                                    </span>
                                                                </div>
                                                                <div className="account">
                                                                    <div className="icon"></div>
                                                                    <div className="text">
                                                                        <p className="name">
                                                                            {company?.contactName?.at(0) +
                                                                                company?.contactName?.at(1) +
                                                                                "**** ****" +
                                                                                company?.contactName?.at(-2) +
                                                                                company?.contactName?.at(-1)}
                                                                        </p>
                                                                        <span className="email">
                                                                            {/* {this.state?.duplicatesDomain?.sub
                                  .split("@")[0]
                                  ?.substring(0, 1) +
                                  "************@" +
                                  this.state?.duplicatesDomain?.sub.split(
                                    "@"
                                  )[1]} */}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}

                                                <p className="option_text">
                                                    What would you prefer to do?
                                                </p>

                                                <div className="sideByside">
                                                    <div className="form-group">
                                                        <button
                                                            className="btn primaryBtn largeBtn"
                                                            type="button"
                                                            onClick={() => {
                                                                this.setState({
                                                                    duplicatesDomain: null,
                                                                    registeredCompanies: [],
                                                                });
                                                            }}
                                                        >
                                                            Add new company
                                                        </button>
                                                        <p className="desc">
                                                            Adding another company account with the same login
                                                            info, so you can see all data of multiple
                                                            companies accounts from one place
                                                        </p>
                                                    </div>

                                                    <div className="form-group">
                                                        <button
                                                            className="btn secondaryBtn largeBtn"
                                                            type="button"
                                                            onClick={() => this.TranferOwnerShip({}, true)}
                                                        >
                                                            Transfer account ownership
                                                        </button>
                                                        <p className="desc">
                                                            You can transfer account ownership by replacing
                                                            the current owner with another one by entering the
                                                            new one’s data. but, you’’ need approval from
                                                            FGC’s admin. for assistance, please contact our
                                                            support at:
                                                        </p>
                                                        <ul className="list-unstyled contact_info nbg">
                                                            {/* <li>
                            <a href="tel:+966 11 219 0888" className="link">
                              <img
                                src={Phone}
                                className="img-fluid icon"
                                alt="phone"
                              />
                              <span className="h6-reg">+966 11 219 0888</span>
                            </a>
                          </li> */}
                                                            {/* <li>
                            <a
                              href="mailto:portal.support@fgc.sa"
                              className="link"
                            >
                              <img
                                src={email_16}
                                className="img-fluid icon"
                                alt="email"
                              />
                              <span className="h6-reg">
                                portal.support@fgc.sa
                              </span>
                            </a>
                          </li> */}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="account_loging text-center">
                                                    <Link to="/Login">
                                                        <button
                                                            className="btn secondaryBtn largeBtn mx-auto"
                                                            type="button"
                                                        >
                                                            Login to your account
                                                        </button>
                                                    </Link>
                                                    <button
                                                        className="btn textBtn largeBtn mx-auto"
                                                        type="button"
                                                        onClick={this.Reset}
                                                    >
                                                        Cancel registration
                                                    </button>
                                                </div>
                                                <p className="support_text desc">
                                                    For any other info. or inquiries, please contact our
                                                    customer support: portal.support@fgc.sa
                                                </p>
                                            </div>
                                        </>
                                    )}
                                <div className="form-group">
                                    <div
                                        className={`input withIcon large ${values.jobTitle ? `focused` : ""
                                            }`}
                                    >
                                        <label className="control-label" htmlFor="jobTitle">
                                            Job title / Position*
                                        </label>
                                        <img
                                            src={JobTitle}
                                            className="img-fluid left_icon"
                                            alt="Position"
                                        />
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className={
                                                "form-control" +
                                                (errors.jobTitle && touched.jobTitle
                                                    ? " has-error"
                                                    : !errors.jobTitle && touched.jobTitle
                                                        ? " has-success"
                                                        : " ")
                                            }
                                            id="jobTitle"
                                            name="jobTitle"
                                            value={values.jobTitle}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />

                                        {errors.jobTitle && touched.jobTitle ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon"
                                                alt=""
                                            />
                                        ) : !errors.jobTitle && touched.jobTitle ? (
                                            <img
                                                src={checkmarkedCircle_24}
                                                className="img-fluid right_icon"
                                                alt=""
                                            />
                                        ) : (
                                            " "
                                        )}
                                    </div>
                                    <div className="help-text">
                                        {errors.jobTitle && touched.jobTitle ? (
                                            <p className="text has-error">{errors.jobTitle}</p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <Select
                                        className={
                                            "select_react large " +
                                            (errors.countryId && touched.countryId
                                                ? " has-error"
                                                : !errors.countryId && touched.countryId
                                                    ? " has-success"
                                                    : " ")
                                        }
                                        classNamePrefix="select"
                                        placeholder="Select country*"
                                        value={
                                            this.state?.countries
                                                ? this.state?.countries.find(
                                                    (option) => option.value === values.countryId
                                                )
                                                : ""
                                        }
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        options={this.state.countries}
                                        name="countryId"
                                        id="countryId"
                                        onChange={(e) => {
                                            this.selectCountryHandler(
                                                e,
                                                setFieldValue,
                                                setFieldTouched
                                            );
                                        }}
                                        onBlur={() => setFieldTouched("countryId", true)}
                                    />
                                    <div className="help-text">
                                        {errors.countryId && touched.countryId ? (
                                            <p className="text has-error">{errors.countryId}</p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="sideByside">
                                    <div className="form-group">
                                        <Select
                                            className={
                                                "select_react large " +
                                                (errors.cityId && touched.cityId
                                                    ? " has-error"
                                                    : !errors.cityId && touched.cityId
                                                        ? " has-success"
                                                        : " ")
                                            }
                                            classNamePrefix="select"
                                            placeholder="Select city*"
                                            value={
                                                this.state?.cities
                                                    ? this.state?.cities.find(
                                                        (option) => option.value === values.cityId
                                                    ) || ""
                                                    : ""
                                            }
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={true}
                                            options={this.state.cities}
                                            name="cityId"
                                            id="cityId"
                                            onChange={(e) => {
                                                this.selectCityHandler(
                                                    e,
                                                    setFieldValue,
                                                    setFieldTouched
                                                );
                                            }}
                                            onBlur={() => setFieldTouched("cityId", true)}
                                        />
                                        <div className="help-text">
                                            {errors.cityId && touched.cityId ? (
                                                <p className="text has-error">{errors.cityId}</p>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div
                                            className={`input large ${values.zipCode ? `focused` : ""
                                                }`}
                                        >
                                            <label className="control-label" htmlFor="zipCode">
                                                Zip / Postal code *
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className={
                                                    "form-control" +
                                                    (errors.zipCode && touched.zipCode
                                                        ? " has-error"
                                                        : !errors.zipCode && touched.zipCode
                                                            ? " has-success"
                                                            : " ")
                                                }
                                                id="zipCode"
                                                name="zipCode"
                                                value={values.zipCode}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            {errors.zipCode && touched.zipCode ? (
                                                <img
                                                    src={error_24}
                                                    className="img-fluid right_icon"
                                                    alt=""
                                                />
                                            ) : !errors.zipCode && touched.zipCode ? (
                                                <img
                                                    src={checkmarkedCircle_24}
                                                    className="img-fluid right_icon"
                                                    alt=""
                                                />
                                            ) : (
                                                " "
                                            )}
                                        </div>
                                        <div className="help-text">
                                            <ul>
                                                {errors.zipCode && touched.zipCode ? (
                                                    <p className="text has-error">{errors.zipCode}</p>
                                                ) : (
                                                    <p className="text">must be 5 numbers</p>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div
                                        className={`input  large ${values.companyAddress ? `focused` : ""
                                            }`}
                                    >
                                        <label className="control-label" htmlFor="companyAddress">
                                            Your detailed address *
                                        </label>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className={
                                                "form-control" +
                                                (errors.companyAddress && touched.companyAddress
                                                    ? " has-error"
                                                    : !errors.companyAddress && touched.companyAddress
                                                        ? " has-success"
                                                        : " ")
                                            }
                                            id="companyAddress"
                                            name="companyAddress"
                                            value={values.companyAddress}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.companyAddress && touched.companyAddress ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon"
                                                alt=""
                                            />
                                        ) : !errors.companyAddress && touched.companyAddress ? (
                                            <img
                                                src={checkmarkedCircle_24}
                                                className="img-fluid right_icon"
                                                alt=""
                                            />
                                        ) : (
                                            " "
                                        )}
                                    </div>
                                    <div className="help-text">
                                        {errors.companyAddress && touched.companyAddress ? (
                                            <p className="text has-error">{errors.companyAddress}</p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div
                                        className={`input  large ${values.building ? `focused` : ""
                                            }`}
                                    >
                                        <label className="control-label" htmlFor="building">
                                            Building, floor, apartment*
                                        </label>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className={
                                                "form-control" +
                                                (errors.building && touched.building
                                                    ? " has-error"
                                                    : !errors.building && touched.building
                                                        ? " has-success"
                                                        : " ")
                                            }
                                            id="building"
                                            name="building"
                                            value={values.building}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.building && touched.building ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon"
                                                alt=""
                                            />
                                        ) : !errors.building && touched.building ? (
                                            <img
                                                src={checkmarkedCircle_24}
                                                className="img-fluid right_icon"
                                                alt=""
                                            />
                                        ) : (
                                            " "
                                        )}
                                    </div>

                                    <div className="help-text">
                                        {errors.building && touched.building ? (
                                            <p className="text has-error">{errors.building}</p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="sideByside phone_w">
                                    <div className="form-group">
                                        <Select
                                            className={
                                                "select_react large " +
                                                (errors.countryCode && touched.countryCode
                                                    ? " has-error"
                                                    : !errors.countryCode && touched.countryCode
                                                        ? " has-success"
                                                        : " ")
                                            }
                                            classNamePrefix="select"
                                            placeholder="code*"
                                            value={
                                                this.state?.countriesCode
                                                    ? this.state?.countriesCode.find(
                                                        (option) => option.value === values.countryCode
                                                    )
                                                    : ""
                                            }
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={true}
                                            options={this.state.countriesCode}
                                            name="countryCode"
                                            id="countryCode"
                                            onChange={(e) => {
                                                this.selectCountryCodeHandler(e, setFieldValue);
                                            }}
                                            onBlur={() => setFieldTouched("countryCode", true)}
                                        />
                                        <div className="help-text">
                                            {errors.countryCode && touched.countryCode ? (
                                                <p className="text has-error">{errors.countryCode}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div
                                            className={`input withIcon large ${values.phone ? `focused` : ""
                                                }`}
                                        >
                                            <label className="control-label" htmlFor="phone">
                                                {" "}
                                                Phone no.*{" "}
                                            </label>
                                            <img
                                                src={Phone}
                                                className="img-fluid left_icon"
                                                alt="phone"
                                            />
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className={
                                                    "form-control " +
                                                    (errors.phone && touched.phone
                                                        ? " has-error"
                                                        : !errors.phone && touched.phone
                                                            ? " has-success"
                                                            : " ")
                                                }
                                                id="phone"
                                                name="phone"
                                                value={values.phone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            {errors.phone && touched.phone ? (
                                                <img
                                                    src={error_24}
                                                    className="img-fluid right_icon"
                                                    alt=""
                                                />
                                            ) : !errors.phone && touched.phone ? (
                                                <img
                                                    src={checkmarkedCircle_24}
                                                    className="img-fluid right_icon"
                                                    alt=""
                                                />
                                            ) : (
                                                " "
                                            )}
                                        </div>
                                        <div className="help-text">
                                            {errors.phone && touched.phone ? (
                                                <p className="text has-error">{errors.phone}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div
                                        className={`input withIcon large ${values.password ? `focused` : ""
                                            }`}
                                    >
                                        <label className="control-label" htmlFor="password">
                                            Password *
                                        </label>
                                        <img
                                            src={Password}
                                            className="img-fluid left_icon"
                                            alt="Password"
                                        />
                                        {this.state.visualisePassword ? (
                                            <img
                                                onClick={() => {
                                                    this.setState({
                                                        visualisePassword: !this.state.visualisePassword,
                                                    });
                                                }}
                                                src={HidePass}
                                                className="img-fluid showPass right_icon"
                                                alt="ShowPass"
                                            />
                                        ) : (
                                            <img
                                                onClick={() => {
                                                    this.setState({
                                                        visualisePassword: !this.state.visualisePassword,
                                                    });
                                                }}
                                                src={ShowPass}
                                                className="img-fluid showPass right_icon"
                                                alt="ShowPass"
                                            />
                                        )}
                                        {!errors.password &&
                                            touched.password &&
                                            values.password?.length == 0 ? null : errors.password &&
                                                touched.password ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon2"
                                                alt="coPassword"
                                            />
                                        ) : null}
                                        <input
                                            autoComplete="off"
                                            type={this.state.visualisePassword ? "text" : "password"}
                                            className={
                                                "form-control " +
                                                (errors.password && touched.password
                                                    ? " has-error"
                                                    : !errors.password && touched.password
                                                        ? " has-success"
                                                        : " ")
                                            }
                                            id="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.password && touched.password ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon2"
                                                alt=""
                                            />
                                        ) : !errors.password && touched.password ? (
                                            <img
                                                src={checkmarkedCircle_24}
                                                className="img-fluid right_icon2"
                                                alt=""
                                            />
                                        ) : (
                                            " "
                                        )}
                                    </div>

                                    <div className="help-text password_help">
                                        <p
                                            className={
                                                "text " +
                                                (this.isLengthGreaterThan(values.password || "", 8) ==
                                                    true
                                                    ? " has-success "
                                                    : touched.password
                                                        ? " has-error "
                                                        : "")
                                            }
                                        >
                                            <img
                                                src={
                                                    this.isLengthGreaterThan(values.password || "", 8) ==
                                                        true
                                                        ? checkmarkedCircle_20
                                                        : touched.password
                                                            ? error_24_updated
                                                            : error_24_gray
                                                }
                                                className="img-fluid"
                                                alt="close"
                                            />

                                            <span>Minimum 8 characters</span>
                                        </p>

                                        <p
                                            className={
                                                "text " +
                                                (this.isContainsUppercas(values.password || "") == true
                                                    ? " has-success "
                                                    : touched.password
                                                        ? " has-error "
                                                        : "")
                                            }
                                        >
                                            <img
                                                src={
                                                    this.isContainsUppercas(values.password || "") == true
                                                        ? checkmarkedCircle_20
                                                        : touched.password
                                                            ? error_24_updated
                                                            : error_24_gray
                                                }
                                                className="img-fluid"
                                                alt="close"
                                            />

                                            <span>One uppercase letter (eg. XYZ)</span>
                                        </p>

                                        <p
                                            className={
                                                "text " +
                                                (this.isContainsLowercas(values.password || "") == true
                                                    ? " has-success "
                                                    : touched.password
                                                        ? " has-error "
                                                        : "")
                                            }
                                        >
                                            <img
                                                src={
                                                    this.isContainsLowercas(values.password || "") == true
                                                        ? checkmarkedCircle_20
                                                        : touched.password
                                                            ? error_24_updated
                                                            : error_24_gray
                                                }
                                                className="img-fluid"
                                                alt="close"
                                            />
                                            <span>One lowercase letter (eg. xyz) </span>
                                        </p>

                                        <p
                                            className={
                                                "text " +
                                                (this.isContainsOneNumbe(values.password || "") == true
                                                    ? " has-success "
                                                    : touched.password
                                                        ? " has-error "
                                                        : "")
                                            }
                                        >
                                            <img
                                                src={
                                                    this.isContainsOneNumbe(values.password || "") == true
                                                        ? checkmarkedCircle_20
                                                        : touched.password
                                                            ? error_24_updated
                                                            : error_24_gray
                                                }
                                                className="img-fluid"
                                                alt="close"
                                            />
                                            <span>One number (eg. 123) </span>
                                        </p>

                                        <p
                                            className={
                                                "text " +
                                                (this.isContainSpecialChar(values.password || "") ==
                                                    true
                                                    ? " has-success "
                                                    : touched.password
                                                        ? " has-error "
                                                        : "")
                                            }
                                        >
                                            <img
                                                src={
                                                    this.isContainSpecialChar(values.password || "") ==
                                                        true
                                                        ? checkmarkedCircle_20
                                                        : touched.password
                                                            ? error_24_updated
                                                            : error_24_gray
                                                }
                                                className="img-fluid"
                                                alt="close"
                                            />

                                            <span>One special character (eg. @#)</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div
                                        className={`input withIcon large ${values.passwordConfirmation ? `focused` : ""
                                            }`}
                                    >
                                        <label
                                            className="control-label"
                                            htmlFor="passwordConfirmation"
                                        >
                                            Confirm password*
                                        </label>
                                        <img
                                            src={Password}
                                            className="img-fluid left_icon"
                                            alt="coPassword"
                                        />
                                        {this.state.visualiseConfirmPassword ? (
                                            <img
                                                onClick={() => {
                                                    this.setState({
                                                        visualiseConfirmPassword:
                                                            !this.state.visualiseConfirmPassword,
                                                    });
                                                }}
                                                src={HidePass}
                                                className="img-fluid showPass right_icon"
                                                alt="ShowPass"
                                            />
                                        ) : (
                                            <img
                                                onClick={() => {
                                                    this.setState({
                                                        visualiseConfirmPassword:
                                                            !this.state.visualiseConfirmPassword,
                                                    });
                                                }}
                                                src={ShowPass}
                                                className="img-fluid showPass right_icon"
                                                alt="ShowPass"
                                            />
                                        )}

                                        {errors.passwordConfirmation &&
                                            touched.passwordConfirmation ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon2"
                                                alt="coPassword"
                                            />
                                        ) : null}

                                        <input
                                            autoComplete="off"
                                            type={
                                                this.state.visualiseConfirmPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            className={
                                                "form-control " +
                                                (errors.passwordConfirmation &&
                                                    touched.passwordConfirmation
                                                    ? " has-error"
                                                    : !errors.passwordConfirmation &&
                                                        touched.passwordConfirmation
                                                        ? " has-success"
                                                        : " ")
                                            }
                                            id="passwordConfirmation"
                                            name="passwordConfirmation"
                                            value={values.passwordConfirmation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            onCopy={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                        />
                                        {errors.passwordConfirmation &&
                                            touched.passwordConfirmation ? (
                                            <img
                                                src={error_24}
                                                className="img-fluid right_icon2"
                                                alt=""
                                            />
                                        ) : !errors.passwordConfirmation &&
                                            touched.passwordConfirmation ? (
                                            <img
                                                src={checkmarkedCircle_24}
                                                className="img-fluid right_icon2"
                                                alt=""
                                            />
                                        ) : (
                                            " "
                                        )}
                                    </div>
                                    <div className="help-text">
                                        {errors.passwordConfirmation &&
                                            touched.passwordConfirmation ? (
                                            <p className="text has-error">
                                                {errors.passwordConfirmation}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="form_actions">
                                {this.state.isLoading == true ? (
                                    <button className="btn primaryBtn largeBtn disabled loading w-100">
                                        Loading...
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={handleSubmit}
                                        className={`btn primaryBtn largeBtn  w-100 ${this.state.invalidEmail ? "disabled" : ""
                                            }  `}
                                    >
                                        Save and next step
                                    </button>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.registration.user,
        currentStep: state.registration.currentStep,
        activeStep: state.registration.activeStep,
        isLoading: state.registration.isLoading,
        isTransferOwnerShip:
            state.registration.isTransferOwnerShip ||
            window.localStorage.getItem("isTransferOwnerShip"),
        companyId: state.registration.companyId,
        transferedOwnerUser:
            state.registration.transferedOwnerUser ||
            window.localStorage.getItem("transferedOwnerUser"),
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
