import React, {useState, useEffect, useContext} from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from './not.module.css'
import { SetTabHeaders } from "../Store/actions/registration";
import ProfileCompletness from "../Components/ProfileCompletness/ProfileCompletness";

import axios from "axios";
import config from '../IP_Configrations.json';
import Loading from "../Components/Loading";
import { toast } from 'react-toastify';

const host = config.static;

const NotificationsSettings = () => {
    const [loadingPage, setLoadingPage] = useState(false)

    const [communicationWays, setCommunicationWays] = useState({isEmail: false, phoneCall: false, sms:false})

    const [selectedButton, setSelectedButton] = useState('Email')

    const emailOptionsObj = {
        newVendorSubmission : false,
        updateYourPO : false
    }
    const messageOptionsObj = {
        newMessage : false,
        stateUpdate : false
    }

    const [emailOptions, setEmailOptions] = useState(emailOptionsObj)
    const [messageOptions, setMessageOptions] = useState(messageOptionsObj)
    const [wholeEmailPrefChanged, setWholeEmailPrefChanged] = useState(false)

   

    const [disturb, setDisturb] = useState(false)
    const [offHours, setOffHours] = useState({from: '16:00', to: '22:00'})
    const [errorHours, setErrorHours] = useState('')
    const [wholeDisturbChanged, setWholeDisturbChanged] = useState(false)

    const daysOffObj = {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
    }
    const [daysOff, setDaysOff] = useState(daysOffObj)

    useEffect(()=> {
        console.log(daysOff)
    }, [daysOff])

    useEffect(()=>{
        console.log(offHours)
        let re = new RegExp(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/);
        if (disturb && (!re.test(offHours.from) || !re.test(offHours.to))){
            setErrorHours('Please enter hours in HH:MM form. Ex: 04:00')
        } else {
            setErrorHours('')
        }
    },[offHours])
    
    const handleSubmitDays = () => {
        const dayss = Object.keys(daysOff).map((v,i)=> daysOff[v] ? i : '')
        const days = dayss.filter((d)=> d !== '')

        const newData = {
            companyId: CompanyId,
            scheduleObject: {
                fromTime: offHours.from + ':00',
                toTime: offHours.to + ':00'
            },
            days: days
        }

        axios.post(
            `${host}UpdateACCOUNTSNotifications`, newData,
            {
                headers: {
                    Authorization: userToken,
                    'Content-Type': 'application/json'
                },
            }
        )
        .then((res) => {
            setWholeDisturbChanged(false)
            toast.success('Changes Saved')
        })
        .catch((err) => console.log(err));

        
    }
    const handleSubmitEmailPref = () => {
        const newData = {companyId: CompanyId, ...emailOptions, ...messageOptions}
        console.log(newData)
        axios.post(
                `${host}UpdateEmailPreferences`, newData,
                {
                    headers: {
                        Authorization: userToken,
                        'Content-Type': 'application/json'
                    },
                }
            )
            .then((res) => {
                setWholeEmailPrefChanged(false)
                toast.success('Changes Saved')
            })
            .catch((err) => console.log(err));
    }

    const dispatch = useDispatch()
	const currentStep = useSelector((state) => state.registration.currentStep);

    const CompanyId = localStorage.getItem('CompanyId')
    const userToken = localStorage.getItem('userToken')

    useEffect(() => {
        const getCommunicationOptions = async () => {
            setLoadingPage(true)
            await axios.get(`${host}GetACCOUNTSNotificationsForEdit?companyId=${CompanyId}`,
                {
                    headers: {
                        Authorization: userToken,
                    },
                }
            ).then((res) => {
                console.log(res)
                if (res.data.comunicateWays ) {
                    setCommunicationWays(res.data.comunicateWays) 
                }
                if (res.data?.emailPreferences){
                    setEmailOptions(res.data?.emailPreferences)
                }
                if (res.data?.message){
                    setMessageOptions(res.data?.message)
                }
                if (res.data?.days.length > 0){
                    const daysOffObj2 = {...daysOffObj}
                    for (let day of res.data?.days ){
                        daysOffObj2[day] = true
                    }
                    setDaysOff(daysOffObj2)
                }
                if (res.data?.scheduleObject){
                    setDisturb(true)
                    const sch = res.data.scheduleObject
                    const from = sch.fromTime.substring(0, 5)
                    const to = sch.toTime.substring(0, 5)

                    setOffHours({from: from, to: to})
                }
            
                setLoadingPage(false)
            }).catch((err) => console.log(err));
        }

        getCommunicationOptions()
    }, [])

	useEffect(() => {
		dispatch(SetTabHeaders("Notifications", "", currentStep));
	})

    return (
        loadingPage ? <Loading /> : 
        <div className={`company_info ${styles.container}`}>
            <div className={styles.section}>
                <div className={styles.head}>
                        <h4 className={`title ${styles.title}`}>
                                <span>Communication options</span>
                        </h4>
                        <p className={`desc ${styles.desc}`} >
                            How would you prefer to communicate with you?
                        </p>
                </div>
                <div className={styles.top_form}>
                    <div className="form-check">
                        <input
                            className="form-check-input "
                            type="checkbox"
                            value=""
                            id="email"
                            checked={communicationWays.isEmail}
                            disabled
                        />
                        <label className="form-check-label" htmlFor="email">                                
                            Email
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input "
                            type="checkbox"
                            value=""
                            id="sms"
                            checked={communicationWays.sms}
                            disabled
                        />
                        <label className="form-check-label" htmlFor="sms">                                
                            SMS
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input "
                            type="checkbox"
                            value=""
                            id="phone"
                            checked={communicationWays.phoneCall}
                            disabled
                        />
                        <label className="form-check-label" htmlFor="phone">                                
                            Phone calls
                        </label>
                    </div>
                </div>
            </div>
            
            <div className={styles.buttons_container}>
                <div 
                    className={ selectedButton === 'Email' ? 
                        `${styles.button} ${styles.selected_button}`
                        : 
                        styles.button
                    }
                    onClick={() => setSelectedButton('Email')}

                >
                    <span>Email preferences</span>
                </div>

                <div 
                    className={ selectedButton === 'SMS' ? 
                        `${styles.button} ${styles.selected_button}`
                        : 
                        styles.button
                    }
                    onClick={() => setSelectedButton('SMS')}
                >
                    <span>SMS preferences</span>
                </div>
            </div>

            <div className={styles.selected_content}>
                <div className={styles.head}>
                    <h4 className={`title ${styles.title}`}>
                            <span>Email preferences</span>
                    </h4>
                    <p className={`desc ${styles.desc}`} >
                        Depending on your preferences, you can choose what types of email notifications to receive,
                        and when. send me email notifications for:
                    </p>
                </div>
                <div className={styles.selected_form}>
                    <div className={styles.mini_form}>
                        <div className={styles.mini_form_title}>
                            Your new vendor submission
                        </div>
                        <div className={`form-check ${styles.form_check}`}>
                            <input
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                id="submission"
                                onChange={(e)=> {
                                    setWholeEmailPrefChanged(true)
                                    setEmailOptions({...emailOptions, newVendorSubmission : e.target.checked})
                                }
                                    
                                }
                                checked={emailOptions.newVendorSubmission }
                            />
                            <label className="form-check-label" htmlFor="submission">                                
                                Updates on your submission
                            </label>
                        </div>
                    </div>

                    <div className={styles.mini_form}>
                        <div className={styles.mini_form_title}>
                            Your POs
                        </div>
                        <div className={`form-check ${styles.form_check}`}>
                            <input
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                id="pos"
                                onChange={(e)=> {
                                    setWholeEmailPrefChanged(true)
                                    setEmailOptions({...emailOptions, updateYourPO : e.target.checked})}
                                }
                                checked={emailOptions.updateYourPO }
                            />
                            <label className="form-check-label" htmlFor="pos">                                
                                Updates on your POs
                            </label>
                        </div>
                    </div>

                    <div className={styles.mini_form}>
                        <div className={styles.mini_form_title}>
                            Messages
                        </div>
                        <div className={`form-check ${styles.form_check}`}>
                            <input
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                id="message"
                                onChange={(e)=> {
                                    setWholeEmailPrefChanged(true)
                                    setMessageOptions({...messageOptions, newMessage : e.target.checked})}
                                }
                                checked={messageOptions.newMessage }
                            />
                            <label className="form-check-label" htmlFor="message">                                
                                New message
                            </label>
                        </div>
                    </div>

                    <div className={styles.mini_form}>
                        <div className={styles.mini_form_title}>
                            Stats
                        </div>
                        <div className={`form-check ${styles.form_check}`}>
                            <input
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                id="stats"
                                onChange={(e)=> {
                                    setWholeEmailPrefChanged(true)
                                    setMessageOptions({...messageOptions, stateUpdate : e.target.checked})}
                                }
                                checked={messageOptions.stateUpdate }
                            />
                            <label className="form-check-label" htmlFor="stats">                                
                                Your stats update
                            </label>
                        </div>
                    </div>

                    <button
                        className="btn primaryBtn largeBtn "
                        style={{margin: '2.5rem 0'}}
                        onClick={handleSubmitEmailPref}
                        type="submit"
                        disabled={!wholeEmailPrefChanged}
                    >
                        Save changes
                    </button>
                    
                </div>
            </div>
            <div className={styles.selected_content}>
                <div className={styles.head}>
                    <h4 className={`title ${styles.title}`}>
                            <span>Do not disturb</span>
                    </h4>
                    <p className={`desc ${styles.desc}`} >
                        Depending on your preferences, you can choose when to receive email, and SMS notifications.
                    </p>
                </div>
                <div className={styles.selected_form2}>
                    <div className={styles.mini_form}>
                        <div className={styles.mini_form_title}>
                            Schedule
                        </div>
                        <div className={`form-check ${styles.form_check_disturb}`}>
                            <input
                                className="form-check-input "
                                type="checkbox"
                                value=""
                                id="disturb"
                                onChange={(e)=> {
                                    setWholeDisturbChanged(true)
                                    setDisturb(e.target.checked)
                                }}
                                checked={disturb}
                            />
                            <label className={`form-check-label ${styles.disturb_label}`} htmlFor="disturb">                                
                                Don't disturb me from: {" "}
                                
                            </label>
                            <div className={styles.disturb_label}>
                                    {" "}
                                    <input 
                                        disabled={!disturb}
                                        value={offHours.from}
                                        pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                                        onChange={(e) => {
                                            setWholeDisturbChanged(true)
                                            setOffHours({...offHours, from: e.target.value})
                                        }}
                                    />
                                    to: {" "}
                                    <input 
                                        disabled={!disturb}
                                        value={offHours.to}
                                        pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                                        onChange={(e) => {
                                            setWholeDisturbChanged(true)
                                            setOffHours({...offHours, to: e.target.value})
                                        }}
                                    />
                            </div>
                        </div>
                        {errorHours && 
                            <div className={styles.error}>
                                {errorHours}
                            </div>
                        }
                    </div>
                    <div className={styles.days_off}>
                        <div className={styles.days_off_title}>
                             Don’t disturb me on my days off
                        </div>
                        <div className={styles.days}>
                            <div className={ daysOff[0]? styles.selected_day: styles.day_check}>
                                <input 
                                    type="checkbox"
                                    value=""
                                    id="sat"
                                    onChange={(e)=> {
                                        setWholeDisturbChanged(true)
                                        setDaysOff({...daysOff, 0: e.target.checked})
                                    }}
                                    checked={daysOff[0]}
                                />
                                <label className={styles.day_label} htmlFor="sat">
                                    Sat
                                </label>
                                
                            </div>
                            <div className={styles.vertical_line}></div>
                            <div className={ daysOff[1]? styles.selected_day: styles.day_check}>
                                <input 
                                    type="checkbox"
                                    value=""
                                    id="sun"
                                    onChange={(e)=> {
                                        setWholeDisturbChanged(true)
                                        setDaysOff({...daysOff, 1: e.target.checked})
                                    }}
                                    checked={daysOff[1]}
                                />
                                <label className={styles.day_label} htmlFor="sun">
                                    Sun
                                </label>
                                
                            </div>
                            <div className={styles.vertical_line}></div>

                            <div className={ daysOff[2]? styles.selected_day: styles.day_check}>
                                <input 
                                    type="checkbox"
                                    value=""
                                    id="mon"
                                    onChange={(e)=> {
                                        setWholeDisturbChanged(true)
                                        setDaysOff({...daysOff, 2: e.target.checked})
                                    }}
                                    checked={daysOff[2]}
                                />
                                <label className={styles.day_label} htmlFor="mon">
                                    Mon
                                </label>
                                
                            </div>
                            <div className={styles.vertical_line}></div>

                            <div className={ daysOff[3]? styles.selected_day: styles.day_check}>
                                <input 
                                    type="checkbox"
                                    value=""
                                    id="tue"
                                    onChange={(e)=> {
                                        setWholeDisturbChanged(true)
                                        setDaysOff({...daysOff, 3: e.target.checked})
                                    }}
                                    checked={daysOff[3]}
                                />
                                <label className={styles.day_label} htmlFor="tue">
                                    Tue
                                </label>
                                
                            </div>
                            <div className={styles.vertical_line}></div>

                            <div className={ daysOff[4]? styles.selected_day: styles.day_check}>
                                <input 
                                    type="checkbox"
                                    value=""
                                    id="wed"
                                    onChange={(e)=> {
                                        setWholeDisturbChanged(true)
                                        setDaysOff({...daysOff, 4: e.target.checked})
                                    }}
                                    checked={daysOff[4]}
                                />
                                <label className={styles.day_label} htmlFor="wed">
                                    Wed
                                </label>
                                
                            </div>
                            <div className={styles.vertical_line}></div>

                            <div className={ daysOff[5]? styles.selected_day: styles.day_check}>
                                <input 
                                    type="checkbox"
                                    value=""
                                    id="thu"
                                    onChange={(e)=> {
                                        setWholeDisturbChanged(true)
                                        setDaysOff({...daysOff, 5: e.target.checked})
                                    }}
                                    checked={daysOff[5]}
                                />
                                <label className={styles.day_label} htmlFor="thu">
                                    Thu
                                </label>
                                
                            </div>
                            <div className={styles.vertical_line}></div>

                            <div className={ daysOff[6]? styles.selected_day: styles.day_check}>
                                <input 
                                    type="checkbox"
                                    value=""
                                    id="fri"
                                    onChange={(e)=> {
                                        setWholeDisturbChanged(true)
                                        setDaysOff({...daysOff, 6: e.target.checked})
                                    }}
                                    checked={daysOff[6]}
                                />
                                <label className={styles.day_label} htmlFor="fri">
                                    Fri
                                </label>
                                
                            </div>

                            
                        </div>
                        <button
                            className="btn primaryBtn largeBtn "
                            style={{margin: '2.5rem 0', width: 'fit-content'}}
                            onClick={handleSubmitDays}
                            type="submit"
                            disabled={!wholeDisturbChanged || (disturb && errorHours)}
                        >
                            Save changes
                        </button>
                    </div>
                </div>
            </div>
            

        </div>
    )
}

export default NotificationsSettings;