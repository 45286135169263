const accountSetting = [
  {
    label: "Company Info",
    route: "/SettingsContainer/CompanyInfo",
    active: "CompanyInfo",
  },
  {
    label: "Vendor Categories",
    route: "/SettingsContainer/SettingVendorCategories",
    active: "SettingVendorCategories",
  },
  {
    label: "Contact Person",
    route: "/SettingsContainer/ContactPerson",
    active: "ContactPerson",
  },
  {
    label: "Financial Details",
    route: "/SettingsContainer/BankDetails",
    active: "BankDetails",
  },
  {
    label: "Company Documents",
    route: "/SettingsContainer/CompanyDocuments",
    active: "CompanyDocuments",
  },
  {
    label: "Terms and conditions",
    route: "/SettingsContainer/TermsAndConditions",
    active: "TermsAndConditions",
  },
];

const userSetting = [
  {
    label: "services",
    route: "/settingsContainer/services",
    permisssion: 10025,
  },
];

const passwordSetting = [
  {
    label: "generalConfig",
    route: "/settingsContainer/GeneralSet/options",
    permisssion: 0,
  },

  {
    label: "distributionList",
    route: "/settingsContainer/distributionList",
    permisssion: 10109,
  },
];
const securitySettig = [
  {
    label: "groupPermissions",
    route: "/settingsContainer/permissionsGroups",
    permisssion: 10199,
  },
];
export { accountSetting, userSetting, passwordSetting, securitySettig };
