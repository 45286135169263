import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import {
  ChangeLayout,
  SetTabHeaders,
} from "../../../Store/actions/registration";
import ProfileCompletness from "../../ProfileCompletness/ProfileCompletness";

import Password from "../../../Styles/images/icons/Password.svg";
import ShowPass from "../../../Styles/images/icons/ShowPass.svg";
import checkmarkedCircle_20 from "../../../Styles/images/icons/checkmarkedCircle-20.svg";
import error_24_updated from "../../../Styles/images/icons/error_24_updated.svg";
import error_24_gray from "../../../Styles/images/icons/error_24_gray.svg";

import DataServices from "../../../Services/DataServices";
import { useNavigate } from "react-router-dom";

function PasswordSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const animateInputs = () => {
    const inputs = document.querySelectorAll(".input");
    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function (e) {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value == "") {
          input.classList.remove("focused");
        }
      });
    });
  };

  useEffect(() => {
    animateInputs();

    dispatch(SetTabHeaders("Change password"));
  }, []);

  const validatePasswordForm = useFormik({
    initialValues: {
      currentPassword: "",
      ViewCurrent: false,
      checkPassword: false,
      password: "",
      ViewPass: false,
      passwordConfirmation: "",
      ViewConf: false,
    },
    validateOnMount: "true",
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string()
        .min(8, "Password must be 8 or more characters")
        .required("Current password is required"),
      password: Yup.string()
        .min(8, "Password must be 8 or more characters")
        .matches(
          /(?=.*[a-z])(?=.*[A-Z])\w+/,
          "Password should contain at least one uppercase and lowercase character"
        )
        .matches(/\d/, "Password should contain at least one number")
        .matches(
          /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
          "Password should contain at least one special character"
        )
        .required("New password is required."),
      passwordConfirmation: Yup.string()
        .min(8, "Password must be 9 or more characters")
        .oneOf([Yup.ref("password")], "The passwords do not match")
        .required("Confirm new password is required"),
    }),

    onSubmit: (values) => {
      const obj = {
        password: values.password,
        oldPawword: values.currentPassword,
      };

      DataServices.addObject("ChangePassword", obj).then((res) => {
        toast.success("Password changed successfully", {
          hideProgressBar: true,
        });
        resetForm();
        dispatch(ChangeLayout(true));
        navigate(`/Dashboard/${window.localStorage.getItem("CompanyId")}`);
      });
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    isValid,
    isSubmitting,
    resetForm,
    submitForm,
  } = validatePasswordForm;

  const isLengthGreaterThan = (str, length) => {
    let word = str || "";

    return word.length >= length;
  };

  const isContainsUppercase = (str) => {
    return /[A-Z]/.test(str);
  };

  const isContainsLowercase = (str) => {
    return /[a-z]/.test(str);
  };

  const isContainsOneNumber = (str) => {
    return /\d/.test(str);
  };

  const isContainSpecialChar = (str) => {
    return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str);
  };

  const handleCurrentPasswordBlur = (e) => {
    let currentPassword = e.target.value;
    if (currentPassword && currentPassword?.length > 8) {
      setFieldValue("checkPassword", true);
      DataServices.GetRowById(
        `GetPassWordEncrypt?password=` + currentPassword
      ).then(async (res) => {
        if (res === false) {
          setFieldValue("checkPassword", false);
          await setFieldTouched("currentPassword", true);
          await setFieldError(
            "currentPassword",
            "Invalid current password",
            false
          );
        } else {
          setFieldValue("checkPassword", false);
          setFieldTouched("currentPassword", true);
        }
      });
    } else {
      setFieldError("currentPassword", "Current password is required.", false);
      setFieldTouched("currentPassword", true);
    }
  };

  return (
    <div className="company_info">
      <div className="left_sec">
        <form className="proForm">
          <div className="form-group">
            <div
              className={
                "input withIcon " + (values.currentPassword ? "populated" : "")
              }
            >
              <label className="control-label" htmlFor="currentPassword">
                Current password*
              </label>
              <img
                src={Password}
                className="img-fluid left_icon"
                alt="currentPassword"
              />
              <img
                src={ShowPass}
                className="img-fluid showPass right_icon"
                alt="ShowPass"
                onClick={() =>
                  setFieldValue("ViewCurrent", !values?.ViewCurrent)
                }
              />
              <input
                autoComplete="off"
                type={values?.ViewCurrent ? "text" : "password"}
                className={
                  "form-control " +
                  (errors.currentPassword && touched.currentPassword
                    ? " has-error"
                    : !errors.currentPassword && touched.currentPassword && "")
                }
                id="currentPassword"
                name="currentPassword"
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleCurrentPasswordBlur}
              />
              {values?.checkPassword && (
                <div
                  className="spinner-border"
                  style={{ marginRight: "30px" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
            <div className="help-text">
              {errors.currentPassword && touched.currentPassword && (
                <p className="text has-error">{errors.currentPassword}</p>
              )}
            </div>
          </div>

          <div className="form-group">
            <div
              className={
                "input withIcon " + (values.password ? "populated" : "")
              }
            >
              <label className="control-label" htmlFor="password">
                Enter new password *
              </label>
              <img
                src={Password}
                className="img-fluid left_icon"
                alt="Password"
              />
              <img
                src={ShowPass}
                className="img-fluid showPass right_icon"
                alt="ShowPass"
                onClick={() => setFieldValue("ViewPass", !values?.ViewPass)}
              />
              <input
                autoComplete="off"
                type={values?.ViewPass ? "text" : "password"}
                className={
                  "form-control " +
                  (errors.password && touched.password
                    ? " has-error"
                    : !errors.password && touched.password && "")
                }
                id="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="help-text password_help">
              <p
                className={
                  "text " +
                  (isLengthGreaterThan(values.password, 8) == true
                    ? " has-success "
                    : touched.password
                    ? " has-error "
                    : "")
                }
              >
                <img
                  src={
                    isLengthGreaterThan(values.password, 8) == true
                      ? checkmarkedCircle_20
                      : touched.password
                      ? error_24_updated
                      : error_24_gray
                  }
                  className="img-fluid"
                  alt="close"
                />

                <span>Minimum 8 characters</span>
              </p>

              <p
                className={
                  "text " +
                  (isContainsUppercase(values.password) == true
                    ? " has-success "
                    : touched.password
                    ? " has-error "
                    : "")
                }
              >
                <img
                  src={
                    isContainsUppercase(values.password) == true
                      ? checkmarkedCircle_20
                      : touched.password
                      ? error_24_updated
                      : error_24_gray
                  }
                  className="img-fluid"
                  alt="close"
                />

                <span>One uppercase letter (eg. XYZ)</span>
              </p>

              <p
                className={
                  "text " +
                  (isContainsLowercase(values.password) == true
                    ? " has-success "
                    : touched.password
                    ? " has-error "
                    : "")
                }
              >
                <img
                  src={
                    isContainsLowercase(values.password) == true
                      ? checkmarkedCircle_20
                      : touched.password
                      ? error_24_updated
                      : error_24_gray
                  }
                  className="img-fluid"
                  alt="close"
                />
                <span>One lowercase letter (eg. xyz) </span>
              </p>

              <p
                className={
                  "text " +
                  (isContainsOneNumber(values.password) == true
                    ? " has-success "
                    : touched.password
                    ? " has-error "
                    : "")
                }
              >
                <img
                  src={
                    isContainsOneNumber(values.password) == true
                      ? checkmarkedCircle_20
                      : touched.password
                      ? error_24_updated
                      : error_24_gray
                  }
                  className="img-fluid"
                  alt="close"
                />
                <span>One number (eg. 123) </span>
              </p>

              <p
                className={
                  "text " +
                  (isContainSpecialChar(values.password) == true
                    ? " has-success "
                    : touched.password
                    ? " has-error "
                    : "")
                }
              >
                <img
                  src={
                    isContainSpecialChar(values.password) == true
                      ? checkmarkedCircle_20
                      : touched.password
                      ? error_24_updated
                      : error_24_gray
                  }
                  className="img-fluid"
                  alt="close"
                />

                <span>One special character (eg. @#)</span>
              </p>
            </div>
          </div>

          <div className="form-group">
            <div
              className={
                "input withIcon " +
                (values.passwordConfirmation ? "populated" : "")
              }
            >
              <label className="control-label" htmlFor="passwordConfirmation">
                Confirm new password*
              </label>
              <img
                src={Password}
                className="img-fluid left_icon"
                alt="passwordConfirmation"
              />
              <img
                src={ShowPass}
                className="img-fluid showPass right_icon"
                alt="ShowPass"
                onClick={() => setFieldValue("ViewConf", !values?.ViewConf)}
              />
              <input
                autoComplete="off"
                type={values?.ViewConf ? "text" : "password"}
                className={
                  "form-control " +
                  (errors.passwordConfirmation && touched.passwordConfirmation
                    ? " has-error"
                    : !errors.passwordConfirmation &&
                      touched.passwordConfirmation &&
                      "")
                }
                id="passwordConfirmation"
                name="passwordConfirmation"
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="help-text">
              {errors.passwordConfirmation && touched.passwordConfirmation && (
                <p className="text has-error">{errors.passwordConfirmation}</p>
              )}
            </div>
          </div>

          <div className="form_actions">
            <button
              className={
                "btn primaryBtn largeBtn " +
                ((!isValid || isSubmitting) && "disabled")
              }
              type="button"
              onClick={submitForm}
            >
              {isSubmitting ? "Saving..." : "Save changes"}
            </button>
          </div>
        </form>
      </div>
      <ProfileCompletness />
    </div>
  );
}

export default PasswordSettings;
