import React, { useEffect, useState } from "react";
import CompanyLogo from "../../Styles/images//dashboard/company_logo.svg";
import "react-circular-progressbar/dist/styles.css";
import ModalContext from "../../Store/ModalProvider";
import { useContext } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import config from "../../IP_Configrations.json";
import API from "../../Services/Axios";

const host = config.static;


const HeaderDashboard = ({ dataDashboard }) => {
    const firstName = dataDashboard?.contactName?.split(" ")[0];
    const { setModalOpenProfile } =
        useContext(ModalContext);
    const countSteps = (obj) => {
        let count = 0;
        for (let key in obj) {
            if (obj[key] === true) {
                count++;
            }
        }
        return count + 5;
    }
    const [count, setCount] = useState(0);

    const companyId = useSelector((state) => state.registration.companyId) || localStorage.getItem("CompanyId");

    const [data, setData] = useState({})

    useEffect(() => {
        const getProgress = async () => {
            await axios.get(`${host}GetCompletnessPercentage/${companyId}`).then((result) => {
                setData(result.data)
                setCount(countSteps(result.data))
            })
        }
        getProgress();

    }, [])

    return (


        <>
            {data.profileCompletenessRatio !== 100 && <>
                <div className="sec_container main">
                    <div className="main_title">
                        <h1 className="name">Hello {firstName}</h1>
                        <div className="company_name">
                            <img src={CompanyLogo} className="img-fluid" alt="logo" />
                            <h2 className="sh2-bold">{dataDashboard?.companyName}</h2>
                        </div>
                        <h5 className='sh5-bold'>This is your dashboard!</h5>
                        <h6 className="sh6-med">
                            All your stats will be shown here after approving your registration
                            request
                        </h6>
                    </div>
                    <div className=" company-complatess ">
                        <div className="img-div">
                            <svg width="33" height="32" viewBox="0 0 33 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" width="32" height="32" rx="16" fill="white" />
                                <path
                                    d="M10 15.9007V18.9861C10 19.685 10.5842 20.2432 11.3 20.2432C12.0158 20.2432 12.6 19.685 12.6 18.9861V15.9007C12.6 15.2018 12.0158 14.6436 11.3 14.6436C10.5842 14.6436 10 15.2018 10 15.9007ZM14.8 15.9007V18.9861C14.8 19.685 15.3842 20.2432 16.1 20.2432C16.8158 20.2432 17.4 19.685 17.4 18.9861V15.9007C17.4 15.2018 16.8158 14.6436 16.1 14.6436C15.3842 14.6436 14.8 15.2018 14.8 15.9007ZM22.5 24.1C23.2158 24.1 23.8 23.5418 23.8 22.843C23.8 22.1441 23.2158 21.5859 22.5 21.5859H9.7C8.98424 21.5859 8.4 22.1441 8.4 22.843C8.4 23.5418 8.98424 24.1 9.7 24.1H22.5ZM19.6 15.9007V18.9861C19.6 19.685 20.1842 20.2432 20.9 20.2432C21.6158 20.2432 22.2 19.685 22.2 18.9861V15.9007C22.2 15.2018 21.6158 14.6436 20.9 14.6436C20.1842 14.6436 19.6 15.2018 19.6 15.9007ZM15.3102 8.09045L8.99172 11.2986C8.99155 11.2986 8.99138 11.2987 8.99121 11.2988C8.6303 11.4773 8.4 11.843 8.4 12.2367C8.4 12.8276 8.89624 13.3009 9.5 13.3009H22.708C23.3043 13.3009 23.8 12.8271 23.8 12.2367C23.8 11.843 23.5697 11.4773 23.2088 11.2988C23.2086 11.2987 23.2085 11.2986 23.2083 11.2986L16.8898 8.09045C16.8897 8.09041 16.8896 8.09037 16.8896 8.09032C16.397 7.83656 15.8031 7.83656 15.3104 8.09032C15.3104 8.09037 15.3103 8.09041 15.3102 8.09045Z"
                                    fill="#AA1E2E" stroke="#AA1E2E" strokeWidth="0.2" />
                            </svg>

                        </div>
                        <h4 className="cyp_title">Complete your company profile</h4>
                        <div className="footer">
                            <p onClick={() => {
                                setModalOpenProfile(true);
                            }}>
                                Your profile completeness
                            </p>

                            <div className="outer_circle" onClick={() => {
                                setModalOpenProfile(true);
                            }}>
                                <div className="inner_circle"
                                    style={{ clip: `rect(0px, 60px, ${data.ProfileCompletenessRatio / 100 * 60}px, 0px)` }}>
                                </div>
                                <p className={"progress_number_percentage"}>{data.ProfileCompletenessRatio}%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"profile_completeness_steps"} onClick={() => {
                        setModalOpenProfile(true);
                    }}>
                    <div className="mb-3 d-flex align-items-center">
                        <p className={"the_title"}>Get started checklist</p>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.1495 6C8.31662 6.00177 8.46063 6.06419 8.58803 6.19651L12.3386 10.0248C12.446 10.1363 12.5 10.2692 12.5 10.4297C12.5 10.7459 12.2517 11 11.9324 11C11.7768 11 11.6328 10.9367 11.5209 10.8271L8.15038 7.37086L4.77811 10.8271C4.66713 10.9358 4.52625 11 4.36574 11C4.04734 11 3.79809 10.7459 3.79809 10.4297C3.79809 10.271 3.8521 10.1363 3.96358 10.0248L7.71096 6.19651C7.8424 6.06331 7.98327 6 8.1495 6Z"
                                fill="#334252" />
                        </svg>
                    </div>

                    <div className="d-flex align-items-center" >


                        {[...Array(6)].map((item, index) => {
                            const total = 6;
                            return (
                                <div className={`steps ${count > index ? "active" : ""}`} key={index}>
                                </div>
                            )
                        })}
                        <div className={`steps ${dataDashboard?.DtoProfileCompleteness?.CompanyLogo ? "active" : ""}`}></div>
                        <div
                            className={` steps ${dataDashboard?.DtoProfileCompleteness?.AditionalCompanyInfo ? "active" : ""}`}
                        ></div>
                        <div
                            className={` steps ${dataDashboard?.DtoProfileCompleteness?.VerifyRecoveryMail ? "active" : ""}`}
                        ></div>
                        <div className={`steps ${dataDashboard?.DtoProfileCompleteness?.CompanySignature ? "active" : ""}`}></div>
                        <div
                            className={`steps ${dataDashboard?.DtoProfileCompleteness?.CompanyAddtionalDocuments ? "active" : ""}`}
                        ></div>
                    </div>
                </div>
            </>}

        </>
    );
};

export default HeaderDashboard;