import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import config from "../../../../IP_Configrations.json";
import UploadFile from "../../../UploadFile";

import checkmarkedCircle_20 from "../../../../Styles/images/icons/checkmarkedCircle-20.svg";
import attached_file from "../../../../Styles/images/settings/attached_file.svg";
import Phone from "../../../../Styles/images/icons/Phone.svg";
import Error_20 from "../../../../Styles/images/icons/error-20.svg";

const CharsDigsRegex = /^[a-zA-Z0-9_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;
const numbersRegex = /^[0-9+]+$/;

function RepresentativeSection({ CompanyDocument, SaveChanges, Saving, companyId }) {
    const [Changed, setChanged] = useState(false);
    const validate = useFormik({
        enableReinitialize: true,
        initialValues: {
            hasOtherAuthorized: CompanyDocument?.hasOtherAuthorized ? true : false,
            representativeName: CompanyDocument?.representativeName,
            representativePhoneNumber: CompanyDocument?.representativePhoneNumber,
            representativeFile: CompanyDocument?.representativeFile,
        },
        validationSchema: Yup.object().shape({
            representativeName: Yup.string().matches(CharsDigsRegex, "Invalid name Please ensure that the name consists of digits amd numbers with no extra spaces.").max(30, "Max length of string is 30").required("Representative full name is required"),
            representativePhoneNumber: Yup.string()
                .matches(numbersRegex, "Invalid phone no. Please ensure that the number consists of digits only with no spaces or any other characters.")
                .min(9, "must be not less than 9 numbers")
                .max(14, "must be less than 14 numbers")
                .required("Representative phone number is required"),
            representativeFile: Yup.mixed().required("file copy is required"),
        }),

        onSubmit: (values) => {
            SaveChanges(values, "Commercial registration");
            validate.setFieldValue("edit", false);
            setChanged(false);
        },
    });

    const [Load, setLoad] = useState(false);

    return (
        <div className="content">
            <div className="sub_head">
                <div className="top">
                    <h4 className="title">
                        <span>Is there any other authorized company representative?</span>
                        {CompanyDocument?.representativeFile ? validate.values.edit ? "" : <img src={checkmarkedCircle_20} className="img-fluid icon" alt="checked" /> : <img src={Error_20} className="img-fluid icon" alt="info" />}
                    </h4>
                    {!validate.values.edit && (
                        <span
                            className="edit"
                            role="button"
                            onClick={() => {
                                validate.setFieldValue("edit", true);
                            }}
                        >
                            Edit
                        </span>
                    )}
                </div>
                <p className="desc">
                    An authorized company representative is a person who is a principal executive officer or other company officers with signatory powers as per the company's by-laws or per a vote of the directors if the company is a corporation, a general partner or proprietor
                    if the company is a partnership or sole proprietorship respectively; or a duly authorized representative. Changing company’s authorized representative will require FGC’s admin approval.
                </p>
            </div>
            {/* <div className="waring_box with_action sm gray sh6-med ">
                <div className="text">
                    <img src={Warning_yellow_blackBG_24} className="img-fluid icon" alt="warning" />
                    <span>
                        Your request for changing company representative is pending approval <br />
                        <a href="#" className="link">
                            See details
                        </a>
                    </span>
                </div>
                <div className="action">
                    <button className="btn textBtn mediumBtn">Undo</button>
                </div>
            </div> */}
            <div className="sideByside check_items">
                <div className="form-group">
                    <div className={"form-check custom " + (validate.values?.hasOtherAuthorized ? "selected " : "") + (validate.values.edit ? "" : " disabled")}>
                        <input
                            className="form-check-input rounded-circle"
                            type="radio"
                            name="isAuth"
                            id="yes"
                            disabled={validate.values.edit ? false : true}
                            checked={validate.values?.hasOtherAuthorized ? true : false}
                            onClick={() => {
                                if (!validate.values?.hasOtherAuthorized) {
                                    validate.setFieldValue("hasOtherAuthorized", true);
                                    setChanged(true);
                                }
                            }}
                        />
                        <label className="form-check-label" htmlFor="yes">
                            Yes
                        </label>
                    </div>
                </div>

                <div className="form-group">
                    <div className={"form-check custom " + (!validate.values?.hasOtherAuthorized ? "selected " : "") + (validate.values.edit ? "" : " disabled")}>
                        <input
                            className="form-check-input rounded-circle"
                            type="radio"
                            name="isAuth"
                            id="no"
                            disabled={validate.values.edit ? false : true}
                            checked={validate.values?.hasOtherAuthorized ? false : true}
                            onClick={() => {
                                if (validate.values?.hasOtherAuthorized) {
                                    validate.setFieldValue("hasOtherAuthorized", false);
                                    // validate.setFieldValue("representativeName", "");
                                    // validate.setFieldValue("representativePhoneNumber", "");
                                    // validate.setFieldValue("representativeFile", "");
                                    setChanged(true);
                                }
                            }}
                        />
                        <label className="form-check-label" htmlFor="no">
                            No, Only company owner
                        </label>
                    </div>
                </div>
            </div>

            {validate.values?.hasOtherAuthorized && (
                <div className="sideByside">
                    <div className="form-group">
                        <div className={"input " + (validate.values.representativeName ? "populated" : "")}>
                            <label className="control-label" htmlFor="Representative_name">
                                Representative full name*
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                className={"form-control " + (validate.errors.representativeName && validate.touched.representativeName && "has-error")}
                                disabled={validate.values.edit ? false : true}
                                value={validate.values?.representativeName}
                                onChange={(e) => {
                                    validate.handleChange(e);
                                    setChanged(true);
                                }}
                                onBlur={validate.handleBlur}
                                id="representativeName"
                                name="representativeName"
                            />
                        </div>
                        <div className="help-text">{validate.values.edit && <ul>{validate.errors.representativeName && validate.touched.representativeName && <p className="text has-error">{validate.errors.representativeName}</p>}</ul>}</div>
                    </div>

                    <div className="form-group">
                        <div className={"input withIcon " + (validate.values.representativePhoneNumber ? "populated" : "")}>
                            <label className="control-label" htmlFor="Phone_no">
                                Phone no.*
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                className={"form-control " + (validate.errors.representativePhoneNumber && validate.touched.representativePhoneNumber && "has-error")}
                                disabled={validate.values.edit ? false : true}
                                value={validate.values?.representativePhoneNumber}
                                onChange={(e) => {
                                    validate.handleChange(e);
                                    setChanged(true);
                                }}
                                onBlur={validate.handleBlur}
                                id="representativePhoneNumber"
                                name="representativePhoneNumber"
                            />
                            <img src={Phone} className="img-fluid left_icon" alt="calendar" />
                        </div>
                        <div className="help-text">{validate.values.edit && <ul>{validate.errors.representativePhoneNumber && validate.touched.representativePhoneNumber && <p className="text has-error">{validate.errors.representativePhoneNumber}</p>}</ul>}</div>
                    </div>
                </div>
            )}

            {validate.values?.hasOtherAuthorized && validate.values.edit && (
                <div className="upload_box">
                    <h6 className="sh6-med">Upload a copy of authorized company representation document</h6>
                    <UploadFile
                        className=" sm "
                        load={Load}
                        setLoad={setLoad}
                        fieldName="representativeFile"
                        title="Representative Signature"
                        removeFile={() => validate.setFieldValue("representativeFile", null)}
                        errors={validate.errors.representativeFile}
                        touched={validate.touched.representativeFile}
                        fileName={validate.values.representativeFile?.split("/")[4] || validate.values.representativeFile}
                        folderName="representativeFile"
                        docType="representativeFile"
                        companyId={companyId}
                        Api="UploadFiles"
                        handleBlur={() => validate.setFieldTouched("representativeFile")}
                        handleChange={() => {
                            validate.setFieldValue("representativeFile", "Done");
                            setChanged(true);
                        }}
                    />
                </div>
            )}
            {validate.values?.hasOtherAuthorized && validate.values.representativeFile && !validate.values.edit && (
                <div className="uploaded_file">
                    <Link target="_blank" to={CompanyDocument.representativeFile && config.download ? config.download + CompanyDocument.representativeFile : ""} className="file">
                        <img src={attached_file} className="img-fluid icon" alt="icon" />
                        <span>{CompanyDocument.representativeFile?.split("/")[4]}</span>
                    </Link>
                    <span className="hint">You can view the file by clicking on it</span>
                </div>
            )}
            {validate.values.edit && (
                <div className="form_actions d-flex">
                    <button className={"btn primaryBtn largeBtn " + (!Changed || Saving ? "disabled" : "")} type="button" onClick={validate.handleSubmit}>
                        {Saving ? "Saving..." : "Save changes"}
                    </button>
                    <button
                        className={"btn secondaryBtn largeBtn mx-3"}
                        type="button"
                        onClick={() => {
                            validate.setFieldValue("edit", false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    );
}

export default RepresentativeSection;
