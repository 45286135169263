import React, { Component } from "react";

import back from "../Styles/images/icons/arright-left-16.svg";
import Select from "react-select";
import DataServices from "../Services/DataServices";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import * as actions from "../Store/actions/registration";
import { bindActionCreators } from "redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Previews from "../Components/UploadFile";
import Phone from "../Styles/images/icons/Phone.svg";
import calendar from "../Styles/images/icons/calendar.svg";
import Warning_yellow from "../Styles/images/icons/Warning_yellow.svg";
import Warning_yellow_black from "../Styles/images/icons/Warning_yellow_black.svg";
import checkmarkedCircle_24 from "../Styles/images/icons/checkmarkedCircle-24.svg";
import error_24 from "../Styles/images/icons/error-24.svg";
import example from "../Styles/images/example.jpg";
import axios from "axios";
import config from "../IP_Configrations.json";
import { DeleteOutlined } from "@ant-design/icons";

const host = config.static;

const numbersRegex = /\d/;
const numRegex = /^[0-9]+$/;

const CharsDigsRegex = /^[a-zA-Z0-9_.!@#$%^&*,<>)(-+=)/\[\]/\{\}]+( [a-zA-Z0-9_.!@#$%^&*,<>)(-+=/\[\]/\{\}]+)*$/;

class StepFour extends Component {
  constructor(props) {
    super(props);
    let currentDate = new Date();
    let startDate = new Date();
    var numberOfDaysToAdd = 30;
    let minDate = currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd);

    const countryCode = window.localStorage.getItem("countryCode");

    this.state = {
      isAvailable: true,
      companyDetailsObject: {
        vatNo: "",

        vatCertificationFile: null,
        commercialRegisterNoFile: null,
        zakatcertificateFile: null,
        addressFile: null,
        representativeFile: null,

        commercialRegisterNo: "",
        commercialRegisterNoExpireDate: null,
        commercialRegisterExpireDate: minDate,

        isSaudia: true,
        isArabic: true,

        buildingNo: "",
        street: "",
        shortAddress: "",
        secondryNo: "",
        cityId: null,
        districtId: null,
        zipCode: "",

        hasOtherAuthorized: null,

        zakatcertificateNo: "",
        zakatcertificateExpireDate: null,

        representativeName: "",
        representativePhoneNumber: countryCode,
      },

      warnCommercialRegisterNoExpireDate: false,
      failCommercialRegisterNoExpireDate: false,
      expiredCommercialRegisterNoExpireDate: false,

      warnZakatcertificateExpireDate: false,
      failZakatcertificateExpireDate: false,
      expiredZakatcertificateExpireDate: false,

      validationSchema: {},
      districts: [],
      selectedDistricts: { label: " Select District *", value: "0" },
      districtSearchTextObj: {
        id: "",
        title: "",
      },
      cities: [],
      selectedCity: { label: " Select City *", value: "0" },
      isSaudia: false,
      load: false,
    };
  }
  setLoad = () => {
    this.setState({
      load: !this.state.load,
    });
  };
  createValidationSchema = (Saudia, hasOtherAuthorized) => {
    let isSaudia = this.props.company.isSaudia;

    let validationSchema = Yup.object().shape({
      vatNo:
        isSaudia == false
          ? Yup.string().nullable(true)
          : Yup.string()
              .matches(
                numRegex,
                "Invalid company VAT number, Please ensure that the number consists of 15 digits with no spaces or other characters."
              )
              .test(
                "len",
                "Invalid company VAT number, Please ensure that the number consists of 15 digits with no spaces or other characters.",
                (val) => val.length === 15
              )
              .required(" company VAT number is required"),

      commercialRegisterNo:
        isSaudia == true
          ? Yup.string()
              .matches(
                numRegex,
                "Invalid company commercial registration number. Please ensure that the number consists of 10 digits with no spaces or other characters"
              )
              .test(
                "len",
                "Invalid company commercial registration number. Please ensure that the number consists of 10 digits with no spaces or other characters",
                (val) => val.length === 10
              )
              .required("Company commercial registration no. is required")
          : Yup.string()
              .matches(
                numRegex,
                "Invalid company registration number. must only contain numbers with no spaces or other characters."
              )
              .required("Company registration number is required"),

      commercialRegisterNoExpireDate: Yup.date()
        .typeError("Invalid company registration date.")
        .required("Commercial registration certificate expiration date is required"),
      zakatcertificateExpireDate:
        isSaudia === false
          ? Yup.string().nullable(true)
          : Yup.date()
              .typeError("Invalid Zakat certificate date.")
              .required("Zakat certificate expiration date is required"),

      zakatcertificateNo:
        isSaudia == false
          ? Yup.string().nullable(true)
          : Yup.string()
              .matches(
                numRegex,
                "Invalid zakat certificate no. Please ensure that the number consists of 10 digits with no spaces or any other characters."
              )
              .test(
                "len",
                "Invalid zakat certificate no. Please ensure that the number consists of 10 digits with no spaces or any other characters.",
                (val) => val.length === 10
              )
              .required("Zakat certificate no. is required.")
              .nullable(),

      shortAddress:
        isSaudia == false
          ? Yup.string().nullable(true)
          : Yup.string()
              .matches(
                /^[a-zA-Z]{4}[0-9]{4}$/g,
                "Invalid Short address. Please ensure that the number consists of 4 characters then 4 numbers with no spaces or any special characters."
              )
              .max(
                8,
                "Invalid Short address. Please ensure that the number consists of 4 characters then 4 numbers with no spaces or any special characters."
              )
              .min(
                8,
                "Invalid Short address. Please ensure that the number consists of 4 characters then 4 numbers with no spaces or any special characters."
              ),
      street: isSaudia == false ? Yup.string().nullable(true) : Yup.string().required("Street is required"),
      secondryNo:
        isSaudia == false
          ? Yup.string().nullable(true)
          : Yup.string()
              .required("Secondary no. is required")
              .matches(
                numRegex,
                "Invalid secondary no. Please ensure that the number consists of 4 digits with no spaces or any other characters."
              )
              .test(
                "len",
                "Invalid secondary no. Please ensure that the number consists of 4 digits with no spaces or any other characters.",
                (val) => val.length === 4
              ),
      buildingNo:
        isSaudia == false
          ? Yup.string().nullable(true)
          : Yup.string()
              .required("Building no. is required")
              .matches(
                numRegex,
                "Invalid building no. Please ensure that the number consists of 4 digits with no spaces or any other characters."
              )
              .test(
                "len",
                "Invalid building no. Please ensure that the number consists of 4 digits with no spaces or any other characters.",
                (val) => val.length === 4
              ),
      cityId: isSaudia == false ? Yup.string().nullable(true) : Yup.string().required("City is required"),
      districtId: isSaudia ? Yup.string().required("District is required") : Yup.string().nullable(true),
      zipCode:
        isSaudia == false
          ? Yup.string().nullable(true)
          : Yup.string()
              .required("Zip / Postal code is required")
              .matches(
                numRegex,
                "Invalid zip code Please ensure that the number consists of 5 digits with no spaces or any other characters."
              )
              .test(
                "len",
                "Invalid zip code Please ensure that the number consists of 5 digits with no spaces or any other characters.",
                (val) => val.length === 5
              )
              .nullable(),

      vatCertificationFile:
        isSaudia == false ? Yup.string().nullable(true) : Yup.mixed().required("A vat Certification File is required"),

      commercialRegisterNoFile:
        isSaudia === false
          ? Yup.string().nullable(true)
          : Yup.mixed().required("A commercial Register  File is required"),

      zakatcertificateFile:
        isSaudia === false ? Yup.string().nullable(true) : Yup.mixed().required("A zakat certificate File is required"),

      addressFile:
        isSaudia === false ? Yup.string().nullable(true) : Yup.mixed().required("A address File is required"),

      representativeFile:
        hasOtherAuthorized === false
          ? Yup.string().nullable(true)
          : Yup.mixed().required("A representative File is required"),

      representativePhoneNumber:
        hasOtherAuthorized === false
          ? Yup.string().nullable(true)
          : Yup.string()
              .matches(
                numbersRegex,
                "Invalid phone no. Please ensure that the number consists of digits only with no spaces or any other characters."
              )
              .min(9, "must be not less than 9 numbers")
              .max(14, "must be less than 14 numbers")
              .required("Representative phone number is required"),

      representativeName:
        hasOtherAuthorized === false
          ? Yup.string().nullable(true)
          : Yup.string()
              .matches(CharsDigsRegex, "English characters")
              .max(30, "Max length of string is 30")
              .required("Representative full name is required")
              .trim(),
    });
    this.setState({ validationSchema });
  };
  animateInputs() {
    const inputs = document.querySelectorAll(".input.large");
    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function (e) {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value == "") {
          input.classList.remove("focused");
        }
      });
    });
  }

  componentDidMount() {
    this.animateInputs();
    let countryId =
      localStorage.getItem("thirdStep") === null ? {} : JSON.parse(window.localStorage.getItem("thirdStep")).countryId;

    this.props.actions.SetTabHeaders(
      "Registration documents",
      "In order to complete your vendor registration process, please provide your us with these required documents ",
      this.props.currentStep
    );
    DataServices.GetDataList(`GetCitiesList?countryId=` + countryId, "title", "id").then((res) => {
      this.setState({
        cities: res,
        isLoading: false,
      });
    });
    // DataServices.GetDataList(`GetDistricts`, "title", "id").then((res) => {
    //   this.setState({
    //     districts: res,
    //     isLoading: false,
    //   });
    // });

    this.createValidationSchema(this.props.company.isSaudia, false);

    const Company_Details = JSON.parse(window.localStorage.getItem("Company_Details")) || {};
    if (Company_Details.companyId) {
      this.setState({
        companyDetailsObject: {
          ...Company_Details,
          commercialRegisterNoExpireDate: new Date(Company_Details.commercialRegisterNoExpireDate),
          zakatcertificateExpireDate: new Date(Company_Details.zakatcertificateExpireDate),
          hasOtherAuthorized: Company_Details.representativeName ? true : false,
        },
        selectedCity: {
          label: "Select City *",
          value: Company_Details.cityId,
        },
        selectedDistricts: {
          label: "Select District *",
          value: Company_Details.districtId,
        },
      });

      this.createValidationSchema(this.props.company.isSaudia, Company_Details.representativeName ? true : false);
    } else {
      this.createValidationSchema(false, false);
    }
  }

  animateInputs() {
    // this.animateInputs();

    const inputs = document.querySelectorAll(".input.large");
    inputs.forEach((input) => {
      input.addEventListener(
        "focusin",
        function (e) {
          input.classList.add("focused");
        },
        false
      );
      input.addEventListener("focusout", function (e) {
        if (e.target.value == "") {
          input.classList.remove("focused");
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.cities !== this.state.cities) {
      const value = this.state.selectedCity.value;
      this.setState({
        selectedCity: {
          value: value,
          label: this.state.cities.filter((city) => city.value == value)[0]?.label || "Select City *",
        },
      });
      DataServices.GetDataList(`GetDistrictByCityId?cityId=${this.state.selectedCity.value}`, "title", "id").then(
        (res) => {
          this.setState({
            districts: res,
            isLoading: false,
          });
        }
      );
    }

    if (prevState.districts !== this.state.districts) {
      const value = this.state.selectedDistricts.value;
      this.setState({
        selectedDistricts: {
          value: value,
          label: this.state.districts.filter((district) => district.value == value)[0]?.label || "Select District *",
        },
      });
    }
  }

  selectDistrictHandler(e, setFieldValue) {
    // console.log("====================================");
    // console.log(e);
    // console.log("====================================");
    if (e) {
      this.setState({
        isLoading: true,
      });
      setFieldValue("districtId", e.value);
      this.setState({
        selectedDistricts: e,
        isLoading: false,
      });
    } else {
      this.setState({
        selectedDistricts: { label: " Select District *", value: "0" },
      });
    }
  }

  handleAddDistrictApi = (setFieldValue) => {
    // console.log("====================================");
    // console.log(
    //   this.state.selectedCity.value,
    //   this.state.districtSearchTextObj.title
    // );
    // console.log("====================================");
    axios
      .post(
        `${host}AddDistrict?cityId=${this.state.selectedCity.value}&title=${this.state.districtSearchTextObj.title}`,
        null,
        {
          headers: {
            Authorization: localStorage.getItem("userToken"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        DataServices.GetDataList(`GetDistrictByCityId?cityId=${this.state.selectedCity.value}`, "title", "id").then(
          (res) => {
            this.setState({
              districts: res,
              isLoading: false,
            });
          }
        );
        setFieldValue("districtId", res.data);
        this.setState({
          selectedDistricts: {
            label: this.state.districtSearchTextObj.title,
            value: res.data,
          },
          districtSearchTextObj: {
            id: "",
            title: null,
          },
        });

        console.log(res);
      })
      .catch((err) => console.log(err));

    // make api request use => this.state.districtSearchTextObj?.title
  };

  selectCityHandler(e, setFieldValue) {
    // console.log("====================================");
    // console.log(e);
    // console.log("====================================");
    if (e) {
      this.setState({
        isLoading: true,
      });
      setFieldValue("cityId", e.value);
      this.setState({
        selectedCity: e,
        isLoading: false,
      });
      DataServices.GetDataList(`GetDistrictByCityId?cityId=${e.value}`, "title", "id").then((res) => {
        this.setState({
          districts: res,
          isLoading: false,
        });
      });
    } else {
      this.setState({
        selectedCity: { label: " Select City *", value: "0" },
      });
      DataServices.GetDataList(`GetDistricts`, "title", "id").then((res) => {
        this.setState({
          districts: res,
          isLoading: false,
        });
      });
    }
  }

  handleChangeForDate = (field, e, setFieldValue) => {
    setFieldValue(field, e);
  };

  AddCompanyDetails = (values) => {
    let companyDetailsObject = { ...values };

    this.props.actions.SetLoading();
    companyDetailsObject.companyId = this.props.companyId;
    this.props.actions.AddCompanyDetails(companyDetailsObject);
  };

  handleChangeCheckBox = (field, value, setFieldValue) => {
    setFieldValue(field, value);
    this.createValidationSchema(this.props.company.isSaudia, value);
  };

  handlerVatBlur(e, setFieldError, setFieldTouched, setFieldValue) {
    let vat = e.target.value;
    if (vat) {
      this.setState({
        isLoading: true,
        CheckVatAvailability: true,
      });

      DataServices.GetRowById(`CheckVATAvailability?VatNumber=` + vat).then(async (res) => {
        if (res === false) {
          this.setState({
            CheckVatAvailability: false,
          });
          setFieldValue("vatNo", "");
          await setFieldTouched("vatNo", true);
          await setFieldError("vatNo", "this VAT no. is already registered by another company", false);
        } else {
          this.setState({
            CheckVatAvailability: false,
          });
          setFieldTouched("vatNo", true);
        }
      });
    } else {
      setFieldError("vatNo", "Company Vat number is required", false);
      setFieldTouched("vatNo", true);
    }
  }
  handlerCRNBlur(e, setFieldError, setFieldTouched, setFieldValue) {
    let vat = e.target.value;
    if (vat) {
      this.setState({
        isLoading: true,
        CheckCRNAvailability: true,
      });

      DataServices.GetRowById(`CheckCRNAvailability?CRNNumber=` + vat).then(async (res) => {
        if (res === false) {
          this.setState({
            CheckCRNAvailability: false,
          });
          setFieldValue("commercialRegisterNo", "");
          await setFieldTouched("commercialRegisterNo", true);
          await setFieldError(
            "commercialRegisterNo",
            "this company registration license is already registered by another company",
            false
          );
        } else {
          this.setState({
            CheckCRNAvailability: false,
          });
          setFieldTouched("commercialRegisterNo", true);
        }
      });
    } else {
      setFieldError("commercialRegisterNo", "Company commercial registration no. is required", false);
      setFieldTouched("commercialRegisterNo", true);
    }
  }

  openDatepicker = () => this._calendar.setOpen(true);

  openDatepicker2 = () => this._calendar2.setOpen(true);

  render() {
    return (
      <>
        <Formik
          initialValues={{ ...this.state.companyDetailsObject }}
          validationSchema={this.state.validationSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.AddCompanyDetails(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} noValidate="novalidate" className="proForm">
              <div className="step4">
                <div className="waring_box sh5-med">
                  <img src={Warning_yellow} className="img-fluid icon" alt="warning" />
                  <span>This info needs to be accurate</span>
                </div>

                {this.props.company.isSaudia === true ? (
                  <div className="step_content">
                    <div className="sec_head">
                      <h3 className="h3-med">Company VAT number*</h3>
                      <p className="desc secondary_text">
                        Company VAT number information will be included as an FGC registered vendor, and will be a part
                        of FGC purchase process. This number will be displayed on invoices, POs.
                      </p>
                    </div>
                    <div className="form-group">
                      <div className={`input  large ${values?.vatNo ? `focused` : ""}`}>
                        <label className="control-label" htmlFor="vatNo">
                          Company VAT number*
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className={
                            "form-control" +
                            (errors.vatNo && touched.vatNo
                              ? " has-error"
                              : !errors.vatNo && touched.vatNo
                              ? " has-success"
                              : " ")
                          }
                          id="vatNo"
                          name="vatNo"
                          value={values?.vatNo}
                          onChange={handleChange}
                          onBlur={(e) => {
                            this.handlerVatBlur(e, setFieldError, setFieldTouched, setFieldValue);
                          }}
                        />
                        {this.state?.CheckVatAvailability ? (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : errors.vatNo && touched.vatNo ? (
                          <img src={error_24} className="img-fluid right_icon" alt="" />
                        ) : !errors.vatNo && touched.vatNo ? (
                          <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                        ) : (
                          " "
                        )}
                      </div>

                      <div className="help-text">
                        <ul>
                          {errors.vatNo && touched.vatNo ? (
                            <p className="text has-error">{errors.vatNo}</p>
                          ) : (
                            <p className="text">Must be 15 numbers</p>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div
                      className={
                        "upload_box " +
                        (touched.vatCertificationFile && errors.vatCertificationFile ? " has-error" : " ")
                      }
                    >
                      <h5 className="sh5-med">Upload a copy of company VAT certificate</h5>
                      {/* {console.log(
                        this.state.vatCertificationFile,
                        "four vatCertificationFile"
                      )} */}

                      <Previews
                        load={this.state.load}
                        setLoad={this.setLoad}
                        name="vatCertificationFile"
                        title="VAT Certification"
                        removeFile={() => setFieldValue("vatCertificationFile", null)}
                        fileName={values?.vatCertificationFile}
                        id="vatCertificationFile"
                        errors={errors.vatCertificationFile}
                        touched={touched.vatCertificationFile}
                        folderName="VAT"
                        docType="vat"
                        companyId={this.props.companyId}
                        Api="UploadFiles"
                        handleBlur={() => setFieldTouched("vatCertificationFile")}
                        handleChange={() => setFieldValue("vatCertificationFile", "Done Uploading debug")}
                      />
                    </div>
                  </div>
                ) : null}

                {this.props.company.isSaudia === true && (
                  <div className="step_content">
                    <div className="sec_head">
                      <h3 className="h3-med">Company commercial registration number*</h3>
                      <p className="desc secondary_text">
                        Company registration number information will be included as an FGC registered vendor
                      </p>
                    </div>
                    <div className="form-group">
                      <div className={`input  large ${values?.commercialRegisterNo ? `focused` : ""}`}>
                        <label className="control-label" htmlFor="commercialRegisterNo">
                          Company commercial registration number (CR#)*
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className={
                            "form-control" +
                            (errors.commercialRegisterNo && touched.commercialRegisterNo
                              ? " has-error"
                              : !errors.commercialRegisterNo && touched.commercialRegisterNo
                              ? " has-success"
                              : " ")
                          }
                          id="commercialRegisterNo"
                          name="commercialRegisterNo"
                          value={values?.commercialRegisterNo}
                          onChange={handleChange}
                          onBlur={(e) => {
                            this.handlerCRNBlur(e, setFieldError, setFieldTouched, setFieldValue);
                          }}
                        />
                        {this.state?.CheckCRNAvailability ? (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : errors.commercialRegisterNo && touched.commercialRegisterNo ? (
                          <img src={error_24} className="img-fluid right_icon" alt="" />
                        ) : !errors.commercialRegisterNo && touched.commercialRegisterNo ? (
                          <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                        ) : (
                          " "
                        )}
                      </div>
                      <div className="help-text">
                        <ul>
                          {errors.commercialRegisterNo && touched.commercialRegisterNo ? (
                            <p className="text has-error">{errors.commercialRegisterNo}</p>
                          ) : (
                            <p className="text">Must be 10 numbers</p>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input large populated">
                        <label className="control-label date" htmlFor="commercialRegisterNoExpireDate">
                          Commercial registration certificate expiration date*
                        </label>
                        <DatePicker
                          ref={(c) => (this._calendar = c)}
                          placeholderText="Please select expiration date"
                          dateFormat={"dd/MM/yyyy"}
                          autoComplete="off"
                          showYearDropdown
                          className={
                            "form-control" +
                            (errors.commercialRegisterNoExpireDate && touched.commercialRegisterNoExpireDate
                              ? " has-error"
                              : !errors.commercialRegisterNoExpireDate && touched.commercialRegisterNoExpireDate
                              ? " has-success"
                              : " ")
                          }
                          id="commercialRegisterNoExpireDate"
                          name="commercialRegisterNoExpireDate"
                          selected={values?.commercialRegisterNoExpireDate}
                          onChange={(date) => {
                            this.setState({
                              warnCommercialRegisterNoExpireDate: false,
                              failCommercialRegisterNoExpireDate: false,
                              expiredCommercialRegisterNoExpireDate: false,
                            });
                            const date1 = new Date();
                            const date2 = new Date(date);
                            const diffTime = date2 - date1;
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            if (diffDays < 1) {
                              this.setState({
                                expiredCommercialRegisterNoExpireDate: true,
                              });
                              this.handleChangeForDate("commercialRegisterNoExpireDate", null, setFieldValue);
                            } else if (diffDays < 30) {
                              this.setState({
                                failCommercialRegisterNoExpireDate: diffDays,
                              });
                              this.handleChangeForDate("commercialRegisterNoExpireDate", null, setFieldValue);
                            } else {
                              if (diffDays < 90) {
                                this.setState({
                                  warnCommercialRegisterNoExpireDate: diffDays,
                                });
                              }
                              this.handleChangeForDate("commercialRegisterNoExpireDate", date, setFieldValue);
                            }
                          }}
                          onBlur={() => setFieldTouched("commercialRegisterNoExpireDate", true)}
                        />
                        {errors.commercialRegisterNoExpireDate && touched.commercialRegisterNoExpireDate ? (
                          <img src={error_24} className="img-fluid right_icon2" alt="" />
                        ) : !errors.commercialRegisterNoExpireDate && touched.commercialRegisterNoExpireDate ? (
                          <img src={checkmarkedCircle_24} className="img-fluid right_icon2" alt="" />
                        ) : (
                          " "
                        )}
                        <img
                          src={calendar}
                          className="img-fluid right_icon"
                          alt="calendar"
                          onClick={this.openDatepicker}
                        />
                      </div>
                      <div className="help-text">
                        <ul>
                          {errors.commercialRegisterNoExpireDate && touched.commercialRegisterNoExpireDate ? (
                            <p className="text  has-error">{errors.commercialRegisterNoExpireDate}</p>
                          ) : this.state.expiredCommercialRegisterNoExpireDate ? (
                            <p className="text has-error">{`this document expired, please renew your certificate and re-register`}</p>
                          ) : this.state.failCommercialRegisterNoExpireDate ? (
                            <p className="text has-error">
                              {`this document Will expire in ${this.state.failCommercialRegisterNoExpireDate}  days, expiration date must be at least 30 days beyond the registration date, please renew your certificate and re-register`}
                            </p>
                          ) : this.state.warnCommercialRegisterNoExpireDate ? (
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <img
                                src={Warning_yellow_black}
                                className="img-fluid right_icon2"
                                alt=""
                                style={{ width: "14px", marginRight: "5px" }}
                              />
                              <p className="text" style={{ color: "#000" }}>
                                {`this document Will expire soon in ${this.state.warnCommercialRegisterNoExpireDate} days, don’t forget to renew it soon.`}
                              </p>
                            </div>
                          ) : (
                            <p className="text">
                              Must be valid, with an expiration date at least 30 days beyond the registration date.
                            </p>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div
                      className={
                        "upload_box" +
                        (touched.commercialRegisterNoFile && errors.commercialRegisterNoFile ? " has-error" : " ")
                      }
                    >
                      <h5 className="sh5-med">
                        Upload a copy of company commercial registration number certificate (CR#)
                      </h5>

                      <Previews
                        load={this.state.load}
                        setLoad={this.setLoad}
                        name="commercialRegisterNoFile"
                        title="CR#"
                        id="commercialRegisterNoFile"
                        removeFile={() => setFieldValue("commercialRegisterNoFile", null)}
                        errors={errors.commercialRegisterNoFile}
                        touched={touched.commercialRegisterNoFile}
                        fileName={values?.commercialRegisterNoFile}
                        folderName="CRN"
                        docType="CRN"
                        companyId={this.props.companyId}
                        Api="UploadFiles"
                        handleBlur={() => setFieldTouched("commercialRegisterNoFile")}
                        handleChange={() => setFieldValue("commercialRegisterNoFile", "Done")}
                      />
                    </div>
                  </div>
                )}

                {this.props.company.isSaudia === false && (
                  <div className="step_content">
                    <div className="sec_head">
                      <h3 className="h3-med">Company registration license*</h3>
                      <p className="desc secondary_text">
                        Company registration number information will be included as an FGC registered vendor
                      </p>
                    </div>
                    <div className="form-group">
                      <div className={`input  large ${values?.commercialRegisterNo ? `focused` : ""}`}>
                        <label className="control-label" htmlFor="commercialRegisterNo">
                          Registration number
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className={
                            "form-control" +
                            (errors.commercialRegisterNo && touched.commercialRegisterNo
                              ? " has-error"
                              : !errors.commercialRegisterNo && touched.commercialRegisterNo
                              ? " has-success"
                              : " ")
                          }
                          id="commercialRegisterNo"
                          name="commercialRegisterNo"
                          value={values?.commercialRegisterNo}
                          onChange={handleChange}
                          onBlur={(e) => {
                            this.handlerCRNBlur(e, setFieldError, setFieldTouched, setFieldValue);
                          }}
                        />
                        {this.state?.CheckCRNAvailability ? (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : errors.commercialRegisterNo && touched.commercialRegisterNo ? (
                          <img src={error_24} className="img-fluid right_icon" alt="" />
                        ) : !errors.commercialRegisterNo && touched.commercialRegisterNo ? (
                          <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                        ) : (
                          " "
                        )}
                      </div>
                      <div className="help-text">
                        {errors.commercialRegisterNo && touched.commercialRegisterNo ? (
                          <p className="text has-error">{errors.commercialRegisterNo}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input large populated">
                        <label className="control-label date" htmlFor="commercialRegisterNoExpireDate">
                          Registration license expiration date*
                        </label>
                        <DatePicker
                          ref={(c) => (this._calendar = c)}
                          placeholderText="Please select expiration date"
                          dateFormat={"dd/MM/yyyy"}
                          autoComplete="off"
                          showYearDropdown
                          className={
                            "form-control" +
                            (errors.commercialRegisterNoExpireDate && touched.commercialRegisterNoExpireDate
                              ? " has-error"
                              : !errors.commercialRegisterNoExpireDate && touched.commercialRegisterNoExpireDate
                              ? " has-success"
                              : " ")
                          }
                          id="commercialRegisterNoExpireDate"
                          name="commercialRegisterNoExpireDate"
                          selected={values?.commercialRegisterNoExpireDate}
                          onChange={(date) => {
                            this.setState({
                              warnCommercialRegisterNoExpireDate: false,
                              failCommercialRegisterNoExpireDate: false,
                              expiredCommercialRegisterNoExpireDate: false,
                            });
                            const date1 = new Date();
                            const date2 = new Date(date);
                            const diffTime = date2 - date1;
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            if (diffDays < 1) {
                              this.setState({
                                expiredCommercialRegisterNoExpireDate: true,
                              });
                              this.handleChangeForDate("commercialRegisterNoExpireDate", null, setFieldValue);
                            } else if (diffDays < 30) {
                              this.setState({
                                failCommercialRegisterNoExpireDate: diffDays,
                              });
                              this.handleChangeForDate("commercialRegisterNoExpireDate", null, setFieldValue);
                            } else {
                              if (diffDays < 90) {
                                this.setState({
                                  warnCommercialRegisterNoExpireDate: diffDays,
                                });
                              }
                              this.handleChangeForDate("commercialRegisterNoExpireDate", date, setFieldValue);
                            }
                          }}
                          onBlur={() => setFieldTouched("commercialRegisterNoExpireDate", true)}
                        />
                        <img
                          src={calendar}
                          className="img-fluid right_icon"
                          alt="calendar"
                          onClick={this.openDatepicker}
                        />
                        {errors.commercialRegisterNoExpireDate && touched.commercialRegisterNoExpireDate ? (
                          <img src={error_24} className="img-fluid right_icon2" alt="" />
                        ) : !errors.commercialRegisterNoExpireDate && touched.commercialRegisterNoExpireDate ? (
                          <img src={checkmarkedCircle_24} className="img-fluid right_icon2" alt="" />
                        ) : (
                          " "
                        )}
                      </div>
                      <div className="help-text">
                        <ul>
                          {errors.commercialRegisterNoExpireDate && touched.commercialRegisterNoExpireDate ? (
                            <p className="text  has-error">{errors.commercialRegisterNoExpireDate}</p>
                          ) : this.state.expiredCommercialRegisterNoExpireDate ? (
                            <p className="text has-error">{`this document expired, please renew your certificate and re-register`}</p>
                          ) : this.state.failCommercialRegisterNoExpireDate ? (
                            <p className="text has-error">
                              {`this document Will expire in ${this.state.failCommercialRegisterNoExpireDate}  days, expiration date must be at least 30 days beyond the registration date, please renew your certificate and re-register`}
                            </p>
                          ) : this.state.warnCommercialRegisterNoExpireDate ? (
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <img
                                src={Warning_yellow_black}
                                className="img-fluid right_icon2"
                                alt=""
                                style={{ width: "14px", marginRight: "5px" }}
                              />
                              <p className="text" style={{ color: "#000" }}>
                                {`this document Will expire soon in ${this.state.warnCommercialRegisterNoExpireDate} days, don’t forget to renew it soon.`}
                              </p>
                            </div>
                          ) : (
                            <p className="text">
                              Must be valid, with an expiration date at least 30 days beyond the registration date.
                            </p>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div
                      className={
                        "upload_box" +
                        (touched.commercialRegisterNoFile && errors.commercialRegisterNoFile ? " has-error" : " ")
                      }
                    >
                      <h5 className="sh5-med">Upload a copy of company registration license</h5>

                      <Previews
                        load={this.state.load}
                        setLoad={this.setLoad}
                        name="commercialRegisterNoFile"
                        title="CR#"
                        id="commercialRegisterNoFile"
                        removeFile={() => setFieldValue("commercialRegisterNoFile", null)}
                        errors={errors.commercialRegisterNoFile}
                        fileName={values?.commercialRegisterNoFile}
                        touched={touched.commercialRegisterNoFile}
                        folderName="CRN"
                        docType="CRN"
                        companyId={this.props.companyId}
                        Api="UploadFiles"
                        handleBlur={() => setFieldTouched("commercialRegisterNoFile")}
                        handleChange={() => setFieldValue("commercialRegisterNoFile", "Done")}
                      />
                    </div>
                  </div>
                )}

                {this.props.company.isSaudia === true ? (
                  <div className="step_content">
                    <div className="sec_head">
                      <h3 className="h3-med">Company national address</h3>
                    </div>

                    <div className="form-group">
                      <div className={`input  large ${values?.shortAddress ? `focused` : ""}`}>
                        <label className="control-label" htmlFor="shortAddress">
                          Short address (Optional)
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className={
                            "form-control" +
                            (errors.shortAddress && touched.shortAddress
                              ? " has-error"
                              : values?.shortAddress && !errors.shortAddress && touched.shortAddress
                              ? " has-success"
                              : " ")
                          }
                          id="shortAddress"
                          name="shortAddress"
                          value={values?.shortAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.shortAddress && touched.shortAddress ? (
                          <img src={error_24} className="img-fluid right_icon" alt="" />
                        ) : values?.shortAddress && !errors.shortAddress && touched.shortAddress ? (
                          <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                        ) : (
                          " "
                        )}
                      </div>

                      <div className="help-text">
                        <ul>
                          {errors.shortAddress && touched.shortAddress ? (
                            <p className="text  has-error">{errors.shortAddress}</p>
                          ) : (
                            <div className="row-field">
                              <p className="text">Must be 4 characters then 4 numbers</p>

                              <span className="mytooltip tooltip-effect-1">
                                <span className="tooltip-item">example</span>
                                <span className="tooltip-content clearfix">
                                  <img src={example} />
                                </span>
                              </span>
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="form-group">
                      <Select
                        className={
                          "select_react large " +
                          (errors.cityId && touched.cityId
                            ? " has-error"
                            : !errors.cityId && touched.cityId
                            ? " has-success"
                            : " ")
                        }
                        classNamePrefix="select"
                        placeholder="Select city*"
                        value={
                          this.state?.cities ? this.state?.cities.find((option) => option.value === values.cityId) : ""
                        }
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={this.state.cities}
                        name="cityId"
                        id="cityId"
                        onChange={(e) => {
                          this.selectCityHandler(e, setFieldValue);
                          setFieldValue("zipCode", "");
                          // values?.zipCode
                          this.setState({
                            districtSearchTextObj: {
                              id: "",
                              title: "",
                            },
                          });
                        }}
                        onBlur={() => setFieldTouched("cityId", true)}
                      />
                      <div className="help-text">
                        {errors.cityId && touched.cityId ? <p className="text has-error">{errors.cityId}</p> : null}
                      </div>
                    </div>

                    <div className="sideByside">
                      <div className="form-group">
                        <div className={`input  large ${values?.buildingNo ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="buildingNo">
                            Building no. *
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control" +
                              (errors.buildingNo && touched.buildingNo
                                ? " has-error"
                                : !errors.buildingNo && touched.buildingNo
                                ? " has-success"
                                : " ")
                            }
                            id="buildingNo"
                            name="buildingNo"
                            value={values?.buildingNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.buildingNo && touched.buildingNo ? (
                            <img src={error_24} className="img-fluid right_icon" alt="" />
                          ) : !errors.buildingNo && touched.buildingNo ? (
                            <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                          ) : (
                            " "
                          )}
                        </div>
                        <div className="help-text">
                          <ul>
                            {errors.buildingNo && touched.buildingNo ? (
                              <p className="text  has-error">{errors.buildingNo}</p>
                            ) : (
                              <p className="text">Must be 4 digits</p>
                            )}
                          </ul>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className={`input  large ${values?.street ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="street">
                            Street *
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control" +
                              (errors.street && touched.street
                                ? " has-error"
                                : !errors.street && touched.street
                                ? " has-success"
                                : " ")
                            }
                            id="street"
                            name="street"
                            value={values?.street}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.street && touched.street ? (
                            <img src={error_24} className="img-fluid right_icon" alt="" />
                          ) : !errors.street && touched.street ? (
                            <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                          ) : (
                            " "
                          )}
                        </div>
                        <div className="help-text">
                          {errors.street && touched.street ? <p className="text  has-error">{errors.street}</p> : null}
                        </div>
                      </div>
                    </div>

                    <div className="sideByside">
                      <div className="form-group">
                        <div className={`input  large ${values?.secondryNo ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="secondryNo">
                            Secondary no. *
                          </label>
                          <input
                            autoComplete="off"
                            id="secondryNo"
                            name="secondryNo"
                            type="text"
                            className={
                              "form-control" +
                              (errors.secondryNo && touched.secondryNo
                                ? " has-error"
                                : !errors.secondryNo && touched.secondryNo
                                ? " has-success"
                                : " ")
                            }
                            value={values?.secondryNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.secondryNo && touched.secondryNo ? (
                            <img src={error_24} className="img-fluid right_icon" alt="" />
                          ) : !errors.secondryNo && touched.secondryNo ? (
                            <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                          ) : (
                            " "
                          )}
                        </div>
                        <div className="help-text">
                          <ul>
                            {errors.secondryNo && touched.secondryNo ? (
                              <p className="text has-error">{errors.secondryNo}</p>
                            ) : (
                              <p className="text">Must be 4 digits</p>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="select-district-wrapper select-district-wrapper11">
                          <Select
                            className={
                              "select_react large " +
                              (errors.districtId && touched.districtId
                                ? " has-error"
                                : !errors.districtId && touched.districtId
                                ? " has-success"
                                : " ")
                            }
                            classNamePrefix="select"
                            placeholder="Select district*"
                            value={
                              this.state?.districts && values.districtId !== ""
                                ? this.state?.districts.find((option) => option.value === values.districtId)
                                : ""
                            }
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            options={this.state.districts}
                            name="districtId"
                            id="districtId"
                            onChange={(e) => {
                              this.selectDistrictHandler(e, setFieldValue);
                            }}
                            onBlur={() => setFieldTouched("districtId", true)}
                            onInputChange={(v) => {
                              if (v) {
                                this.setState({
                                  districtSearchTextObj: {
                                    id: "",
                                    title: v,
                                  },
                                });
                              }
                            }}
                          />
                          {this.state.districtSearchTextObj?.title ? (
                            <button
                              onClick={() => this.handleAddDistrictApi(setFieldValue)}
                              title="add new district"
                              type="button"
                              className="add-district-btn"
                            >
                              +
                            </button>
                          ) : null}
                          {values?.districtId ? (
                            <button
                              onClick={() => {
                                setFieldValue("districtId", "");
                                values.districtId = "";

                                // this.setState({
                                //   selectedDistricts: {
                                //     label: "",
                                //     value: "",
                                //   },
                                // });
                                // setFieldTouched("districtId", false)
                                DataServices.GetDataList(
                                  `GetDistrictByCityId?cityId=${this.state.selectedCity.value}`,
                                  "title",
                                  "id"
                                ).then((res) => {
                                  this.setState({
                                    districts: res,
                                    isLoading: false,
                                    selectedDistricts: {
                                      label: " Select District *",
                                      value: "0",
                                    },
                                    districtSearchTextObj: {
                                      label: " Select District *",
                                      value: "0",
                                    },
                                  });
                                });
                              }}
                              title="Remove district"
                              type="button"
                              className="remove-district-btn"
                            >
                              <DeleteOutlined />
                            </button>
                          ) : null}
                        </div>
                        <div className="help-text">
                          {errors.districtId && touched.districtId ? (
                            <p className="text has-error">{errors.districtId}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className={`input  large ${values?.zipCode ? `focused` : ""}`}>
                        <label className="control-label" htmlFor="zipCode">
                          Zip / Postal code *
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className={
                            "form-control" +
                            (errors.zipCode && touched.zipCode
                              ? " has-error"
                              : !errors.zipCode && touched.zipCode
                              ? " has-success"
                              : " ")
                          }
                          id="zipCode"
                          name="zipCode"
                          value={values?.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.zipCode && touched.zipCode ? (
                          <img src={error_24} className="img-fluid right_icon" alt="" />
                        ) : !errors.zipCode && touched.zipCode ? (
                          <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                        ) : (
                          " "
                        )}
                      </div>
                      <div className="help-text">
                        <ul>
                          {errors.zipCode && touched.zipCode ? (
                            <p className="text has-error">{errors.zipCode}</p>
                          ) : (
                            <p className="text">Must be 5 digits</p>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className={"upload_box" + (touched.addressFile && errors.addressFile ? " has-error" : " ")}>
                      <h5 className="sh5-med">Upload a copy of company national address certificate</h5>
                      <Previews
                        load={this.state.load}
                        setLoad={this.setLoad}
                        title="National address"
                        name="addressFile"
                        id="addressFile"
                        removeFile={() => setFieldValue("addressFile", null)}
                        fileName={values?.addressFile}
                        errors={errors.addressFile}
                        touched={touched.addressFile}
                        folderName="addressFile"
                        docType="addressFile"
                        companyId={this.props.companyId}
                        Api="UploadFiles"
                        handleBlur={() => setFieldTouched("addressFile")}
                        handleChange={() => setFieldValue("addressFile", "Done uploading debug")}
                      />
                    </div>
                  </div>
                ) : null}

                {this.props.company.isSaudia === true ? (
                  <div className="step_content">
                    <div className="sec_head">
                      <h3 className="h3-med">Valid zakat certificate*</h3>
                    </div>
                    <div className="form-group">
                      <div className={`input  large ${values?.zakatcertificateNo ? `focused` : ""}`}>
                        <label className="control-label" htmlFor="zakatcertificateNo">
                          Zakat certificate no.*
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className={
                            "form-control" +
                            (errors.zakatcertificateNo && touched.zakatcertificateNo
                              ? " has-error"
                              : !errors.zakatcertificateNo && touched.zakatcertificateNo
                              ? " has-success"
                              : " ")
                          }
                          id="zakatcertificateNo"
                          name="zakatcertificateNo"
                          value={values?.zakatcertificateNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.zakatcertificateNo && touched.zakatcertificateNo ? (
                          <img src={error_24} className="img-fluid right_icon" alt="" />
                        ) : !errors.zakatcertificateNo && touched.zakatcertificateNo ? (
                          <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                        ) : (
                          " "
                        )}
                      </div>
                      <div className="help-text">
                        <ul>
                          {errors.zakatcertificateNo && touched.zakatcertificateNo ? (
                            <p className="text has-error">{errors.zakatcertificateNo}</p>
                          ) : (
                            <p className="text"> Must be 10 numbers.</p>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="input large populated date">
                        <label className="control-label date" htmlFor="expiration_date2">
                          Zakat certificate expiration date*
                        </label>
                        <DatePicker
                          ref={(c) => (this._calendar2 = c)}
                          placeholderText="Please select expiration date"
                          autoComplete="off"
                          dateFormat={"dd/MM/yyyy"}
                          showYearDropdown
                          className={
                            "form-control" +
                            (errors.zakatcertificateExpireDate && touched.zakatcertificateExpireDate
                              ? " has-error"
                              : !errors.zakatcertificateExpireDate && touched.zakatcertificateExpireDate
                              ? " has-success"
                              : " ")
                          }
                          id="zakatcertificateExpireDate"
                          name="zakatcertificateExpireDate"
                          selected={values?.zakatcertificateExpireDate}
                          onChange={(date) => {
                            this.setState({
                              warnZakatcertificateExpireDate: false,
                              failZakatcertificateExpireDate: false,
                              expiredZakatcertificateExpireDate: false,
                            });
                            const date1 = new Date();
                            const date2 = new Date(date);
                            const diffTime = date2 - date1;
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            if (diffDays < 1) {
                              this.setState({
                                expiredZakatcertificateExpireDate: true,
                              });
                              this.handleChangeForDate("zakatcertificateExpireDate", null, setFieldValue);
                            } else if (diffDays < 30) {
                              this.setState({
                                failZakatcertificateExpireDate: diffDays,
                              });
                              this.handleChangeForDate("zakatcertificateExpireDate", null, setFieldValue);
                            } else {
                              if (diffDays < 90) {
                                this.setState({
                                  warnZakatcertificateExpireDate: diffDays,
                                });
                              }
                              this.handleChangeForDate("zakatcertificateExpireDate", date, setFieldValue);
                            }
                          }}
                          onBlur={() => setFieldTouched("zakatcertificateExpireDate", true)}
                        />
                        <img
                          src={calendar}
                          className="img-fluid right_icon"
                          alt="calendar"
                          onClick={this.openDatepicker2}
                        />
                        {errors.zakatcertificateExpireDate && touched.zakatcertificateExpireDate ? (
                          <img src={error_24} className="img-fluid right_icon2" alt="" />
                        ) : !errors.zakatcertificateExpireDate && touched.zakatcertificateExpireDate ? (
                          <img src={checkmarkedCircle_24} className="img-fluid right_icon2" alt="" />
                        ) : (
                          " "
                        )}
                      </div>
                      <div className="help-text">
                        <ul>
                          {errors.zakatcertificateExpireDate && touched.zakatcertificateExpireDate ? (
                            <p className="text  has-error">{errors.zakatcertificateExpireDate}</p>
                          ) : this.state.expiredZakatcertificateExpireDate ? (
                            <p className="text has-error">{`this document expired, please renew your certificate and re-register`}</p>
                          ) : this.state.failZakatcertificateExpireDate ? (
                            <p className="text has-error">
                              {`this document Will expire in ${this.state.failZakatcertificateExpireDate}  days, expiration date must be at least 30 days beyond the registration date, please renew your certificate and re-register`}
                            </p>
                          ) : this.state.warnZakatcertificateExpireDate ? (
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <img
                                src={Warning_yellow_black}
                                className="img-fluid right_icon2"
                                alt=""
                                style={{ width: "14px", marginRight: "5px" }}
                              />
                              <p className="text" style={{ color: "#000" }}>
                                {`this document Will expire soon in ${this.state.warnZakatcertificateExpireDate} days, don’t forget to renew it soon.`}
                              </p>
                            </div>
                          ) : (
                            <p className="text">
                              Must be valid, with an expiration date at least 30 days beyond the registration date.
                            </p>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div
                      className={
                        "upload_box" +
                        (touched.zakatcertificateFile && errors.zakatcertificateFile ? " has-error" : " ")
                      }
                    >
                      <h5 className="sh5-med">Upload a copy of company zakat certificate</h5>
                      <Previews
                        load={this.state.load}
                        setLoad={this.setLoad}
                        title="Zakat Certificate"
                        name="zakatcertificateFile"
                        id="zakatcertificateFile"
                        removeFile={() => setFieldValue("zakatcertificateFile", null)}
                        fileName={values?.zakatcertificateFile}
                        errors={errors.zakatcertificateFile}
                        touched={touched.zakatcertificateFile}
                        folderName="zakaatCertificate"
                        docType="zakaatCertificate"
                        companyId={this.props.companyId}
                        Api="UploadFiles"
                        handleBlur={() => setFieldTouched("zakatcertificateFile")}
                        handleChange={() => setFieldValue("zakatcertificateFile", "Done uploading debug")}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="step_content">
                  <div className="sec_head">
                    <h3 className="h3-med">Is there any other authorized company representative?*</h3>
                    <p className="desc secondary_text">
                      An authorized company representative is a person who is a principal executive officer or other
                      company officers with signatory powers as per the company's by-laws or per a vote of the directors
                      if the company is a corporation, a general partner or proprietor if the company is a partnership
                      or sole proprietorship respectively; or a duly authorized representative
                    </p>
                  </div>
                  <div className="sideByside">
                    <div className="form-group">
                      <div className={"form-check custom " + (values?.hasOtherAuthorized === true ? " selected" : " ")}>
                        <input
                          className="form-check-input rounded-circle"
                          type="radio"
                          name="isAuth"
                          id="isAuthYes"
                          onChange={(e) => this.handleChangeCheckBox("hasOtherAuthorized", true, setFieldValue)}
                          value={values?.hasOtherAuthorized}
                        />
                        <label className="form-check-label" htmlFor="isAuthYes">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div
                        className={"form-check custom " + (values?.hasOtherAuthorized === false ? " selected" : " ")}
                      >
                        <input
                          className="form-check-input rounded-circle"
                          type="radio"
                          name="isAuth"
                          id="isAuthNo"
                          onChange={(e) => this.handleChangeCheckBox("hasOtherAuthorized", false, setFieldValue)}
                          value={values?.hasOtherAuthorized}
                        />
                        <label className="form-check-label" htmlFor="isAuthNo">
                          No, Only company owner
                        </label>
                      </div>
                    </div>
                  </div>

                  {values?.hasOtherAuthorized === true ? (
                    <div className="representative">
                      <div className="form-group">
                        <div className={`input  large ${values?.representativeName ? `focused ` : ""}`}>
                          <label className="control-label" htmlFor="representativeName">
                            Representative full name*
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control" +
                              (errors.representativeName && touched.representativeName
                                ? " has-error"
                                : !errors.representativeName && touched.representativeName
                                ? " has-success"
                                : " ")
                            }
                            id="representativeName"
                            name="representativeName"
                            value={values?.representativeName}
                            onChange={handleChange}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur(e);
                            }}
                          />
                          {errors.representativeName && touched.representativeName ? (
                            <img src={error_24} className="img-fluid right_icon" alt="" />
                          ) : !errors.representativeName && touched.representativeName ? (
                            <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                          ) : (
                            " "
                          )}
                        </div>
                        <div className="help-text">
                          {errors.representativeName && touched.representativeName ? (
                            <p className="text has-error">{errors.representativeName}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group">
                        <div className={`input withIcon  large ${values?.representativePhoneNumber ? `focused` : ""}`}>
                          <label className="control-label" htmlFor="representativePhoneNumber">
                            Phone no. *
                          </label>
                          <img src={Phone} className="img-fluid left_icon" alt="phone" />
                          <input
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control" +
                              (errors.representativePhoneNumber && touched.representativePhoneNumber
                                ? " has-error"
                                : !errors.representativePhoneNumber && touched.representativePhoneNumber
                                ? " has-success"
                                : " ")
                            }
                            id="representativePhoneNumber"
                            name="representativePhoneNumber"
                            value={values?.representativePhoneNumber}
                            onChange={handleChange}
                            onFocus={(e) => e.target.parentElement.classList.add("focused")}
                            onBlur={(e) => {
                              if (e.target.value == "") e.target.parentElement.classList.remove("focused");
                              handleBlur(e);
                            }}
                          />
                          {errors.representativePhoneNumber && touched.representativePhoneNumber ? (
                            <img src={error_24} className="img-fluid right_icon" alt="" />
                          ) : !errors.representativePhoneNumber && touched.representativePhoneNumber ? (
                            <img src={checkmarkedCircle_24} className="img-fluid right_icon" alt="" />
                          ) : (
                            " "
                          )}
                        </div>
                        <div className="help-text">
                          {errors.representativePhoneNumber && touched.representativePhoneNumber ? (
                            <p className="text has-error">{errors.representativePhoneNumber}</p>
                          ) : null}
                        </div>
                      </div>

                      <div
                        className={
                          "upload_box" + (touched.representativeFile && errors.representativeFile ? " has-error" : " ")
                        }
                      >
                        <h5 className="sh5-med">Upload a copy of authorized company representation document</h5>

                        <Previews
                          load={this.state.load}
                          setLoad={this.setLoad}
                          title="Representative Signature"
                          name="representativeFile"
                          id="representativeFile"
                          removeFile={() => setFieldValue("representativeFiles", null)}
                          fileName={values?.representativeFile}
                          errors={errors.representativeFile}
                          touched={touched.representativeFile}
                          folderName="representativeFile"
                          docType="representativeFile"
                          companyId={this.props.companyId}
                          Api="UploadFiles"
                          handleBlur={() => setFieldTouched("representativeFile")}
                          handleChange={() => setFieldValue("representativeFile", "Done")}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form_actions">
                {this.props.isLoading == true || this.state.load ? (
                  <button className="btn primaryBtn largeBtn disabled loading w-100">Loading...</button>
                ) : (
                  <button
                    type="submit"
                    className={
                      "btn primaryBtn largeBtn " +
                      (isSubmitting == true || this.state.load ? " disabled " : " ") +
                      " w-100"
                    }
                    disabled={isSubmitting}
                  >
                    Save and next step
                  </button>
                )}
                <button
                  className="btn textBtn largeBtn icon-l w-100"
                  type="button"
                  onClick={(e) => this.props.actions.backAction()}
                >
                  <img src={back} className="icon" alt="arrow" /> Back
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.registration.user,
    company: state.registration.company,
    companyDetails: state.registration.companyDetails,
    currentStep: state.registration.currentStep,
    activeStep: state.registration.activeStep,
    isLoading: state.registration.isLoading,
    isSaudia: state.registration.isSaudia,
    companyId: state.registration.companyId,

    vatCertificationFile: state.registration.vatCertificationFile,
    commercialRegisterNoFile: state.registration.commercialRegisterNoFile,
    representativeFile: state.registration.representativeFile,
    zakatcertificateFile: state.registration.zakatcertificateFile,
    addressFile: state.registration.addressFile,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepFour);
