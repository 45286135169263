import React, {useState, useEffect, useContext} from "react";
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";

import attached_file from "../Styles/images/settings/attached_file.svg";
import styles from './tac.module.css'
import { SetTabHeaders } from "../Store/actions/registration";
import ProfileCompletness from "../Components/ProfileCompletness/ProfileCompletness";


const TermsAndCon = () => {
	const [privacyPolicy, setPrivacyPolicy] = useState(true)
	const [termsOfUse, setTermsOfUse] = useState(true)

	const dispatch = useDispatch()
	const currentStep = useSelector((state) => state.registration.currentStep);

	useEffect(() => {
		dispatch(SetTabHeaders("Terms and conditions", "", currentStep));
	})

	const handleSubmit = () => {

	}
	
    return (
		<div className={`company_info ${styles.wrapper}`}>
			
			<div className={styles.container}>
				<div className={styles.coc_form}>
				<div className={styles.head}>
					<div className="top">
							<h4 className="title"
								style={{fontWeight: 700, fontSize: '1rem', lineHeight: '1.5rem'}}
							>
									<span>Code of conduct form</span>
							</h4>
					</div>
					<p className="desc"
								style={{fontWeight: 400, fontSize: '0.75rem', lineHeight: '1rem'}}
							>
								You’ve agreed to FGC’s code of conduct successfully
					</p>

					<div className="uploaded_file terms_file">

						
						<Link target="_blank" to='' className="file">
							<img src={attached_file} className="img-fluid icon" alt="icon" />
							<span>CodeOfCon_0234.pdf</span>
						</Link>
						<span className="hint">You can view the file by clicking on it</span>
					</div>
				</div>

				<div className={styles.policy_and_terms}>
					<div className={styles.phead}>
							<h4 className="title"
								style={{fontWeight: 700, fontSize: '1rem', lineHeight: '1.5rem'}}
							>
									<span>Our policy and terms of use</span>
							</h4>
							<p className="desc"
								style={{fontWeight: 400, fontSize: '0.75rem', lineHeight: '1rem'}}
							>
								You’ve agreed to FGC’s code of conduct successfully
							</p>
					</div>
					
					<div className={styles.terms_form}>
						<div className="form-check">
							<input
									className="form-check-input "
									disabled
									type="checkbox"
									value=""
									id="pp_agree"
									onChange={(e)=> setPrivacyPolicy(e.target.checked)}
									checked={privacyPolicy}
							/>
							<label className="form-check-label" htmlFor="pp_agree">
									
									You’ve agreed FGC’s {" "}
									<span>
										<Link to='/PrivacyPolicy'>
												Privacy policy
										</Link>
									</span>
							</label>
						</div>
						<div className="form-check">
							<input
									className="form-check-input "
									disabled
									type="checkbox"
									value=""
									id="tou_agree"
									onChange={(e)=> setTermsOfUse(e.target.checked)}
									checked={termsOfUse}
							/>
							<label className="form-check-label" htmlFor="tou_agree">
									
									You’ve agreed FGC’s {" "}
									<span>
										<Link to='/TermsOfUse'>
												Terms of use
										</Link>
									</span>
									
							</label>
						</div>
					</div>
				</div>
				</div>
				
				<button
					className="btn primaryBtn largeBtn "
					onClick={handleSubmit}
					type="submit"
					disabled
				>
					Save changes
				</button>
				
			</div>
			<ProfileCompletness />
		</div>	
						
    )
}

export default TermsAndCon